import * as React from 'react';
import ShowIf from '../../lib/ShowIf/ShowIf';
import useWizard from '../../hooks/useWizard';
import PageTitle from '../../lib/PageTitle/PageTitle';
import {
  Page,
  PageContent,
  List,
  ListInput,
  ListItem,
  Button,
  Progressbar,
} from 'framework7-react';
import StarRating from '../../lib/StarRating/StarRating';
import ImgUploader from '../../lib/ImgUploader/ImgUploader';
import UploadImgBox from '../../lib/UploadImgBox/UploadImgBox';
import { useParams, useLocation } from 'react-router';
import useBackendApi from '../../hooks/useBackendApi';
import useToggle from '../../hooks/useToggle';
import useSelectItem from '../../hooks/useSelectItem';
import queryString from 'query-string';

interface IRequestReviewProps { }
const steps = ['rating', 'pics'];

const stepsContentMapping = {
  rating: {
    heading: 'Review',
    subheading:
      'Big thank you in advance for taking the time to review my services.',
    cta: 'Next',
  },
  pics: {
    heading: 'A picture is worth a 1,000 words',
    subheading:
      'Would you be able to upload a few pictures of yourself after the makeover.',
    cta: 'Submit',
  },
};

const commentsPlaceholderMapping = {
  1: `What do you feel I should do better?`,
  2: 'What do you feel I should do better?',
  3: 'What do you feel I should do better?',
  4: `Can you leave me a testimonial please`,
  5: `Can you leave me a testimonial please`,
};
const RequestReview: React.FC<IRequestReviewProps> = ({ }) => {
  const { step, stepNumber, next, setStep } = useWizard(steps);
  const { post } = useBackendApi();
  const [userInput, setUserInput] = React.useState<any>({
    score: '',
    comments: '',
    customerName: '',
    date: new Date().toISOString(),
  });
  const { userId } = useParams();
  const [imgs, setImgs] = React.useState<string[]>([]);
  const [buttonDisabled, setButtonDisabled] = React.useState<any>(false);
  const submitDisabled = useToggle();
  const location = useLocation();
  const parsedQuery = queryString.parse(location.search);

  const [uploadingIdx, setUploadingIdx, clearUploadingIdx] = useSelectItem();

  function handleUserInputChange(e: any) {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });
  }

  function handleScoreSelect(score) {
    setUserInput({
      ...userInput,
      score,
    });
  }

  async function handleNext() {
    const { source } = parsedQuery;
    if (
      step === 'rating' &&
      !(userInput.customerName && userInput.score && userInput.comments)
    ) {
      return;
    }
    if (step === 'pics') {
      // submitData

      const review = {
        ...userInput,
        images: imgs,
        userId,
        status: source === 'nishi' ? 'visible' : 'hidden',
        source
      };
      await post('/v1/review', review, { auth: false });
      return setStep('finish');
    }
    next();
  }

  function handleUploadStart(src, index) {
    setUploadingIdx(index);
    submitDisabled.open();
    setButtonDisabled(true);
    const newImgs = imgs.slice();
    newImgs.splice(index, 1, src);
    setImgs(newImgs);
  }

  function handleUploadComplete(e) {
    submitDisabled.close();
    const index = +e.target.name;
    const newImgs = imgs.slice();
    newImgs.splice(index, 1, e.target.value);
    setImgs(newImgs);
    setButtonDisabled(false);
    clearUploadingIdx();
  }

  return (
    <Page pageContent={false}>
      <PageContent className="bg-white flex flex-col">
        <ShowIf condition={step !== 'finish'}>
          <section className="flex-grow p-5">
            <h1 className="mb-2 mt-5">
              <PageTitle>{stepsContentMapping[step]?.heading}</PageTitle>
            </h1>
            <div className="text-lg mb-4">
              {stepsContentMapping[step]?.subheading}
            </div>
            <ShowIf condition={step === 'rating'}>
              <List>
                <ListInput
                  inlineLabel
                  label="Your name"
                  placeholder="Name"
                  name="customerName"
                  value={userInput.customerName}
                  onChange={handleUserInputChange}
                />

                <ListItem>
                  <div className="flex items-center w-full">
                    <div>
                      <div className="font-medium">How did I do</div>
                      <div className="text-gray-300 text-sm">
                        Tap a Star to rate
                      </div>
                    </div>

                    <div className="ml-auto">
                      <StarRating
                        starClassName="ml-3 text-2xl"
                        onSelect={handleScoreSelect}
                        rating={userInput.score}
                      />
                    </div>
                  </div>
                </ListItem>

                {!!userInput.score && (
                  <ListInput
                    inlineLabel
                    onChange={handleUserInputChange}
                    name="comments"
                    placeholder={commentsPlaceholderMapping[userInput.score]}
                    type="textarea"
                    value={userInput.comments}
                  />
                )}
              </List>
            </ShowIf>

            <ShowIf condition={step === 'pics'}>
              <div className="grid grid-cols-3 gap-3 mt-5">
                <div>
                  <ImgUploader
                    name="0"
                    onUploadStart={(src) => handleUploadStart(src, 0)}
                    onUploadComplete={handleUploadComplete}
                  >
                    <UploadImgBox
                      img={imgs[0]}
                      uploading={uploadingIdx === 0}
                    />
                  </ImgUploader>
                </div>
                <div>
                  <ImgUploader
                    name="1"
                    onUploadStart={(src) => handleUploadStart(src, 1)}
                    onUploadComplete={handleUploadComplete}
                  >
                    <UploadImgBox
                      img={imgs[1]}
                      uploading={uploadingIdx === 1}
                    />
                  </ImgUploader>
                </div>
                <div>
                  <ImgUploader
                    name="2"
                    onUploadStart={(src) => handleUploadStart(src, 2)}
                    onUploadComplete={handleUploadComplete}
                  >
                    <UploadImgBox
                      img={imgs[2]}
                      uploading={uploadingIdx === 2}
                    />
                  </ImgUploader>
                </div>
              </div>
            </ShowIf>
          </section>

          <section className="bg-white p-5">
            <Button
              raised
              large
              fill
              onClick={handleNext}
              disabled={buttonDisabled}
            >
              {stepsContentMapping[step]?.cta}
            </Button>
            <div className="mt-3 text-center text-gray-300">
              <p className="mb-2">
                {stepNumber + 1} of {steps.length} steps
              </p>
              <Progressbar progress={(stepNumber / steps.length) * 100} />
            </div>
          </section>
        </ShowIf>

        <ShowIf condition={step === 'finish'}>
          <div className="flex-grow flex flex-col justify-center p-5">
            <h1>
              <PageTitle>Thank you,</PageTitle>
            </h1>
            <h1>
              <PageTitle>thank you,</PageTitle>
            </h1>
            <h1>
              <PageTitle>thank you.</PageTitle>
            </h1>
          </div>
        </ShowIf>
      </PageContent>
    </Page>
  );
};

export default RequestReview;
