import React from 'react';
import { IReviewCardTemplate } from '../../types/reviewCardTemplate';
import classnames from 'classnames';
import Img from '../../lib/Img/Img';
import { Button, Checkbox } from 'framework7-react';

interface ReviewTemplateCardPickerProps {
  templates?: IReviewCardTemplate[];
  type?: string;
  selected?: string;
  onSelect?: (e?: any) => void;
  onDownload?: (e?: any) => void;
}

const ReviewTemplateCardPicker: React.FunctionComponent<ReviewTemplateCardPickerProps> = ({
  templates,
  type,
  selected,
  onSelect,
  onDownload,
}) => {
  function handleDownload() {
    onDownload?.(type);
  }

  return (
    <>
      <div className="my-5 flex flex-no-wrap max-w-screen overflow-x-auto pb-4 w-full">
        {templates
          ?.sort((a, b) => a.order - b.order)
          .map((template) => (
            <React.Fragment key={template.id}>
              {template.type === type && (
                <div className="flex-none w-4/5 md:w-7/16 p-2 ">
                  <div
                    className={classnames(
                      'rounded overflow-hidden border-2 relative shadow',
                      {
                        'border-transparent': selected !== template.id,
                        'border-primary': selected === template.id,
                      }
                    )}
                    onClick={() => onSelect?.(template)}
                  >
                    <Img
                      src={template.img}
                      size={type === 'feed' ? 'square' : '3by4'}
                      cover={false}
                      imgClassName="object-contain"
                    />
                    <div className="absolute right-0 top-0 p-2">
                      <Checkbox checked={template.id === selected} />
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
      </div>
      <div className="py-3 sticky bottom-0 z-10 center bg-white">
        <Button fill large onClick={handleDownload}>
          Generate Review Card
        </Button>
        <div style={{ textAlign: 'center' }}>
          <small>We will email you the card once its generated</small>
        </div>
      </div>
    </>
  );
};
export default ReviewTemplateCardPicker;
