import * as React from 'react';
import {
  Popup,
  Page,
  PageContent,
} from 'framework7-react';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_KEY } from '../../constants';
import ShowIf from '../../lib/ShowIf/ShowIf';
import useBackendApi from '../../hooks/useBackendApi';
import { ISubscription } from '../../types/subscription';
import { ISubItem } from '../../types/subItem';

interface ISubscriptionPopupProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  onSubscribe?: (sub: ISubscription) => void;
  subItem?: ISubItem;
  buttonText?: string;

}
const stripePromise = loadStripe(STRIPE_KEY || '');

const SubscriptionPopup: React.FC<ISubscriptionPopupProps> = ({
  open,
  onClose,
  subItem,
  onSubscribe,
  children,
  buttonText = 'Subscribe'
}) => {
  const { post } = useBackendApi();

  const handleSubscribe = async (token: any) => {
    try {
      const result = await post('/v1/subscription', {
        subItemId: subItem?._id,
        token: token
      });
      onSubscribe?.(result);
    } catch (err) {
      console.log(err);
    }
  }


  return (
    <div >
      <>
        <header className="px-4 mt-3">
          {children}
        </header>
        <section className="flex-grow mt-5 pt-5 flex flex-col">
          <Elements stripe={stripePromise}>
            <CheckoutForm buttonText={buttonText} onTokenCreate={handleSubscribe} />
          </Elements>
        </section>
      </>

    </div>
  );
};

export default SubscriptionPopup;
