import * as React from 'react';
import { Popup, List, ListItem, Icon } from 'framework7-react';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import PageTitle from '../../lib/PageTitle/PageTitle';
import Toggle from '../../lib/Toggle/Toggle';
import useToggle from '../../hooks/useToggle';
import ReminderDaysSetting from '../ReminderDaysSetting/ReminderDaysSetting';

interface IGigsSettingsProps {
  open?: boolean;
  onClose?: (e?: any) => void;
}
const GigsSettings: React.FC<IGigsSettingsProps> = ({ open, onClose }) => {
  const [settings, setSettings] = React.useState<any>({
    eventRemindersEnabled: false,
    reminderBefore: 3,
    template: 'default',
    textRemindersEnabled: false,
  });

  const reminderDaysSetting = useToggle();

  function handleUserInputChange(e: any) {
    let { name, value, checked } = e.target;
    if (name === 'eventRemindersEnabled') {
      value = checked;
    }
    setSettings({ ...settings, [name]: value });
  }

  return (
    <Popup opened={open} onPopupClosed={onClose} animate={false}>
      <PopupNavbar
        sticky
        backIcon
        letfBtnLabel="Back"
        hideRightBtn
        onCancel={onClose}
      />
      <div className="p-4">
        <h2>
          <PageTitle>Settings</PageTitle>
        </h2>

        <List>
          <ListItem>
            <div className="flex-grow">
              <span className="">Event Reminders</span>
              <p className="text-gray-500 text-base">
                Send customers reminders that their event is coming up. This
                will help reduce last minute cancellations.
              </p>
            </div>

            <div className="pl-3 self-start -mr-3">
              <Toggle
                checked={settings.eventRemindersEnabled}
                onChange={handleUserInputChange}
                name="eventRemindersEnabled"
              />
            </div>
          </ListItem>
          {settings.eventRemindersEnabled && [
            <ListItem>
              <span>Send reminder</span>
              <span className="ml-auto text-gray-500 -mr-3 text-base">
                {settings.reminderBefore} days before
              </span>
            </ListItem>,
            <ListItem>
              <span>Template</span>
              <span className="ml-auto text-gray-500 -mr-3 text-base">
                {settings.template}
              </span>
            </ListItem>,
            <ListItem>
              <Icon slot="media">
                <div className="py-1 px-2 bg-gray-200 text-white rounded">
                  <i className="fas fa-comment" />
                </div>
              </Icon>
              <div>
                <span className="text-base">Text reminders</span>
                <p className="text-gray-500">
                  Send customers mobile text message reminders as well. Usage
                  charges apply
                  <br />
                  <div className="text-primary">Learn more...</div>
                </p>
              </div>
              <div className="ml-auto text-gray-500 -mr-3">
                <Toggle />
              </div>
            </ListItem>,
          ]}
        </List>

        {/* <ReminderDaysSetting /> */}
      </div>
    </Popup>
  );
};

export default GigsSettings;
