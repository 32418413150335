import * as React from 'react';
import useUserStore from '../../hooks/useUserStore';
import useSelectItem from '../../hooks/useSelectItem';
import ShowIf from '../../lib/ShowIf/ShowIf';
import Img from '../../lib/Img/Img';
import ImgActionMenu from '../ImgActionMenu/ImgActionMenu';
import ImgUploader from '../../lib/ImgUploader/ImgUploader';
import useBackendApi from '../../hooks/useBackendApi';
import useToast from '../../hooks/useToast';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import GalleryBrowser from '../GalleryBrowser/GalleryBrowser';
import useToggle from '../../hooks/useToggle';
import ProfileImgEditor from '../ProfileImgEditor/ProfileImgEditor';
import ProfileListItem from '../ProfileListItem/ProfileListItem';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import { Page, List, ListInput, Button } from 'framework7-react';
import PageTitle from '../../lib/PageTitle/PageTitle';
import ProfileEditorPopup from '../ProfileEditorPopup/ProfileEditorPopup';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import ProFeature from '../ProFeature/ProFeature';
import ProStar from '../../lib/ProStar/ProStar';
import Toggle from '../../lib/Toggle/Toggle';
import { isProSubscribed } from '../../utils';
import CoverPicsEditor from '../CoverPicsEditor/CoverPicsEditor';
import ImgAutoplay from '../../lib/ImgAutoplay/ImgAutoplay';
import ProLearnMore from '../ProLearnMore/ProLearnMore';
import { useHistory } from 'react-router';

interface IProfileEditorProps {}
const ProfileEditor: React.FC<IProfileEditorProps> = () => {
  const { user, setUser } = useUserStore();
  const { patch } = useBackendApi();
  const { successToast, errorToast } = useToast();
  const proFeature = useToggle();
  const autoplayPreview = useToggle();
  const history = useHistory();

  const [
    selectedOption,
    setSelectedOption,
    clearSelectedOption,
  ] = useSelectItem('');

  async function updateUserData(body) {
    const update = await patch(`/v1/user/${user._id}`, body, {
      onError: (err) => {
        if (err.errors?.codeName === 'DuplicateKey') {
          const kv = err.errors?.keyValue || {};
          const text = Object.keys(kv)
            .map((key) => `${key}: ${kv[key]}`)
            .join(', ');
          errorToast(`Another account with ${text} already exists`);
        } else {
          errorToast(err.message);
        }
      },
    });

    if (update._id) {
      successToast('Success');
      setUser(update);
    }
    clearSelectedOption();
  }

  function redirectToDesign() {
    history.push('/dashboard/preview-site');
  }

  return (
    <>
      <h3 className="mb-4">
        <PageTitle>Information</PageTitle>
      </h3>
      <ProfileImgEditor onSave={updateUserData} />
      <section className="mt-3 -mx-4 border-t border-gray-200">
        <div className="px-3">
          <ProfileListItem
            name="Business"
            value={<div className="truncate">{user.businessName}</div>}
            onClick={setSelectedOption}
          />
          <ProfileListItem
            name="Slogan"
            value={user.slogan}
            onClick={setSelectedOption}
          />
          <ProfileListItem
            name="Bio"
            value={<div className="text-sm">{user.bio}</div>}
            onClick={setSelectedOption}
          />
          <ProfileListItem
            name="Brands"
            value={
              <div className="text-sm">
                {user.kit.map((brand) => brand.name).join(', ')}
              </div>
            }
            onClick={setSelectedOption}
          />

          <div className="mt-8">
            <h3 className="mb-3">
              <SectionTitle size="base">Contact details</SectionTitle>
            </h3>

            <ProfileListItem
              name="Location"
              placeholder="add business address"
              value={
                <ShowIf condition={!!user.location.city}>
                  <div className="text-sm">
                    <p>
                      {!!user.location.street && (
                        <span>{user.location.street}, </span>
                      )}
                      {user.location.city},
                    </p>
                    <p>
                      {user.location.state} {user.location.postcode},{' '}
                      {user.location.country}
                    </p>
                  </div>
                </ShowIf>
              }
              onClick={setSelectedOption}
            />
            <ProfileListItem
              name="Email"
              value={<div className="truncate">{user.email}</div>}
              onClick={setSelectedOption}
            />
            <ProfileListItem
              name="Mobile"
              value={user.mobile}
              onClick={setSelectedOption}
            />
            <ProfileListItem
              name="Instagram"
              value={user.instaUsername}
              onClick={setSelectedOption}
            />

            <ProfileListItem
              name="Facebook"
              value={
                user.facebookPage ? (
                  <div className="text-sm truncate">{user.facebookPage}</div>
                ) : (
                  ''
                )
              }
              placeholder="Add a facebook profile page"
              onClick={setSelectedOption}
            />
          </div>

          <div className="my-8">
            <h3 className="mb-3">
              <SectionTitle size="base">Website</SectionTitle>
            </h3>

            <ProfileListItem
              name="Design"
              value={user.theme?.name || ''}
              onClick={redirectToDesign}
            />
            <ProfileListItem
              name="Website"
              value={<div className="truncate">{user.website}</div>}
              onClick={setSelectedOption}
            />
          </div>

          {/* <div className="my-8">
            <h3 className="mb-3">
              <SectionTitle size="base">Business registration</SectionTitle>
            </h3>

            <ProfileListItem
              name="Terms"
              value={user.terms || 'Enter your terms & conditions'}
              onClick={redirectToDesign}
            />
            <ProfileListItem
              name="ABN"
              value={user.businessNumber}
              placeholder="add ABN"
              onClick={setSelectedOption}
            />
          </div> */}
        </div>
      </section>
      <ProfileEditorPopup
        open={!!selectedOption}
        onClose={clearSelectedOption}
        selectedOption={selectedOption}
        onSave={updateUserData}
      />

      <SlideInModal open={autoplayPreview.on} onClose={autoplayPreview.close}>
        <div className="w-full">
          <h2 className="mb-2">
            <PageTitle>Autoplay Preview</PageTitle>
          </h2>
          <div
            className="relative w-full mb-3 overflow-hidden"
            style={{ maxHeight: '70vh', height: '70vh' }}
          >
            <ImgAutoplay images={user.coverImgs} />
          </div>
          <Button fill large raised onClick={autoplayPreview.close}>
            Close
          </Button>
        </div>
      </SlideInModal>
    </>
  );
};

export default ProfileEditor;
