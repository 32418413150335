import React from 'react';

export default function useWizard(steps: string[] = []) {
  const [step, setStep] = React.useState<any>('');
  const [stepNumber, setStepNumber] = React.useState<number>(0);

  React.useEffect(() => {
    if (steps.length) {
      setStep(steps[0]);
    }
  }, [steps]);

  React.useEffect(() => {
    if (step) {
      setStepNumber(steps.indexOf(step));
    }
  }, [step]);

  function next() {
    if (stepNumber + 1 <= steps.length - 1) {
      setStep(steps[stepNumber + 1]);
    }
  }

  function prev() {
    if (stepNumber >= 0) {
      setStep(steps[stepNumber - 1]);
    }
  }

  // cleanup
  React.useEffect(() => {
    return () => {
      setStep('');
      setStepNumber(0);
    };
  }, []);

  return {
    step,
    setStep,
    next,
    prev,
    stepNumber,
  };
}
