import React, { useContext } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import useToggle from '../../hooks/useToggle';
import useBackendApi from '../../hooks/useBackendApi';
import { EWizardStep } from '../../types/user';
import ShowIf from '../../lib/ShowIf/ShowIf';
import useUserStore from '../../hooks/useUserStore';
import { f7Context } from '../..';
import SetupRoutes from '../SetupRoutes/SetupRoutes';
import { wizardStepsUrlMapping } from '../../static/wizardSteps';
import SetupComplete from '../../pages/SetupComplete/SetupComplete';
import { getTokenData, clearUserDataFromStorage } from '../../utils';
import Profile from '../../pages/Profile/Profile';
import SubscribePro from '../../pages/SubscribePro/SubscribePro';
import EditProfile from '../../pages/EditProfile/EditProfile';
import useNavLinkStore from '../../hooks/useNavLinkStore';
import Gigs from '../../pages/Gigs/Gigs';
import Home from '../../pages/Home/Home';
import Design from '../../pages/Design/Design';
import PreviewSite from '../../pages/PreviewSite/PreviewSite';
import useWizardStepsStore from '../../hooks/useWizardStepsStore';
import { Page, PageContent } from 'framework7-react';
import PhotosPage from '../../pages/PhotosPage/PhotosPage';
import useSubItemsStore from '../../hooks/useSubItemStore';
import ReviewsPage from '../../pages/ReviewsPage/ReviewsPage';
import TabBar from '../../components/TabBar/TabBar';
import TodayPage from '../../pages/TodayPage/TodayPage';
import FeaturePage from '../../pages/FeaturePage/FeaturePage';

interface DashboardRoutesProps {}

function isSetupPath(pathname) {
  return pathname.match('setup');
}
const DashboardRoutes: React.FunctionComponent<DashboardRoutesProps> = ({}) => {
  // the dashboard route holds all the routes after a user has logged in
  // during initialization, this route fetches user data by calling the init() func
  // a preloader spinner is shown until the user details is fetched
  // if the user data exists in the db, the user is allowed to continue
  // else localstorage token is cleared and the user is redirected to the signup page

  // also if the user has not completed the signup process, she is redirected to the setup page
  // where she left off.

  const initialized = useToggle();
  const { user, setUser } = useUserStore();
  const { setSubItems } = useSubItemsStore();
  const { navLinks } = useNavLinkStore();
  const history = useHistory();
  const { setWizardSteps } = useWizardStepsStore();

  const location = useLocation();
  const { f7 } = useContext(f7Context);

  const { get } = useBackendApi();

  /* Fetch subItems */
  async function fetchSubItems() {
    const subItems = await get('/v1/subscription/item');
    setSubItems(subItems);
  }

  async function init() {
    f7.preloader.show();
    const tokenData: any = getTokenData();
    fetchSubItems();
    const data = await get(
      `/v1/user/profile-data/${tokenData.userId}`,
      { email: tokenData.email },
      {
        onError: (err) => {
          if (err === 'could not find the user') {
            clearUserDataFromStorage();
            history.push('/landing');
          }
        },
      }
    );
    setUser(data.user);
    if (data?.user?.wizardStep !== EWizardStep.complete) {
      setWizardSteps(data.wizardSteps);
      history.push(
        wizardStepsUrlMapping[data.user.wizardStep] + location.search
      );

      // redirect to home page if the user has completed the
      // steps but somehow lands on the setup route
    } else if (
      data.user.wizardStep === EWizardStep.complete &&
      isSetupPath(history.location.pathname)
    ) {
      history.push('/dashboard');
    }

    initialized.toggle();
    f7.preloader.hide();
  }

  React.useEffect(() => {
    let didCancel = false;
    if (!didCancel) {
      init();
    }
    return () => {
      didCancel = true;
    };
  }, []);

  return (
    <Page pageContent={false}>
      <PageContent
        style={{
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
        }}
      >
        <ShowIf condition={user.wizardStep === EWizardStep.complete}>
          <TabBar items={navLinks} />
        </ShowIf>
        <section>
          <ShowIf condition={initialized.on}>
            <Switch>
              <Redirect exact from="/dashboard" to="/dashboard/today" />
              <Route exact path="/dashboard/today" component={TodayPage} />
              <Route exact path="/dashboard/home/:action" component={Home} />
              <Route exact path="/dashboard/gigs" component={Gigs} />
              <Route exact path="/dashboard/photos" component={PhotosPage} />
              <Route
                excact
                path="/dashboard/photos/album/:albumId"
                component={PhotosPage}
              />

              <Route path="/dashboard/setup" component={SetupRoutes} />
              <Route
                exact
                path="/dashboard/complete"
                component={SetupComplete}
              />
              <Route exact path="/dashboard/profile" component={Profile} />
              <Route exact path="/dashboard/upgrade" component={SubscribePro} />
              <Route
                exact
                path="/dashboard/edit-profile"
                component={EditProfile}
              />
              <Route exact path="/dashboard/reviews" component={ReviewsPage} />
              <Route
                exact
                path="/dashboard/manage-reviews"
                component={ReviewsPage}
              />
              <Route
                exact
                path="/dashboard/manage-reviews/:action"
                component={ReviewsPage}
              />
              <Route
                exact
                path="/dashboard/profile/:action"
                component={Profile}
              />
              <Route
                exact
                path="/dashboard/feature/:subItemId"
                component={FeaturePage}
              />

              <Route exact path="/dashboard/design" component={Design} />
              <Route
                exact
                path="/dashboard/preview-site"
                component={PreviewSite}
              />
              <Route exact path="/dashboard/preview" component={PreviewSite} />
              <Route
                exact
                path="/dashboard/preview-site/demo/:themeId"
                component={PreviewSite}
              />
            </Switch>
          </ShowIf>
        </section>
        {/* <ShowIf condition={user.wizardStep === EWizardStep.complete}>
          <FooterTabbar items={navLinks} />
        </ShowIf> */}
      </PageContent>
    </Page>
  );
};
export default DashboardRoutes;
