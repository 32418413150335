import { useDispatch, useSelector } from 'react-redux';
import { IAppStore } from '../types/appStore';
import { setUserAction } from '../actions/user';
import { IUser, EWizardStep } from '../types/user';
import { IAppointment } from '../types/appointment';
import { findIndex } from 'lodash';

export default function useUserStore() {
  const dispatch = useDispatch();

  const user: IUser = useSelector((store: IAppStore) => store.user);

  const setUser = (payload: IUser | any) => dispatch(setUserAction(payload));


  const setAppointment = (newApt: IAppointment) => {
    const aptsObj = { ...user?.appointments }; //clone apts object
    const apts = aptsObj[newApt?.date || '']; //find property with same date key
    const updateIdx = findIndex(apts, { _id: newApt?._id });
    apts[updateIdx] = newApt;
    aptsObj[newApt?.date || ''] = apts;
    setUser({ appointments: aptsObj })
  }

  const addAppointment = (newApt: IAppointment) => {
    const date = newApt.date
    const aptsObj = { ...user?.appointments };
    const existingArr = aptsObj[date || ''];
    if (existingArr) {
      aptsObj[date || ''].push(newApt);
    } else {
      aptsObj[date || ''] = [newApt]
    }

    setUser({ appointments: aptsObj })

  }


  const resetUser = () =>
    dispatch(
      setUserAction({
        _id: '',
        email: '',
        businessName: '',
        instaUsername: '',
        kit: [],
        location: {
          city: '',
          formatted: '',
        },
        mobile: '',
        wizardStep: EWizardStep.complete,
        facebookPage: '',
        slogan: '',
      })
    );

  return {
    user,
    setUser,
    resetUser,
    setAppointment,
    addAppointment
  };
}
