import React from 'react';
import { ITestimonyItem } from '../../types/testimonyItem';
import TestimonyCarousel from '../../lib/TestimonyCarousel/TestimonyCarousel';

interface LandingPageTestimoniesProps {}

const LandingPageTestimonies: React.FC<LandingPageTestimoniesProps> = ({}) => {
  const testimonies: ITestimonyItem[] = [
    {
      name: 'Susy Kelly',
      website: 'style-spectrum.com',
      comments:
        'Thankyou for the website, I love it. So simple and perfect. My old one was pages long, and I never could deal with updating it.',
      image:
        'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/FPgV_GkwSc.png',
    },
    {
      name: 'Victoria Wigmore',
      website: 'makeupwithvic.com.au',
      comments:
        'This platform was exactly what I needed to further my business. I had been wanting to create a website for a while and Nishi made everything so simple. Couldn’t be happier with the results!',
      image:
        'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/wCe_ZYKui.png',
    },
    {
      name: 'Indy M Beauty',
      website: 'indymbeauty.com.au',
      comments:
        'I like that Nishi takes care of everything in the business. It setup my site, my Google Business and my Google Ads.',
      image:
        'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/LMudks8oa.png',
    },
    {
      name: 'Intan',
      website: 'stunningbyintan.com',
      comments:
        'I love using Nishi apps because I can send the link to my old or new customer [to] review my work and It will help my business in the future.',
      image:
        'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/ix3DGAwaf.png',
    },
  ];
  return <TestimonyCarousel items={testimonies} />;
};
export default LandingPageTestimonies;
