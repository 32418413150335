import * as React from 'react';

interface ISectionTitleProps {
  size?: string;
  className?: string;
}
const SectionTitle: React.FC<ISectionTitleProps> = ({
  children,
  size = 'xl',
  className = '',
}) => {
  return (
    <span className={`font-semibold text-${size} text-heading ${className}`}>
      {children}
    </span>
  );
};

export default SectionTitle;
