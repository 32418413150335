import * as React from 'react';
import { Button } from 'framework7-react';

interface IBackBtnProps {
  onClick?: (e?: any) => void;
}
const BackBtn: React.FC<IBackBtnProps> = ({ onClick }) => {
  return (
    <Button type="button" className="flex w-auto" onClick={onClick}>
      <i className="far fa-chevron-left text-xl mr-1 mt-px pt-px" />

      <span className="normal-case font-normal text-base">Back</span>
    </Button>
  );
};

export default BackBtn;
