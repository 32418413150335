import React from 'react';
import { IUser } from '../../types/user';
import PageTitle from '../../lib/PageTitle/PageTitle';

interface CustomDomainProps {
  user?: IUser;
  onShare?: (e?: any) => void;
}

const CustomDomain: React.FC<CustomDomainProps> = ({ user }) => {
  return (
    <div>
      <h1 className="mb-3">
        <PageTitle>Link</PageTitle>
      </h1>

      <div className="pb-2 text-base border-b border-gray-200">
        {user?.website}
      </div>
    </div>
  );
};
export default CustomDomain;
