// loader
export const SET_LOADER = 'SET_LOADER';

// requests
export const SET_REQUESTS = 'SET_REQUESTS';

// provider / user
export const SET_USER = 'SET_USER';

// navlink
export const SET_NAV_LINK = 'SET_NAV_LINK';

// wizard steps
export const SET_WIZARD_STEPS = 'SET_WIZARD_STEPS';



// wizard steps
export const SET_SUB_ITEMS = 'SET_SUB_ITEMS';
