import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { List, ListInput, Button } from 'framework7-react';
import './CheckoutForm.scss';
import classnames from 'classnames';
import useToast from '../../hooks/useToast';
import useBackendApi from '../../hooks/useBackendApi';
import useUserStore from '../../hooks/useUserStore';
import { isProSubscribed } from '../../utils';
import PoweredByStripe from '../../lib/PoweredByStripe/PoweredByStripe';

interface CheckoutFormProps {
  buttonText?: string;
  onTokenCreate?: (token) => void;
}

const CheckoutForm: React.FunctionComponent<CheckoutFormProps> = ({ buttonText, onTokenCreate }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState();
  const { user, setUser } = useUserStore();
  const { post } = useBackendApi();
  const { errorToast, catchErrorToast, successToast } = useToast()
  const [stripeInputState, setStripeInputState] = React.useState<string>(
    'initial'
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      //card: ,
      const result: any = await stripe?.createToken(elements?.getElement(CardElement) as any, {
        currency: 'aud'
      })
      if (result?.error) {
        return errorToast(result?.error?.message)
      } else {
        onTokenCreate?.(result.token)

        /* const res = await post('/v1/user/subscribe', { name, token: result.token });
        if (res._id) {
          console.log(res);
          setUser({ subscriptions: [res] })
          return successToast('Successfully subscribed')
        } */
      }
    } catch (err) {
      console.log(err)
      catchErrorToast(err);
    }
  };

  const CARD_OPTIONS: any = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#333',
        color: '#000',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        border: '1px solid black',
        ':-webkit-autofill': { color: '#fce883' },
        '::placeholder': { color: '#8e8e8e', fontSize: '18px' },
      },
      invalid: {
        iconColor: '#ff3b30',
        color: '#ff3b30',
      },
    },
  };

  function handleFocus() {
    setStripeInputState('focused');
  }

  function handleBlur() {
    setStripeInputState('initial');
  }

  return (

    <>

      <form onSubmit={handleSubmit} className="flex-grow flex flex-col">
        <section className="flex-grow">
          <List noHairlines>
            <ListInput
              floatingLabel
              label="Name on card"
              placeholder="Name"
              onChange={e => setName(e.target.value)}
              outline
            />
          </List>
          <div className="px-4 mb-5">
            <div
              className={classnames('CheckoutForm-stripe flex items-center', {
                'CheckoutForm-stripe-focused': stripeInputState === 'focused',
              })}
            >
              <div className="w-full">
                <CardElement
                  options={{ ...CARD_OPTIONS, hidePostalCode: true }}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="mt-2">
              <PoweredByStripe />
            </div>

          </div>
        </section>

        <section className="px-4 text-center mb-5 pb-5">
          <Button
            type="submit"
            fill
            raised
            large
            disabled={!stripe}
            className="focus:outline-none"
          >
            {buttonText || 'Purchase'}
          </Button>
          <div className="mt-2 text-gray-600">Cancel any time for free</div>
        </section>
      </form>
    </>
  );
};
export default CheckoutForm;
