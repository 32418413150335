import * as React from 'react';
import UpgradeToPro from '../../components/UpgradeToPro/UpgradeToPro';
import { useHistory } from 'react-router';

interface ISubscribeProProps {}
const SubscribePro: React.FC<ISubscribeProProps> = ({}) => {
  const history = useHistory();
  return <UpgradeToPro open onClose={history.goBack} />;
};

export default SubscribePro;
