import * as React from 'react';
import { IAppointment } from '../../../types/appointment';
import SectionTitle from '../../../lib/SectionTitle/SectionTitle';
import dayjs from 'dayjs';
import InfoGrid from '../InfoGrid/InfoGrid';
import { timeMapping } from '../../../static/timeMapping';
import { List, ListItem, Icon } from 'framework7-react';

interface IInformationProps {
  appointment?: IAppointment;
}
const Information: React.FC<IInformationProps> = ({ appointment }) => {
  const address = appointment?.location?.formatted || (appointment?.location?.city + ' ' + appointment?.location?.state)
  return (
    <div>
      <InfoGrid className="mb-2" title="Location" icon="fas fa-map-marker-alt">
        <span className="text-primary"> {address}</span>
      </InfoGrid>
      <InfoGrid
        className="mb-2"
        title="Appointment date"
        icon="fas fa-calendar-alt"
      >
        {dayjs(appointment?.date).format('ddd MMM DD, YYYY')}
      </InfoGrid>
      <InfoGrid
        className="mb-2"
        title="Appointment time"
        icon="fal fa-clock"
        border={false}
      >
        {timeMapping[appointment?.time?.from || '']} to{' '}
        {timeMapping[appointment?.time?.to || '']}
      </InfoGrid>
    </div>
  );
};

export default Information;
