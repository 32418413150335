import React from 'react';
import { Button, Checkbox } from 'framework7-react';

interface CheckListItemProps {
  onChange?: () => void;
  onCtaClick?: (e: string) => void;
  checked: boolean;
  title: string;
  subTitle?: string;
  ctaText?: string;
  id?: string;
}

const CheckListItem: React.FunctionComponent<CheckListItemProps> = ({
  checked,
  onChange,
  onCtaClick,
  title,
  subTitle,
  ctaText = 'View',
  id = '',
}) => {
  function handleCtaClick() {
    onCtaClick?.(id);
  }

  return (
    <div
      className="flex items-center px-2 pt-2 mb-1 bg-white"
      onClick={handleCtaClick}
    >
      <div className="w-8 pb-3">
        <Checkbox checked={checked} />
      </div>
      <div className="flex items-center flex-grow pb-3 border-b border-gray-200">
        <div className="flex-grow px-3">
          <div className="text-base">{title}</div>
          <div className="text-gray-500">{subTitle}</div>
        </div>
        <div>
          <Button round className="normal-case bg-gray-100 shadow-xs">
            {ctaText}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default CheckListItem;
