import * as React from 'react';
import { Page, PageContent, List, ListInput, Button } from 'framework7-react';
import useToast from '../../hooks/useToast';
import useBackendApi from '../../hooks/useBackendApi';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import { useHistory } from 'react-router';

interface IForgotPasswordProps {}
const ForgotPassword: React.FC<IForgotPasswordProps> = ({}) => {
  const [userInput, setUserInput] = React.useState<any>({ email: '' });
  const history = useHistory();
  const { post } = useBackendApi();
  const { catchErrorToast, successToast, errorToast } = useToast();
  function handleUserInputChange(e: any) {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });
  }

  async function onForgotClick() {
    try {
      let response = await post('/v1/auth/forgot-password', {
        email: userInput.email,
      });
      if (response === 'success') {
        successToast('An email has been sent to you with reset instructions');
      } else {
        errorToast('Unexpected error has occured');
      }
    } catch (err) {
      console.error(err);
      catchErrorToast(err);
    }
  }

  function back() {
    history.push('/login');
  }

  return (
    <Page pageContent={false}>
      <PageContent className="bg-white">
        <PopupNavbar
          backIcon
          title="Forgot Password"
          className="py-2 shadow-xs"
          letfBtnLabel="Back"
          onCancel={back}
          rightBtnLabel=" "
        />

        <div className="p-4">
          <div className=" mt-5 text-lg px-4">Enter your email</div>
          <div className="text-gray-600 px-4">
            we will send you a password reset link shortly
          </div>
          <List noHairlines className="mt-4">
            <ListInput
              outline
              label="Email"
              floatingLabel
              type="email"
              placeholder="Your Email"
              clearButton
              name="email"
              value={userInput.email}
              onChange={handleUserInputChange}
              className="input-bg-gray mb-4"
            />
          </List>
          <div className="px-4">
            <Button
              fill
              className="w-full"
              large
              raised
              onClick={onForgotClick}
              disabled={!userInput.email}
            >
              Reset Password
            </Button>
          </div>
        </div>
      </PageContent>
    </Page>
  );
};

export default ForgotPassword;
