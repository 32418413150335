import * as React from 'react';
import Script from 'react-load-script';
import { geocodeByAddress } from 'react-places-autocomplete';
import classnames from 'classnames';
import { IAddress } from '../../types/address';
import { ListInput, List } from 'framework7-react';
import './LocationInput.scss';

interface ILocationInputProps {
  value: IAddress;
  label?: string;
  onChange?: (e: { target: { name: string; value: IAddress } }) => void;
  onLocationSelect?: (e: any) => void;
  onClear?: () => void;
  onBlur?: (e: any) => void;
  onKeyUp?: (e: any) => void;
  onFocus?: (e: any) => void;
  autoFocus?: boolean;
  name?: string;
  app?: any;
  types?: string[];
  placeholder?: string;
  id: string;
  disabled?: boolean;
  country?: string;
  size?: string;
  className?: string;
}

class LocationInput extends React.Component<ILocationInputProps> {
  autocomplete: any;
  autoCompleteRef: HTMLInputElement | null = null;

  componentDidMount() {
    console.log(this.props.value);
    if (window['google']) {
      this.handleScriptLoad();
    }
  }

  handleScriptLoad = () => {
    const autoCompleteRef: any = document.getElementById(this.props.id);
    if (autoCompleteRef) {
      // Declare Options For Autocomplete
      const options = {
        types: this.props.types || [],
      };
      const { country } = this.props;

      if (country) {
        options['componentRestrictions'] = { country };
      }
      // Initialize Google Autocomplete
      /*global google*/
      this.autocomplete = new google.maps.places.Autocomplete(
        autoCompleteRef,
        options
      );
      // Fire Event when a suggested name is selected
      this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
    }
  };

  // componentWillUnmount() {
  //   document
  //     .querySelectorAll('.pac-container')
  //     .forEach(entry => entry.remove());
  // }

  // componentDidUpdate(oldProps) {
  //   console.log({ oldProps });
  // }

  handlePlaceSelect = async () => {
    // Extract City From Address Object
    let addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;
    let postcode;
    let state;
    let streetNo = '';
    let street = '';
    let city;
    let country;

    if (address && address.length) {
      for (let a of address) {
        if (a.types.includes('country')) {
          country = a.short_name;
        }
        if (a.types.includes('street_number')) {
          streetNo = a.short_name;
        }
        if (a.types.includes('route')) {
          street = a.short_name;
        }
        if (a.types.includes('postal_code')) {
          postcode = a.short_name;
        }
        if (a.types.includes('administrative_area_level_1')) {
          state = a.short_name;
        }
        if (a.types.includes('locality')) {
          city = a.short_name;
        }
      }
    }
    if (!address && addressObject.name) {
      let addressToGeocode = addressObject.name;
      let results = await geocodeByAddress(addressToGeocode);
      if (results.length) {
        addressObject = results[0];
        for (let a of results[0].address_components) {
          if (a.types.includes('country')) {
            country = a.short_name;
          }
          if (a.types.includes('street_number')) {
            streetNo = a.short_name;
          }
          if (a.types.includes('route')) {
            street = a.short_name;
          }
          if (a.types.includes('postal_code')) {
            postcode = a.short_name;
          }
          if (a.types.includes('administrative_area_level_1')) {
            state = a.short_name;
          }
          if (a.types.includes('locality')) {
            city = a.short_name;
          }
        }
      }
    }

    const newAddress: IAddress = {
      street: `${streetNo} ${street}`.trim(),
      city,
      state,
      postcode,
      country,
      formatted: addressObject.formatted_address,
      lat: addressObject.geometry.location.lat(),
      lng: addressObject.geometry.location.lng(),
    };
    this.props.onLocationSelect && this.props.onLocationSelect(newAddress);
    this.propagateChange(newAddress);
  };

  handleInputChange = (e: any) => {
    const address: IAddress = {
      ...this.props.value,
      formatted: e.target.value,
    };
    this.props.onChange &&
      this.props.onChange({
        target: {
          name: e.target.name || 'locationAutocomplete',
          value: address,
        },
      });
  };

  handleClear = () => {
    this.propagateChange({
      street: '',
      city: '',
      state: '',
      postcode: '',
      country: '',
      formatted: '',
      lat: undefined,
      lng: undefined,
    });
  };

  propagateChange = (value: IAddress) => {
    this.props.onChange &&
      this.props.onChange({
        target: {
          name: this.props.name || 'locationAutocomplete',
          value,
        },
      });
  };

  render() {
    return (
      <>
        <List className="m-0" noHairlines>
          <ListInput
            label={this.props.label}
            floatingLabel
            outline
            inputId={this.props.id || this.props.name || 'location'}
            type="text"
            className={classnames(`-mx-4 ${this.props.className}`)}
            placeholder={this.props.placeholder || 'Location'}
            onChange={this.handleInputChange}
            onBlur={(e) =>
              this.props.onBlur && this.props.onBlur(this.props.value)
            }
            name={this.props.name || 'locationAutocomplete'}
            value={this.props.value.formatted || ''}
            disabled={this.props.disabled}
            autocomplete="off"
            autocorrect="off"
            autocapitalize="none"
            spellcheck="false"
          />
        </List>
        {!window['google'] && (
          <Script
            url="https://maps.googleapis.com/maps/api/js?key=AIzaSyBvDA0hOnuN-KijkcT9IFYapOH9042QSso&libraries=places"
            onLoad={this.handleScriptLoad}
          />
        )}
      </>
    );
  }
}
export default LocationInput;
