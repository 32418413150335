import React from 'react';
import './StarRating.scss';

interface StarRatingProps {
  rating?: number;
  outlineStarClassName?: string;
  starClassName?: string;
  onSelect?: (e?: any) => void;
  fillColor?: string;
}
const StarRating: React.FC<StarRatingProps> = ({
  rating = 0,
  starClassName = '',
  onSelect,
  fillColor,
}) => {
  return (
    <span
      className="StarRating inline-flex"
      style={{ '--fill-color': fillColor } as React.CSSProperties}
    >
      {[1, 2, 3, 4, 5].map((num) => (
        <span
          key={num}
          className={'star ' + starClassName}
          style={getFill(num, rating)}
          onClick={() => onSelect?.(num)}
        />
      ))}
    </span>
  );
};
function getFill(num: number, rating: number) {
  let fill = '0%';
  if (rating >= num) {
    fill = '100%';
  }
  const flooredRating = Math.floor(rating);
  if (flooredRating === num - 1 && flooredRating < rating) {
    fill = (rating % 1) * 100 + '%';
  }

  return {
    '--fill': fill,
  } as React.CSSProperties;
}
export default StarRating;
