import React from 'react';
import { ISubItem } from '@sprajwal078/nishi-types';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import { currencySymbolMapping } from '../../static/currencyMapping';
import { formatCentsToReadable } from '../../utils';

interface IFeaturePreviewInfoProps {
  item?: any;
}
const FeaturePreviewInfo: React.FC<IFeaturePreviewInfoProps> = ({ item }) => {
  return (
    <div className="text-base">
      <h3 className="mb-3">
        <SectionTitle size="2xl">Information</SectionTitle>
      </h3>

      <div className="flex pb-2 mb-2 border-b border-gray-200">
        <div className="flex-grow text-gray-400">Cost</div>
        <span>
          {!!item?.price
            ? '$' + formatCentsToReadable(item?.price)
            + `${item.interval ? `/${item?.interval}` : ''}`
            : 'Free'}
        </span>
      </div>
      {item?.priceDesc &&
        <div className="flex pb-2 mb-2 border-b border-gray-200">
          <div className="flex-grow text-gray-400">Pricing Info</div>
          <span>
            {item.priceDesc}
          </span>
        </div>
      }


      <div className="flex pb-2 mb-2 border-b border-gray-200">
        <div className="flex-grow text-gray-400">Category</div>
        <span>{item?.category}</span>
      </div>
    </div>
  );
};

export default FeaturePreviewInfo;
