import * as React from 'react';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import { List, ListInput, Button } from 'framework7-react';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import useUserStore from '../../hooks/useUserStore';
import { sendEmail } from '../../utils';
import loaderReducer from '../../reducers/loader.reducer';
import useLoaderStore from '../../hooks/useLoaderStore';
import useToast from '../../hooks/useToast';
interface ISupportModalProps {
  open?: boolean;
  onClose?: (e?: any) => void;
}
const SupportModal: React.FC<ISupportModalProps> = ({ open, onClose }) => {
  const { user } = useUserStore();
  const { hideLoader, showLoader } = useLoaderStore();
  const { successToast } = useToast();

  const [userInput, setUserInput] = React.useState<any>({
    subject: 'Need Help',
    message: '',
  });

  function handleUserInputChange(e: any) {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });
  }

  async function handleSend() {
    showLoader();
    const res = await sendEmail(
      'hey@flayr.io',
      userInput.subject,
      `
		 	<p style="white-space: pre-wrap;overflow-wrap: break-word;">
${userInput.message}
				<br/>
			</p>
			<p>
				email: ${user.email} <br/>
				id: ${user._id} 
			</p>
		`
    );
    hideLoader();
    successToast('Email sent. We will get back to you shortly');
    onClose?.();
  }

  return (
    <SlideInModal open={open} onClose={onClose}>
      <div className="w-full p-2">
        <h2>
          <SectionTitle>Support</SectionTitle>
        </h2>
        <List noHairlines>
          <ListInput
            floatingLabel
            outline
            value={userInput.subject}
            onChange={handleUserInputChange}
            name="subject"
            label="Subject"
          />

          <ListInput
            floatingLabel
            outline
            type="textarea"
            resizable
            value={userInput.message}
            name="message"
            label="Your message"
            onChange={handleUserInputChange}
          />
        </List>

        <div className="px-4">
          <Button
            raised
            fill
            large
            disabled={!(userInput.subject && userInput.message)}
            onClick={handleSend}
          >
            Send
          </Button>
        </div>
      </div>
    </SlideInModal>
  );
};

export default SupportModal;
