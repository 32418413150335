import * as React from 'react';
import { ActionsButton, ActionsGroup, Actions } from 'framework7-react';
import ShowIf from '../../lib/ShowIf/ShowIf';
import BtnContent from '../../lib/BtnContent/BtnContent';
import { IoIosPhotos } from 'react-icons/io';
import ImgUploader from '../../lib/ImgUploader/ImgUploader';

interface IImgActionMenuProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  onMenuSelect?: (e: 'upload' | 'browseGallery' | 'getFromInsta') => void;
  hideCancelBtn?: boolean;
  enableInsta?: boolean;
  hideGalleryOption?: boolean;
  builtInImageUploader?: boolean;
  onUploadStart?: (e?: any) => void;
  onUploadComplete?: (e?: any) => void;
  enableMultiplImages?: boolean;
  enableImageEditor?: boolean;
  enablePreviewUploadingImages?: boolean;
  extension?: React.ReactNode | React.ReactNodeArray;
  extensionOrder?: 'first' | 'last';
}
const ImgActionMenu: React.FC<IImgActionMenuProps> = ({
  open,
  onClose,
  onMenuSelect,
  hideCancelBtn,
  enableInsta,
  hideGalleryOption,
  builtInImageUploader,
  onUploadComplete,
  onUploadStart,
  enableImageEditor,
  enableMultiplImages,
  enablePreviewUploadingImages,
  extension,
  extensionOrder = 'first',
}) => {
  return (
    <Actions opened={open} onActionsClosed={onClose}>
      <ActionsGroup>
        {extensionOrder === 'first' && extension}
        <ShowIf
          condition={builtInImageUploader}
          elseTemplate={
            <ActionsButton onClick={() => onMenuSelect?.('upload')}>
              <BtnContent icon={<i className="text-2xl fas fa-camera" />}>
                Upload photo
              </BtnContent>
            </ActionsButton>
          }
        >
          <ActionsButton>
            <ImgUploader
              multiple={enableMultiplImages}
              onUploadStart={onUploadStart}
              onUploadComplete={onUploadComplete}
              enableEditor={enableImageEditor}
              previewUploadingImage={enablePreviewUploadingImages}
            >
              <BtnContent icon={<i className="text-2xl fas fa-camera" />}>
                Upload photo{enableMultiplImages && 's'}
              </BtnContent>
            </ImgUploader>
          </ActionsButton>
        </ShowIf>

        {enableInsta && (
          <ActionsButton onClick={() => onMenuSelect?.('getFromInsta')}>
            <BtnContent
              icon={<i className="relative text-2xl fab fa-instagram" />}
            >
              Get from Instagram
            </BtnContent>
          </ActionsButton>
        )}

        {!hideGalleryOption && (
          <ActionsButton onClick={() => onMenuSelect?.('browseGallery')}>
            <BtnContent icon={<IoIosPhotos className="text-2xl" />}>
              Choose from gallery
            </BtnContent>
          </ActionsButton>
        )}

        {extensionOrder === 'last' && extension}
      </ActionsGroup>
      <ShowIf condition={!hideCancelBtn}>
        <ActionsGroup>
          <ActionsButton bold>Cancel</ActionsButton>
        </ActionsGroup>
      </ShowIf>
    </Actions>
  );
};

export default ImgActionMenu;
