import * as React from 'react';
import { compress } from 'image-conversion';
import { fileToDataURI, uploadSingleImage, dataURItoBlob } from '../../utils';
import ShowIf from '../ShowIf/ShowIf';
import { Progressbar, Page } from 'framework7-react';
import useLoaderStore from '../../hooks/useLoaderStore';
import useSelectItem from '../../hooks/useSelectItem';
import SlideInModal from '../SlideInModal/SlideInModal';
import ImgEditor from '../ImgEditor/ImgEditor';
import Navbar from '../Navbar/Navbar';
import BackBtn from '../BackBtn/BackBtn';
import SectionTitle from '../SectionTitle/SectionTitle';

interface IImgUploaderProps {
  name?: string;
  className?: string;
  id?: string;
  hideProgress?: boolean;
  onUploadStart?: (e?: any) => void;
  onUploadComplete?: (e?: any) => void;
  onProgressChange?: (e?: any) => void;
  enableEditor?: boolean;
  multiple?: boolean;
  previewUploadingImage?: boolean;
}

function compressImg(file) {
  const sizeInMB = Math.floor(file.size / 1024 ** 2);
  let compression = 1;
  if (sizeInMB > 0) {
    compression = 1 / (sizeInMB * 2);
  }
  return compress(file, compression);
}
const ImgUploader: React.FC<IImgUploaderProps> = ({
  onUploadStart,
  onUploadComplete,
  onProgressChange,
  className = '',
  id = '',
  name = '',
  children,
  hideProgress,
  multiple,
  previewUploadingImage,
  enableEditor,
}) => {
  const [uploadProgress, setUploadProgress] = React.useState<number>(0);
  const { showLoader, hideLoader } = useLoaderStore();
  const [fileToEdit, setFileToEdit, clearFileToEdit] = useSelectItem();

  async function handleFileUpload(e: any) {
    if (!multiple) {
      if (!e || !e.target.files[0]) {
        return;
      }

      let file: File = e.target.files[0];

      if (enableEditor) {
        setFileToEdit(file);
        return;
      }
      const url = await fileToDataURI(file);
      onUploadStart?.(url);
      compressAndUploadImg(file);
    } else {
      showLoader();
      const srcs: any[] = [];
      const files = e.target.files;
      if (previewUploadingImage) {
        const urls: any = [];
        for (const file of files) {
          const url = await fileToDataURI(file);
          urls.push(url);
        }
        onUploadStart?.(urls);
      }
      for (const file of files) {
        if (file.type !== 'image/gif') {
          const compressed: any = await compressImg(file);
          const uploaded = await uploadSingleImage(compressed);
          srcs.push(uploaded.data);
        } else {
          const uploaded = await uploadSingleImage(file);
          srcs.push(uploaded.data);
        }
      }
      hideLoader();
      onUploadComplete?.({
        target: { name, value: srcs },
      });
    }
  }

  async function compressAndUploadImg(file) {
    try {
      if (file.type !== 'image/gif') {
        file = await compressImg(file);
      }
      const res = await uploadSingleImage(file, {
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
          onProgressChange?.(percentCompleted);
        },
      });
      const value = res.data;
      onUploadComplete?.({
        target: { name, value },
      });
      setUploadProgress(0);
    } catch (err) {
      console.error(err);
    }
  }

  function handleUploadAfterEdit(url) {
    clearFileToEdit();
    onUploadStart?.(url);
    const file = dataURItoBlob(url);
    compressAndUploadImg(file);
  }

  return (
    <div className={'relative ' + className}>
      {children}
      <input
        className="absolute top-0 left-0 z-10 w-full h-full opacity-0 appearance-none"
        type="file"
        onChange={handleFileUpload}
        name={name}
        id={id || name}
        accept="image/png, image/jpeg, image/gif"
        multiple={multiple}
      />
      <ShowIf
        condition={
          !hideProgress && (!!uploadProgress || uploadProgress === 100)
        }
      >
        <div className="absolute bottom-0 left-0 w-full px-2">
          <Progressbar progress={uploadProgress} />
        </div>
      </ShowIf>

      <ShowIf condition={enableEditor}>
        <SlideInModal
          open={!!fileToEdit}
          onClose={clearFileToEdit}
          fullScreen
          direction="right"
        >
          <Page>
            <Navbar
              leftContent={<BackBtn onClick={clearFileToEdit} />}
              centerConetnt={
                <h3>
                  <SectionTitle>Edit Image</SectionTitle>
                </h3>
              }
              rightContent={<div>&nbsp;</div>}
            />

            <ImgEditor file={fileToEdit} onUpload={handleUploadAfterEdit} />
          </Page>
        </SlideInModal>
      </ShowIf>
    </div>
  );
};

export default ImgUploader;
