import * as React from 'react';
import { IReview } from '../../types/review';
import classnames from 'classnames';
import Img from '../../lib/Img/Img';
import StarRating from '../../lib/StarRating/StarRating';
import ShowIf from '../../lib/ShowIf/ShowIf';
import PaintBrushStroke from './PaintBrushStroke/PaintBrushStroke';
import { IReviewCardTemplate } from '../../types/reviewCardTemplate';
import CollectedByNishi from '../CollectedByNishi/CollectedByNishi';
import './ReviewStoryTemplateCard.scss';

interface IReviewStoryTemplateCardProps {
  review?: IReview;
  logo?: string;
  template?: IReviewCardTemplate;
  onImgLoaded?: (e?: any) => void;
  source?: string;
}

const ReviewStoryTemplateCard: React.FC<IReviewStoryTemplateCardProps> = ({
  review,
  logo,
  template,
  onImgLoaded,
  source = 'nishi',
}) => {
  const [requiredItems, setRequiredItems] = React.useState<string[]>([]);
  const [loadedImgs, setLoadedImgs] = React.useState<any>({
    logo: false,
    img1: false,
    img2: false,
  });

  function handleImgLoaded(e) {
    const name = e.target.id;
    setLoadedImgs({ ...loadedImgs, [name]: true });
  }

  React.useEffect(() => {
    const newRequired: string[] = [];
    if (template?.logo) {
      newRequired.push('logo');
    }
    if (template?.img1) {
      newRequired.push('img1');
    }
    if (template?.img2) {
      newRequired.push('img2');
    }

    setRequiredItems([...newRequired]);
  }, [template]);

  React.useEffect(() => {
    const requiredFulfilled = !requiredItems.some((req) => !loadedImgs[req]);

    if (requiredFulfilled) {
      onImgLoaded?.();
    }
  }, [loadedImgs]);

  function getImgSize() {
    if (template?.templateId === 'story-1') {
      return '3by4';
    }

    return undefined;
  }

  return (
    <div
      className={classnames(
        'ReviewStoryTemplateCard',
        `ReviewStoryTemplateCard-${template?.templateId}`,
        `ReviewStoryTemplateCard-theme-${template?.theme}`
      )}
    >
      <div className="w-screen h-screen ReviewStoryTemplateCard-bg">
        {/* show stars if no logo for story-2 template  */}
        <ShowIf
          condition={template?.templateId === 'story-2' && !template.logo}
        >
          <div className="text-2xl ReviewStoryTemplateCard-logo">
            <div className="text-center">
              <StarRating
                rating={5}
                starClassName="mr-2 text-current"
                fillColor="currentColor"
              />
              <p className="text-lg">5 out of 5</p>
            </div>
          </div>
          <div className="text-2xl ReviewStoryTemplateCard-logo">
            <div className="text-center">
              <StarRating
                rating={5}
                starClassName="mr-2 text-current"
                fillColor="currentColor"
              />
              <p className="text-lg">5 out of 5</p>
            </div>
          </div>
        </ShowIf>
        <ShowIf condition={template?.logo}>
          <div className="ReviewStoryTemplateCard-logo">
            <Img
              src={`https://api-live.flayr.me/v1/util/route-image?url=` + logo}
              imgId="logo"
              size="64x64"
              className="rounded-full"
              onImgLoaded={handleImgLoaded}
              crossOrigin="anonymous"
            />
          </div>
        </ShowIf>
        <ShowIf condition={template?.templateId === 'story-2' && template.logo}>
          <div className="ReviewStoryTemplateCard-logo">
            <Img
              src={`https://api-live.flayr.me/v1/util/route-image?url=` + logo}
              imgId="logo2"
              size="64x64"
              className="rounded-full"
              onImgLoaded={handleImgLoaded}
              crossOrigin="anonymous"
            />
          </div>
        </ShowIf>
        {/* empty shell for logo to fix grid spacing if no logo on  story card 4 */}
        <ShowIf
          condition={template?.templateId === 'story-4' && !template.logo}
        >
          <div className="ReviewStoryTemplateCard-logo"></div>
        </ShowIf>

        <div className="text-2xl uppercase ReviewStoryTemplateCard-heading">
          customer love
        </div>
        <div className="ReviewStoryTemplateCard-rating">
          <StarRating
            rating={5}
            starClassName="text-white ml-1"
            fillColor="currentColor"
          />
        </div>

        <div className="ReviewStoryTemplateCard-comments">
          <span>{review?.comments}</span>
        </div>
        <div className="text-base uppercase ReviewStoryTemplateCard-customer">
          <span>{review?.customerName}</span>
        </div>

        <ShowIf condition={template?.img1}>
          <div className="ReviewStoryTemplateCard-image">
            <ShowIf condition={template?.templateId === 'story-1'}>
              <PaintBrushStroke className="px-8 brush-stroke" />
              <PaintBrushStroke className="px-8 brush-stroke" />
            </ShowIf>
            <Img
              src={
                `https://api-live.flayr.me/v1/util/route-image?url=` +
                review?.images?.[0]
              }
              size={getImgSize()}
              imgId="img1"
              onImgLoaded={handleImgLoaded}
              crossOrigin="anonymous"
            />
          </div>
        </ShowIf>
        <ShowIf condition={template?.img2}>
          <div className="ReviewStoryTemplateCard-image">
            <Img
              src={
                `https://api-live.flayr.me/v1/util/route-image?url=` +
                review?.images?.[1]
              }
              imgId="img2"
              size={getImgSize()}
              onImgLoaded={handleImgLoaded}
              crossOrigin="anonymous"
            />
          </div>
        </ShowIf>
        <div className="text-base text-center ReviewStoryTemplateCard-verified-by">
          <div
            className={classnames({
              'w-full flex justify-end': template?.templateId === 'story-3',
              'pr-6': template?.templateId === 'story-3' && !template.logo,
              'text-black': template?.theme === 'light',
              'text-white': template?.theme === 'dark',
            })}
          >
            {source === 'nishi' && (
              <div
                className={classnames({
                  'w-2/5': template?.templateId === 'story-3',
                  // 'w-2/6 mx-auto': ,
                  'w-2/6 mx-auto': ['story-1', 'story-2', 'story-4'].includes(
                    template?.templateId || ''
                  ),
                })}
              >
                <CollectedByNishi />
              </div>
            )}
            {source === 'kudosninja' && (
              <div className="inline-block px-4 py-1 text-center border-2 border-current rounded-lg">
                <div className="text-sm">collected by</div>
                <div className="text-lg font-semibold">Kudos Ninja</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewStoryTemplateCard;
