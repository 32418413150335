import * as React from 'react';
import ShowIf from '../../../lib/ShowIf/ShowIf';

interface IInfoGrid {
  title?: string;
  icon?: React.ReactChild;
  className?: string;
  border?: boolean;
}
const InfoGrid: React.FC<IInfoGrid> = ({
  title = '',
  icon,
  children,
  className = '',
  border = true,
}) => {
  return (
    <section className={`grid grid-cols-8 gap-3 ${className}`}>
      <div className="col-span-1 flex-center">
        <span className="text-xl text-primary">
          <ShowIf condition={typeof icon === 'string'} elseTemplate={icon}>
            <i className={icon as string} />
          </ShowIf>
        </span>
      </div>
      <div
        className={`col-span-7 ${
          border ? 'border-b border-gray-200 pb-2' : ''
        }`}
      >
        <div className="text-base">{title}</div>
        <p className="text-gray-500 pr-8">{children}</p>
      </div>
    </section>
  );
};

export default InfoGrid;
