import React from 'react';
import './Toggle.scss';
import classnames from 'classnames';

interface ToggleProps {
  name?: string;
  value?: string;
  color?: string;
  checked?: boolean;
  id?: string;
  onChange?: (e: any) => void;
}

const Toggle: React.FunctionComponent<ToggleProps> = ({
  color = 'green',
  onChange,
  name,
  value,
  checked,
  id,
}) => {
  return (
    <label htmlFor={id} className={classnames('toggle', `color-${color}`)}>
      <input
        type="checkbox"
        className="appearance-none opacity-0 h-0 w-0"
        onChange={onChange}
        id={id}
        name={name}
        value={value}
        checked={checked}
      />
      <span className="toggle-icon" />
    </label>
  );
};
export default Toggle;
