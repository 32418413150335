import * as React from 'react';
import { Page, PageContent, Button, Progressbar } from 'framework7-react';
import Img, { ImgSizes } from '../../lib/Img/Img';
import ShowIf from '../../lib/ShowIf/ShowIf';
import classnames from 'classnames';

interface ISetupWizardLayoutProps {
  img?: ImgSizes;
  topSection?: React.ReactNode;
  imgSize?: string;
  stepNum?: number;
  totalSteps?: number;
  title?: string;
  subtitle?: string;
  disabled?: boolean;
  ctaText?: string;
  hideContent?: boolean;
  skipabble?: boolean;
  onNext?: (e?: any) => void;
  onSkip?: (e?: any) => void;
}
const SetupWizardLayout: React.FC<ISetupWizardLayoutProps> = ({
  img,
  imgSize = '128x128',
  stepNum,
  totalSteps,
  ctaText = 'Next',
  title,
  subtitle,
  disabled,
  onNext,
  children,
  topSection,
  hideContent,
  skipabble,
  onSkip,
}) => {
  const [completePercentage, setCompletePercentage] = React.useState<number>(0);

  React.useEffect(() => {
    if (stepNum && totalSteps) {
      const percentage = (stepNum / totalSteps) * 100;
      setCompletePercentage(percentage);
    }
  }, [stepNum]);

  return (
    <Page pageContent={false}>
      <PageContent className="flex flex-col p-5 bg-white">
        <ShowIf condition={skipabble}>
          <div className="flex justify-end">
            <Button onClick={onSkip}>
              skip <i className="fal fa-chevron-right"></i>
            </Button>
          </div>
        </ShowIf>
        <section className="flex items-center flex-grow py-5">
          <ShowIf condition={!!img}>
            <Img src={img!} size={imgSize as ImgSizes} />
          </ShowIf>
          {topSection}
        </section>

        <section>
          <div className="mb-3 text-blue-500">Step {stepNum}</div>

          <div className="mb-2 text-4xl">
            <strong>{title}</strong>
          </div>
          <p className="pr-4 text-lg">{subtitle}</p>

          <div className={classnames({ invisible: hideContent })}>
            <div className="pb-2 mt-5">{children}</div>
            <section className="bg-white">
              <Button
                raised
                fill
                className="w-full"
                disabled={disabled}
                onClick={onNext}
                large
              >
                {ctaText}
              </Button>

              <div className="mt-3 text-xs text-center text-gray-400">
                {stepNum} of {totalSteps} steps
              </div>
              <div className="pb-5 mt-3">
                <Progressbar progress={completePercentage} />
              </div>
            </section>
          </div>
        </section>
      </PageContent>
    </Page>
  );
};

export default SetupWizardLayout;
