import * as React from 'react';
import { Actions, ActionsGroup, ActionsButton } from 'framework7-react';
import BtnContent from '../../lib/BtnContent/BtnContent';
import ShowIf from '../../lib/ShowIf/ShowIf';

interface IExtendedDeleteMenuProps {
  onDeleteImages?: (e?: any) => void;
  onDeleteAlbum?: (e?: any) => void;
  defaultAlbum?: boolean;
  open?: boolean;
  onClose?: (e?: any) => void;
}
const ExtendedDeleteMenu: React.FC<IExtendedDeleteMenuProps> = ({
  open,
  onClose,
  onDeleteAlbum,
  onDeleteImages,
  defaultAlbum,
}) => {
  return (
    <Actions opened={open} onActionsClosed={onClose}>
      <ActionsGroup>
        <ActionsButton onClick={onDeleteImages}>
          <BtnContent icon={<i className="fal fa-trash-alt" />}>
            Delete photos
          </BtnContent>
        </ActionsButton>

        <ShowIf condition={!defaultAlbum}>
          <ActionsButton color="red" onClick={onDeleteAlbum}>
            <BtnContent icon={<i className="fal fa-exclamation-circle" />}>
              Delete album
            </BtnContent>
          </ActionsButton>
        </ShowIf>
      </ActionsGroup>
      <ActionsGroup>
        <ActionsButton bold>Cancel</ActionsButton>
      </ActionsGroup>
    </Actions>
  );
};

export default ExtendedDeleteMenu;
