export const timeMapping: { [time: string]: string } = {
  '05:00': '5 AM',
  '05:30': '5:30 AM',
  '06:00': '6 AM',
  '06:30': '6:30 AM',
  '07:00': '7 AM',
  '07:30': '7:30 AM',
  '08:00': '8 AM',
  '08:30': '8:30 AM',
  '09:00': '9 AM',
  '09:30': '9:30 AM',
  '10:00': '10 AM',
  '10:30': '10:30 AM',
  '11:00': '11 AM',
  '11:30': '11:30 AM',
  '12:00': 'Midday',
  '12:30': '12:30 PM',
  '13:00': '1 PM',
  '13:30': '1:30 PM',
  '14:00': '2 PM',
  '14:30': '2:30 PM',
  '15:00': '3 PM',
  '15:30': '3:30 PM',
  '16:00': '4 PM',
  '16:30': '4:30 PM',
  '17:00': '5 PM',
  '17:30': '5:30 PM',
  '18:00': '6 PM',
  '18:30': '6:30 PM',
  '19:00': '7 PM',
  '19:30': '7:30 PM',
  '20:00': '8 PM',
  '20:30': '8:30 PM',
  '21:00': '9 PM',
  '21:30': '9:30 PM',
  '22:00': '10 PM',
  '22:30': '10:30 PM',
  '23:00': '11 PM',
  '23:30': '11:30 PM',
  '00:00': 'Midnight',
  '01:00': '1 AM',
  '01:30': '1:30 AM',
  '02:00': '2 AM',
  '02:30': '2:30 AM',
  '03:00': '3 AM',
  '03:30': '3:30 AM',
  '04:00': '4 AM',
  '04:30': '4:30 AM',
};
