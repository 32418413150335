import * as React from 'react';

interface IBtnContentProps {
  icon?: React.ReactNode;
  className?: string;
}
const BtnContent: React.FC<IBtnContentProps> = ({
  icon,
  children,
  className = '',
}) => {
  return (
    <div className={'flex justify-between px-4 items-center' + ' ' + className}>
      <span>{children}</span>
      <span className="flex-center-y">{icon}</span>
    </div>
  );
};

export default BtnContent;
