import { useDispatch, useSelector } from 'react-redux';
import { IAppStore } from '../types/appStore';
import { setWizarsStepsAction } from '../actions/wizardSteps';

export default function useWizardStepsStore() {
  const dispatch = useDispatch();

  const wizardSteps = useSelector((store: IAppStore) => store.wizardSteps);

  const setWizardSteps = (payload: string[]) =>
    dispatch(setWizarsStepsAction(payload));

  return {
    wizardSteps,
    setWizardSteps,
  };
}
