import { SET_WIZARD_STEPS } from '../actions/types';

const initialState: string[] = [];

export default function wizardStepsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_WIZARD_STEPS:
      return [...payload];
    default:
      return state;
  }
}
