import React from 'react';
import classnames from 'classnames';

interface SpinnerProps {
  size?: number;
  fullScreen?: boolean;
}

const Spinner: React.FunctionComponent<SpinnerProps> = ({
  size = 28,
  fullScreen,
}) => {
  return (
    <div
      className={classnames({
        'fixed top-0 left-0 w-full h-full z-50 flex-center': fullScreen,
      })}
    >
      <span
        className="preloader"
        style={{ '--f7-preloader-size': `${size}px` } as React.CSSProperties}
      >
        <span className="preloader-inner">
          <span className="preloader-inner-line"></span>
          <span className="preloader-inner-line"></span>
          <span className="preloader-inner-line"></span>
          <span className="preloader-inner-line"></span>
          <span className="preloader-inner-line"></span>
          <span className="preloader-inner-line"></span>
          <span className="preloader-inner-line"></span>
          <span className="preloader-inner-line"></span>
          <span className="preloader-inner-line"></span>
          <span className="preloader-inner-line"></span>
          <span className="preloader-inner-line"></span>
          <span className="preloader-inner-line"></span>
        </span>
      </span>
    </div>
  );
};
export default Spinner;
