import * as React from 'react';
import { Button, Icon, Badge, Page, List, ListItem } from 'framework7-react';
import { BsBellFill } from 'react-icons/bs';
import useToggle from '../../hooks/useToggle';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import PageTitle from '../../lib/PageTitle/PageTitle';
import { INotification } from '../../types/notification';
import useBackendApi from '../../hooks/useBackendApi';
import ShowIf from '../../lib/ShowIf/ShowIf';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import Img from '../../lib/Img/Img';
import { useHistory } from 'react-router';
import useUserStore from '../../hooks/useUserStore';

interface INotificationProps {
  className?: string;
  onNotificationClick?: (e?: any) => void;
}
const Notification: React.FC<INotificationProps> = ({
  className = '',
  onNotificationClick,
}) => {
  const showDetails = useToggle();
  const { user } = useUserStore();
  const history = useHistory();

  // const { get } = useBackendApi();

  async function fetchData() {}

  React.useEffect(() => {
    let didCancel = false;
    if (!didCancel) {
      fetchData();
    }
    return () => {
      didCancel = true;
    };
  }, []);

  return (
    <>
      <Button
        className={'relative navbar-btn overflow-visible  ' + className}
        onClick={showDetails.open}
      >
        <Icon>
          <BsBellFill className="text-xl" />
          <ShowIf condition={!!user?.notifications?.length}>
            <Badge color="red" className="z-20">
              {user?.notifications?.length}
            </Badge>
          </ShowIf>
        </Icon>
      </Button>
      <SlideInModal
        direction="right"
        fullScreen
        open={showDetails.on}
        onClose={showDetails.close}
      >
        <Page className="bg-white">
          <PopupNavbar
            backIcon
            letfBtnLabel="Back"
            className="py-2"
            onCancel={showDetails.close}
            hideRightBtn
          />
          <section className="px-4">
            <h1>
              <PageTitle>Notifications</PageTitle>
            </h1>

            <List className="mt-1">
              {(user?.notifications?.length ? user.notifications : []).map(
                (notification, key) => (
                  <ListItem
                    key={key}
                    className="-ml-4"
                    onClick={() => {
                      showDetails.close();
                      onNotificationClick?.(notification);
                    }}
                  >
                    <Icon slot="media">
                      <ShowIf condition={notification.type === 'instagram'}>
                        <div
                          className="flex justify-center items-center"
                          style={{ height: 48, width: 48 }}
                        >
                          <i className="fab fa-instagram relative text-4xl mt-1 text-blue-500" />
                        </div>
                      </ShowIf>

                      <ShowIf condition={!!notification.img}>
                        <Img
                          src={notification.img}
                          size="48x48"
                          className="rounded-full"
                        />
                      </ShowIf>
                    </Icon>

                    <div className="flex items-center -mr-3 flex-grow">
                      <div>
                        <p>
                          <SectionTitle size="base">
                            {notification.type === 'instagram' &&
                              'New photos to add'}
                            {notification.type === 'freeMonth' &&
                              `You've got a free month`}
                            {notification.type === 'review' && `New review`}
                          </SectionTitle>
                        </p>
                        <p className="text-gray-500 text-xs">
                          {notification.message}
                        </p>
                      </div>

                      <div className="ml-auto">
                        <Button round className="bg-gray-200 px-3 text-base">
                          {notification.type === 'freeMonth' ? 'View' : 'More'}
                        </Button>
                      </div>
                    </div>
                  </ListItem>
                )
              )}
            </List>
          </section>
        </Page>
      </SlideInModal>
    </>
  );
};

export default Notification;
