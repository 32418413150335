import * as React from 'react';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import {
  Page,
  List,
  ListItem,
  AccordionContent,
  Button,
  Popup,
} from 'framework7-react';
import { IAppointment } from '../../types/appointment';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import Alert from '../../lib/Alert/Alert';
import dayjs from 'dayjs';
import { formatWithCommas } from '../../utils';
import PricingDetails from './PricingDetails/PricingDetails';
import Information from './Information/Information';
import Services from './Services/Services';
import Look from './Look/Look';
import CustomerDetails from './CustomerDetails/CustomerDetails';
import Team from './Team/Team';
import './AppointmentDetail.scss';
import ShowIf from '../../lib/ShowIf/ShowIf';
import useUserStore from '../../hooks/useUserStore';
import useBackendApi from '../../hooks/useBackendApi';
import useToast from '../../hooks/useToast';
import { findIndex } from 'lodash';
import useToggle from '../../hooks/useToggle';
import AppointmentDetailsMenu from '../ApointmentDetails/AppointmentDetailsMenu/AppointmentDetailsMenu';
import Portal from '../../lib/Portal/Portal';

interface IAppointmentDetailProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  onEdit?: (e?: any) => void;
  setSelectedAppointment?: (e?: any) => void;
  appointment?: IAppointment;
}

const statusColorMapping = {
  booked: 'green-500',
  offered: 'yellow-500',
  expired: 'red-500',
};
const statusLabelMapping = {
  booked: 'booked',
  offered: 'offer sent',
  expired: 'expired',
};
const AppointmentDetail: React.FC<IAppointmentDetailProps> = ({
  open,
  onClose,
  onEdit,
  appointment,
  setSelectedAppointment,
}) => {
  const { user, setUser, setAppointment } = useUserStore();
  const { patch } = useBackendApi();
  const { successToast } = useToast();
  const menu = useToggle();

  const handleMarkPaid = async () => {
    const updateBody = { _id: appointment?._id, paid: true };
    const updated = await patch('/v1/appointment', updateBody);
    setAppointment(updated);
    setSelectedAppointment?.(updated);
    successToast('Marked as paid!');
  };

  return (
    <Popup opened={open} onPopupClosed={onClose} animate={false}>
      <Page className="AppointmentDetail">
        <PopupNavbar
          letfBtnLabel="Back"
          backIcon
          rightBtnLabel={<i className="far fa-ellipsis-h text-xl" />}
          onCancel={onClose}
          onDone={menu.open}
          sticky
        />

        <div className="px-4 pb-5">
          <header className="mb-3">
            <div className="flex-center-y  truncate">
              <h3 className="truncate pr-4">
                <SectionTitle size="4xl">
                  {appointment?.customerName}
                </SectionTitle>
              </h3>

              <div className="ml-auto text-right">
                <p
                  className={
                    ' font-semibold capitalize ' +
                    `text-${statusColorMapping[appointment?.status || '']}`
                  }
                >
                  {statusLabelMapping[appointment?.status || '']}
                </p>
                {appointment?.status === 'offered' && (
                  <p className="text-gray-500">12 hours ago</p>
                )}
              </div>
            </div>
            <div className="text-xl -mt-2">Request #{appointment?._id}</div>
          </header>

          {appointment?.status === 'booked' && !appointment?.paid && (
            <Alert
              btnLabel="Mark paid"
              className="-mx-4"
              onBtnClick={handleMarkPaid}
            >
              The customer owes you a payment for this gig
            </Alert>
          )}

          <section className="mt-3">
            <PricingDetails appointment={appointment} />
          </section>

          <List accordionList>
            <ListItem
              accordionItem
              title="Information"
              className="-ml-4"
              accordionItemOpened={appointment?.status === 'booked'}
            >
              <AccordionContent>
                <div className="px-4">
                  <Information appointment={appointment} />
                </div>
              </AccordionContent>
            </ListItem>

            <ListItem
              accordionItem
              title={`Services (${appointment?.services?.length} ${
                (appointment?.services?.length || 0) > 1 ? 'People' : 'Person'
              } )`}
              className="-ml-4"
              accordionItemOpened={appointment?.status === 'booked'}
            >
              <AccordionContent>
                <div className="px-4">
                  <Services appointment={appointment} />
                </div>
              </AccordionContent>
            </ListItem>

            <ShowIf condition={appointment?.description ? true : false}>
              <ListItem
                accordionItem
                title="The Look"
                className="-ml-4"
                accordionItemOpened={appointment?.status === 'booked'}
              >
                <AccordionContent>
                  <div className="px-4">
                    <Look appointment={appointment} />
                  </div>
                </AccordionContent>
              </ListItem>
            </ShowIf>

            <ListItem
              accordionItem
              title="Contact"
              className="-ml-4"
              accordionItemOpened={appointment?.status === 'booked'}
            >
              <AccordionContent>
                <div className="px-4">
                  <CustomerDetails appointment={appointment} />
                </div>
              </AccordionContent>
            </ListItem>
          </List>

          <ShowIf condition={appointment?.status === 'offered'}>
            <div className="sticky py-3 bg-white bottom-0 z-10">
              <p>
                <Button large fill raised>
                  Send a reminder
                </Button>
              </p>
              <p className="mt-2">
                <Button large>or Withdraw offer</Button>
              </p>
            </div>
          </ShowIf>
        </div>
      </Page>
      <Portal>
        <AppointmentDetailsMenu
          open={menu.on}
          onClose={menu.close}
          onEdit={() => onEdit?.(appointment)}
        />
      </Portal>
    </Popup>
  );
};

export default AppointmentDetail;
