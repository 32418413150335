import * as React from 'react';
import ActionSheet from '../../lib/ActionSheet/ActionSheet';
import { SPA_URL } from '../../constants';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import { Link, Button } from 'framework7-react';
import { formatSmsLinkForDevice, copyToClipboard } from '../../utils';
import useUserStore from '../../hooks/useUserStore';
import useToggle from '../../hooks/useToggle';
import PageTitle from '../../lib/PageTitle/PageTitle';

interface IShareReviewLinkProps {
  open?: boolean;
  onClose?: (e?: any) => void;
}
const ShareReviewLink: React.FC<IShareReviewLinkProps> = ({
  open,
  onClose,
}) => {
  const { user } = useUserStore();
  const link = SPA_URL + '/post-review/' + user._id;
  const copied = useToggle();

  const message = `Can you please leave me a review through this link -> ${link}`;

  function handleCopy() {
    copyToClipboard(link);
    copied.open();
  }

  React.useEffect(() => {
    if (copied.on) {
      setTimeout(() => {
        copied.close();
      }, 1000);
    }
  }, [copied.on]);

  return (
    <ActionSheet open={open} onClose={onClose}>
      <div className="flex justify-end">
        <Button large onClick={onClose}>
          Cancel
        </Button>
      </div>

      <h3 className="mb-3">
        <PageTitle>Request reviews</PageTitle>
      </h3>

      <p className="text-lg mb-3">
        Get reviews from past customers by sharing your review link. You pick
        which reviews are published to your site.
      </p>

      {/* <section className="flex border-b border-gray-100 items-center pb-3">
        <div className="flex-grow px-3">
          <p>
            <SectionTitle size="base">Request Review</SectionTitle>
          </p>
          <Link
            href={link}
            external
            target="__blank"
            className="text-sm text-blue-500"
          >
            {link}
          </Link>
        </div>

        <button
          className="bg-gray-100 rounded-full outline-none w-6 h-6 flex items-center justify-center self-start"
          onClick={onClose}
        >
          <i className="fal fa-times mt-1" />
        </button>
      </section> */}

      <section className="mb-5 pb-5">
        <div className="flex py-3 border-b border-gray-200 px-4">
          <Link
            href={`mailto:?body=${message}`}
            className="bg-white shadow mr-4 p-2 rounded-lg active:bg-gray-100"
            target="__blank"
            external
          >
            <i className="fal fa-envelope h-10 w-10 text-4xl flex items-center justify-center mt-1" />
          </Link>

          <Link
            href={formatSmsLinkForDevice('', message)}
            className="bg-white shadow mr-4 p-2 rounded-lg active:bg-gray-100"
            target="__blank"
            external
          >
            <i className="fas fa-comment h-10 w-10 text-4xl flex items-center justify-center mt-1" />
          </Link>
        </div>

        <div className="mt-4 mb-5">
          <p>
            <SectionTitle size="base">Share your review link</SectionTitle>
          </p>
          <Link
            href={link}
            external
            target="__blank"
            className="text-sm text-blue-500"
          >
            {link}
          </Link>
        </div>

        <section className="mb-5 pb-5 mt-3">
          <button
            className="px-4 flex rounded shadow py-3 items-center active:bg-gray-100 focus:outline-none "
            onClick={handleCopy}
          >
            <span className="text-base">Copy link</span>
            <i className="fal fa-copy ml-auto text-xl" />
          </button>
          <div
            className={`mt-3 text-sm text-center ${
              copied.on ? 'text-gray-500' : 'invisible'
              } `}
          >
            link copied
          </div>
        </section>
      </section>
    </ActionSheet>
  );
};

export default ShareReviewLink;
