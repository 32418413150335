import React from 'react';
import { IAddress } from '../types/address';
import { geocodeByAddress } from 'react-places-autocomplete';

const defaultOptions = {};
export default function useGooglePlacesApi(
  selector,
  onSelect,
  options: any = defaultOptions
) {
  const autocompleteRef: any = React.useRef(null);
  const savedCallback: any = React.useRef();

  React.useEffect(() => {
    savedCallback.current = onSelect;
  });

  React.useEffect(() => {
    init();
    return () => {
      if (autocompleteRef?.current) {
        autocompleteRef?.current?.removeListener?.('place_changed');
      }
    };
  }, [selector]);

  React.useEffect(() => {
    if (options.forceInit && !autocompleteRef.current) {
      init();
    }
    return () => {
      if (autocompleteRef?.current) {
        autocompleteRef?.current?.removeListener?.('place_changed');
      }
    };
  }, [options]);

  function init() {
    setTimeout(() => {
      let inputRef = document.querySelector(selector);

      console.log({ inputRef });

      if (inputRef) {
        autocompleteRef.current = new google.maps.places.Autocomplete(
          inputRef,
          options
        );

        // Fire Event when a suggested name is selected
        autocompleteRef?.current?.addListener?.(
          'place_changed',
          handlePlaceSelect
        );
      }
    }, 0);
  }

  React.useEffect(() => {
    return () => {
      if (autocompleteRef.current) {
        autocompleteRef?.current?.removeListener?.('place_changed');
        autocompleteRef.current = null;
      }
    };
  }, []);

  async function handlePlaceSelect() {
    // Extract City From Address Object
    let addressObject = autocompleteRef.current.getPlace();
    const address = addressObject.address_components;
    let postcode;
    let state;
    let streetNo = '';
    let street = '';
    let city;
    let country;

    if (address && address.length) {
      for (let a of address) {
        if (a.types.includes('country')) {
          country = a.short_name;
        }
        if (a.types.includes('street_number')) {
          streetNo = a.short_name;
        }
        if (a.types.includes('route')) {
          street = a.short_name;
        }
        if (a.types.includes('postal_code')) {
          postcode = a.short_name;
        }
        if (a.types.includes('administrative_area_level_1')) {
          state = a.short_name;
        }
        if (a.types.includes('locality')) {
          city = a.short_name;
        }
      }
    }
    if (!address && addressObject.name) {
      let addressToGeocode = addressObject.name;
      let results = await geocodeByAddress(addressToGeocode);
      if (results.length) {
        addressObject = results[0];
        for (let a of results[0].address_components) {
          if (a.types.includes('country')) {
            country = a.short_name;
          }
          if (a.types.includes('street_number')) {
            streetNo = a.short_name;
          }
          if (a.types.includes('route')) {
            street = a.short_name;
          }
          if (a.types.includes('postal_code')) {
            postcode = a.short_name;
          }
          if (a.types.includes('administrative_area_level_1')) {
            state = a.short_name;
          }
          if (a.types.includes('locality')) {
            city = a.short_name;
          }
        }
      }
    }

    const newAddress: IAddress = {
      street: `${streetNo} ${street}`.trim(),
      city,
      state,
      postcode,
      country,
      formatted: addressObject.formatted_address,
      lat: addressObject.geometry.location.lat(),
      lng: addressObject.geometry.location.lng(),
    };
    savedCallback?.current?.(newAddress);
  }
}
