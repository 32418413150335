import * as React from 'react';
import { Page } from 'framework7-react';
import PageTitle from '../../lib/PageTitle/PageTitle';
import ImgGrid from '../ImgGrid/ImgGrid';
import { IImage } from '../../types/image';
import useBackendApi from '../../hooks/useBackendApi';
import useUserStore from '../../hooks/useUserStore';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import { selectDeselectItem } from '../../utils';

interface IGalleryBrowserProps {
  onClose?: (e?: any) => void;
  title?: string;
  multiple?: boolean;
  skip?: string[];
  onSave?: (e?: any) => void;
}
const GalleryBrowser: React.FC<IGalleryBrowserProps> = ({
  onClose,
  title = 'Pick a photo',
  onSave,
  multiple,
  skip = [],
}) => {
  const [images, setImages] = React.useState<IImage[]>([]);
  const { user } = useUserStore();
  const { get } = useBackendApi();
  const [selected, setSelected] = React.useState<IImage | IImage[]>(
    multiple ? [] : {}
  );

  async function init() {
    const fetched: IImage[] = await get(`/v1/album/images/all`, {
      userId: user._id,
    });
    setImages(fetched.filter((img) => !skip.includes(img._id || '')));
  }

  React.useEffect(() => {
    let didCancel = false;
    if (!didCancel) {
      init();
    }
    return () => {
      didCancel = true;
    };
  }, []);

  function handleSave() {
    if (multiple) {
      return (selected as IImage[]).length && onSave?.(selected);
    }
    return (selected as IImage)._id && onSave?.(selected);
  }

  function handleImgSelect(img) {
    if (multiple) {
      const selection = selectDeselectItem(img, selected as IImage[]);
      setSelected(selection);
    } else {
      setSelected(img);
    }
  }

  function isSaveDisabled() {
    if (multiple) {
      return !(selected as IImage[]).length;
    }
    return !(selected as IImage).src;
  }

  return (
    <Page>
      <PopupNavbar
        onCancel={onClose}
        onDone={handleSave}
        sticky
        rightBtnDisabled={isSaveDisabled()}
      />
      <div className="px-4">
        <h2 className="border-b border-gray-200">
          <PageTitle>{title}</PageTitle>
        </h2>

        <div className="pb-20 mt-3">
          <ImgGrid
            images={images}
            selectable
            selectedImages={
              multiple ? (selected as IImage[]) : ([selected] as IImage[])
            }
            onImageSelect={handleImgSelect}
          />
        </div>
      </div>
    </Page>
  );
};

export default GalleryBrowser;
