import * as React from 'react';
import { IAppointment } from '../../../types/appointment';
import { formatWithCommas } from '../../../utils';
import dayjs from 'dayjs';
import ShowIf from '../../../lib/ShowIf/ShowIf';
import classnames from 'classnames';

interface IPricingDetailsProps {
  appointment?: IAppointment;
}
const PricingDetails: React.FC<IPricingDetailsProps> = ({ appointment }) => {

  return (
    <section className="flex">
      <div
        className={classnames('flex-grow flex flex-col', {
          'justify-between': appointment?.status === 'booked',
          'justify-center': appointment?.status !== 'booked',
        })}
      >
        {!!appointment?.pricing?.deposit && (
          <p>Deposit: ${appointment?.pricing?.deposit}</p>
        )}
        {!!appointment?.pricing?.total && (
          <p>
            Remaining: $
            {(appointment?.pricing?.total || 0) -
              (appointment?.pricing?.deposit || 0)}
          </p>
        )}

        <ShowIf condition={appointment?.status === 'booked'}>
          <p>
            Payment due on:{' '}
            {dayjs(appointment?.paymentDueDate).format('ddd DD MMM, YYYY')}
          </p>x
        </ShowIf>
      </div>

      <div className="text-right flex flex-col justify-between">
        {!!appointment?.pricing?.total && (
          <p className="text-gray-500">Total</p>
        )}
        <p className="font-semibold text-xl">
          {'$'}{(appointment?.pricing?.total || 0)}

        </p>
        <ShowIf condition={appointment?.status === 'booked'}>
          <ShowIf
            condition={
              !appointment?.paid &&
              dayjs().isAfter(dayjs(appointment?.paymentDueDate))
            }
          >
            <p className="text-red-500">Overdue</p>
          </ShowIf>

          <ShowIf condition={appointment?.paid}>
            <div className="text-green-500">Paid</div>
          </ShowIf>

          <ShowIf
            condition={
              !appointment?.paid &&
              dayjs().isBefore(dayjs(appointment?.paymentDueDate))
            }
          >
            <p className="text-yellow-500 invisible">pending</p>
          </ShowIf>
        </ShowIf>
      </div>
    </section>
  );
};

export default PricingDetails;
