import React from 'react';
import useBackendApi from '../../hooks/useBackendApi';
import useUserStore from '../../hooks/useUserStore';
import {
  calculateAvgReviewScore,
  updateItemInArray,
  isSubscribedTo,
} from '../../utils';
import ShareReviewLink from '../../components/ShareReviewLink/ShareReviewLink';
import useToggle from '../../hooks/useToggle';
import { Page, Popup } from 'framework7-react';
import PageTitle from '../../lib/PageTitle/PageTitle';
import ReviewCardsPaywall from '../../components/ReviewCardsPaywall/ReviewCardsPaywall';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import ReviewsSummarySlide from '../../components/ReviewsSummarySlide/ReviewsSummarySlide';
import ReviewsTabSelector from '../../components/ReviewsTabSelector/ReviewsTabSelector';
import { IReview } from '../../types/review';
import ReviewItem from '../../components/ReviewItem/ReviewItem';
import useSelectItem from '../../hooks/useSelectItem';
import ImgActionMenu from '../../components/ImgActionMenu/ImgActionMenu';
import GalleryBrowser from '../../components/GalleryBrowser/GalleryBrowser';
import { ESubItemId } from '../../types/subItem';
import GenerateReviewCard from '../../components/GenerateReviewCard/GenerateReviewCard';
import ImportReviewModal from '../../components/ImportReviewModal/ImportReviewModal';
import useToast from '../../hooks/useToast';
import InstagramAuth from '../../components/InstagramAuth/InstagramAuth';
import { IG_REVIEWS_REDIRECT_URI } from '../../constants';
import DeletePrompt from '../../components/DeletePrompt/DeletePrompt';
import Img from '../../lib/Img/Img';
import InstagramPickerV2 from '../../components/InstagramPickerV2/InstagramPickerV2';

interface ReviewsPageProps {}

const ReviewsPage: React.FunctionComponent<ReviewsPageProps> = ({}) => {
  const { user, setUser } = useUserStore();
  const { get, patch } = useBackendApi();
  const { successToast } = useToast();

  const shareReviewLink = useToggle();
  const paywall = useToggle();
  const [activeTab, setActiveTab] = React.useState<string>('all');

  const [filteredReviews, setFilteredReviews] = React.useState<IReview[]>([]);

  const [
    selectedReviewForImgUpload,
    setSelectedReviewForImgUpload,
    clearSelectedReviewForImgUpload,
  ] = useSelectItem(undefined);

  const [
    addImgActionSelection,
    setAddImgActionSelection,
    clearAddImgActionSelection,
  ] = useSelectItem('');

  const [uploadingImg, setUploadingImg, clearUploadingImg] = useSelectItem();

  const [
    cardToDownload,
    setCardToDownload,
    clearCardToDownload,
  ] = useSelectItem(undefined);

  const importForm = useToggle();
  const addImgMenu = useToggle();

  const [
    reviewWithDeletedImgs,
    setReviewWithDeletedImgs,
    clearReviewWithDeletedImgs,
  ] = useSelectItem();

  const subscribedToReviewCards = isSubscribedTo(
    ESubItemId.reviewCards,
    user.subscriptions
  );

  React.useEffect(() => {
    markReviewsAsSeen();
  }, []);

  async function markReviewsAsSeen() {
    await get('/v1/review/mark-as-seen', {}, { loader: false });
    //delete review notif
    const newNotifs = [...(user.notifications || [])].filter(
      (i) => i.type !== 'review'
    );
    setUser({ notifications: newNotifs });
  }

  React.useEffect(() => {
    filterReviewsBasedOnSelectedTab();
  }, [user.reviews, activeTab]);

  function filterReviewsBasedOnSelectedTab() {
    if (user.reviews) {
      switch (activeTab) {
        case 'visible':
          setFilteredReviews(
            user.reviews.filter((rev) => rev.status === 'visible')
          );
          break;
        case 'hidden':
          setFilteredReviews(
            user.reviews.filter((rev) => rev.status === 'hidden')
          );
          break;
        default:
          setFilteredReviews(user.reviews);
          break;
      }
    }
  }

  /*   React.useEffect(() => {
      if (
        addImgActionSelection === 'getFromInsta' &&
        !user.instagramAuth?.token
      ) {
        linkInsta.open();
      }
    }, [addImgActionSelection]);
   */

  async function handleReviewToggle(review) {
    const status = review.status === 'visible' ? 'hidden' : 'visible';
    const updatedReview = { ...review, status };
    const updatedUserReviews = updateItemInArray(user.reviews!, updatedReview);
    const reviewScore = calculateAvgReviewScore(updatedUserReviews);
    setUser({ reviews: updatedUserReviews, avgReviewScore: reviewScore });
    await postReviewToUpdate(updatedReview);
  }

  async function updateImagesInReview(imgs: string[], review) {
    const newReview = {
      ...review,
      images: [...review.images, ...imgs],
    };

    const updated = await postReviewToUpdate(newReview);
    const updatedUserReviews = updateItemInArray(user.reviews!, updated);
    const reviewScore = calculateAvgReviewScore(updatedUserReviews);
    setUser({ reviews: updatedUserReviews, avgReviewScore: reviewScore });
    successToast('Upload complete');
  }

  function handleUploadStart(img) {
    setUploadingImg(img);
    const newReview = { ...selectedReviewForImgUpload };
    newReview.images = [...newReview.images, img];
    const updated = updateItemInArray(filteredReviews, newReview);
    setFilteredReviews(updated);
  }

  function handleUploadComplete(e) {
    if (e.target.value.length) {
      updateImagesInReview([e.target.value], selectedReviewForImgUpload);
      // clearUploadElementValue();
      clearUploadingImg();
    }
  }

  function postReviewToUpdate(review) {
    return patch('/v1/review', review);
  }

  function getVisibleReviewsCount() {
    return (user.reviews || []).filter((rev) => rev.status === 'visible')
      .length;
  }

  function handleAddPhoto(review) {
    setSelectedReviewForImgUpload(review);
    addImgMenu.open();
  }

  function handleSaveImagesToReview(images) {
    updateImagesInReview(
      images.map((i) => i.src),
      selectedReviewForImgUpload
    );
    clearAddImgActionSelection();
  }

  function handleDownloadClick(review) {
    if (!subscribedToReviewCards) {
      paywall.open();
      return;
    }
    setCardToDownload(review);
  }

  async function handleDeleteImg() {
    const updated = await postReviewToUpdate(reviewWithDeletedImgs);
    clearReviewWithDeletedImgs();
    const reviews = updateItemInArray(user.reviews, updated);
    setUser({ reviews });
    successToast('Deleted image');
  }

  function getImgToDelete() {
    if (!!reviewWithDeletedImgs) {
      const rev = filteredReviews.find(
        (r) => r._id === reviewWithDeletedImgs._id
      )!;
      const img = rev.images?.find(
        (i) => !reviewWithDeletedImgs.images.includes(i)
      );
      return img;
    }
    return '';
  }

  return (
    <Page>
      {/* <Navbar
        logo
        rightContent={
          <div className="ml-auto flex-center-y ">
            <Notification />
            <Button className="navbar-btn" onClick={shareReviewLink.open}>
              <i className="text-2xl fal fa-plus" />
            </Button>
          </div>
        }
      /> */}

      <section className="px-4 pb-20">
        <header>
          <h1>
            <PageTitle>Reviews</PageTitle>
          </h1>
        </header>
        {/* <Alert
          btnLabel="import"
          className="-mx-4"
          onBtnClick={ importForm.toggle}
        >
          <span className="inline-block pr-3">
            Import reviews from other platforms
          </span>
        </Alert> */}

        <ReviewsSummarySlide
          score={user.avgReviewScore}
          reviewCount={getVisibleReviewsCount()}
          onAddReviewClick={shareReviewLink.open}
          onInstaCardClick={paywall.open}
          hideInstaCard={subscribedToReviewCards}
          onImportClick={importForm.open}
        />

        <div className="my-4">
          <ReviewsTabSelector selected={activeTab} onSelect={setActiveTab} />
        </div>

        <div className="mt-3">
          {filteredReviews.map((review) => (
            <ReviewItem
              key={review._id}
              review={review}
              enableHideToggle={review.placeholder ? false : true}
              onToggle={handleReviewToggle}
              enableReviewCard
              onAdd={handleAddPhoto}
              onDownload={handleDownloadClick}
              onCollectReviews={shareReviewLink.open}
              onDelete={setReviewWithDeletedImgs}
            />
          ))}
        </div>
      </section>

      <SlideInModal
        open={paywall.on}
        onClose={paywall.close}
        fullScreen
        direction="right"
      >
        <Page>
          <ReviewCardsPaywall onCancel={paywall.close} />
        </Page>
      </SlideInModal>

      <ShareReviewLink
        open={shareReviewLink.on}
        onClose={shareReviewLink.close}
      />

      <DeletePrompt
        open={!!reviewWithDeletedImgs}
        onClose={clearReviewWithDeletedImgs}
        onConfirm={handleDeleteImg}
        heading="Delete Photo"
        text="Are you surre you wish to delete this photo from the review?"
        confirmBtnLabel="Yes - delete photo"
      >
        <div className="my-4">
          <Img
            src={getImgToDelete()}
            size="128x128"
            className="mx-auto rounded-lg"
          />
        </div>
      </DeletePrompt>

      <>
        <ImgActionMenu
          open={addImgMenu.on}
          onClose={addImgMenu.close}
          enableInsta
          builtInImageUploader
          onMenuSelect={setAddImgActionSelection}
          onUploadStart={handleUploadStart}
          onUploadComplete={handleUploadComplete}
          enableImageEditor
        />

        <Popup
          opened={addImgActionSelection === 'browseGallery'}
          onPopupClosed={clearAddImgActionSelection}
        >
          <GalleryBrowser
            onClose={clearAddImgActionSelection}
            onSave={handleSaveImagesToReview}
            multiple
          />
        </Popup>

        <InstagramPickerV2
          open={addImgActionSelection === 'getFromInsta'}
          onClose={clearAddImgActionSelection}
          onAddPhotos={handleSaveImagesToReview}
          enableAll
          skipUpdate
        />

        <ImportReviewModal open={importForm.on} onClose={importForm.toggle} />
      </>

      <GenerateReviewCard
        open={!!cardToDownload}
        onClose={clearCardToDownload}
        review={cardToDownload}
        onDownload={clearCardToDownload}
        onAddPhoto={handleAddPhoto}
      />

      <InstagramAuth redirectUri={IG_REVIEWS_REDIRECT_URI} />
    </Page>
  );
};
export default ReviewsPage;
