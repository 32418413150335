import * as React from 'react';
import { Page, PageContent, Button, List, ListInput } from 'framework7-react';
import { useHistory } from 'react-router';
import { FacebookProvider, LoginButton } from 'react-facebook';
import useToast from '../../hooks/useToast';
import { setUserDataToStorage } from '../../utils';
import useBackendApi from '../../hooks/useBackendApi';
interface ILoginLayoutProps {
  topCtaLabel?: string;
  topCtaBtnLabel?: string;
  topCtaLink?: string;
  mainCtaLabel?: string;
  hideForgotPassword?: boolean;
  onMainCtaClick?: (e?: any) => void;
  onForgotClick?: (e?: any) => void;
  extension?: React.ReactNode;
  ctaExtension?: React.ReactNode;
  onChange?: (e: any) => void;
  onFacebookBtnClick?: (e?: any) => void;
  onGoogleBtnClick?: (e?: any) => void;
}
const LoginLayout: React.FC<ILoginLayoutProps> = ({
  topCtaLabel = '',
  topCtaBtnLabel = '',
  topCtaLink = '',
  children,
  mainCtaLabel,
  onForgotClick,
  onMainCtaClick,
  extension,
  ctaExtension,
}) => {
  const { errorToast, catchErrorToast } = useToast();
  const history = useHistory();
  const { post } = useBackendApi();
  const [userInput, setUserInput] = React.useState<any>({
    email: '',
    password: '',
  });

  function handleUserInputChange(e: any) {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });
  }

  function handleMainCtaClick() {
    onMainCtaClick && onMainCtaClick(userInput);
  }

  const responseFacebook = (response) => {
    const email = response?.profile?.email;
    const fbUserId = response?.profile?.id;
    const fbToken = response?.tokenDetail?.accessToken;
    if (!response?.profile?.email) {
      return errorToast(
        'No email present on Facebook account. Please use email/password.'
      );
    }
    handleFbAuth({ email, fbUserId, fbToken });
  };

  async function handleFbAuth(fbAuthObj) {
    try {
      let data = await post(`/v1/auth/fb-login`, fbAuthObj);
      setUserDataToStorage({ token: data.token });
      return history.push('/dashboard');
    } catch (err) {
      catchErrorToast(err);
      console.error(err);
    }
  }
  const errorFacebook = (response) => {
    console.log(response);
  };

  function handleInputFocus(e) {
    e.target.scrollIntoView();
  }

  return (
    <Page>
      <main className="px-4">
        <div className="pb-5">
          <section className="sticky top-0 z-10 flex items-center pt-4 mb-10 bg-white">
            <div className="flex-grow"></div>
            <div className="pr-3 text-xs text-blue-500">
              <span className="inline-block">{topCtaLabel}</span>
            </div>
            <div>
              <Button fill onClick={() => history.push(topCtaLink)} small round>
                {topCtaBtnLabel}
              </Button>
            </div>
          </section>
          <section>{children}</section>
          <input type="text" autoFocus className="display-none" />
          <section className="sticky top-0 z-10 pt-4 mb-4">
            <List noHairlines className="mt-0 mb-0">
              <ListInput
                outline
                label="Email"
                floatingLabel
                type="email"
                placeholder="Your Email"
                clearButton
                name="email"
                value={userInput.email}
                onFocus={handleInputFocus}
                onChange={handleUserInputChange}
                className="mb-4"
              />
              <ListInput
                outline
                label="Password"
                floatingLabel
                type="password"
                placeholder="Enter Password"
                clearButton
                name="password"
                onFocus={handleInputFocus}
                value={userInput.password}
                onChange={handleUserInputChange}
                className="mb-4"
              />
            </List>
            <div className="px-4 pb-10 mb-5">
              {onForgotClick && (
                <div className="mb-4">
                  <Button onClick={onForgotClick}>forgot password?</Button>
                </div>
              )}
              <div>
                <Button
                  large
                  raised
                  fill
                  onClick={handleMainCtaClick}
                  className="w-full mb-4"
                  disabled={!(userInput.email && userInput.password)}
                >
                  {mainCtaLabel}
                </Button>

                <div>
                  <FacebookProvider appId="164650904876568">
                    <LoginButton
                      scope="email"
                      onCompleted={responseFacebook}
                      onError={errorFacebook}
                    >
                      <Button large raised fill className="w-full mb-4">
                        Login With Facebook
                      </Button>
                    </LoginButton>
                  </FacebookProvider>
                </div>

                {ctaExtension}
              </div>
            </div>
          </section>
        </div>
      </main>

      <div>{extension}</div>
    </Page>
  );
};

export default LoginLayout;
