import * as React from 'react';
import './ImgAutoplay.scss';
import Img from '../Img/Img';
import classnames from 'classnames';

interface IImgAutoplayProps {
  images?: string[];
  className?: string;
  disabled?: boolean;
}

const ImgAutoplay: React.FC<IImgAutoplayProps> = ({
  images = [],
  className = '',
  disabled,
}) => {
  return (
    <div
      className={classnames('ImgAutoplay ' + className, {
        'ImgAutoplay-disabled': disabled,
      })}
    >
      {images?.map((img, key) => (
        <Img src={img} key={key} />
      ))}
    </div>
  );
};

export default ImgAutoplay;
