import React from 'react';

interface ICloseBtnProps {
  size?: string;
  className?: string;
  onClick?: (e?: any) => void;
}
const CloseBtn: React.FC<ICloseBtnProps> = ({
  size = '6',
  onClick,
  className = '',
}) => {
  return (
    <button
      className={`w-${size} h-${size} ${className} rounded-full flex-center focus:outline-none active:opacity-75`}
      onClick={onClick}
    >
      <i className="fal fa-times" />
    </button>
  );
};

export default CloseBtn;
