import React, { RefObject } from 'react';
import * as ReactDom from 'react-dom';

interface PortalProps {
  target?: string;
  className?: string;
}

const Portal: React.FunctionComponent<PortalProps> = ({
  children,
  target,
  className = '',
}) => {
  const [element, setElement] = React.useState<any>();

  React.useEffect(() => {
    const el: HTMLElement | null = document.createElement('div');
    if (el) {
      el.classList.add('Portal');
      if (className) {
        el.classList.add(className);
      }

      if (target) {
        const targetEl = document.querySelector(target);
        targetEl?.appendChild(el);
      } else {
        document.body.appendChild(el);
      }
      setElement(el);
    }
    return () => {
      if (el) {
        if (target) {
          const targetEl = document.querySelector(target);
          targetEl?.removeChild(el);
        } else {
          document.body.removeChild(el);
        }
        setElement(null);
      }
    };
  }, []);

  if (!element) {
    return null;
  }
  return ReactDom.createPortal(children, element);
};
export default Portal;
