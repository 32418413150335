import React from 'react';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import { Page, List, ListInput, Button } from 'framework7-react';
import Navbar from '../../lib/Navbar/Navbar';
import BackBtn from '../../lib/BackBtn/BackBtn';
import F7NavTitle from 'framework7-react/components/nav-title-large';
import useToast from '../../hooks/useToast';
import useBackendApi from '../../hooks/useBackendApi';
import PageTitle from '../../lib/PageTitle/PageTitle';

interface ImportReviewModalProps {
  open: boolean;
  onClose: () => void;
}

const ImportReviewModal: React.FunctionComponent<ImportReviewModalProps> = ({
  open,
  onClose,
}) => {
  const [text, setText] = React.useState('');
  const { errorToast } = useToast();
  const { post } = useBackendApi();
  async function handleSubmit() {
    if (!text) {
      return errorToast('Please list the links for Nishi to collect reviews');
    }
    await post(
      `/v1/util/import-reviews-zendesk`,
      { body: text },
      {
        showToastOnError: true,
        showToastOnSuccess: true,
        successMsg: `Successfully submitted. We will notify you once reviews are imported.`,
      }
    );
    onClose();
  }

  return (
    <SlideInModal open={open} onClose={onClose} fullScreen direction="right">
      <Page>
        <Navbar leftContent={<BackBtn onClick={onClose} />} />
        <div className="p-3">
          <h1 className="mb-3">
            <PageTitle>Import Reviews</PageTitle>
          </h1>
          <List>
            <ListInput
              type="textarea"
              borderColor="black"
              onChange={(e) => setText(e.target.value)}
              placeholder="List the links where you want Nishi to collect reviews from. e.g https://facebook.com/my-awesome-makeup-business"
            />
          </List>
          <Button fill large onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Page>
    </SlideInModal>
  );
};
export default ImportReviewModal;
