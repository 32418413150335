import * as React from 'react';

interface INoContentProps {
  onClick?: (e?: any) => void;
}
const NoContent: React.FC<INoContentProps> = ({ onClick, children }) => {
  return (
    <div
      className="bg-gray-100 border-dashed border-2 border-gray-300 py-2 px-3 rounded-md"
      onClick={onClick}
    >
      {!children && 'No content. Tap here to create one.'}
      {children}
    </div>
  );
};

export default NoContent;
