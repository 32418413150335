import * as React from 'react';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import { useHistory } from 'react-router';
import { Page, theme, Button } from 'framework7-react';
import Navbar from '../../lib/Navbar/Navbar';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import PageTitle from '../../lib/PageTitle/PageTitle';
import Alert from '../../lib/Alert/Alert';
import { GrShare } from 'react-icons/gr';
import useUserStore from '../../hooks/useUserStore';
import Img from '../../lib/Img/Img';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import ShowIf from '../../lib/ShowIf/ShowIf';
import useBackendApi from '../../hooks/useBackendApi';
import { ITheme } from '../../types/theme';

interface IDesignProps { }
const Design: React.FC<IDesignProps> = ({ }) => {
  const { user } = useUserStore();
  const selectedTheme = user.theme;
  const [themes, setThemes] = React.useState<ITheme[]>([]);

  const history = useHistory();

  function handleClose() {
    history.push('/dashboard/preview-site');
  }

  function goToPreview(id) {
    if (id === selectedTheme?.id) {
      handleClose();
    } else {
      history.push(`/dashboard/preview-site/demo/${id}`);
    }
  }

  const { get } = useBackendApi();

  async function fetchThemes() {
    const fetched = await get(`/v1/subscription/themes`);
    setThemes(fetched);
  }

  React.useEffect(() => {
    let didCancel = false;
    if (!didCancel) {
      fetchThemes();
    }
    return () => { didCancel = true }
  }, []);

  React.useEffect(() => {
    if (!user._id) {
      handleClose();
    }
  }, [user]);
  /*   const themes = [
      {
        default: true,
        name: 'Basic',
        id: 'basic',
        image:
          'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/bLxX71mt6.png',
        price: 0,
        currency: '$',
        desc: `Free design to help you get started on your freelance journey`,
        author: 'Nishi',
      },
      {
        default: false,
        name: 'Moona',
        id: 'moona',
        image:
          'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/U145_57Dy.png',
        price: 1.99,
        currency: '$',
        desc: `Look different from others`,
        author: 'Nishi',
      },
      {
        default: true,
        name: 'Kenji',
        id: 'kenji',
        image:
          'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/PZBSPjOBu.png',
        price: 4.99,
        currency: '$',
        desc: `Get rid of distractions and present a clean minimalist canvas, the Kenji`,
        author: 'Nishi',
      },
    ];
   */
  return (
    <SlideInModal open onClose={handleClose} fullScreen direction="right">
      <Page>
        <PopupNavbar
          backIcon
          letfBtnLabel="Back"
          onCancel={handleClose}
          hideRightBtn
          sticky
        />
        <div className="px-4">
          <h1 className="mb-2">
            <PageTitle>Design</PageTitle>
          </h1>
          <Alert
            className="-mx-4 mb-3"
            btnLabel="View"
            icon={<GrShare className="text-white text-lg stroke-current" />}
            onBtnClick={handleClose}
          >
            <p>
              You can change your website design at any time and preview it.
            </p>
          </Alert>

          <section>
            {themes.map((theme) => (
              <div className="grid grid-cols-12 gap-3 mb-10" key={theme.id}>
                <div
                  className="col-span-6 relative rounded-lg overflow-hidden shadow-sm"
                  style={{ minHeight: '50vh' }}
                >
                  <img
                    src={theme.image}
                    className="absolute top-0 left-0 w-full h-full object-cover object-left-top"
                  />
                </div>
                <div className="col-span-6">
                  <h2>
                    <SectionTitle>{theme.name}</SectionTitle>
                    <ShowIf condition={theme.id === selectedTheme?.id}>
                      <span className="text-green-400"> (active)</span>
                    </ShowIf>
                  </h2>
                  <div className="text-gray-500 text-sm">
                    <ShowIf condition={!!theme.price} elseTemplate="Free">
                      $
                      {(theme?.price || 0) / 100}/month
                    </ShowIf>
                  </div>

                  <div className="my-3">
                    <Button
                      className="bg-gray-100 inline-block w-auto text-base px-4"
                      type="button"
                      round
                      onClick={() => goToPreview(theme.id)}
                    >
                      {theme.id === selectedTheme?.id ? 'Edit' : 'Preview'}
                    </Button>
                  </div>

                  <section className="mb-3">{theme.desc}</section>

                  <div className="text-gray-500 text-sm">By {theme.author}</div>
                </div>
              </div>
            ))}
          </section>
        </div>
      </Page>
    </SlideInModal>
  );
};

export default Design;
