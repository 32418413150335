import React from 'react';
import {
  Actions,
  ActionsGroup,
  ActionsLabel,
  ActionsButton,
} from 'framework7-react';
import Img from '../../lib/Img/Img';

interface InstaProfileConfirmActionProps {
  name?: string;
  profileImg?: string;
  open?: boolean;
  onClose?: (e?: any) => void;
  onConfirm?: (e?: any) => void;
  onDecline?: (e?: any) => void;
}

const InstaProfileConfirmAction: React.FunctionComponent<InstaProfileConfirmActionProps> = ({
  open,
  onClose,
  name,
  profileImg,
  onConfirm,
  onDecline,
}) => {
  return (
    <Actions opened={open} onActionsClosed={onClose}>
      <ActionsGroup>
        <ActionsLabel>
          <div className="flex items-center py-3">
            <span className="flex-grow flex-col flex">
              <span>Is this your instagram profile?</span>
              <span className="text-black text-lg mt-1">{name}</span>
            </span>
            <Img src={profileImg} size="48x48" className="ml-3 rounded-full" />
          </div>
        </ActionsLabel>
        <ActionsButton bold onClick={onConfirm}>
          Yes
        </ActionsButton>
        <ActionsButton color="red" onClick={onDecline}>
          No
        </ActionsButton>
      </ActionsGroup>
    </Actions>
  );
};
export default InstaProfileConfirmAction;
