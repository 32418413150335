import * as React from 'react';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import { Page, List, ListInput } from 'framework7-react';
import PageTitle from '../../lib/PageTitle/PageTitle';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';

interface IAboutEditorProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  onDone?: (e?: any) => void;
  bio?: string;
}
const AboutEditor: React.FC<IAboutEditorProps> = ({
  open,
  onClose,
  onDone,
  bio = '',
}) => {
  const [editData, setEditData] = React.useState<string>('');

  React.useEffect(() => {
    setEditData(bio);
    return () => {
      setEditData(bio);
    };
  }, [open]);

  function handleInputChange(e) {
    setEditData(e.target.value);
  }

  function handleDone() {
    onDone?.(editData);
  }

  return (
    <SlideInModal open={open} onClose={onClose} direction="right" fullScreen>
      <Page className="bg-white">
        <PopupNavbar
          backIcon
          className="my-2"
          onCancel={onClose}
          onDone={handleDone}
        />
        <section className="px-4">
          <PageTitle>About Us</PageTitle>
          <hr className="border-t border-gray-100 my-2" />

          <List noHairlines className="m-0 -mx-4">
            <ListInput
              type="textarea"
              resizable
              placeholder="About"
              name="bio"
              value={editData || ''}
              onChange={handleInputChange}
            />
          </List>
        </section>
      </Page>
    </SlideInModal>
  );
};

export default AboutEditor;
