import * as React from 'react';
import ProfileEditMenu from '../../components/ProfileEditMenu/ProfileEditMenu';
import { useHistory } from 'react-router';
import useToggle from '../../hooks/useToggle';
import useUserStore from '../../hooks/useUserStore';

interface IEditProfileProps {}
const EditProfile: React.FC<IEditProfileProps> = ({}) => {
  const history = useHistory();
  const open = useToggle(true);
  const { user } = useUserStore();

  function goBack() {
    open.close();
  }

  React.useEffect(() => {
    if (open.off) {
      history.push('/dashboard/profile');
    }
    let didCancel = false;
    if (!didCancel) {
    }
    return () => {
      didCancel = true;
    };
  }, [open.off]);

  if (!user) {
    return null;
  }
  return <ProfileEditMenu open={open.on} onClose={goBack} />;
};

export default EditProfile;
