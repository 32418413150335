import { useDispatch, useSelector } from 'react-redux';
import { IAppStore } from '../types/appStore';
import { setNavLinkAction } from '../actions/navLink';
import { INavLink } from '../types/navLink';

export default function useNavLinkStore() {
  const dispatch = useDispatch();

  const navLinks = useSelector((store: IAppStore) => store.navLinks);

  const setNavLinks = (payload: INavLink[]) =>
    dispatch(setNavLinkAction(payload));

  return {
    navLinks,
    setNavLinks,
  };
}
