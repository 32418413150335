import * as React from 'react';
import { IAppointment } from '../../types/appointment';
import { List, ListItem } from 'framework7-react';
import dayjs from 'dayjs';
import AppointmentItem from '../AppointmentItem/AppointmentItem';
import useSelectItem from '../../hooks/useSelectItem';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import AppointmentDetail from '../AppointmentDetail/AppointmentDetail';
import useUserStore from '../../hooks/useUserStore';
import { groupBy } from 'lodash';
import EventForm from '../EventForm/EventForm';
import ShowIf from '../../lib/ShowIf/ShowIf';
import useBackendApi from '../../hooks/useBackendApi';
import useToast from '../../hooks/useToast';

interface IAppointmentListProps {
  selectedDate?: string;
  statusFilter?: any;
  showDatesAfter?: Date;
  search?: string;
}
const AppointmentList: React.FC<IAppointmentListProps> = ({
  selectedDate,
  statusFilter,
  search,
  showDatesAfter,
}) => {
  const { user, setAppointment } = useUserStore();
  const { patch } = useBackendApi();
  const { successToast } = useToast();
  let apts = user.appointments as any;

  /* Filter if filter present */
  if (apts && statusFilter) {
    apts = filterAptsByStatus(apts, statusFilter);
  }

  /* Search filter if any present */
  if (apts && search) {
    apts = filterAptsBySearch(apts, search);
  }

  /* Filter by dates after X */
  if (apts && showDatesAfter) {
    apts = filterAptsByDateAfter(apts, showDatesAfter);
  }
  const [
    selectedAppointment,
    setSelectedAppointment,
    clearSelectedAppointment,
  ] = useSelectItem();

  const [editingApt, setEditingApt] = React.useState<any>();

  function filterAptsBySearch(apts, searchTerm) {
    if (!apts) return;
    let totalApts = getTotalAptsArray(apts);
    totalApts = totalApts.filter((i: any) =>
      JSON.stringify(i).toLowerCase().includes(searchTerm.toLowerCase())
    );
    return groupBy(totalApts, 'date');
  }
  function filterAptsByStatus(apts, status) {
    if (!apts) return {};
    let totalApts = getTotalAptsArray(apts);
    totalApts = totalApts.filter((i: any) => i.status === status);
    return groupBy(totalApts, 'date');
  }
  function filterAptsByDateAfter(apts, date) {
    if (!apts) return;
    let totalApts = getTotalAptsArray(apts);
    totalApts = totalApts.filter(
      (i: any) => i.date >= dayjs(date).format('YYYY-MM-DD')
    );
    return groupBy(totalApts, 'date');
  }

  function getTotalAptsArray(aptObj) {
    let totalApts = [];
    Object?.keys(apts)?.map((date: any) => {
      totalApts = totalApts.concat(apts[date]);
    });
    return totalApts;
  }

  function handleEditClick(apt) {
    console.log(apt);
    setEditingApt({
      ...apt,
      date: [dayjs(apt.date).toDate()],
      paymentDueDate: [dayjs(apt.paymentDueDate).toDate()],
    });
  }

  async function handleAptUpdate(apt) {
    const updated = await patch('/v1/appointment', apt);
    setAppointment(updated);
    setSelectedAppointment(updated);
    setEditingApt(undefined);
    successToast('Successfully saved!');
  }

  return (
    <div>
      {!selectedDate && apts && (
        <>
          {Object?.keys(apts)?.map((key) => {
            return (
              <React.Fragment key={key}>
                <header
                  className="py-1 px-4 bg-gray-100 font-semibold text-sm"
                  key={key}
                >
                  {dayjs(key).format('ddd, DD MMMM YYYY')}
                </header>
                <div className="border-b border-gray-200">
                  {apts[key].map &&
                    apts[key].map((app) => (
                      <AppointmentItem
                        appointment={app}
                        onClick={setSelectedAppointment}
                        key={app._id}
                      />
                    ))}
                </div>
              </React.Fragment>
            );
          })}
        </>
      )}

      {!!selectedDate && apts[selectedDate] && (
        <div className="border-b border-gray-200">
          {apts[selectedDate].map &&
            apts[selectedDate].map((app) => (
              <AppointmentItem
                appointment={app}
                onClick={setSelectedAppointment}
                key={app._id}
              />
            ))}
        </div>
      )}

      <ShowIf condition={!selectedDate && (!apts || !Object.keys(apts).length)}>
        <div className="rounded bg-gray-100 text-gray-500 text-center mx-4 py-2">
          No Appointment
        </div>
      </ShowIf>

      <ShowIf condition={!!selectedDate && !apts[selectedDate]?.length}>
        <div className="rounded bg-gray-100 text-gray-500 text-center  mx-4 py-2">
          No Appointment for this day
        </div>
      </ShowIf>

      {/* Edit existing appointment */}
      <EventForm
        mode="edit"
        appointment={editingApt}
        open={!!editingApt}
        onClose={() => setEditingApt(undefined)}
        onSave={handleAptUpdate}
      />

      <AppointmentDetail
        open={!!selectedAppointment}
        onClose={clearSelectedAppointment}
        appointment={selectedAppointment}
        setSelectedAppointment={setSelectedAppointment}
        onEdit={handleEditClick}
      />
    </div>
  );
};

export default AppointmentList;
