import React from 'react';
import { Page, Button } from 'framework7-react';
import { isTokenExpired, getUserDataFromStorage } from '../../utils';
import { useHistory } from 'react-router';
import PageTitle from '../../lib/PageTitle/PageTitle';
import LoginWithFacebookBtn from '../../components/LoginWithFacebookBtn/LoginWithFacebookBtn';
import StarRating from '../../lib/StarRating/StarRating';
import LandingPageTestimonies from '../../components/LandingPageTestimonies/LandingPageTestimonies';
import Logo from '../../components/Logo/Logo';

interface LandingProps {}

const Landing: React.FunctionComponent<LandingProps> = ({}) => {
  const history = useHistory();

  React.useEffect(() => {
    const storageData = getUserDataFromStorage();
    if (storageData.token && !isTokenExpired(storageData.token)) {
      history.push('/dashboard');
    }
  }, []);

  function gotToSignup() {
    history.push('/signup');
  }

  function goToLogin() {
    history.push('/login');
  }

  return (
    <Page>
      <div className="px-5 py-4">
        <header className="mb-10">
          <h1 className="flex text-3xl">
            Hi, I'm
            <div className="w-20 pt-1 ml-2 text-primary">
              <Logo />
            </div>
          </h1>
          <p className="text-2xl">Let's grow your makeup business</p>

          <p className="text-gray-500 flex-center-y">
            website
            <i className="mx-1 fas fa-circle" style={{ fontSize: '.3em' }} />
            leads
            <i className="mx-1 fas fa-circle" style={{ fontSize: '.3em' }} />
            payments
            <i className="mx-1 fas fa-circle" style={{ fontSize: '.3em' }} />
            reviews
            <i className="mx-1 fas fa-circle" style={{ fontSize: '.3em' }} />
          </p>
        </header>

        <div className="my-4">
          <LandingPageTestimonies />
        </div>

        <section className="mt-10">
          <Button outline large className="mb-3 text-lg" onClick={gotToSignup}>
            Sign Up - it's free
          </Button>

          <LoginWithFacebookBtn />

          <div className="mt-3">
            <button
              className="py-2 text-lg font-semibold rounded-lg flex-center focus:outline-none active:opacity-50 active:bg-translucent-500"
              onClick={goToLogin}
            >
              <span>Log In</span>
              <i className="ml-2 text-xl far fa-chevron-right" />
            </button>
          </div>
        </section>
      </div>
    </Page>
  );
};
export default Landing;
