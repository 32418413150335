import React from 'react';
import { FacebookProvider, LoginButton } from 'react-facebook';
import useToast from '../../hooks/useToast';
import useBackendApi from '../../hooks/useBackendApi';
import { setUserDataToStorage } from '../../utils';
import { useHistory } from 'react-router';
import { Button } from 'framework7-react';

interface ILoginWithFacebookBtnProps {
  label?: string;
}
const LoginWithFacebookBtn: React.FC<ILoginWithFacebookBtnProps> = ({
  label = 'Continue with Facebook',
}) => {
  const { errorToast, catchErrorToast } = useToast();
  const { post } = useBackendApi();
  const history = useHistory();

  const responseFacebook = (response) => {
    const email = response?.profile?.email;
    const fbUserId = response?.profile?.id;
    const fbToken = response?.tokenDetail?.accessToken;
    if (!response?.profile?.email) {
      return errorToast(
        'No email present on Facebook account. Please use email/password.'
      );
    }
    handleFbAuth({ email, fbUserId, fbToken });
  };

  async function handleFbAuth(fbAuthObj) {
    try {
      let data = await post(`/v1/auth/fb-login`, fbAuthObj);
      setUserDataToStorage({ token: data.token });
      return history.push('/dashboard');
    } catch (err) {
      catchErrorToast(err);
      console.error(err);
    }
  }
  const errorFacebook = (response) => {
    console.log(response);
  };

  return (
    <FacebookProvider appId="164650904876568">
      <LoginButton
        scope="email"
        onCompleted={responseFacebook}
        onError={errorFacebook}
      >
        <Button large fill className="w-full mb-4">
          <i className="static mr-2 fab fa-facebook-square" /> {label}
        </Button>
      </LoginButton>
    </FacebookProvider>
  );
};

export default LoginWithFacebookBtn;
