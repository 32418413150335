import React from 'react';
import './ReviewTemplateCard.scss';
import { IReview } from '../../types/review';
import classnames from 'classnames';
import ShowIf from '../../lib/ShowIf/ShowIf';
import { IUser } from '../../types/user';
import ReviewTemplatePattern from './ReviewTemplatePattern/ReviewTemplatePattern';
import Img from '../../lib/Img/Img';
import { getDataUrl } from '../../utils';
import ReviewStoryTemplateCard from '../ReviewStoryTemplateCard/ReviewStoryTemplateCard';
import { IReviewCardTemplate } from '../../types/reviewCardTemplate';
import ReviewFeedTemplateCard from '../ReviewFeedTemplateCard/ReviewFeedTemplateCard';

interface ReviewTemplateCardProps {
  review?: IReview;
  template?: IReviewCardTemplate;
  logo?: string;
  onImgLoaded?: (e?: any) => void;
  source?: string;
}

const ReviewTemplateCard: React.FunctionComponent<ReviewTemplateCardProps> = ({
  review,
  template,
  logo,
  onImgLoaded,
  source = 'nishi',
  // type = 'feed',
}) => {
  const useOriginalImg = false;
  // const [logo, setLogo] = React.useState<string>('');
  // const [img1, setImg1] = React.useState<string>('');
  // const [img2, setImg2] = React.useState<string>('');

  // React.useEffect(() => {
  //   init();
  // }, [template]);

  // async function init() {
  //   if (type === 'feed') {
  //     if (!useOriginalImg) {
  //       const noImgRequired = ['default', '1'];
  //       if (!noImgRequired.includes(template)) {
  //         const logoRequired = ['2', '4'];
  //         if (logoRequired.includes(template)) {
  //           await setDataUrl(user?.profileImg, setLogo);
  //         }
  //         const img1Required = ['2', '3', '4'];
  //         if (img1Required.includes(template)) {
  //           await setDataUrl(review?.images?.[0], setImg1);
  //         }
  //         const img2Required = ['4'];
  //         if (img2Required.includes(template)) {
  //           await setDataUrl(review?.images?.[1], setImg2);
  //         }
  //       }
  //     }
  //     onImgLoaded?.();
  //   }
  // }

  async function setDataUrl(url, setter) {
    const data = await getDataUrl(url);
    setter(data);
  }

  return (
    <ShowIf
      condition={template?.type === 'feed'}
      elseTemplate={
        <ReviewStoryTemplateCard
          review={review}
          template={template}
          logo={logo}
          onImgLoaded={onImgLoaded}
          source={source}
        />
      }
    >
      <ReviewFeedTemplateCard
        review={review}
        template={template}
        logo={logo}
        onImgLoaded={onImgLoaded}
        source={source}
      />
    </ShowIf>
  );
};
export default ReviewTemplateCard;
