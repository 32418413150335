import * as React from 'react';
import { Actions, ActionsGroup, ActionsButton } from 'framework7-react';
import BtnContent from '../../lib/BtnContent/BtnContent';
import Img from '../../lib/Img/Img';

interface IAlbumMenuProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  coverImg?: string;

  enableInstaSync?: boolean;
  enableAlbumRename?: boolean;
  enableDeleteAlbum?: boolean;

  onAdd?: (e?: any) => void;
  onInstaSync?: (e?: any) => void;
  onChangeCoverImg?: (e?: any) => void;
  onRename?: (e?: any) => void;
  onReorder?: (e?: any) => void;
  onDelete?: (e?: any) => void;
}
const AlbumMenu: React.FC<IAlbumMenuProps> = ({
  open,
  onClose,
  enableInstaSync,
  enableAlbumRename,
  enableDeleteAlbum,
  coverImg,
  onAdd,
  onInstaSync,
  onChangeCoverImg,
  onRename,
  onReorder,
  onDelete,
}) => {
  return (
    <Actions opened={open} onActionsClosed={onClose}>
      <ActionsGroup>
        <ActionsButton onClick={onAdd}>
          <BtnContent icon={<i className="fal fa-plus text-2xl" />}>
            Add Photos
          </BtnContent>
        </ActionsButton>

        {enableInstaSync && (
          <ActionsButton onClick={onInstaSync}>
            <BtnContent
              icon={<i className="fab fa-instagram relative text-3xl" />}
            >
              Get from Instagram
            </BtnContent>
          </ActionsButton>
        )}

        <ActionsButton onClick={onChangeCoverImg}>
          <BtnContent
            icon={<Img src={coverImg} size="32x32" className="rounded-lg" />}
          >
            Change cover photo
          </BtnContent>
        </ActionsButton>

        {enableAlbumRename && (
          <ActionsButton onClick={onRename}>
            <BtnContent icon={<i className="fal fa-info-circle text-2xl" />}>
              Change album name
            </BtnContent>
          </ActionsButton>
        )}

        <ActionsButton onClick={onReorder}>
          <BtnContent icon={<i className="fal fa-list text-2xl" />}>
            Reorder photos
          </BtnContent>
        </ActionsButton>

        <ActionsButton onClick={onDelete} color="red">
          <BtnContent
            icon={<i className="fal fa-exclamation-circle text-2xl" />}
          >
            Delete photos {enableDeleteAlbum && 'or album'}
          </BtnContent>
        </ActionsButton>
      </ActionsGroup>
      <ActionsGroup>
        <ActionsButton bold>Cancel</ActionsButton>
      </ActionsGroup>
    </Actions>
  );
};

export default AlbumMenu;
