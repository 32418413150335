import * as React from 'react';
import { IoIosStar } from 'react-icons/io';
import { Button } from 'framework7-react';
import useToggle from '../../hooks/useToggle';
import ProLearnMore from '../ProLearnMore/ProLearnMore';
import UpgradeToPro from '../UpgradeToPro/UpgradeToPro';
import Portal from '../../lib/Portal/Portal';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';

interface IProAccountBlockProps {
  onUpgrade?: (e?: any) => void;
}
const ProAccountBlock: React.FC<IProAccountBlockProps> = ({ onUpgrade }) => {
  const detailModal = useToggle();

  function handleUpgrade() {
    detailModal.close();
    onUpgrade?.();
  }

  return (
    <>
      <div className="p-3 flex items-center bg-gray-100 w-full rounded-lg">
        <div
          className="p-2 text-blue-500 mr-3 rounded-lg text-3xl"
          style={{ background: 'rgba(102, 175, 255, 0.3411764705882353)' }}
        >
          <IoIosStar />
        </div>
        <section>
          <div className="font-semibold text-lg">Pro Account</div>
          <div className="text-sm text-gray-500">just $11.99/month</div>
        </section>
        <div className="ml-auto">
          <Button small raised fill round onClick={detailModal.open}>
            learn
          </Button>
        </div>
      </div>
      <ProLearnMore
        open={detailModal.on}
        onClose={detailModal.close}
        onUpgrade={handleUpgrade}
      />
    </>
  );
};

export default ProAccountBlock;
