import { IUser, EWizardStep } from '../types/user';
import { SET_USER } from '../actions/types';

const initialState: IUser = {
  _id: '',
  email: '',
  businessName: '',
  instaUsername: '',
  kit: [],
  location: {
    city: '',
    formatted: '',
  },
  mobile: '',
  wizardStep: EWizardStep.businessName,
  facebookPage: '',
  slogan: '',
  subscriptions: [],
  reviewCardTemplate: 'default',
};

export default function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_USER:
      return { ...state, ...payload };
    default:
      return state;
  }
}
