import * as React from 'react';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import { Page, Toggle } from 'framework7-react';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import PageTitle from '../../lib/PageTitle/PageTitle';
import dayjs from 'dayjs';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import { IAppointment } from '../../types/appointment';
import AppointmentList from '../AppointmentList/AppointmentList';

interface IAppointmentDayPlannerProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  date?: string;
  onAdd?: (e?: any) => void;
}

const AppointmentDayPlanner: React.FC<IAppointmentDayPlannerProps> = ({
  open,
  onClose,
  date,
  onAdd,
}) => {
  const appointments: IAppointment[] = [
    {
      _id: '1232',
      userId: '123',
      status: 'booked',
      requestId: '123',
      pricing: {
        total: 123,
        deposit: 10,
      },
      ported: true,
      paid: true,
      paymentDueDate: '2020-05-12',
      location: {
        city: 'Sydney',
        formatted: '12 Hill ROad, West Pennant Hills',
      },
      date: '27-05-2020',
      custom: true,
      time: {
        from: '13:30',
        to: '14:30',
      },
      services: [
        {
          title: 'Makeup',
          subTitle: 'Bridal',
        },
      ],
      description: ` lorem lorem`,
      images: [],
      customerId: `123`,
      customerName: 'Mko',
      customerEmail: 'examplecutomer@example.com',
      customerMobile: '+61 401 123 123',
    },
    {
      _id: '1034',
      userId: '123',
      status: 'offered',
      requestId: '123',
      pricing: {
        total: 123,
        deposit: 10,
      },
      ported: true,
      paid: true,
      paymentDueDate: '2020-05-12',
      location: {
        city: 'Sydney',
        formatted: '12 Hill ROad, West Pennant Hills',
      },
      date: '27-05-2020',
      custom: true,
      time: {
        from: '13:30',
        to: '14:30',
      },
      services: [
        {
          title: 'Makeup',
          subTitle: 'Bridal',
        },
      ],
      description: ` lorem lorem`,
      images: [],
      customerId: `123`,
      customerName: 'PJ the DJ',
      customerEmail: 'examplecutomer@example.com',
      customerMobile: '+61 401 123 123',
    },
    {
      _id: '1234',
      userId: '123',
      status: 'expired',
      requestId: '123',
      pricing: {
        total: 123,
        deposit: 10,
      },
      ported: true,
      paid: true,
      paymentDueDate: '2020-05-12',
      location: {
        city: 'Sydney',
        formatted: '12 Hill ROad, West Pennant Hills',
      },
      date: '27-05-2020',
      custom: true,
      time: {
        from: '13:30',
        to: '14:30',
      },
      services: [
        {
          title: 'Makeup',
          subTitle: 'Bridal',
        },
      ],
      description: ` lorem lorem`,
      images: [],
      customerId: `123`,
      customerName: 'Rosh',
      customerEmail: 'examplecutomer@example.com',
      customerMobile: '+61 401 123 123',
    },
  ];

  function handleAdd() {
    onAdd?.(date);
  }

  return (
    <SlideInModal
      open={open}
      onClose={onClose}
      direction="right"
      fullScreen
      belowPopup
    >
      <Page>
        <PopupNavbar
          onCancel={onClose}
          letfBtnLabel="Back"
          sticky
          backIcon
          rightBtnLabel={<i className="fal fa-plus text-xl"></i>}
          onDone={handleAdd}
        />

        <header className="px-4">
          <h2>
            <PageTitle>
              {dayjs(date).format('DD MMMM')}{' '}
              <span className="font-normal">{dayjs(date).format('YYYY')}</span>{' '}
            </PageTitle>
          </h2>
          <div className="uppercase text-gray-500 font-semibold">
            {dayjs(date).format('dddd')}
          </div>
        </header>

        {/* <section className="px-4 mt-3">
          <div className="flex-center-y text-lg border-b border-gray-200 pb-3">
            <span>Available for new gigs</span>

            <div className="ml-auto">
              <Toggle color="green" checked />
            </div>
          </div>

          <div className="mt-2 text-sm text-gray-500">
            Customers will still be able to place requests for this day
          </div>
        </section> */}

        <div className="mt-5">
          <h2 className="px-4 pb-3 z-10 relative bg-white">
            <SectionTitle>Appointments</SectionTitle>
          </h2>

          <AppointmentList selectedDate={date} />
        </div>
      </Page>
    </SlideInModal>
  );
};

export default AppointmentDayPlanner;
