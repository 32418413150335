import * as React from 'react';
import ProStar from '../../lib/ProStar/ProStar';
import ShowIf from '../../lib/ShowIf/ShowIf';
import useUserStore from '../../hooks/useUserStore';
import { isProSubscribed } from '../../utils';

interface IProfileListItemProps {
  name?: string;
  value?: React.ReactNode;
  onClick?: (e?: any) => void;
  colWidth?: string[];
  proFeature?: boolean;
  onProFeatureClick?: (e?: any) => void;
  placeholder?: string;
  id?: string;
}
const ProfileListItem: React.FC<IProfileListItemProps> = ({
  name,
  value,
  onClick,
  colWidth = ['4', '8'],
  proFeature,
  onProFeatureClick,
  id,
  placeholder,
}) => {
  const { user } = useUserStore();

  function handleItemClick() {
    if (proFeature && !isProSubscribed(user.subscriptions)) {
      return onProFeatureClick?.();
    }
    onClick?.(id || name);
  }

  return (
    <div
      className="grid grid-cols-12 border-b border-gray-200 items-center py-3 px-1 text-base gap-1"
      onClick={handleItemClick}
    >
      <div className={`col-span-${colWidth[0]} flex-center-y`}>
        <span>{name}</span>
        <ShowIf condition={proFeature}>
          <ProStar className="opacity-50 ml-3" />
        </ShowIf>
      </div>
      <div className={`col-span-${colWidth[1]}`}>
        <ShowIf condition={!!value}>{value}</ShowIf>
        <span className="text-gray-500 lowercase">
          <ShowIf condition={!value}>
            <ShowIf condition={!placeholder} elseTemplate={placeholder}>
              Add {name}
            </ShowIf>
          </ShowIf>
        </span>
      </div>
    </div>
  );
};

export default ProfileListItem;
