import Axios from "axios";
import useLoaderStore from "./useLoaderStore";
import useToast from "./useToast";

const useInstagramApi = () => {
  const { showLoader, hideLoader } = useLoaderStore();
  const { errorToast } = useToast();

  const fetch = async (param: string, includeNext: boolean = false) => {
    try {
      showLoader()
      const result = (await Axios.get(`https://api.instacloud.io/${param}`)).data;
      hideLoader();
      if (includeNext) {
        return { data: result?.data, next: result?.pagination?.next_url }
      }
      return result?.data;
    } catch (err) {
      errorToast(err.toString());
      hideLoader();
      throw err;
    }
  }

  return {
    fetch
  };
}



export default useInstagramApi;
