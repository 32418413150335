import React from 'react';

interface ISquareContentProps {
  className?: string;
}
const SquareContent: React.FC<ISquareContentProps> = ({
  children,
  className = '',
}) => {
  return (
    <div className="square">
      <div className={'square-inner ' + className}>{children}</div>
    </div>
  );
};

export default SquareContent;
