import * as React from 'react';
import Logo from '../../components/Logo/Logo';
import classnames from 'classnames';
interface INavbarProps {
  leftContent?: React.ReactNode;
  centerConetnt?: React.ReactNode;
  rightContent?: React.ReactNode;
  className?: string;
  zIndex?: string;
  logo?: boolean;
  sticky?: boolean;
  style?: React.CSSProperties;
}
const Navbar: React.FC<INavbarProps> = ({
  leftContent,
  rightContent,
  centerConetnt,
  className = '',
  zIndex = '10',
  logo,
  sticky = true,
  style = {},
}) => {
  return (
    <nav
      className={classnames(
        `flex-center-y justify-between  w-full  px-3 backdrop-blur-20 bg-translucent-400 z-${zIndex} ${className}`,
        {
          'sticky top-0': sticky,
        }
      )}
      style={{ height: 48, ...style }}
    >
      {!!logo && <Logo style={{ width: 55 }} className="text-primary" />}
      {!logo && leftContent}
      {centerConetnt}
      {rightContent}
    </nav>
  );
};

export default Navbar;
