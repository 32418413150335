import * as React from 'react';
import ProAccountBlock from '../ProAccountBlock/ProAccountBlock';
import { Button } from 'framework7-react';
import useUserStore from '../../hooks/useUserStore';
import ShowIf from '../../lib/ShowIf/ShowIf';

interface IProFeatureProps {
  onUpgrade?: (e?: any) => void;
  onClose?: (e?: any) => void;
}
const ProFeature: React.FC<IProFeatureProps> = ({ onClose, onUpgrade }) => {
  const { user } = useUserStore();
  return (
    <div className="w-full p-2">
      <ProAccountBlock onUpgrade={onUpgrade} />
      <div className="mt-3">
        <div className="text-4xl font-bold">
          You've discovered a pro feature
        </div>
      </div>

      <div className="mt-3 text-xl">
        Pro accounts give you a ton of features like custom templates all for
        just $11.99/month
      </div>

      <div className="mt-5 pt-5">
        <Button raised fill large onClick={onUpgrade}>
          <ShowIf
            condition={user.hadTrial}
            elseTemplate="Try it for free for 14 days!"
          >
            Upgrade to Pro for $11.99/month
          </ShowIf>
        </Button>
      </div>
      <div className="mt-3">
        <Button large onClick={onClose}>
          or maybe later
        </Button>
      </div>
    </div>
  );
};

export default ProFeature;
