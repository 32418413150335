import React from 'react';
import useUserStore from '../../hooks/useUserStore';
import ImgUploader from '../../lib/ImgUploader/ImgUploader';
import UploadImgBox from '../../lib/UploadImgBox/UploadImgBox';
import useSelectItem from '../../hooks/useSelectItem';

interface ProfileImgSetupProps {}

const ProfileImgSetup: React.FunctionComponent<ProfileImgSetupProps> = ({}) => {
  const { user, setUser } = useUserStore();
  const [uploadingImg, setUploadingImg, clearUploadingImg] = useSelectItem();

  function handleUploadComplete(e) {
    setUser({ profileImg: e.target.value });
    clearUploadingImg();
  }

  return (
    <div className="flex-center my-10">
      <div className="flex-none w-1/2">
        <ImgUploader
          onUploadStart={setUploadingImg}
          onUploadComplete={handleUploadComplete}
        >
          <UploadImgBox
            img={uploadingImg || user.profileImg}
            uploading={!!uploadingImg}
          />
        </ImgUploader>
      </div>
    </div>
  );
};
export default ProfileImgSetup;
