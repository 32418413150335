import React from 'react';
import { IFooterTabItem } from '../../types/footerTabItem';
import { NavLink } from 'react-router-dom';

interface TabBarProps {
  items: IFooterTabItem[];
}

const TabBar: React.FunctionComponent<TabBarProps> = ({ items }) => {
  return (
    <div className="sticky z-20 top-0 w-full p-2">
      <div
        className="text-white rounded-lg flex justify-between overflow-hidden py-2 px-3 backdrop-blur-20"
        style={{ background: 'hsla(216, 100%, 57%, .7)' }}
      >
        {items.map((item) => (
          <div key={item.link} className="flex">
            <NavLink
              to={item.link || ''}
              activeClassName="bg-blue-700 rounded-full"
              className="external block m-0 py-2 px-3"
            >
              <div className="text-sm leading-3">{item.label}</div>
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
};
export default TabBar;
