import React from 'react';
import SquareContent from '../../lib/SquareContent/SquareContent';
import CloseBtn from '../../lib/CloseBtn/CloseBtn';
import { Button } from 'framework7-react';
import ShowIf from '../../lib/ShowIf/ShowIf';

interface ISuggestionCardProps {
  title?: string;
  text?: string;
  truncate?: boolean;
  onView?: (e?: any) => void;
  onDismiss?: (e?: any) => void;
  hide?: boolean;
  name?: string;
  ctaBtnFill?: boolean;
  className?: string;
  id?: string;
}
const SuggestionCard: React.FC<ISuggestionCardProps> = ({
  title,
  onDismiss,
  text,
  truncate,
  id,
  onView,
  hide,
  name,
  className = '',
  ctaBtnFill = true,
}) => {
  function handleDismiss(e) {
    e.stopPropagation();
    onDismiss?.(name);
  }

  if (hide) {
    return null;
  }

  return (
    <div
      className={'flex-none p-2 rounded-lg shadow ' + className}
      onClick={() => { onView?.(id) }}
    >
      <SquareContent className="flex flex-col">
        <div className="flex justify-between">
          <CloseBtn
            className="text-lg text-gray-700 bg-white"
            onClick={handleDismiss}
          />

          <Button
            round
            fill={ctaBtnFill}
            className={ctaBtnFill ? '' : 'bg-gray-100 text-gray-800'}
          >
            View
          </Button>
        </div>

        <div className="pt-1 mt-auto">
          <ShowIf
            condition={!truncate}
            elseTemplate={<p className="truncate">{text}</p>}
          >
            <p>{text}</p>
            <h3 className="text-xl font-semibold leading-tight">{title}</h3>
          </ShowIf>
        </div>
      </SquareContent>
    </div>
  );
};

export default SuggestionCard;
