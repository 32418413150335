import * as React from 'react';
import { GrSettingsOption } from 'react-icons/gr';
import { Button, Icon } from 'framework7-react';
import Notification from '../Notification/Notification';
import Navbar from '../../lib/Navbar/Navbar';

interface ICalendarNavBarProps {
  onAdd?: (e?: any) => void;
  onSearch?: (e?: any) => void;
  onSettingsClick?: (e?: any) => void;
}
const CalendarNavBar: React.FC<ICalendarNavBarProps> = ({
  onAdd,
  onSearch,
  onSettingsClick,
}) => {
  return (
    <Navbar
      leftContent={
        <div>
          {/* <Button
               type="button"
               className="text-xl flex"
               onClick={onSettingsClick}
             >
               <GrSettingsOption className="stroke-current" />
             </Button> */}
        </div>
      }
      rightContent={
        <div className="flex ml-auto">
          <Button type="button" className="navbar-btn" onClick={onSearch}>
            <i className="fal fa-search text-lg"></i>
          </Button>

          <Notification />

          <Button type="buttton" className="navbar-btn" onClick={onAdd}>
            <i className="fal fa-plus text-lg"></i>
          </Button>
        </div>
      }
    />
  );
};

export default CalendarNavBar;
