import * as React from 'react';
import './ProStar.scss';
import { IoIosStar } from 'react-icons/io';

interface IProStarProps {
  className?: string;
}
const ProStar: React.FC<IProStarProps> = ({ className = '' }) => {
  return (
    <div className={'ProStar text-primary  inline-block relative ' + className}>
      <div className="w-full relative">
        <div className="square bg-primary opacity-25 rounded-lg"></div>
        <div className="square-inner flex-center">
          <IoIosStar />
        </div>
      </div>
    </div>
  );
};

export default ProStar;
