import React from 'react';
import { Link } from 'react-router-dom';

interface INotFoundPageProps {}
const NotFoundPage: React.FC<INotFoundPageProps> = ({}) => {
  return (
    <div className="w-screen h-screen flex-center">
      <div className="p-5 mainbox">
        <div className="mb-5 text-6xl flex-center">
          <div className="err">4</div>
          <div className="px-3">
            <i className="fal fa-question-circle fa-spin"></i>
          </div>
          <div className="err2">4</div>
        </div>

        <div className="text-2xl text-center">
          Maybe this page moved? Got deleted? <br /> Is hiding out in
          quarantine? <br /> Never existed in the first place?
          <p className="mt-4 ">
            Let's go{' '}
            <Link to="/dashboard" className="text-blue-500 underline">
              Home
            </Link>{' '}
            and try from there.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
