import React from 'react';

export default function useSelectItem<T>(
  initial: any = undefined,
  resetWithInitial = true
) {
  const [state, setState]: [T, (e: T) => void] = React.useState<T>(initial);

  function reset() {
    setState(resetWithInitial ? initial : undefined);
  }

  return [state, setState, reset];
}
