import React from 'react';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import { IUserSuggestion } from '@sprajwal078/nishi-types';
import SuggestionCard from '../SuggestionCard/SuggestionCard';

interface IFeaturesSuggestionsListProps {
  suggestions?: IUserSuggestion[];
  onDismiss?: (e?: any) => void;
  onView?: (e?: any) => void;
}

const bgs = [
  'bg-gradient-blue',
  'bg-gradient-green',
  'bg-gradient-purple',
  'bg-gradient-gray',
];
function getSuggestionBg(index) {
  return bgs[index % bgs.length];
}

const FeaturesSuggestionsList: React.FC<IFeaturesSuggestionsListProps> = ({
  suggestions = [],
  onDismiss,
  onView,
}) => {
  if (!suggestions.filter((sug) => sug.status === 'visible').length) {
    return null;
  }
  return (
    <div>
      <h2 className="mb-3">
        <SectionTitle size="2xl">Suggestions</SectionTitle>
      </h2>

      <div className="flex w-full pb-3 overflow-x-auto">
        {suggestions
          .filter((sug) => sug.status === 'visible')
          .map((sug, idx) => (
            <SuggestionCard
              key={sug._id}
              id={sug._id}
              title={sug.item.title}
              text={sug.item.subTitle}
              className={`${getSuggestionBg(idx)} text-white mr-2 w-9/20`}
              ctaBtnFill={false}
              name={sug._id}
              onDismiss={onDismiss}
              onView={onView}
            />
          ))}
      </div>
    </div>
  );
};

export default FeaturesSuggestionsList;
