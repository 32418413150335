import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import DashboardRoutes from '../DashboardRoutes/DashboardRoutes';
import Login from '../../pages/Login/Login';
import ForgotPassword from '../../pages/ForgotPassword/ForgotPassword';
import ResetPassword from '../../pages/ResetPassword/ResetPassword';
import PrivateRoute from '../../lib/PrivateRoute/PrivateRoute';
import Signup from '../../pages/Signup/Signup';
import VerifyEmail from '../../pages/VerifyEmail/VerifyEmail';
import { f7Context } from '../..';
import useLoaderStore from '../../hooks/useLoaderStore';
import RequestReview from '../../pages/RequestReview/RequestReview';
import ReviewCard from '../../pages/ReviewCard/ReviewCard';
import Impersonate from '../../pages/Impersonate/Impersonate';
import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage';
import Landing from '../../pages/Landing/Landing';

interface AppRoutesProps { }

const AppRoutes: React.FunctionComponent<AppRoutesProps> = ({ }) => {
  const { f7 } = useContext(f7Context);
  const { loader } = useLoaderStore();

  React.useEffect(() => {
    if (f7) {
      if (loader.loading) {
        f7.preloader.show();
      } else {
        f7.preloader.hide();
      }
    }
  }, [loader]);

  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <Route exact path="/landing" component={Landing} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/on-signup-complete" component={VerifyEmail} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/impersonate/:token" component={Impersonate} />
      <Route exact path="/login/:emailCode" component={Login} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/reset-password/:code" component={ResetPassword} />
      <Route exact path="/post-review/:userId" component={RequestReview} />
      <PrivateRoute path="/dashboard" component={DashboardRoutes} />
      <Route
        exact
        path="/review/generate-card/:reviewId"
        component={ReviewCard}
      />
      <Route exact path="/not-found" component={NotFoundPage} />
      <Redirect to={'/not-found'} />
    </Switch>
  );
};
export default AppRoutes;
