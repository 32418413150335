import * as React from 'react';
import { Popup, Page, Button } from 'framework7-react';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import useBackendApi from '../../hooks/useBackendApi';
import { IImage } from '../../types/image';
import PageTitle from '../../lib/PageTitle/PageTitle';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import ImgGrid from '../ImgGrid/ImgGrid';
import { selectDeselectItem, convertElfsightImageToNishi, sendEmail, sanitizeInstaUsername } from '../../utils';
import ShowIf from '../../lib/ShowIf/ShowIf';
import useToast from '../../hooks/useToast';
import useUserStore from '../../hooks/useUserStore';
import { useHistory } from 'react-router';
import useInstagramApi from '../../hooks/useInstagramApi';

interface IInstagramPickerV2Props {
  open?: boolean;
  onClose?: (e: any) => void;
  onAddPhotos?: (e?: any) => void;
  fetchAlbumData?: (userId?: string, loader?: boolean) => void;
  existingImages?: IImage[];
  enableAll?: boolean;
  albumId?: string;
  skipUpdate?: boolean;
}
const InstagramPickerV2: React.FC<IInstagramPickerV2Props> = ({
  open,
  onClose,
  onAddPhotos,
  fetchAlbumData,
  enableAll,
  albumId,
  skipUpdate,
  existingImages = [],
}) => {
  const [images, setImages] = React.useState<IImage[]>([]);
  const [next, setNext] = React.useState('');
  const [selectedImages, setSelectedImages] = React.useState<IImage[]>([]);
  const { catchErrorToast, errorToast, successToast } = useToast();
  const { get, post } = useBackendApi();
  const { user } = useUserStore();
  const { fetch } = useInstagramApi();

  const history = useHistory();

  async function fetchData(loader = true) {
    const { data, next }: any = (await fetch(`?count=12&path=/v1/users/${sanitizeInstaUsername(user.instaUsername)}/media/recent`, true) as any);


    if (!data?.length) {
      await sendEmail(['mko@flayr.com.au'], `${user.instaUsername} image api failed`, `${user.instaUsername} image api failed`)
    }
    const convertedImgs = data?.filter(i => i.type !== 'video')?.map((i) => convertElfsightImageToNishi(i, user._id || ''));

    //handle existing or non existing
    if (enableAll) {
      setImages(convertedImgs.map((m) => ({ ...m, existing: false })));
    } else {
      setImages(convertedImgs.map(m => ({ ...m, existing: isExistingImage(m, existingImages) })));
    }
    console.log(next);
    setNext(next);
  }

  async function loadMore() {
    const res: any = (await fetch(next, true) as any);
    const newImgs = res?.data?.map((i) => convertElfsightImageToNishi(i, user._id || ''));

    const newImages = [...images, ...newImgs];
    setImages(newImages);
    setNext(res.next || '');
  }

  React.useEffect(() => {
    let didCancel = false;
    if (!didCancel && open && !images.length) {
      fetchData();
    }
    return () => {
      didCancel = true;
      setSelectedImages([]);
    };
  }, [open]);



  function isExistingImage(image, existingImages) {
    const existingPermaLinks = existingImages.map((i: any) => i.permaLink);
    if (existingPermaLinks.includes(image.permaLink)) {
      return true;
    }
    return false;
  }

  function handleImgSelect(selection) {
    if (selection.existing) return; //if image already exists, dont do anything
    setSelectedImages(selectDeselectItem(selection, selectedImages));
  }

  async function handleAddPhotos() {
    if (!skipUpdate) {
      try {
        const result = await post('/v1/image/bulk-from-instagram', {
          images: selectedImages,
        });
        fetchData(false);
        fetchAlbumData?.(user._id, false);
        successToast('Sucessfully added ' + selectedImages.length + ' photos');
        setSelectedImages([]);
      } catch (err) {
        console.log(err);
        catchErrorToast(err);
      }
    }
    onAddPhotos?.(selectedImages);
  }

  return (
    <Popup opened={open} onPopupClosed={onClose} animate={false}>
      <Page className="bg-white">
        <PopupNavbar
          hideRightBtn
          onCancel={onClose}
          className="py-2 sticky z-10 bg-white top-0"
        />

        <h1 className="my-2 px-4">
          <PageTitle>Get from Instagram</PageTitle>

          <hr className="my-2 border-t border-gray-100" />
        </h1>

        <>
          <section className="px-4">
            <h2 className="my-2">
              <SectionTitle>Pick photos</SectionTitle>
            </h2>
          </section>
          <ImgGrid
            images={images}
            selectable
            selectedImages={selectedImages}
            onImageSelect={handleImgSelect}
          />
          {next && <Button onClick={loadMore}>Load More</Button>}

          <section className="mt-4 sticky bottom-0 bg-white z-10 p-4">
            <Button
              disabled={!selectedImages.length}
              onClick={handleAddPhotos}
              fill
              raised
              large
            >
              Add{' '}
              <ShowIf condition={!!selectedImages.length}>
                <span> {selectedImages.length} </span>
              </ShowIf>
                photos
              </Button>
          </section>
        </>
      </Page>
    </Popup>
  );
};

export default InstagramPickerV2;
