import React from 'react';
import { f7Context } from '../..';
import { List, ListInput } from 'framework7-react';
import useSelectItem from '../../hooks/useSelectItem';

interface KitInputProps {
  brands?: string[];
  existingKit?: any[];
  onSelect?: (e?: any) => void;
  inputId?: string;
  onAutoCompleteSelect?: (e?: any) => void;
}

const KitInput: React.FunctionComponent<KitInputProps> = ({
  brands = [],
  onSelect,
  inputId = 'input',
  existingKit = [],
  onAutoCompleteSelect,
}) => {
  const { f7 } = React.useContext(f7Context);
  // const inputValue: any = React.useRef('');
  const [kit, setKit, clearKit] = useSelectItem('');

  const kitRef: any = React.useRef('');
  const kitsRef: any = React.useRef(existingKit || []);

  React.useEffect(() => {
    let autocomplete;
    if (f7 && brands.length) {
      autocomplete = f7.autocomplete.create({
        inputEl: `#${inputId}`,
        openIn: 'dropdown',
        dropdownPlaceholderText: 'Try "MAC"',
        source: (search, render) => {
          if (!search) {
            return render([]);
          }
          return render(
            brands.filter((brand) => {
              const regex = new RegExp(search, 'i');
              return regex.test(brand);
            })
          );
        },
        on: {
          closed: (autocomplete) => {
            const value = autocomplete.value[0];
            if (value) {
              handleAddFromSelect(value);
            }
          },
        },
      });
    }
    return () => {
      if (autocomplete) {
        autocomplete.destroy();
      }
    };
  }, [brands]);

  function handleInputChange(e) {
    setKit(e.target.value);
  }

  function handleAdd() {
    if (kit) {
      onSelect?.(kit);
      clearKit();
    }
  }

  function handleClear() {
    clearKit();
    kitRef.current = '';
  }

  function handleAddFromSelect(name) {
    if (kitsRef.current.findIndex((k) => k.name === name) >= 0) {
      return handleClear();
    }
    const newKit = [...kitsRef.current, { name }];
    kitsRef.current = newKit;
    onAutoCompleteSelect?.(kitsRef.current);
    handleClear();
  }

  return (
    <div className="relative">
      <List noHairlines className="m-0">
        <ListInput
          outline
          label="Kit"
          floatingLabel
          type="text"
          placeholder="Your kit"
          name="kit"
          className="mb-4 -mx-4"
          inputId={inputId}
          value={kit}
          onChange={handleInputChange}
          autocomplete="off"
          autocorrect="off"
          autocapitalize="none"
          spellcheck="false"
        />
      </List>
      <div className="absolute top-0 right-0 z-10 flex items-center justify-end h-full pt-2">
        <button
          className="px-3 py-2 mr-2 focus:outline-none "
          onClick={handleAdd}
        >
          <i className="fal fa-check" />
        </button>
        <button
          className="px-3 py-2 mr-2 focus:outline-none "
          onClick={clearKit}
        >
          <i className="fal fa-times" />
        </button>
      </div>
    </div>
  );
};
export default KitInput;
