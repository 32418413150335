import React from 'react';
import { ISubItem } from '@sprajwal078/nishi-types';
import Navbar from '../../lib/Navbar/Navbar';
import BackBtn from '../../lib/BackBtn/BackBtn';
import SubscriptionPopup from '../SubscriptionPopup/SubscriptionPopup';
import PageTitle from '../../lib/PageTitle/PageTitle';
import Img from '../../lib/Img/Img';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import { formatCentsToReadable } from '../../utils';

interface FeaturePaymentFormProps {
  subItem?: ISubItem;
  onBack?: () => void;
  open?: boolean;
  onSubscribed?: (e?: any) => void;
}

const FeaturePaymentForm: React.FunctionComponent<FeaturePaymentFormProps> = ({
  subItem,
  onBack,
  onSubscribed,
  open,

}) => {
  return (
    <div className="flex flex-col">
      <Navbar leftContent={<BackBtn onClick={onBack} />} />
      <SubscriptionPopup
        open={open}
        onSubscribe={onSubscribed}
        subItem={subItem}
      >
        <h3>
          <PageTitle>Activate Skill</PageTitle>
        </h3>
        <section className="flex-center-y">
          <div>
            <Img
              src={subItem?.image}
              size="64x64"
              className="rounded-lg"
            />
          </div>
          <div className="ml-4 pb-3 border-b border-gray-200 flex-grow">
            <h2>
              <SectionTitle>{subItem?.name}</SectionTitle>
            </h2>
            <div className="text-base">
              ${formatCentsToReadable(subItem?.price)}/month
          </div>
            <div className="text-gray-600">cancel anytime</div>
          </div>
        </section>
      </SubscriptionPopup>
    </div>
  );
};
export default FeaturePaymentForm;