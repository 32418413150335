import * as React from 'react';
import {
  Popup,
  Page,
  Button,
  List,
  ListInput,
  PageContent,
} from 'framework7-react';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import PageTitle from '../../lib/PageTitle/PageTitle';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_KEY } from '../../constants';
import { isProSubscribed, isOnTrial } from '../../utils';
import useUserStore from '../../hooks/useUserStore';
import ShowIf from '../../lib/ShowIf/ShowIf';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import { ITheme } from '../../types/theme';
import useBackendApi from '../../hooks/useBackendApi';
import useToast from '../../hooks/useToast';

interface ISubscribeToThemeProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  onThemeSubscribe?: (e: any) => void;
  theme?: ITheme;
}
const stripePromise = loadStripe(STRIPE_KEY || '');

const SubscribeToTheme: React.FC<ISubscribeToThemeProps> = ({ open, onClose, theme, onThemeSubscribe }) => {
  const { post } = useBackendApi();
  const { successToast } = useToast();
  const handleThemeSubscribe = async (token: any) => {
    try {
      const result = await post('/v1/subscription', {
        subItemId: theme?.subItemId,
        token: token
      });
      onThemeSubscribe?.(result);
      console.log(result);
    } catch (err) {
      console.log(err);
    }
  }


  return (
    <Popup opened={open} onPopupClosed={onClose}>
      <Page className="bg-white" pageContent={false}>
        <PageContent className="flex flex-col py-16">
          <ShowIf condition={open}>
            <PopupNavbar sticky onCancel={onClose} hideRightBtn />
            <>
              <header className="px-4 mt-3">
                <PageTitle>Subscribe to {theme?.name} theme</PageTitle>
                <div className="mt-1">
                  <br />
                  <SectionTitle>
                    <small>
                      only ${(theme?.price || 0)} per month
                      </small>
                  </SectionTitle>
                </div>
              </header>
              <section className="flex-grow mt-5 pt-5 flex flex-col">
                <Elements stripe={stripePromise}>
                  <CheckoutForm buttonText={`Subscribe to theme`} onTokenCreate={handleThemeSubscribe} />
                </Elements>
              </section>
            </>

          </ShowIf>
        </PageContent>
      </Page>
    </Popup>
  );
};

export default SubscribeToTheme;
