import * as React from 'react';
import { Popup, Page, Button } from 'framework7-react';
import { IImage } from '../../types/image';
import ImgGrid from '../ImgGrid/ImgGrid';

interface IDeleteImagesProps {
  albumName?: string;
  images?: IImage[];
  open?: boolean;
  onClose?: (e?: any) => void;
  onDelete?: (images: IImage[]) => void;
}
const DeleteImages: React.FC<IDeleteImagesProps> = ({
  open,
  onClose,
  albumName,
  onDelete,
  images,
}) => {
  const [selectedImages, setSelectedImages] = React.useState<IImage[]>([]);

  function handleImgSelect(e) {
    const index = selectedImages.indexOf(e);
    if (index !== -1) {
      setSelectedImages([...selectedImages.filter((img) => img._id !== e._id)]);
    } else {
      setSelectedImages([...selectedImages, e]);
    }
  }

  function handleDeleteClick() {
    onDelete?.(selectedImages);
    setSelectedImages([]);
  }
  return (
    <Popup opened={open} onPopupClosed={onClose}>
      <Page className="bg-white">
        <nav className="p-2 flex sticky top-0 bg-white z-10">
          <Button onClick={onClose}>
            <span className="normal-case text-lg font-normal">Cancel</span>
          </Button>
        </nav>

        <section className="mt-2 text-3xl font-semibold px-4">
          {albumName}
        </section>

        <hr className="mt-1 border-t border-gray-100 mx-4 mb-2" />

        <section className="px-4">
          <div className="font-semibold text-lg mb-2">Pick photos</div>

          <ImgGrid
            images={images}
            selectable
            onImageSelect={handleImgSelect}
            selectedImages={selectedImages}
          />

          <div className="mt-4 sticky bottom-0 bg-white z-10 py-4">
            <Button
              raised
              large
              color="red"
              fill
              disabled={!selectedImages.length}
              onClick={handleDeleteClick}
            >
              Delete {!!selectedImages.length && selectedImages.length} photos
            </Button>
          </div>
        </section>
      </Page>
    </Popup>
  );
};

export default DeleteImages;
