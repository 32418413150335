import React from 'react';
import FeaturePreview from '../FeaturePreview/FeaturePreview';
import useSubItemsStore from '../../hooks/useSubItemStore';
import { ESubItemId } from '../../types/subItem';
import Img from '../../lib/Img/Img';
import PageTitle from '../../lib/PageTitle/PageTitle';
import { Button } from 'framework7-react';
import { formatCentsToReadable } from '../../utils';
import ReviewCardsDemo from '../ReviewCardsDemo/ReviewCardsDemo';
import useBackendApi from '../../hooks/useBackendApi';
import useToast from '../../hooks/useToast';
import useUserStore from '../../hooks/useUserStore';

interface ReviewCardsPaywallProps {
  onCancel?: () => void;
}

const ReviewCardsPaywall: React.FunctionComponent<ReviewCardsPaywallProps> = ({
  onCancel,
}) => {
  const { subItems } = useSubItemsStore();
  const { user, setUser } = useUserStore();
  const { post } = useBackendApi();
  const { catchErrorToast, successToast } = useToast();
  const subItem = subItems.find((i) => i.id === ESubItemId.reviewCards);

  /* create free subscription */
  const onNext = async () => {
    try {
      const sub = await post('/v1/subscription', {
        subItemId: subItem?._id
      }, {
        showToastOnSuccess: true,
        successMsg: `Successfully activated Review Cards`,
        showToastOnError: true
      });

      setUser({ subscriptions: [...(user.subscriptions || []), sub] })
      onCancel?.();
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return (
    <FeaturePreview
      currency="$"
      trialPeriod={subItem?.trialPeriod + ' days'}
      ctaLabel="Activate For Free"
      onClose={onCancel}
      onNext={onNext}
      tagLine={subItem?.tagLine}
      desc={subItem?.desc}
      image={subItem?.image}
      name={subItem?.name}
      price={formatCentsToReadable(subItem?.price) || ''}
    >
      <section className="mt-5">
        <ReviewCardsDemo />
      </section>
    </FeaturePreview>
  );
};
export default ReviewCardsPaywall;
