import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import useSubItemsStore from '../../hooks/useSubItemStore';
import { ISubItem, ESubItemStatus } from '@sprajwal078/nishi-types';
import FeaturePreview from '../../components/FeaturePreview/FeaturePreview';
import { formatCentsToReadable, isSubscribedTo, sendEmail } from '../../utils';
import { Button } from 'framework7-react';
import FeaturePreviewScreenshots from '../../components/FeaturePreviewScreenshots/FeaturePreviewScreenshots';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import FeaturePreviewInfo from '../../components/FeaturePreviewInfo/FeaturePreviewInfo';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import FeaturePaymentForm from '../../components/FeaturePaymentForm/FeaturePaymentForm';
import useUserStore from '../../hooks/useUserStore';
import useToast from '../../hooks/useToast';
import queryString from 'querystring';
import useBackendApi from '../../hooks/useBackendApi';
interface FeaturePageProps {}

const FeaturePage: React.FunctionComponent<FeaturePageProps> = ({}) => {
  const { subItemId }: any = useParams();
  const location = useLocation();
  const { subItems } = useSubItemsStore();
  const [subItem, setSubItem] = useState<ISubItem>({});
  const { user, setUser } = useUserStore();
  const [step, setStep] = React.useState<'payment' | 'preview' | 'success'>(
    'preview'
  );
  const [requestedAccess, setRequestedAccess] = React.useState(false);
  const { search } = useLocation();
  const { patch } = useBackendApi();
  const { suggestionId, checklistItemId } = queryString?.parse(
    search?.slice(1, search?.length)
  );
  const history = useHistory();
  const {
    image,
    images,
    trialPeriod,
    desc,
    id,
    price,
    name,
    tagLine,
    category,
  } = subItem;

  const { successToast } = useToast();
  const subscribed = isSubscribedTo(subItem.id, user.subscriptions);

  function getCtaText() {
    if (subscribed) {
      return 'Active';
    }
    if (subItem.status === ESubItemStatus.notReady && !requestedAccess) {
      return 'Request Access';
    }

    if (requestedAccess) {
      return 'Pending Access';
    }
    return 'Activate';
  }

  useEffect(() => {
    //set corresponding subItem, if not found push not found page
    const matchingSubItem = subItems.find((i) => i._id === subItemId);
    if (!matchingSubItem) {
      //push to 404 page
      return history.replace('/not-found');
    } else {
      setSubItem(matchingSubItem);
    }
  }, []);

  function handleActivate() {
    if (subscribed) return; //if subscribed dont execute

    if (
      subItem.status === ESubItemStatus.live ||
      subItem.status === ESubItemStatus.manual
    ) {
      setStep('payment');
    } else if (subItem.status === ESubItemStatus.notReady) {
      return requestAccess();
    }
  }

  async function requestAccess() {
    setRequestedAccess(true);
    sendEmail(
      ['hey@nishi.app', 'team@flayr.com.au'],
      `${user.email} has requested access to ${subItem.name}`,
      `userId: ${user._id} <br/> email: ${user.email} <br/> price: ${subItem.price}`
    );
    successToast(
      'Requested access to this feature. We will email you when it is available.'
    );
    if (suggestionId) {
      dismissSuggestion(suggestionId);
    }
    if (checklistItemId) {
      markChecked(checklistItemId);
    }
  }

  function dismissSuggestion(suggestionId) {
    patch(
      `/v1/checklist/user/items/${suggestionId}?suggestion=true`,
      { status: 'dismissed', checked: true },
      { loader: false }
    );
  }

  function markChecked(checklistItemId) {
    patch(
      `/v1/checklist/user/items/${checklistItemId}`,
      { checked: true },
      { loader: false }
    );
  }

  function goBack() {
    setSubItem({});
    history.push('/dashboard/today');
  }

  function handleSubscribed(sub) {
    setUser({ subscriptions: [...(user.subscriptions || []), sub] });
    successToast('Successfully Activated');
    sendEmail(
      ['hey@nishi.app', 'team@flayr.com.au'],
      `${user.email} has subscribed to ${subItem.name}`,
      `userId: ${user._id} <br/> email: ${user.email} <br/> price: ${subItem.price}`
    );
    if (suggestionId) {
      dismissSuggestion(suggestionId);
    }
    if (checklistItemId) {
      markChecked(checklistItemId);
    }
    setStep('preview');
  }

  return (
    <>
      {step === 'preview' && (
        <SlideInModal open={!!subItem._id} disableAnimation fullScreen>
          <FeaturePreview
            image={image}
            currency="$"
            price={formatCentsToReadable(price)}
            category={category}
            name={name}
            tagLine={tagLine}
            onClose={goBack}
            hideCtaBtn
            ctaLabel={getCtaText()}
            navBarCtaLabel={getCtaText()}
            screenshots={images}
            onNext={handleActivate}
            disabled={subscribed || requestedAccess}
            subscribed={subscribed}
          >
            <div className="mt-4 mb-8 safari:pb-20">
              <FeaturePreviewScreenshots images={images} />

              <div className="my-3">
                <h3 className="mb-2">
                  <SectionTitle size="2xl">Overview</SectionTitle>
                </h3>
                <p
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: desc || '' }}
                />
              </div>

              <div className="my-3">
                <Button
                  fill
                  raised
                  large
                  onClick={handleActivate}
                  disabled={subscribed || requestedAccess}
                  color={subscribed ? 'green' : 'blue'}
                >
                  {getCtaText()}
                </Button>
              </div>

              <div className="mt-4">
                <FeaturePreviewInfo item={subItem} />
              </div>
            </div>
          </FeaturePreview>
        </SlideInModal>
      )}
      {step === 'payment' && (
        <SlideInModal open={!!subItem._id} disableAnimation fullScreen>
          <FeaturePaymentForm
            subItem={subItem}
            onSubscribed={handleSubscribed}
            onBack={() => setStep('preview')}
            open={step === 'payment'}
          />
        </SlideInModal>
      )}
    </>
  );
};
export default FeaturePage;
