import * as React from 'react';
import { Page, Button } from 'framework7-react';
import useUserStore from '../../hooks/useUserStore';
import { useHistory, useParams } from 'react-router';
import useToggle from '../../hooks/useToggle';
import DesignEditor from '../../components/DesignEditor/DesignEditor';
import useBackendApi from '../../hooks/useBackendApi';
import ThemeLoader from '../../components/ThemeLoader/ThemeLoader';
import { DEFAULT_THEME, IG_GALLERY_REDIRECT_URI, IG_PREVIEW_REDIRECT_URI } from '../../constants';
import { ITheme } from '../../types/theme';
import PreviewSiteFooter from '../../components/PreviewSiteFooter/PreviewSiteFooter';
import ShowIf from '../../lib/ShowIf/ShowIf';
import useToast from '../../hooks/useToast';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import AlbumDisplay from '../../components/AlbumDisplay/AlbumDisplay';
import SubscribeToTheme from '../../components/SubscribeToTheme/SubscribeToTheme';
import InstagramAuth from '../../components/InstagramAuth/InstagramAuth';

interface IPreviewSiteProps { }
const PreviewSite: React.FC<IPreviewSiteProps> = ({ }) => {
  const [theme, setTheme] = React.useState<ITheme>();
  const { user, setUser } = useUserStore();
  const { themeId } = useParams();
  const { get, patch, post } = useBackendApi();
  const freeForMonth = useToggle();
  const photos = useToggle();
  const { successToast } = useToast();
  const [checkoutOpen, setCheckoutOpen] = React.useState(false);
  const fetchProfileData = async (userId) => {
    try {
      const data = await get('/v1/user/profile-data/' + userId);
      setUser(data.user);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    if (!themeId && user.theme?.id) {
      setTheme(user.theme);
    } else {
      fetchTheme(themeId);
    }

    /*     if (!user.albums?.length) {
          fetchProfileData(user._id);
        } */
  }, [user, themeId]);

  const history = useHistory();

  const designEditor = useToggle();

  async function fetchTheme(id) {
    const theme = await get(`/v1/util/theme/${id}`);
    if (theme.id) {
      setTheme(theme);
    }
  }

  function goToProfile() {
    history.push('/dashboard/profile');
  }

  function goToDesign() {
    history.push('/dashboard/design');
  }

  function handleExit() {
    if (themeId) {
      return goToDesign();
    }
    return goToProfile();
  }

  if (!user._id) {
    return null;
  }

  function getUserTheme() {
    const { primaryColor, heading } = DEFAULT_THEME;
    const styles = {
      '--user-theme-color-primary': theme?.primaryColor || primaryColor,
      '--user-theme-color-heading': theme?.heading.color || heading.color,
      '--f7-theme-color': 'var(--user-theme-color-primary)',
      '--f7-button-text-color': 'var(--user-theme-color-primary)',
      '--f7-actions-button-text-color': 'var(--user-theme-color-primary)',
    } as React.CSSProperties;
    return styles;
  }

  function handleApplyTheme() {
    if ((theme?.price || 0) > 0) {
      //if paid theme
      setCheckoutOpen(true);
    } else {
      setThemeOnUser();
      cancelPaidThemes();
    }
  }

  async function cancelPaidThemes() {
    post('/v1/subscription/cancel-theme-subs', {}, { loader: false });
  }

  async function setThemeOnUser() {
    const updateData: any = {
      _id: user._id,
      theme,
    };
    if (user.themeConfigs?.find((tc) => tc.id === theme?.id)) {
      updateData.themeConfigs = user.themeConfigs.map((tc) => {
        if (tc.id === theme?.id) {
          return theme;
        }
        return tc;
      });
    }
    const updated = await patch('/v1/user', updateData, {
      showToastOnError: true,
    });
    if (updated._id) {
      setUser(updateData);
      if (theme?.price) {
        freeForMonth.open();
      } else {
        successToast('Theme Applied');
        handleExit();
      }
    }
  }

  const onThemeSubscribe = (subscription) => {
    if (subscription) {
      setThemeOnUser();
      setCheckoutOpen(false);
    }
  };

  function getDefaultAlbum() {
    return user.albums?.find((al) => al.default);
  }

  function updateAlbum(album) {
    setUser({
      albums: user.albums?.map((al) => {
        if (al._id === album._id) {
          return album;
        }
        return al;
      }),
    });
    forceRerender();
  }

  function forceRerender() {
    setUser({ website: '' });
    setUser({ website: user.website });
  }

  return (
    <section className="z-popup absolute top-0 left-0 w-full h-full bg-white flex flex-col">
      <Page>
        <div style={{ paddingBottom: 105, ...getUserTheme() }}>
          <ThemeLoader themeId={theme?.id || ''} website={user.website || ''} />
        </div>
      </Page>

      <PreviewSiteFooter
        onExit={handleExit}
        onColorClick={designEditor.open}
        onDesinClick={goToDesign}
        onPhotosClick={photos.toggle}
        demo={!!themeId}
        onApplyClick={handleApplyTheme}
      />

      <SubscribeToTheme
        open={checkoutOpen}
        onClose={() => setCheckoutOpen(false)}
        theme={theme}
        onThemeSubscribe={onThemeSubscribe}
      />

      <ShowIf condition={!!theme}>
        <DesignEditor
          open={designEditor.on}
          onClose={designEditor.close}
          onSave={setTheme}
          theme={theme}
          demo={!!themeId}
        />
      </ShowIf>

      <SlideInModal open={freeForMonth.on} onClose={handleExit}>
        <div className="w-full py-4">
          <h2 className="mb-3 text-center">
            <SectionTitle>Theme Applied</SectionTitle>
          </h2>

          <div className="my-8  text-center text-green-500">
            <i className="fal fa-check-circle text-5xl" />
          </div>

          {/* <div className="text-lg text-center">
            Enjoy this theme free for 7 days.
          </div> */}

          <div className="px-8 mt-5">
            <Button fill raised large onClick={handleExit}>
              Close
            </Button>
          </div>
        </div>
      </SlideInModal>
      <InstagramAuth redirectUri={IG_PREVIEW_REDIRECT_URI} />

      <SlideInModal
        open={photos.on}
        onClose={photos.close}
        fullScreen
        direction="right"
        belowPopup
      >
        {photos.on && (
          <AlbumDisplay
            onClose={photos.close}
            album={getDefaultAlbum()}
            onAlbumSave={updateAlbum}
            redirectUri={IG_PREVIEW_REDIRECT_URI}
          />
        )}
      </SlideInModal>
    </section>
  );
};

export default PreviewSite;
