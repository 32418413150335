import { IRequest } from '../types/request';
import { SET_REQUESTS } from '../actions/types';

const initialState: IRequest[] = [];

export default function requestsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_REQUESTS:
      return [...payload];
    default:
      return state;
  }
}
