import React from 'react';
import dayjs from 'dayjs';
import PageTitle from '../../lib/PageTitle/PageTitle';
import useUserStore from '../../hooks/useUserStore';
import Img from '../../lib/Img/Img';
import { Link, useHistory } from 'react-router-dom';
import FeaturesSuggestionsList from '../../components/FeaturesSuggestionsList/FeaturesSuggestionsList';
import { IUserChecklistItem, IUserSuggestion } from '@sprajwal078/nishi-types';
import useBackendApi from '../../hooks/useBackendApi';
import Checklist from '../../components/Checklist/Checklist';
import { updateItemInArray } from '../../utils';

interface ITodayPageProps {}
const TodayPage: React.FC<ITodayPageProps> = ({}) => {
  const { user } = useUserStore();
  const history = useHistory();

  const [suggestionsChecklists, setSuggestionsChecklists] = React.useState<{
    suggestions: IUserSuggestion[];
    checklistItems: IUserChecklistItem[];
  }>({
    suggestions: [],
    checklistItems: [],
  });

  const { get, patch } = useBackendApi();

  async function fetchChecklistAndSuggestionsForUser() {
    const fetched = await get(`/v1/checklist/user/items`);
    setSuggestionsChecklists(fetched);
  }

  React.useEffect(() => {
    let didCancel = false;
    if (!didCancel) {
      fetchChecklistAndSuggestionsForUser();
    }
    return () => {
      didCancel = true;
    };
  }, []);

  async function handleDismiss(id) {
    const updated = await patch(
      `/v1/checklist/user/items/dismiss/${id}?suggestion=true`,
      {
        status: 'dismissed',
      }
    );

    const suggestions = updateItemInArray(
      suggestionsChecklists.suggestions,
      updated
    );
    setSuggestionsChecklists({ ...suggestionsChecklists, suggestions });
  }

  function handleChecklistView(id) {
    const checklistItem = suggestionsChecklists.checklistItems.find(
      (cl) => cl._id === id
    );
    //find matching suggestion
    const matchingSuggestion = suggestionsChecklists.suggestions.find(
      (cl) => cl?.item?._id === checklistItem?.item?._id
    );
    const suggestionQueryString = matchingSuggestion
      ? `&suggestionId=${matchingSuggestion._id}`
      : '';
    return redirectViewClick(
      checklistItem,
      `?checklistItemId=${id}${suggestionQueryString}`
    );
  }

  function handleSuggestionView(id) {
    const checklistItem = suggestionsChecklists.suggestions.find(
      (cl) => cl._id === id
    );

    //find matching checklist
    const matchingChecklistItem = suggestionsChecklists.checklistItems.find(
      (cl) => cl?.item?._id === checklistItem?.item?._id
    );
    const checkListQueryString = matchingChecklistItem
      ? `&checklistItemId=${matchingChecklistItem._id}`
      : '';
    return redirectViewClick(
      checklistItem,
      `?suggestionId=${id}${checkListQueryString}`
    );
  }

  function redirectToLink(href, newTab = false) {
    let target = '';
    if (newTab) {
      target = '_blank';
    }
    Object.assign(document.createElement('a'), {
      target,
      href,
    }).click();
  }

  function redirectViewClick(checklistItem, queryString) {
    const type = checklistItem?.item.type;
    if (type === 'feature') {
      history.push(
        `/dashboard/feature/${checklistItem?.item?.subItemId}/${queryString}`
      );
    }

    if (type !== 'feature') {
      const link = checklistItem?.item?.link;
      if (link === 'USER_WEBSITE') {
        redirectToLink(user.website, true);
      } else {
        redirectToLink(link, type === 'externalLink');
      }
    }
  }

  return (
    <div className="px-4">
      <div className="font-semibold text-gray-500 uppercase">
        {dayjs().format('dddd D MMMM')}
      </div>
      <header className="flex mb-3">
        <h1>
          <PageTitle>{user.businessName}</PageTitle>
        </h1>

        {/* <div className="ml-auto -mt-3">
          <Link to="/dashboard/accounts" className="external">
            <Img src={user.profileImg} size="48x48" round shadow="md" />
          </Link>
        </div> */}
      </header>

      <section>
        <FeaturesSuggestionsList
          suggestions={suggestionsChecklists.suggestions}
          onDismiss={handleDismiss}
          onView={handleSuggestionView}
        />
      </section>

      <section className="mt-8">
        <Checklist
          items={suggestionsChecklists.checklistItems}
          onView={handleChecklistView}
        />
      </section>
    </div>
  );
};

export default TodayPage;
