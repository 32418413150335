import React from 'react';
import useSelectItem from '../../hooks/useSelectItem';
import useInstagramApi from '../../hooks/useInstagramApi';
import InstaProfileConfirmAction from '../InstaProfileConfirmAction/InstaProfileConfirmAction';
import { sanitizeInstaUsername } from '../../utils';
import { setUser } from '@sentry/browser';

interface IConfirmInstaUsernameProps {
  instaUsername?: string;
  open?: boolean;
  onClose?: (e?: any) => void;
  onConfirm?: (e?: any) => void;
  onDecline?: (e?: any) => void;
}

const ConfirmInstaUsername: React.FC<IConfirmInstaUsernameProps> = ({
  open,
  onClose,
  instaUsername,
  onConfirm,
  onDecline,
}) => {
  const [userDetails, setUserDetails] = React.useState({
    instaPk: '',
    profileImg: '',
    name: '',
  });

  function resetUserDetails() {
    return setUserDetails({
      instaPk: '',
      profileImg: '',
      name: '',
    });
  }

  const { fetch } = useInstagramApi();

  React.useEffect(() => {
    if (open) {
      getUserDetails();
    }

    return () => {
      resetUserDetails();
    };
  }, [open]);

  async function getUserDetails() {
    const sanitizedUn = sanitizeInstaUsername(instaUsername || '');
    const profile = await fetch(`?path=/v1/users/${sanitizedUn}`);
    if (profile.id) {
      const data = {
        instaPk: profile.id,
        profileImg: profile.profile_picture,
        name: profile.full_name,
      };
      setUserDetails(data);
    }
  }

  function handleConfirm() {
    resetUserDetails();
    onConfirm?.();
  }

  return (
    <div>
      <InstaProfileConfirmAction
        open={open && !!userDetails.instaPk}
        name={userDetails.name}
        profileImg={userDetails.profileImg}
        onClose={onClose}
        onConfirm={handleConfirm}
        onDecline={onDecline}
      />
    </div>
  );
};

export default ConfirmInstaUsername;
