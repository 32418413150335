import { SET_NAV_LINK } from '../actions/types';
import { INavLink } from '../types/navLink';

const initialState: INavLink[] = [
  {
    icon: '',
    id: 'today',
    label: 'Today',
    link: '/dashboard/today',
    notifications: 0,
  },
  // {
  //   icon: '',
  //   id: 'gigs',
  //   label: 'Gigs',
  //   link: '/dashboard/gigs',
  //   notifications: 0,
  // },
  {
    icon: '',
    id: 'reviews',
    label: 'Reviews',
    link: '/dashboard/reviews',
    notifications: 0,
  },
  {
    icon: '',
    id: 'photos',
    label: 'Photos',
    link: '/dashboard/photos',
    notifications: 0,
  },
  {
    icon: '',
    id: 'profile',
    label: 'Site',
    link: '/dashboard/profile',
    notifications: 0,
  },
];

export default function navLinkReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_NAV_LINK:
      return [...payload];
    default:
      return state;
  }
}
