import * as React from 'react';
import { Button, Segmented } from 'framework7-react';

interface IReviewsTabSelectorProps {
  selected?: string;
  onSelect?: (e?: any) => void;
}

const items = ['all', 'visible', 'hidden'];
const ReviewsTabSelector: React.FC<IReviewsTabSelectorProps> = ({
  selected,
  onSelect,
}) => {
  function handleMenuClick(e) {
    onSelect?.(e.target.id);
  }

  return (
    <Segmented strong tag="div">
      {items.map((item) => (
        <Button
          key={item}
          id={item}
          active={selected === item}
          onClick={handleMenuClick}
          className="capitalize"
        >
          {item}
        </Button>
      ))}
    </Segmented>
  );
};

export default ReviewsTabSelector;
