import * as React from 'react';
import { Button } from 'framework7-react';
import { IImage } from '../../types/image';
import useUserStore from '../../hooks/useUserStore';
import useBackendApi from '../../hooks/useBackendApi';
import ImgGrid from '../ImgGrid/ImgGrid';
import { selectDeselectItem } from '../../utils';

interface IImgPickerProps {
  selectedImages?: IImage[];
  ctaLabel?: string;
  onChange?: (e?: any) => void;
  onNext?: (e?: any) => void;
}
const ImgPicker: React.FC<IImgPickerProps> = ({
  onChange,
  selectedImages = [],
}) => {
  const [images, setImages] = React.useState<IImage[]>([]);

  const { user } = useUserStore();
  const { get } = useBackendApi();

  async function init() {
    const fetched = await get(`/v1/album/images/all`, { userId: user._id });
    setImages(fetched);
  }

  React.useEffect(() => {
    let didCancel = false;
    if (!didCancel) {
      init();
    }
    return () => {
      didCancel = true;
    };
  }, []);

  function handleSelectImg(img) {
    const imgs = selectDeselectItem(img, selectedImages);
    onChange?.(imgs);
  }

  return (
    <div className="pb-40">
      <ImgGrid
        images={images}
        selectable
        selectedImages={selectedImages}
        onImageSelect={handleSelectImg}
      />
    </div>
  );
};

export default ImgPicker;
