import React from 'react';
import { IAlbum } from '../../types/album';
import Img from '../../lib/Img/Img';
import ShowIf from '../../lib/ShowIf/ShowIf';

interface AlbumItemProps {
  album: IAlbum;
  onClick?: (e?: any) => void;
  onDismiss?: (e?: any) => void;
  onAdd?: (e?: any) => void;
  showCreatedBy?: boolean;
}

const AlbumItem: React.FunctionComponent<AlbumItemProps> = ({
  album,
  onClick,
  onDismiss,
  onAdd,
  showCreatedBy,
}) => {
  function handleAlbumClick() {
    onClick && onClick(album);
  }

  function handleDismissClick(e) {
    e.stopPropagation();
    onDismiss?.(album);
  }

  function handleAddAlbum(e) {
    e.stopPropagation();
    onAdd?.(album);
  }

  const isSuggested = album.auto && album.status === 'hidden';

  return (
    <div className="w-full" onClick={handleAlbumClick}>
      <div className="relative overflow-hidden text-white rounded-lg">
        <Img src={album.coverImg} size="square" />
        <div className="absolute top-0 left-0 flex flex-col w-full h-full p-3 bg-translucent-500">
          <h3 className="text-2xl font-semibold">{album.name}</h3>
          <p>{album.imageIds?.length}</p>
        </div>
      </div>
      {/* <Img
        src={album.coverImg || ''}
        size="square"
        grayScale={isSuggested}
        className="rounded shadow"
      />

      <div className="pl-1 mt-1">
        <p className="font-semibold">
          {album.name}
          <span className="font-normal"> ({album.imageIds?.length})</span>
        </p>

        <ShowIf condition={showCreatedBy}>
          <p className="mb-2 leading-none text-gray-400">
            <small>Created by Nishi</small>
          </p>
        </ShowIf>

        <ShowIf condition={isSuggested}>
          
          <div className="absolute top-0 left-0">
            <button
              className="w-6 h-6 text-white transform -translate-x-2 -translate-y-2 bg-red-500 rounded-full shadow focus:outline-none active:bg-red-400"
              onClick={handleDismissClick}
            >
              <i className="text-base fal fa-times" />
            </button>
          </div>

          <p>
            <button
              className="w-auto px-5 py-1 font-semibold uppercase bg-gray-200 rounded-lg text-primary focus:outline-none active:bg-gray-100"
              onClick={handleAddAlbum}
            >
              Add album
            </button>
          </p>
        </ShowIf>
      </div> */}
    </div>
  );
};
export default AlbumItem;
