import * as React from 'react';
import { IAppointment } from '../../../types/appointment';
import SectionTitle from '../../../lib/SectionTitle/SectionTitle';

interface ICustomerDetailsProps {
  appointment?: IAppointment;
}
const CustomerDetails: React.FC<ICustomerDetailsProps> = ({ appointment }) => {
  return (
    <div>
      <section className="mb-2 pb-2 border-b border-gray-200">
        <p className="text-gray-500">name</p>
        <p className="text-primary text-base">{appointment?.customerName}</p>
      </section>
      <section className="mb-2 pb-2 border-b border-gray-200">
        <p className="text-gray-500">mobile</p>
        <p className="text-primary text-base">{appointment?.customerMobile}</p>
      </section>
      <section className="mb-2 pb-2">
        <p className="text-gray-500">email</p>
        <p className="text-primary text-base">{appointment?.customerEmail}</p>
      </section>
    </div>
  );
};

export default CustomerDetails;
