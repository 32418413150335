import { useDispatch, useSelector } from 'react-redux';
import { IAppStore } from '../types/appStore';
import { ISubItem } from '../types/subItem';
import { setSubItemAction } from '../actions/subItems';

export default function useSubItemsStore() {
  const dispatch = useDispatch();

  const subItems = useSelector((store: IAppStore) => store.subItems);
  const setSubItems = (payload: ISubItem[]) =>
    dispatch(setSubItemAction(payload));

  return {
    subItems,
    setSubItems
  };
}