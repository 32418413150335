import React from 'react';
import { useHistory, useParams } from 'react-router';
import { setUserDataToStorage } from '../../utils';
import Spinner from '../../lib/Spinner/Spinner';

interface ImpersonateProps {}

const Impersonate: React.FunctionComponent<ImpersonateProps> = ({}) => {
  const { token } = useParams();
  const history = useHistory();

  React.useEffect(() => {
    if (token) {
      setUserDataToStorage({ token });
      return history.push('/dashboard');
    }
  }, [token]);

  return <Spinner />;
};
export default Impersonate;
