import * as React from 'react';
import { IAlbum } from '../../types/album';
import { Actions, ActionsGroup, ActionsButton } from 'framework7-react';
import PageTitle from '../../lib/PageTitle/PageTitle';

interface IDeletePromptProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  onConfirm?: (e?: any) => void;
  heading?: string;
  confirmBtnLabel?: string;
  text?: string;
}
const DeletePrompt: React.FC<IDeletePromptProps> = ({
  open,
  onClose,
  onConfirm,
  heading = 'Are you sure you want to delete?',
  confirmBtnLabel = 'Confirm',
  text = '',
  children,
}) => {
  return (
    <Actions opened={open} onActionsClosed={onClose}>
      <ActionsGroup>
        <div className="p-3">
          <h3>
            <PageTitle size="2xl">{heading}</PageTitle>
          </h3>
          <hr className="border-t border-gray-200 my-2" />

          <p className="my-4">{text}</p>

          <div>{children}</div>

          <button
            className="bg-red-500 w-full mb-4 rounded text-lg py-2 text-white focus:outline-none active:bg-red-400"
            onClick={onConfirm}
          >
            {confirmBtnLabel}
          </button>
        </div>
      </ActionsGroup>
      <ActionsGroup>
        <ActionsButton bold onClick={onClose}>
          Cancel
        </ActionsButton>
      </ActionsGroup>
    </Actions>
  );
};

export default DeletePrompt;
