import * as React from 'react';
import { useParams, useHistory } from 'react-router';
import useUserStore from '../../hooks/useUserStore';
import useBackendApi from '../../hooks/useBackendApi';
import useToggle from '../../hooks/useToggle';
import PageTitle from '../../lib/PageTitle/PageTitle';
import ProfileEditor from '../../components/ProfileEditor/ProfileEditor';
import Navbar from '../../lib/Navbar/Navbar';
import { Button, Page } from 'framework7-react';
import { FiShare } from 'react-icons/fi';
import ShareSite from '../../components/ShareSite/ShareSite';
import SiteSuggestions from '../../components/SiteSuggestions/SiteSuggestions';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import BackBtn from '../../lib/BackBtn/BackBtn';
import CustomDomain from '../../components/CustomDomain/CustomDomain';
import { copyToClipboard } from '../../utils';

interface IProfileProps {}
const Profile: React.FC<IProfileProps> = ({}) => {
  const { user, setUser } = useUserStore();
  const { get } = useBackendApi();
  const shareSite = useToggle();
  const customDomain = useToggle();

  React.useEffect(() => {
    if (!user._id) {
      fetchProfileData(user._id);
    }
  }, []);

  const fetchProfileData = async (userId): Promise<void> => {
    try {
      const data = await get('/v1/user/profile-data/' + userId);
      setUser(data.user);
    } catch (err) {
      console.error(err);
    }
  };

  function openLinkInNewTab(e: any, link: string = user.website || ''): void {
    const a = document.createElement('a');
    a.setAttribute('href', link);
    a.setAttribute('target', '__blank');
    a.click();
  }

  function handleAddToInstagram(e): void {
    copyToClipboard(user.website);
    openLinkInNewTab(e, `https://instagram.com/accounts/edit`);
  }

  return (
    <div>
      <div className="sticky z-10 px-4" style={{ top: 50 }}>
        <Navbar
          className="mb-2 bg-transparent rounded-md"
          rightContent={
            <div className="flex ml-auto">
              <Button
                large
                className="text-2xl navbar-btn"
                onClick={shareSite.open}
              >
                <FiShare />
              </Button>
            </div>
          }
        />
      </div>

      <main className="px-4">
        <section className="pb-10">
          <SiteSuggestions
            onVisitWebsite={openLinkInNewTab}
            onChangeWebsiteAddress={customDomain.toggle}
            onAddToInstagram={handleAddToInstagram}
          />

          <SlideInModal
            fullScreen
            open={customDomain.on}
            onClose={customDomain.close}
            direction="right"
            belowPopup
          >
            <Page>
              <Navbar
                leftContent={<BackBtn onClick={customDomain.close} />}
                rightContent={
                  <Button
                    className="text-base normal-case"
                    onClick={shareSite.open}
                  >
                    Share
                  </Button>
                }
              />
              <div className="px-4">
                <CustomDomain user={user} />
              </div>
            </Page>
          </SlideInModal>
          <ProfileEditor />
        </section>

        <ShareSite open={shareSite.on} onClose={shareSite.close} />
      </main>
    </div>
  );
};

export default Profile;
