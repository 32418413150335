import * as React from 'react';
import { List, ListInput } from 'framework7-react';
import useUserStore from '../../hooks/useUserStore';

interface IBusinessProfileProps {}
const BusinessProfile: React.FC<IBusinessProfileProps> = ({}) => {
  const { user, setUser } = useUserStore();

  function handleInputChange(e) {
    const businessName = e.target.value;
    setUser({ businessName });
  }

  return (
    <div className="-mx-4">
      <List noHairlines className="m-0 business-name">
        <ListInput
          outline
          label="Business name"
          floatingLabel
          type="text"
          placeholder="Your business name"
          clearButton
          name="businessName"
          value={user.businessName}
          onChange={handleInputChange}
          className="mb-4"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="none"
          spellcheck="false"
        />
      </List>
    </div>
  );
};

export default BusinessProfile;
