import React from 'react';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import ShowIf from '../../lib/ShowIf/ShowIf';

interface ReviewsSummarySlideProps {
  score?: number;
  reviewCount?: number;
  hideInstaCard?: boolean;
  onInstaCardClick?: (e?: any) => void;
  onAddReviewClick?: (e?: any) => void;
  onImportClick?: (e?: any) => void;
}

const ReviewsSummarySlide: React.FunctionComponent<ReviewsSummarySlideProps> = ({
  score,
  reviewCount,
  hideInstaCard,
  onInstaCardClick,
  onAddReviewClick,
  onImportClick,
}) => {
  return (
    <div className="flex flex-no-wrap overflow-x-auto my-2 items-stretch -mx-1 px-1 py-2">
      <section className="flex-none w-7/16 bg-gray-200 mr-2 p-2 rounded-lg shadow text-center relative">
        <ShowIf
          condition={!!score && !!reviewCount}
          elseTemplate={
            <div className="flex-center absolute top-0 left-0 w-full h-full px-3 ">
              <div>
                <p>
                  <i className="far fa-eye-slash text-2xl" />
                </p>
                You do not have visible reviews yet
              </div>
            </div>
          }
        >
          <div className="px-3">
            <p className="text-4xl">
              {score} <span className="star text-3xl" />
            </p>
            <p className="text-sm">based on {reviewCount} visible reviews</p>
          </div>
        </ShowIf>
      </section>

      {!hideInstaCard && (
        <section
          className="flex-none w-7/16 bg-insta text-white mr-2 p-2 rounded-lg shadow"
          onClick={onInstaCardClick}
        >
          <p className="mb-2">
            <i className="fal fa-cloud-download text-2xl" />
          </p>
          <h4 className="mb-1">
            <SectionTitle size="base">
              <span className="text-white">Review cards to</span>
            </SectionTitle>
          </h4>
          <p className="text-sm">share on Instagram now available</p>
        </section>
      )}

      <section
        className="flex-none w-7/16 bg-gray-200 mr-2 p-2 rounded-lg shadow"
        onClick={onAddReviewClick}
      >
        <p className="mb-2">
          <i className="fal fa-plus text-xl" />
        </p>
        <h4 className="mb-1">
          <SectionTitle size="base">Add new reviews</SectionTitle>
        </h4>
        <p className="text-sm">Get reviews from previous customers</p>
      </section>

      <section
        className="flex-none w-7/16 bg-gray-200 mr-2 p-2 rounded-lg shadow"
        onClick={onImportClick}
      >
        <p className="mb-2">
          <i className="fas fa-heart"></i>
        </p>
        <h4 className="mb-1">
          <SectionTitle size="base">All your reviews in 1 place.</SectionTitle>
        </h4>
        <p className="text-sm">Import your reviews</p>
      </section>
    </div>
  );
};
export default ReviewsSummarySlide;
