import * as React from 'react';
import {
  Popup,
  Page,
  List,
  ListInput,
  Toggle,
  Icon,
  Chip,
  Button,
  PageContent,
} from 'framework7-react';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import PageTitle from '../../lib/PageTitle/PageTitle';
import useUserStore from '../../hooks/useUserStore';
import ImgUploader from '../../lib/ImgUploader/ImgUploader';
import Img from '../../lib/Img/Img';
import ProStar from '../../lib/ProStar/ProStar';
import CoverPicsEditor from '../CoverPicsEditor/CoverPicsEditor';
import { IImage } from '../../types/image';
import ReadMore from '../../lib/ReadMore/ReadMore';
import ShowIf from '../../lib/ShowIf/ShowIf';
import useToggle from '../../hooks/useToggle';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import AboutEditor from '../AboutEditor/AboutEditor';
import useGooglePlacesApi from '../../hooks/useGooglePlacesApi';
import KitEditor from '../KitEditor/KitEditor';
import MobileNumberInput from '../../lib/MobileNumberInput/MobileNumberInput';
import NoContent from '../../lib/NoContent/NoContent';
import useBackendApi from '../../hooks/useBackendApi';
import useToast from '../../hooks/useToast';
import ImgAutoplay from '../../lib/ImgAutoplay/ImgAutoplay';
import { isProSubscribed } from '../../utils';
import ProFeature from '../ProFeature/ProFeature';
import UpgradeToPro from '../UpgradeToPro/UpgradeToPro';
import Portal from '../../lib/Portal/Portal';

interface IProfileEditMenuProps {
  open?: boolean;
  onClose?: (e?: any) => void;
}

const fieldLabelMapping = {
  businessName: 'Business Name',
  mobile: 'Mobile',
  email: 'Email',
  location: 'Address',
};

const ProfileEditMenu: React.FC<IProfileEditMenuProps> = ({
  open,
  onClose,
}) => {
  const bioPopup = useToggle();
  const kitPopup = useToggle();

  const { user, setUser } = useUserStore();
  const [uploadingImage, setUploadingImage] = React.useState('');
  const [userInput, setUserInput] = React.useState<any>({
    businessName: '',
    slogan: '',
    mobile: '',
    email: '',
    location: {
      formatted: '',
    },
    facebookPage: '',
    coverImgs: [],
    imgAutoplay: false,
  });

  const autoplayPreview = useToggle();
  const proFeature = useToggle();
  const upgrade = useToggle();

  const { patch } = useBackendApi();
  const { successToast, errorToast } = useToast();
  const locationRef = React.useRef;

  React.useEffect(() => {
    setUserInput(user);
  }, [user]);

  function setLocation(location) {
    setUserInput({ ...userInput, location });
  }

  useGooglePlacesApi('#addressLocation', setLocation);

  function handleInputChange(e) {
    let { name, value } = e.target;
    switch (name) {
      case 'location':
        value = { formatted: value };
        break;
    }
    setUserInput({
      ...userInput,
      [name]: value,
    });
  }

  async function updateUserData(event?: any, body = userInput) {
    const requiredFields = ['businessName', 'location', 'mobile', 'email'];
    const required = Object.keys(userInput).map((key) => {
      if (requiredFields.includes(key)) {
        switch (key) {
          case 'location':
            if (!userInput.location.city) {
              errorToast(`${fieldLabelMapping[key]} is missing`);
              return false;
            }
          default:
            if (!userInput[key]) {
              errorToast(`${fieldLabelMapping[key]} is missing`);
              return false;
            }
        }
      }
      return true;
    });
    if (required.includes(false)) {
      return;
    }

    if (body.facebookPage && !body.facebookPage.includes('https')) {
      body.facebookPage = 'https://' + body.facebookPage;
    }
    delete userInput.reviews;
    const update = await patch(`/v1/user/${user._id}`, body, {
      onError: (err) => {
        if (err.errors?.codeName === 'DuplicateKey') {
          const kv = err.errors?.keyValue || {};
          const text = Object.keys(kv)
            .map((key) => `${key}: ${kv[key]}`)
            .join(', ');
          errorToast(`Another account with ${text} already exists`);
        } else {
          errorToast(err.message);
        }
      },
    });
    if (update._id) {
      successToast('Success');
      setUser(update);
      onClose?.();
    }
  }

  function handleEditAboutDone(bio) {
    bioPopup.close();
    setUserInput({ ...userInput, bio });
  }

  function handleKitDone(kit) {
    kitPopup.close();
    setUserInput({ ...userInput, kit });
  }

  function profileImgUploadStart(p) {
    setUploadingImage(p);
  }

  async function handleUploadComplete(img) {
    const src = img.target.value;
    await updateUserData(null, { profileImg: src });
    setUploadingImage('');
  }

  function handleImageAutoplayChange(e) {
    if (isProSubscribed(user.subscriptions)) {
      setUserInput({ ...userInput, imgAutoplay: e.target.checked });
    } else {
      proFeature.open();
    }
  }

  return (
    <Portal>
      <ShowIf condition={open}>
        <Page pageContent={false} className="z-popup">
          <PageContent>
            <PopupNavbar
              onCancel={onClose}
              onDone={updateUserData}
              className="py-2 fixed top-0 bg-white w-full z-20"
            />
            <h2 className="px-4 mt-12">
              <PageTitle>Edit profile</PageTitle>
            </h2>
            <hr className="border-t border-gray-100 mx-3 my-2" />

            <section className="flex items-center">
              <div className="px-4">
                <ImgUploader
                  onUploadStart={profileImgUploadStart}
                  onUploadComplete={handleUploadComplete}
                >
                  <div>
                    <ShowIf
                      condition={!uploadingImage}
                      elseTemplate={
                        <Img
                          src={uploadingImage}
                          grayScale
                          size="96x96"
                          className="rounded-full"
                        />
                      }
                    >
                      <Img
                        src={user.profileImg}
                        size="96x96"
                        className="rounded-full"
                      />
                    </ShowIf>

                    <div className="mt-1 text-center text-blue-500 text-base">
                      change
                    </div>
                  </div>
                </ImgUploader>
              </div>

              <div className="pb-4">
                <List className="m-0">
                  <ListInput
                    placeholder="Business name"
                    name="businessName"
                    value={userInput.businessName}
                    onChange={handleInputChange}
                  />
                  <ListInput
                    placeholder="Slogan"
                    name="slogan"
                    value={userInput.slogan}
                    onChange={handleInputChange}
                  />
                </List>
              </div>
            </section>

            <section className="flex my-3 justify-between px-4 items-center">
              <span className="text-base flex-center-y">
                <span>Autoplay cover photos</span>
                <span
                  className="inline-block ml-3 text-blue-500"
                  onClick={autoplayPreview.open}
                >
                  <i className="fas fa-eye" />
                </span>
                <ProStar className="opacity-50 ml-3" />
              </span>

              <Toggle
                color="green"
                value="imgAutoplay"
                name="imgAutoplay"
                checked={userInput.imgAutoplay || false}
                onChange={handleImageAutoplayChange}
              />
            </section>

            <section className="my-3 px-4">
              <CoverPicsEditor
                images={user.coverImgs?.map((i) => ({ src: i })) || []}
              />
            </section>

            <hr className="border-t border-gray-100  my-2" />

            <section className="my-3 px-4" onClick={bioPopup.open}>
              <ShowIf
                condition={!!userInput.bio}
                elseTemplate={<NoContent>Set your bio</NoContent>}
              >
                <ReadMore text={userInput.bio} disabled enableCounter />
              </ShowIf>
            </section>

            <section className="px-4">
              <List className="m-0">
                <ListInput
                  placeholder="Business Location"
                  name="location"
                  inputId="addressLocation"
                  value={userInput?.location?.formatted || ''}
                  onChange={handleInputChange}
                  clearButton
                  onInputClear={() =>
                    setLocation({
                      formatted: '',
                      city: '',
                    })
                  }
                >
                  <Icon slot="media">
                    <i className="fal fa-map-marker-alt text-gray-600 mt-1" />
                  </Icon>
                </ListInput>
              </List>
            </section>

            <section className="px-4 my-2">
              <ShowIf
                condition={userInput.kit?.length}
                elseTemplate={
                  <NoContent onClick={kitPopup.open}>Add your kit</NoContent>
                }
              >
                {userInput.kit?.map((kit, key) => (
                  <Chip
                    key={key}
                    text={kit.name}
                    className="mr-1"
                    onClick={kitPopup.open}
                  />
                ))}
              </ShowIf>
            </section>

            <div className="section px-4">
              <div className="border-t border-gray-100 py-px">
                <MobileNumberInput
                  name="mobile"
                  value={userInput.mobile}
                  noBorder
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <section className="px-4">
              <List className="m-0">
                <ListInput
                  placeholder="email"
                  name="email"
                  type="email"
                  value={userInput.email}
                  onChange={handleInputChange}
                />
              </List>
            </section>

            <section className="px-4 mb-3">
              <div className="border-b border-gray-100">
                <List className="m-0" noHairlines>
                  <ListInput
                    placeholder="Add facebook page"
                    name="facebookPage"
                    value={userInput.facebookPage}
                    onChange={handleInputChange}
                  >
                    <Icon slot="media">
                      <i className="fab fa-facebook-square relative text-blue-400 text-xl mt-1" />
                    </Icon>
                  </ListInput>
                </List>
              </div>
            </section>
            <AboutEditor
              bio={userInput.bio}
              open={bioPopup.on}
              onClose={bioPopup.close}
              onDone={handleEditAboutDone}
            />
            <KitEditor
              kit={userInput.kit}
              open={kitPopup.on}
              onClose={kitPopup.close}
              onDone={handleKitDone}
            />

            <SlideInModal
              open={autoplayPreview.on}
              onClose={autoplayPreview.close}
            >
              <div className="w-full">
                <h2 className="mb-2">
                  <PageTitle>Autoplay Preview</PageTitle>
                </h2>
                <div
                  className="relative overflow-hidden w-full mb-3"
                  style={{ maxHeight: '70vh', height: '70vh' }}
                >
                  <ImgAutoplay images={userInput.coverImgs} />
                </div>
                <Button fill large raised onClick={autoplayPreview.close}>
                  Close
                </Button>
              </div>
            </SlideInModal>

            <SlideInModal open={proFeature.on} onClose={proFeature.close}>
              <ProFeature
                onUpgrade={() => {
                  proFeature.close();
                  upgrade.open();
                }}
                onClose={proFeature.close}
              />
            </SlideInModal>
            <UpgradeToPro open={upgrade.on} onClose={upgrade.close} />
          </PageContent>
        </Page>
      </ShowIf>
    </Portal>
  );
};

export default ProfileEditMenu;
