import React, { useState, useEffect } from 'react';
import useBackendApi from '../../hooks/useBackendApi';
import {
  setUserDataToStorage,
  isTokenExpired,
  getUserDataFromStorage,
} from '../../utils';
import { useHistory, useParams } from 'react-router';
import LoginLayout from '../../components/LoginLayout/LoginLayout';
import useToast from '../../hooks/useToast';

interface LoginProps { }
const Login: React.FunctionComponent<LoginProps> = ({ }) => {
  const { emailCode } = useParams();
  const { post } = useBackendApi();
  const { catchErrorToast, successToast, errorToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    /* Logic for routing if already logged in */
    if (!isTokenExpired(getUserDataFromStorage().token)) {
      return history.push('/dashboard');
    }
    if (emailCode) {
      verifyEmail(emailCode);
    }
  }, []);

  async function verifyEmail(emailCode) {
    try {
      let response = await post('/v1/auth/verify-email', {
        emailCode,
      });

      if (response === 'success') {
        successToast('Email verified! Please login');
      } else {
        errorToast('An error has occured');
      }
    } catch (err) {
      catchErrorToast(err);
      console.error(err);
    }
  }

  async function handleOnLogin(userInput) {
    try {
      let data = await post(`/v1/auth/login`, {
        email: userInput.email,
        password: userInput.password,
      });
      setUserDataToStorage({ token: data.token });
      return history.push('/dashboard');
    } catch (err) {
      catchErrorToast(err);
      console.error(err);
    }
  }

  return (
    <LoginLayout
      topCtaLabel="Don't have an account?"
      topCtaBtnLabel="SIGN UP"
      topCtaLink="/signup"
      mainCtaLabel="Log in"
      onMainCtaClick={handleOnLogin}
      ctaExtension={<div className="mb-40" />}
      onForgotClick={() => history.push('/forgot-password')}
    >
      <div className="px-4">
        <h1>
          <span className="font-bold text-4xl">Welcome back</span>
        </h1>
        <p className="pr-5 text-lg">
          Enter your email address and password to access your account
        </p>
      </div>
    </LoginLayout>
  );
};
export default Login;
