import React from 'react';
import { IAlbum } from '../../types/album';
import { IImage } from '../../types/image';
import useUserStore from '../../hooks/useUserStore';
import useBackendApi from '../../hooks/useBackendApi';
import useToast from '../../hooks/useToast';
import useSelectItem from '../../hooks/useSelectItem';
import useToggle from '../../hooks/useToggle';
import { Page, Popup, Button } from 'framework7-react';
import Navbar from '../../lib/Navbar/Navbar';
import Img from '../../lib/Img/Img';
import ShowIf from '../../lib/ShowIf/ShowIf';
import ImgGrid from '../ImgGrid/ImgGrid';
import ReorderPhotos from '../ReorderPhotos/ReorderPhotos';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import PageTitle from '../../lib/PageTitle/PageTitle';
import InstagramPicker from '../InstagramPicker/InstagramPicker';
import AlbumMenu from '../AlbumMenu/AlbumMenu';
import DeleteImages from '../DeleteImages/DeleteImages';
import GalleryBrowser from '../GalleryBrowser/GalleryBrowser';
import AlbumDisplayMenu from '../AlbumDisplayMenu/AlbumDisplayMenu';
import ExtendedDeleteMenu from '../ExtendedDeleteMenu/ExtendedDeleteMenu';
import DeleteAlbumPrompt from '../DeleteAlbumPrompt/DeleteAlbumPrompt';
import EditPhotosMenu from '../EditPhotosMenu/EditPhotosMenu';
import AlbumRenameAction from '../AlbumRenameAction/AlbumRenameAction';
import Alert from '../../lib/Alert/Alert';
import LinkInstagram from '../LinkInstagram/LinkInstagram';
import ImgActionMenu from '../ImgActionMenu/ImgActionMenu';
import InstagramPickerV2 from '../InstagramPickerV2/InstagramPickerV2';

interface AlbumDisplayProps {
  album?: IAlbum;
  onAlbumSave?: (e?: any) => void;
  onClose?: (e?: any) => void;
  mode?: string;
  menuBtns?: string[];
  redirectUri?: string;
  onDeleteAlbum?: (e?: any) => void;
  onEnableAlbum?: (e?: any) => void;
}

const AlbumDisplay: React.FunctionComponent<AlbumDisplayProps> = ({
  album,
  onClose,
  onAlbumSave,
  menuBtns = ['add', 'reorder', 'delete'],
  onDeleteAlbum,
  redirectUri,
  onEnableAlbum,
}) => {
  const { user, setUser } = useUserStore();
  const { get, patch, post } = useBackendApi();
  const { successToast, errorToast } = useToast();

  const [images, setImages] = React.useState<IImage[]>([]);
  const [imgsToUpload, setImgsToUpload] = React.useState<string[]>([]);
  const [fullScreenImg, setFullScreenImg, clearFullScreenImg] = useSelectItem();
  const [newCoverImg, setNewCoverImg, clearNewCoverImg] = useSelectItem();
  const albumMenu = useToggle();
  const reorder = useToggle();
  const deleteImg = useToggle();
  const addImg = useToggle();
  const changeCover = useToggle();
  const extendedDelete = useToggle();
  const edit = useToggle();
  const rename = useToggle();
  const deleteAlbumPrompt = useToggle();
  const instagramPrompt = useToggle();
  const instaPicker = useToggle();
  const galleryPicker = useToggle();

  async function fetchData(loader = true) {
    const images = await get('/v1/album/images/' + album?._id, {}, { loader });
    setImages(images);
  }

  React.useEffect(() => {
    let didCancel = false;
    if (!didCancel && album?.name) {
      fetchData();
    }
    return () => {
      didCancel = true;
    };
  }, [album]);

  async function handleImgUploadComplete(e) {
    const srcs = e.target.value;
    const newAlbum = await post('/v1/image/multiple', {
      images: srcs,
      albumId: album?._id,
    });
    successToast('Successfully uploaded image to ' + album?.name);
    const newImages = [...newAlbum.images, ...images];
    setImages(newImages);
    onAlbumSave?.(newAlbum);
    setImgsToUpload([]);
    // fetchData(false);
  }

  function updateUserAlbumStore(newAlbum) {
    const userAlbums = [...(user?.albums || [])];
    const modifiedIdx = userAlbums.findIndex((a) => a._id === newAlbum._id);
    if (modifiedIdx) {
      userAlbums[modifiedIdx] = newAlbum;
      setUser({ albums: userAlbums });
    }
  }

  function updateAlbum(body) {
    return patch('/v1/album', body, { showToastOnSuccess: true });
  }
  async function handleReorderSave(reorderedImages) {
    const reorderedIds = reorderedImages.map((i) => i._id);
    await patch('/v1/album', { _id: album?._id, imageIds: reorderedImages });
    successToast('Success');
    const updatedAlbum = {
      ...album,
      coverImg: reorderedImages[0].src,
      imageIds: reorderedIds,
      images: reorderedImages,
    };
    updateUserAlbumStore(updatedAlbum);
    setImages(reorderedImages);

    reorder.close();
  }

  async function deleteImages(e) {
    const imageIds = e.map((i) => i._id);
    await post(
      '/v1/image/delete-images',
      { albumId: album?._id, imageIds },
      {
        onError: (error) => {
          errorToast(error);
        },
      }
    );

    const newImages = [...images].filter((i) => !imageIds.includes(i._id));
    setImages(newImages);

    successToast('Successfully deleted');
    onAlbumSave?.({
      ...album,
      images: newImages,
      imageIds: newImages.map((i) => i._id),
    });
    deleteImg.close();
  }

  async function handleSaveCoverImg() {
    if (newCoverImg) {
      const coverImg = newCoverImg.src;
      const updated = await patch(
        '/v1/album',
        {
          _id: album?._id,
          coverImg,
        },
        { showToastOnSuccess: true }
      );
      changeCover.close();
      if (updated._id) {
        onAlbumSave?.({ ...album, coverImg });
        clearNewCoverImg();
      }
    }
  }

  async function handleSaveFromGallery(e) {
    const ids = e.map((img) => img._id);
    const res = await patch('/v1/album', {
      _id: album?._id,
      imageIds: [...(album?.imageIds || []), ...ids],
    });

    if (res._id) {
      successToast('Added image to ' + album?.name);
      fetchData();
      galleryPicker.close();
    }
  }

  async function onAddInstagramPhotos(photos) {
    const result = await post('/v1/image/bulk-from-instagram', {
      images: photos,
      albumId: album?._id,
    });
    if (result._id) {
      successToast('Added images to ' + album?.name);
      fetchData(false);
      instaPicker.close();
    }
  }

  async function deleteAlbum() {
    deleteAlbumPrompt.close();
    const res = await post('/v1/album/delete-album', album);
    if (res === 'success') {
      onDeleteAlbum?.();
    }
  }

  function handleDeleteAlbumOrPhotos() {
    if (album?.default) {
      return deleteImg.open();
    }
    extendedDelete.open();
  }

  async function handleRenameAlbum(name) {
    rename.close();
    const newAlbum = await updateAlbum({ _id: album?._id, name });
    onAlbumSave?.(newAlbum);
  }

  function handleAddPhotoMenuSelect(option) {
    switch (option) {
      case 'getFromInsta':
        instaPicker.open();
        break;
      case 'browseGallery':
        galleryPicker.open();
        break;
    }
  }

  return (
    <>
      <Page>
        <Navbar
          className="sticky top-0 z-30"
          leftContent={
            <Button
              className="p-0 text-base normal-case flex-center"
              onClick={onClose}
            >
              <i className="mr-1 text-2xl far fa-chevron-left" /> Back
            </Button>
          }
          rightContent={
            <Button
              className="p-0 text-base normal-case flex-center"
              onClick={albumMenu.open}
            >
              Edit
            </Button>
          }
        />

        <AlbumMenu
          open={albumMenu.on}
          onClose={albumMenu.close}
          coverImg={album?.coverImg}
          enableInstaSync
          enableAlbumRename={!album?.default}
          enableDeleteAlbum={!album?.default}
          onAdd={addImg.open}
          onInstaSync={instaPicker.open}
          onChangeCoverImg={changeCover.open}
          onRename={rename.open}
          onReorder={reorder.open}
          onDelete={handleDeleteAlbumOrPhotos}
        />

        <div className="flex flex-col">
          {album?.auto && album.status === 'hidden' && (
            <div className="fixed left-0 z-30 w-full p-4">
              <Button large raised fill onClick={onEnableAlbum}>
                Enable album
              </Button>
            </div>
          )}
          <section className="sticky top-0">
            <div className="relative">
              <Img
                imgClassName="absolute top-0 left-0 w-full h-full"
                src={album?.coverImg}
                style={{ height: '60vh' }}
              />

              {/* {album?.auto && album.status === 'visible' && (
                <div className="absolute top-0 right-0 p-6">
                  <button
                    className="px-4 py-2 text-lg text-white rounded-lg shadow bg-primary focus:outline-none"
                    onClick={changeCover.open}
                  >
                    <span className="inline-block py-1">Change cover</span>
                  </button>
                </div>
              )} */}
            </div>
          </section>
          <section className="relative z-10 pb-20 -mt-1">
            <div className="w-full px-3 py-2 text-2xl font-semibold bg-white rounded-t-lg flex-center-y">
              <h2>{album?.name}</h2>

              {/* <AlbumDisplayMenu
                buttons={menuBtns}
                onAdd={albumMenu.open}
                onReorder={reorder.open}
                onDelete={deleteImg.open}
                onExtendedDelete={extendedDelete.open}
                onEdit={edit.open}
              /> */}
            </div>
            <ShowIf condition={!!images.length}>
              <div className="pb-20">
                <ImgGrid
                  className="bg-white"
                  images={images}
                  placeholders={imgsToUpload}
                  onImageSelect={setFullScreenImg}
                  imagesPerRow={{ default: 3, md: 6 }}
                />
              </div>
            </ShowIf>
          </section>
        </div>
      </Page>

      <ReorderPhotos
        open={reorder.on}
        onClose={reorder.close}
        images={images}
        onDone={handleReorderSave}
      />

      <ShowIf condition={!!fullScreenImg}>
        <div className="fixed top-0 left-0 z-10 w-screen h-screen px-4 flex-center">
          <div
            className="fixed top-0 left-0 w-screen h-screen bg-translucent-700"
            onClick={clearFullScreenImg}
          />
          <div className="relative z-10 -mt-4">
            <div className="mb-3 text-right">
              <i
                className="text-2xl text-gray-400 fas fa-times-circle"
                onClick={clearFullScreenImg}
              />
            </div>
            <Img src={fullScreenImg?.src} />
          </div>
        </div>
      </ShowIf>

      <Popup opened={changeCover.on} onPopupClosed={changeCover.close}>
        <Page className="bg-white">
          <PopupNavbar
            className="sticky top-0 z-10 py-2 bg-white"
            onDone={handleSaveCoverImg}
            onCancel={changeCover.close}
          />
          <div className="px-4 mb-3">
            <PageTitle>Pick a photo</PageTitle>
          </div>
          <ImgGrid
            images={images}
            selectable
            selectedImages={newCoverImg ? [newCoverImg] : []}
            onImageSelect={setNewCoverImg}
            imagesPerRow={{ default: 3, md: 6 }}
          />
          <LinkInstagram
            open={instagramPrompt.on}
            redirectUri={redirectUri}
            onClose={instagramPrompt.close}
            heading={'Link your instagram'}
            desc="We noticed your Instagram account has not been linked. Please link your account so we can keep your smart albums in sync."
          />

          <InstagramPickerV2
            open={instaPicker.on}
            onClose={instaPicker.close}
            albumId={album?._id}
            skipUpdate
            existingImages={images}
            onAddPhotos={onAddInstagramPhotos}
          />
        </Page>
      </Popup>

      <Popup opened={galleryPicker.on} onPopupClosed={galleryPicker.close}>
        <GalleryBrowser
          onClose={galleryPicker.close}
          multiple
          skip={album?.imageIds || []}
          onSave={handleSaveFromGallery}
        />
      </Popup>

      <ImgActionMenu
        open={addImg.on}
        onClose={addImg.close}
        hideGalleryOption={album?.default}
        onMenuSelect={handleAddPhotoMenuSelect}
        enableInsta
        builtInImageUploader
        enableMultiplImages
        enablePreviewUploadingImages
        onUploadStart={setImgsToUpload}
        onUploadComplete={handleImgUploadComplete}
      />

      <DeleteImages
        open={deleteImg.on}
        onClose={deleteImg.close}
        albumName={album?.name}
        images={images}
        onDelete={deleteImages}
      />

      <ExtendedDeleteMenu
        open={extendedDelete.on}
        onClose={extendedDelete.close}
        defaultAlbum={album?.default}
        onDeleteImages={deleteImg.open}
        onDeleteAlbum={deleteAlbumPrompt.open}
      />

      <DeleteAlbumPrompt
        open={deleteAlbumPrompt.on}
        onClose={deleteAlbumPrompt.close}
        album={album}
        onConfirm={deleteAlbum}
      />

      <EditPhotosMenu
        open={edit.on}
        onClose={edit.close}
        onRename={rename.open}
        onReorder={reorder.open}
      />
      <AlbumRenameAction
        open={rename.on}
        onClose={rename.close}
        onSave={handleRenameAlbum}
      />
    </>
  );
};
export default AlbumDisplay;
