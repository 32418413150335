import * as React from 'react';
import classnames from 'classnames';
import './SlideInModal.scss';
import Portal from '../Portal/Portal';
import ShowIf from '../ShowIf/ShowIf';

interface ISlideInModalProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  direction?: 'top' | 'bottom' | 'left' | 'right';
  fullScreen?: boolean;
  disableAnimation?: boolean;
  target?: string;
  belowPopup?: boolean;
  containerPadding?: string;
}
const SlideInModal: React.FC<ISlideInModalProps> = ({
  open,
  onClose,
  direction = 'top',
  children,
  fullScreen,
  disableAnimation,
  belowPopup,
  target,
  containerPadding = '3',
}) => {
  return (
    <Portal
      target={target}
      className={classnames({ 'SlideInModal-below-popup': belowPopup })}
    >
      <div
        className={classnames('actions-backdrop SlideInModal-backdrop', {
          'backdrop-in': open,
        })}
        onClick={onClose}
      />
      <div
        className={classnames(
          'SlideInModal-content flex items-center bg-white',
          `SlideInModal-content-slide-${direction}`,
          `p-${containerPadding}`,
          {
            'SlideInModal-content-open': open,
            'rounded-lg': !fullScreen,
            'min-h-screen min-w-full': fullScreen,
            'SlideInModal-content-no-animation': disableAnimation,
            'SlideInModal-content-fullscreen': fullScreen,
          }
        )}
      >
        <ShowIf condition={open}>{children}</ShowIf>
      </div>
    </Portal>
  );
};

export default SlideInModal;
