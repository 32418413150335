import * as React from 'react';
import { List, ListItem, Button, ListInput } from 'framework7-react';
import BtnContent from '../../../lib/BtnContent/BtnContent';
import useToggle from '../../../hooks/useToggle';
import SlideInModal from '../../../lib/SlideInModal/SlideInModal';
import PopupNavbar from '../../../lib/PopupNavbar/PopupNavbar';

interface IServiceSelectorProps {
  services?: any[];
  onChange?: (any) => void;
}
const defaultValue = {
  title: '',
  subTitle: '',
};
const ServiceSelector: React.FC<IServiceSelectorProps> = ({
  services = [],
  onChange,
}) => {
  const addPerson = useToggle();

  const [personService, setPersonService] = React.useState<any>(defaultValue);

  function handlePersonServiceChange(e) {
    const { name, value } = e.target;

    setPersonService({ ...personService, [name]: value });
  }

  function handleAdd() {
    if (Object.values(personService).some((value) => !value)) {
      return;
    }
    onChange?.([...services, personService]);
    addPerson.close();
  }

  React.useEffect(() => {
    setPersonService(defaultValue);
  }, [addPerson.on]);

  function removeService(e) {
    const i = +e.target.dataset.index;
    const newService = services.slice();
    newService.splice(i, 1);
    onChange?.(newService);
  }

  return (
    <div>
      <List className="my-3">
        {!services.length && (
          <ListInput
            className="-ml-3"
            inlineLabel
            label="Services"
            name="services"
            placeholder="None"
            value=""
            readonly
            onFocus={addPerson.open}
          />
        )}

        {!!services.length && (
          <ListInput
            inlineLabel
            className="-ml-3"
            label="Services"
            name="services"
            value={services.length || ''}
            readonly
            inputStyle={{ textAlign: 'right' }}
          />
        )}

        {services.map((service, key) => (
          <ListItem key={key} className="-ml-3">
            <BtnContent
              className="w-full -mr-4"
              icon={
                <i
                  className="fas fa-times-circle text-gray-300 text-lg"
                  data-index={key}
                  onClick={removeService}
                />
              }
            >
              <div>
                <p className="text-base">{service.title}</p>
                <p className="text-sm text-gray-500">{service.subTitle}</p>
              </div>
            </BtnContent>
          </ListItem>
        ))}

        {!!services.length && (
          <ListItem onClick={addPerson.open} className="-ml-3">
            <div>
              <i className="fal fa-plus mr-3" /> Add Service
            </div>
          </ListItem>
        )}
      </List>

      {/* <div className="my-3 flex">
        <div className="flex-center-y" onClick={addPerson.open}>
          <Button className="bg-gray-100 w-8 h-8 mr-3 flex-center" round>
            <i className="fal fa-plus text-base" />
          </Button>
          <span className="text-primary text-base">Add a person</span>
        </div>
      </div>
      <div className="-mx-4 border-b border-gray-200" /> */}

      <SlideInModal
        open={addPerson.on}
        onClose={addPerson.close}
        disableAnimation
      >
        <div className="w-full">
          <PopupNavbar
            className="w-full"
            onCancel={addPerson.close}
            rightBtnLabel="Add"
            onDone={handleAdd}
            title="Add a person"
          />
          <List>
            <ListInput
              className="-ml-4"
              placeholder="Who is this service for"
              name="title"
              value={personService.title || ''}
              onChange={handlePersonServiceChange}
            />
            <ListInput
              className="-ml-4"
              placeholder="What service would they like"
              name="subTitle"
              value={personService.subTitle || ''}
              onChange={handlePersonServiceChange}
            />
          </List>

          <Button large raised fill onClick={handleAdd}>
            Add
          </Button>
        </div>
      </SlideInModal>
    </div>
  );
};

export default ServiceSelector;
