import * as React from 'react';
import { IImage } from '../../types/image';
import Img from '../../lib/Img/Img';
import ImgUploader from '../../lib/ImgUploader/ImgUploader';
import { Actions, ActionsGroup, ActionsButton, Popup } from 'framework7-react';
import useToggle from '../../hooks/useToggle';
import ShowIf from '../../lib/ShowIf/ShowIf';
import { selectDeselectItem, isProSubscribed } from '../../utils';
import ProStar from '../../lib/ProStar/ProStar';
import useUserStore from '../../hooks/useUserStore';
import useBackendApi from '../../hooks/useBackendApi';
import UploadImgBox from '../../lib/UploadImgBox/UploadImgBox';
import BtnContent from '../../lib/BtnContent/BtnContent';
import { IoIosPhotos } from 'react-icons/io';
import GalleryBrowser from '../GalleryBrowser/GalleryBrowser';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';

interface ICoverPicsEditorProps {
  images?: IImage[];
  onChange?: (e?: any) => void;
  onProFeatureClick?: (e?: any) => void;
}
const CoverPicsEditor: React.FC<ICoverPicsEditorProps> = ({
  images = [],
  onChange,
  onProFeatureClick,
}) => {
  const [uploadImgList, setUploadImgList] = React.useState<IImage[]>([]);
  const { user, setUser } = useUserStore();
  const { patch } = useBackendApi();
  React.useEffect(() => {
    setUploadImgList(images);
  }, [images]);

  const [indexToUpdate, setIndexToUpdate] = React.useState<number>();

  const [uploadingImg, setUploadingImg] = React.useState<string>('');

  const uploadMenu = useToggle();
  const imgBrowser = useToggle();

  function handleChangeImg(index) {
    // if (index > 0 && !isProSubscribed(user.subscriptions)) {
    //   return onProFeatureClick?.();
    // }
    setIndexToUpdate(index);
    uploadMenu.open();
  }

  function handleImgUploadStart(e) {
    uploadMenu.close();
    setUploadingImg(e);
  }

  async function handleFinishUpload(e) {
    const newList: IImage[] = uploadImgList.slice();
    newList.splice(indexToUpdate!, 1, {
      ...uploadImgList[indexToUpdate!],
      src: e.target.value,
    });
    setUploadImgList(newList);
    const newListArray = newList.map((i) => i.src);
    setUser({ coverImgs: newListArray });
    await patch('/v1/user', { coverImgs: newListArray, _id: user._id });
    onChange?.(newList);
    setUploadingImg('');
  }

  async function saveSelectFromGallery(img: IImage) {
    const coverImgs = user.coverImgs?.slice();
    coverImgs?.splice(indexToUpdate!, 1, img.src!);
    const res = await patch('/v1/user', { coverImgs, _id: user._id });
    if (res._id) {
      setUser({ coverImgs });
    }
    imgBrowser.close();
  }

  return (
    <div className="grid grid-cols-3 gap-2">
      {images.map((img, key) => (
        <div
          key={key + (img.src || '')}
          className="relative"
          onClick={() => handleChangeImg(key)}
        >
          <ShowIf
            condition={!(key === indexToUpdate && !!uploadingImg)}
            elseTemplate={
              <Img
                src={uploadingImg}
                grayScale
                size="square"
                className="rounded-md"
              />
            }
          >
            <Img src={img.src} size="square" className="rounded-lg" />
          </ShowIf>

          {/* <ShowIf condition={key > 0}>
            <div className="absolute top-0 right-0 pr-1 pt-1">
              <ProStar />
            </div>
          </ShowIf> */}

          <div className="backdrop-blur-20 bg-translucent-300 absolute text-primary absolute bottom-0 left-0 w-full text-center text-base py-1 rounded-b-md">
            Change
          </div>
        </div>
      ))}

      {[0, 1, 2].map((num) => (
        <div
          className={!images[num] ? '' : 'hidden'}
          key={num}
          onClick={() => handleChangeImg(num)}
        >
          <UploadImgBox />
        </div>
      ))}

      <Actions opened={uploadMenu.on} onActionsClosed={uploadMenu.close}>
        <ActionsGroup>
          <ActionsButton close={false}>
            <ImgUploader
              name="addPhoto"
              onUploadStart={handleImgUploadStart}
              onUploadComplete={handleFinishUpload}
            >
              <div className="flex justify-between items-center px-4 relative">
                <span>Upload a photo</span>
                <span>
                  <i className="fas fa-camera text-2xl" />
                </span>
              </div>
            </ImgUploader>
          </ActionsButton>

          <ActionsButton onClick={imgBrowser.open}>
            <BtnContent icon={<IoIosPhotos />}>Choose from gallery</BtnContent>
          </ActionsButton>
        </ActionsGroup>
      </Actions>

      <SlideInModal
        open={imgBrowser.on}
        onClose={imgBrowser.close}
        fullScreen
        disableAnimation
      >
        <GalleryBrowser
          onClose={imgBrowser.close}
          onSave={saveSelectFromGallery}
        />
      </SlideInModal>
    </div>
  );
};

export default CoverPicsEditor;
