import * as React from 'react';
import { List, ListInput } from 'framework7-react';

interface ITagInputProps {
  placeholder?: string;
  label?: string;
  id?: string;
  onAdd?: (e?: any) => void;
  onClear?: (e?: any) => void;
}
const TagInput: React.FC<ITagInputProps> = ({
  placeholder,
  label,
  onAdd,
  id = '',
  onClear,
}) => {
  const [text, setText] = React.useState<string>('');

  function handleAdd() {
    if (text) {
      onAdd?.(text);
      handleClear();
    }
  }

  function handleClear() {
    setText('');
    onClear?.();
  }

  function handleInputChange(e) {
    setText(e.target.value);
  }

  return (
    <div className="relative">
      <List noHairlines className="m-0">
        <ListInput
          outline
          floatingLabel
          placeholder={placeholder}
          label={label}
          value={text}
          onChange={handleInputChange}
          inputId={id}
        />
      </List>

      <div className="absolute right-0 top-0 z-10 flex h-full items-stretch pr-4 py-2">
        <button
          className="active:opacity-50 px-4 focus:outline-none"
          onClick={handleClear}
        >
          <i className="fal fa-times" />
        </button>
        <button
          className="active:opacity-50 px-4 focus:outline-none"
          onClick={handleAdd}
        >
          <i className="fal fa-check" />
        </button>
      </div>
    </div>
  );
};

export default TagInput;
