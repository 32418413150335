import * as React from 'react';
import { useParams, useHistory } from 'react-router';
import useToggle from '../../hooks/useToggle';
import {
  Page,
  Preloader,
  PageContent,
  Navbar,
  List,
  ListItem,
  ListInput,
  Button,
} from 'framework7-react';
import Spinner from '../../lib/Spinner/Spinner';
import useToast from '../../hooks/useToast';
import useBackendApi from '../../hooks/useBackendApi';

interface IResetPasswordProps { }
const ResetPassword: React.FC<IResetPasswordProps> = ({ }) => {
  const { code } = useParams();
  const { successToast, errorToast, catchErrorToast } = useToast();
  const codeVerified = useToggle();
  const { post } = useBackendApi();
  const history = useHistory();
  React.useEffect(() => {
    setTimeout(() => {
      codeVerified.toggle();
    }, 1000);
  }, []);

  const [userInput, setUserInput] = React.useState<any>({ password: '' });

  function handleUserInputChange(e: any) {
    const { name, value } = e.target;
    setUserInput({ [name]: value });
  }

  async function submitPassword() {
    try {
      const response = await post('/v1/auth/new-password', {
        code,
        password: userInput.password
      })
      if (response === 'success') {
        successToast('Password successfully changed. Please login');
        history.push('/login')

      } else {
        errorToast('Unexpected error has occured')
      }
    } catch (err) {
      catchErrorToast(err);
      console.error(err);
    }
  }

  return (
    <Page pageContent={false}>
      {codeVerified.off && (
        <PageContent className="flex justify-center items-center">
          <div>
            <div className="text-center">
              <Spinner size={48} />
            </div>
            <div className="mt-4">Please wait</div>
          </div>
        </PageContent>
      )}
      {codeVerified.on && (
        <PageContent className="bg-white">
          <Navbar title="Reset Password" />
          <div className="p-4">
            <div className=" mt-5 text-lg px-4">Enter your new password</div>
            <List noHairlines className="mt-4">
              <ListInput
                outline
                label="New Password"
                floatingLabel
                type="password"
                placeholder="Your New Password"
                clearButton
                name="password"
                value={userInput.password}
                onChange={handleUserInputChange}
                className="input-bg-gray mb-4"
              />
            </List>
            <div className="px-4">
              <Button fill className="w-full" large raised onClick={submitPassword}>
                Reset Password
              </Button>
            </div>
          </div>
        </PageContent>
      )}
    </Page>
  );
};

export default ResetPassword;
