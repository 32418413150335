import React from 'react';
import FeaturePreview from '../FeaturePreview/FeaturePreview';
import ShowIf from '../../lib/ShowIf/ShowIf';
import Img from '../../lib/Img/Img';
import SubscriptionPopup from '../SubscriptionPopup/SubscriptionPopup';
import PageTitle from '../../lib/PageTitle/PageTitle';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import AlbumItem from '../AlbumItem/AlbumItem';
import useSubItemsStore from '../../hooks/useSubItemStore';
import { ESubItemId } from '../../types/subItem';
import { Page, PageContent, Button } from 'framework7-react';
import Navbar from '../../lib/Navbar/Navbar';
import BackBtn from '../../lib/BackBtn/BackBtn';
import { ISubscription } from '../../types/subscription';
import SetupCompleteProgress from '../SetupCompleteProgress/SetupCompleteProgress';
import useUserStore from '../../hooks/useUserStore';
import useToggle from '../../hooks/useToggle';
import useToast from '../../hooks/useToast';
import { formatCentsToReadable } from '../../utils';

interface SmartAlbumPaywallProps {
  onCancel?: (e?: any) => void;
  onSubscribed?: (e?: any) => void;
}

const displayItems = [
  {
    icon: 'fab fa-instagram static text-2xl',
    heading: 'Connect to Instagram',
    subHeading: '',
    bg: 'bg-insta',
  },
  {
    icon: 'fas fa-heart text-lg',
    heading: 'Add a smart album',
    subHeading: 'and tell Nishi what # to look out for',
    bg: 'bg-primary',
  },
  {
    icon: 'fal fa-sync text-lg',
    heading: 'Nishi will auto sync',
    subHeading: 'your instagram pics to your album',
    bg: 'bg-black',
  },
];
const SmartAlbumPaywall: React.FunctionComponent<SmartAlbumPaywallProps> = ({
  onCancel,
  onSubscribed,
}) => {
  const { setUser, user } = useUserStore();
  const [step, setStep] = React.useState<any>('preview');
  const [subscription, setSubscription] = React.useState<ISubscription>();
  const { subItems } = useSubItemsStore();
  const { successToast } = useToast();
  const smartAlbum = subItems.find((i) => i.id === ESubItemId.smartAlbums);
  function proceedToPayments() {
    setStep('payment');
  }

  function goBack() {
    setStep('preview');
  }

  function handleSubscirbed(sub) {
    setSubscription(sub);
    setStep('success');
  }

  function handleSetupComplete() {
    setUser({
      subscriptions: [...(user.subscriptions || []), subscription],
    });
    successToast('Subscribed to smart albums.');
    onSubscribed?.();
  }

  return (
    <>
      {step === 'preview' && (
        <FeaturePreview
          currency="$"
          price={formatCentsToReadable(smartAlbum?.price)}
          desc={smartAlbum?.desc}
          tagLine={smartAlbum?.tagLine}
          trialPeriod={smartAlbum?.trialPeriod + ' days'}
          onNext={proceedToPayments}
          onClose={onCancel}
          image={smartAlbum?.image}
          name={smartAlbum?.name}
          ctaLabel={smartAlbum?.trialPeriod ? `Activate Free Trial` : undefined}
          advisoryText={`Free for ${smartAlbum?.trialPeriod} days, then $
          ${((smartAlbum?.price || 0) / 100).toFixed(2)}/mo. Cancel anytime.`}
        >


          <section className="flex flex-no-wrap mt-5 items-stretch overflow-x-auto pb-4">
            {displayItems.map((item, key) => (
              <div
                className="flex-none w-7/16 mr-2 flex items-stretch"
                key={key}
              >
                <div className={` ${item.bg} text-white p-2 rounded-lg w-full`}>
                  <div>
                    <i className={`${item.icon}`} />
                  </div>

                  <div className="mt-2 font-semibold">{item.heading}</div>
                  <p>{item.subHeading}</p>
                </div>
              </div>
            ))}
          </section>

        </FeaturePreview>
      )}

      {step === 'payment' && (
        <Page pageContent={false}>
          <PageContent className="flex flex-col">
            <Navbar leftContent={<BackBtn onClick={goBack} />} />
            <SubscriptionPopup
              open={step === 'payment'}
              onSubscribe={handleSubscirbed}
              subItem={smartAlbum}
            >
              <h3>
                <PageTitle>Activate Skill</PageTitle>
              </h3>
              <section className="flex-center-y">
                <div>
                  <Img
                    src={smartAlbum?.image}
                    size="64x64"
                    className="rounded-lg"
                  />
                </div>
                <div className="ml-4 pb-3 border-b border-gray-200 flex-grow">
                  <h2>
                    <SectionTitle>Smart Albums</SectionTitle>
                  </h2>
                  <div className="text-base">
                    ${((smartAlbum?.price || 0) / 100).toFixed(2)}/month
                  </div>
                  <div className="text-gray-600">cancel anytime</div>
                </div>
              </section>
            </SubscriptionPopup>
          </PageContent>
        </Page>
      )}

      {step === 'success' && (
        <SetupCompleteProgress
          onFinish={handleSetupComplete}
          title="Activating Smart Albums"
          steps={[
            'Analyzing photos',
            'Setting up image categorizer',
            'Creating albums',
          ]}
        />
      )}
    </>
  );
};
export default SmartAlbumPaywall;
