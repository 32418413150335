import React from 'react';
import { IReview } from '../../types/review';
import { IReviewCardTemplate } from '../../types/reviewCardTemplate';
import classnames from 'classnames';
import ShowIf from '../../lib/ShowIf/ShowIf';
import Img from '../../lib/Img/Img';
import ReviewTemplatePattern from '../ReviewTemplateCard/ReviewTemplatePattern/ReviewTemplatePattern';
import './ReviewFeedTemplateCard.scss';
import CollectedByNishi from '../CollectedByNishi/CollectedByNishi';
import StarRating from '../../lib/StarRating/StarRating';
import ReviewTemplateHeadingPattern from './ReviewTemplateHeadingPattern/ReviewTemplateHeadingPattern';

interface ReviewFeedTemplateCardProps {
  review?: IReview;
  logo?: string;
  template?: IReviewCardTemplate;
  onImgLoaded?: (e?: any) => void;
  source?: string;
}

function getImgUrl(url) {
  return `https://api-live.flayr.me/v1/util/route-image?url=${url}`;
}
const ReviewFeedTemplateCard: React.FunctionComponent<ReviewFeedTemplateCardProps> = ({
  template,
  logo,
  review,
  onImgLoaded,
  source = 'nishi',
}) => {
  const [requiredItems, setRequiredItems] = React.useState<string[]>([]);
  const [loadedImgs, setLoadedImgs] = React.useState<any>({
    logo: false,
    img1: false,
    img2: false,
  });

  function handleImgLoaded(e) {
    const name = e.target.id;
    setLoadedImgs({ ...loadedImgs, [name]: true });
  }

  React.useEffect(() => {
    const newRequired: string[] = [];
    if (template?.logo) {
      newRequired.push('logo');
    }
    if (template?.img1) {
      newRequired.push('img1');
    }
    if (template?.img2) {
      newRequired.push('img2');
    }

    setRequiredItems([...newRequired]);
  }, [template]);

  React.useEffect(() => {
    const requiredFulfilled = !requiredItems.some((req) => !loadedImgs[req]);

    if (requiredFulfilled) {
      onImgLoaded?.();
    }
  }, [loadedImgs]);

  return (
    <div
      className={classnames(
        'ReviewFeedTemplateCard h-screen w-screen',
        `ReviewFeedTemplateCard-${template?.templateId}`,
        `ReviewFeedTemplateCard-theme-${template?.theme}`
      )}
    >
      <div className="w-screen h-screen max-h-screen overflow-hidden ReviewFeedTemplateCard-bg max-w-screen">
        <ShowIf condition={template?.templateId === '3'}>
          <div className="ReviewFeedTemplateCard-image">
            <Img
              crossOrigin="anonymous"
              src={getImgUrl(review?.images?.[0])}
              size="square"
              className="rounded"
              imgId="img1"
              onImgLoaded={handleImgLoaded}
            />
          </div>
        </ShowIf>
        <ShowIf condition={template?.templateId === '1'}>
          <div className="pattern pattern-top-left">
            <ReviewTemplatePattern />
          </div>
          <div className="pattern pattern-top-right">
            <ReviewTemplatePattern />
          </div>
          <div className="pattern pattern-bottom-left">
            <ReviewTemplatePattern />
          </div>
          <div className="pattern pattern-bottom-right">
            <ReviewTemplatePattern />
          </div>
        </ShowIf>
        <div className="ReviewFeedTemplateCard-inner">
          <div className="uppercase ReviewFeedTemplateCard-heading">
            <ShowIf condition={template?.templateId === 'default'}>
              <div className="my-2 flex-center">
                <div className="w-10 mr-3">
                  <ReviewTemplateHeadingPattern />
                </div>
                <span>customer love</span>
                <div className="w-10 ml-3 transform rotate-180">
                  <ReviewTemplateHeadingPattern />
                </div>
              </div>
            </ShowIf>
            <ShowIf condition={template?.templateId === '1'}>thank you</ShowIf>
          </div>

          <ShowIf condition={template?.templateId === 'default'}>
            <div className="my-2 flex-center">
              <StarRating
                fillColor="currentColor"
                rating={5}
                starClassName="mr-2 text-4xl"
              />
            </div>
          </ShowIf>

          <ShowIf condition={template?.templateId === '2'}>
            <div className="ReviewFeedTemplateCard-image">
              <Img
                src={getImgUrl(review?.images?.[0])}
                size="square"
                className="rounded"
                imgId="img1"
                onImgLoaded={handleImgLoaded}
              />
            </div>

            <ShowIf condition={template?.logo}>
              <div className="ReviewFeedTemplateCard-brand-logo">
                <Img
                  crossOrigin="anonymous"
                  src={getImgUrl(logo)}
                  className="rounded-full"
                  size="48x48"
                  imgId="logo"
                  onImgLoaded={handleImgLoaded}
                />
              </div>
            </ShowIf>
          </ShowIf>

          <ShowIf condition={template?.templateId === '4'}>
            <div className="ReviewFeedTemplateCard-content">
              <div className="ReviewFeedTemplateCard-image">
                <Img
                  crossOrigin="anonymous"
                  src={getImgUrl(review?.images?.[0])}
                  size="square"
                />
              </div>

              <div className="ReviewFeedTemplateCard-brand-logo">
                <ShowIf
                  condition={template?.logo}
                  elseTemplate={
                    <div className="text-xl text-center">
                      <StarRating
                        fillColor="currentColor"
                        rating={5}
                        starClassName="mr-2 text-2xl"
                      />
                      <p>5 out of 5</p>
                    </div>
                  }
                >
                  <Img
                    crossOrigin="anonymous"
                    src={getImgUrl(logo)}
                    className="rounded-full"
                    size="96x96"
                    imgId="logo"
                    onImgLoaded={handleImgLoaded}
                  />
                </ShowIf>
              </div>

              <div className="ReviewFeedTemplateCard-text">
                <div className="ReviewFeedTemplateCard-comments">
                  {review?.comments}
                </div>
                <div className="ReviewFeedTemplateCard-customer">
                  {review?.customerName}
                </div>
                <div className="ReviewFeedTemplateCard-verified-by">
                  {source === 'nishi' && (
                    <div className="w-1/2 pt-2 mx-auto">
                      <CollectedByNishi />
                    </div>
                  )}
                  {source === 'kudosninja' && (
                    <div className="pt-2">
                      <div className="inline-block px-4 py-1 text-xs text-center border-2 border-current rounded-lg">
                        <div className="leading-tight">
                          <span>collected by</span>
                        </div>
                        <div className="font-semibold whitespace-no-wrap">
                          Kudos Ninja
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <ShowIf condition={template?.img2}>
                <div className="ReviewFeedTemplateCard-image">
                  <Img
                    crossOrigin="anonymous"
                    src={getImgUrl(review?.images?.[1])}
                    size="square"
                    imgId="img2"
                    onImgLoaded={handleImgLoaded}
                  />
                </div>
              </ShowIf>
            </div>
          </ShowIf>

          <div className="ReviewFeedTemplateCard-verified-by">
            <div
              className={classnames({
                'w-full flex justify-center': template?.templateId === '2',
              })}
            >
              {/* {template?.templateId}, {template?.id} */}
              {source === 'nishi' && (
                <div
                  className={classnames({
                    'w-2/5': template?.templateId === '2',
                    'w-2/6 mx-auto': template?.templateId === '2',
                    'w-4/16 mx-auto':
                      template?.templateId === '3' ||
                      template?.templateId === 'default' ||
                      template?.templateId === '1',
                  })}
                >
                  <CollectedByNishi />
                </div>
              )}

              {source === 'kudosninja' && (
                <div className="inline-block px-4 py-1 text-center border-2 border-current rounded-lg">
                  <div className="text-xs leading-tight">
                    <span>collected by</span>
                  </div>
                  <div className="text-lg font-semibold">Kudos Ninja</div>
                </div>
              )}
            </div>
          </div>

          <div className="ReviewFeedTemplateCard-comments">
            {review?.comments}
          </div>

          <div className="ReviewFeedTemplateCard-customer">
            {review?.customerName}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReviewFeedTemplateCard;
