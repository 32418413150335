import * as React from 'react';
import ActionSheet from '../ActionSheet/ActionSheet';
import Calendar from '../Calendar/Calendar';
import useToggle from '../../hooks/useToggle';
import SlideInModal from '../SlideInModal/SlideInModal';

interface IDatepickerProps {
  name?: string;
  value?: string;
  onChange?: (e?: any) => void;
  target?: string;
}
const Datepicker: React.FC<IDatepickerProps> = ({
  name,
  value = '',
  onChange,
  target = '',
}) => {
  const showing = useToggle();

  React.useEffect(() => {
    const el = document.querySelector(target);
    if (el) {
      el.addEventListener('focus', showing.open);
    }
    return () => {
      if (el) {
        el.removeEventListener('focus', showing.open);
      }
    };
  }, []);

  function handleChange(value) {
    onChange?.({ target: { name, value } });
    showing.close();
  }

  return (
    <SlideInModal
      open={showing.on}
      onClose={showing.close}
      disableAnimation
      containerPadding="0"
    >
      <div className="w-full">
        <Calendar value={value} onDateSelect={handleChange} />
      </div>
    </SlideInModal>
  );
};

export default Datepicker;
