import * as React from 'react';
import ShowIf from '../../lib/ShowIf/ShowIf';
import useToggle from '../../hooks/useToggle';
import useUserStore from '../../hooks/useUserStore';
import {
  List,
  ListInput,
  Icon,
  Button,
  Segmented,
  Page,
} from 'framework7-react';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import PageTitle from '../../lib/PageTitle/PageTitle';
import useBackendApi from '../../hooks/useBackendApi';
import { ITheme } from '../../types/theme';

interface IDesignEditorProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  onSave?: (e?: any) => void;
  theme?: any;
  demo?: boolean;
}
const DesignEditor: React.FC<IDesignEditorProps> = ({
  open,
  onClose,
  onSave,
  demo,
  theme = {},
}) => {
  const [userInput, setUserInput] = React.useState<any>({});
  const { user, setUser } = useUserStore();
  const { patch } = useBackendApi();

  React.useEffect(() => {
    setUserInput({
      primaryColor: theme?.primaryColor,
      headingColor: theme?.heading.color,
    });
  }, [theme]);

  const primaryColorTabSelected = useToggle(true);

  function getModifiedTheme() {
    const styles = {
      '--user-theme-color-primary': userInput?.primaryColor,
      '--user-theme-color-heading': userInput?.headingColor,
      '--f7-theme-color': 'var(--user-theme-color-primary)',
      '--f7-button-text-color': 'var(--user-theme-color-primary)',
      '--f7-actions-button-text-color': 'var(--user-theme-color-primary)',
    } as React.CSSProperties;
    return styles;
  }

  function handleUserInputChange(e: any) {
    const { name, value } = e.target;

    const newInput = { ...userInput, [name]: value };
    setUserInput(newInput);
  }

  async function handleSave() {
    const theme: ITheme = {
      ...user.theme,
      primaryColor: userInput.primaryColor,
      heading: {
        ...user.theme?.heading,
        color: userInput.headingColor,
      },
    };
    if (!demo) {
      const res = await patch(
        '/v1/user',
        {
          _id: user._id,
          theme,
          themeConfigs: user.themeConfigs?.map((tc) => {
            if (theme.id === tc.id) {
              return theme;
            }
            return tc;
          }),
        },
        {
          showToastOnError: true,
          showToastOnSuccess: true,
          successMsg: 'Updated colours on your website',
        }
      );
      if (res._id) {
        setUser({ theme });
        onSave?.(theme);
        onClose?.();
      }
    } else {
      onSave?.(theme);
      onClose?.();
    }
  }

  return (
    <SlideInModal
      open={open}
      onClose={onClose}
      fullScreen
      direction="right"
      belowPopup
    >
      <Page>
        <PopupNavbar
          sticky
          onCancel={onClose}
          letfBtnLabel="Back"
          backIcon
          rightBtnLabel="Save"
          onDone={handleSave}
        />
        <div className="px-4">
          <h1 className="mb-5">
            <PageTitle>Customize your website</PageTitle>
          </h1>
          <div className="my-3 px-5 mb-4">
            <Segmented strong>
              <Button
                active={primaryColorTabSelected.on}
                onClick={primaryColorTabSelected.open}
              >
                Primary Colour
              </Button>
              <Button
                active={primaryColorTabSelected.off}
                onClick={primaryColorTabSelected.close}
              >
                Heading Colour
              </Button>
            </Segmented>
          </div>
          <ShowIf condition={primaryColorTabSelected.on}>
            <section style={getModifiedTheme()}>
              <List className="mb-0">
                <ListInput
                  className="-ml-3 input-label-auto"
                  label="Primary Colour"
                  inlineLabel
                  type="colorpicker"
                  inputStyle={{ textAlign: 'right' }}
                  readonly
                  colorPickerParams={{
                    modules: ['palette'],
                    openIn: 'auto',
                    openInPhone: 'sheet',
                  }}
                  name="primaryColor"
                  value={{ hex: userInput.primaryColor }}
                  onColorPickerChange={(color) =>
                    handleUserInputChange({
                      target: { name: 'primaryColor', value: color.hex },
                    })
                  }
                >
                  <Icon slot="media" className="mt-1">
                    <div className="w-5 h-5 rounded bg-primary" />
                  </Icon>
                </ListInput>
              </List>
              <div className="text-right text-gray-400 pr-3 mb-10">
                Change colour
              </div>

              <h3 className="mb-3">
                <SectionTitle>Preview</SectionTitle>
              </h3>

              <div className="grid grid-cols-2 gap-4 text-gray-500 items-center">
                <div>The buttons on your website will look like this</div>
                <div>
                  <Button fill large>
                    Button
                  </Button>
                </div>

                <div>The links will look like this</div>
                <div>
                  <div className="text-primary underline">{user.website}</div>
                </div>

                <div>Icons will look like this</div>
                <div>
                  <div className="text-primary text-xl">
                    <p>
                      <span className="star" />
                      <span className="star" />
                      <span className="star" />
                      <span className="star" />
                      <span className="star-outline" />
                    </p>
                    <p className="text-2xl">
                      <i className="fab fa-facebook-square relative" />
                      <i className="fab fa-instagram relative ml-2" />
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </ShowIf>

          <ShowIf condition={primaryColorTabSelected.off}>
            <section style={getModifiedTheme()}>
              <List className="mb-0">
                <ListInput
                  className="-ml-3 input-label-auto"
                  label="Heading Colour"
                  inlineLabel
                  type="colorpicker"
                  inputStyle={{ textAlign: 'right' }}
                  readonly
                  colorPickerParams={{
                    modules: ['palette'],
                    openIn: 'auto',
                    openInPhone: 'sheet',
                  }}
                  name="headingColor"
                  value={{ hex: userInput.headingColor }}
                  onColorPickerChange={(color) =>
                    handleUserInputChange({
                      target: { name: 'headingColor', value: color.hex },
                    })
                  }
                >
                  <Icon slot="media" className="mt-1">
                    <div className="w-5 h-5 rounded bg-heading" />
                  </Icon>
                </ListInput>
              </List>
              <div className="text-right text-gray-400 pr-3 mb-10">
                Change colour
              </div>

              <h3 className="mb-3">
                <SectionTitle>Preview</SectionTitle>
              </h3>
              <div className="text-gray-500 py-3">
                The headings on your weebsite will look like this
              </div>
              <div className="px-4 text-center">
                <h3 className="mb-3">
                  <SectionTitle size="3xl">Gallery</SectionTitle>
                </h3>
                <h3 className="mb-3">
                  <SectionTitle size="2xl">Photos</SectionTitle>
                </h3>
                <h3 className="mb-3">
                  <SectionTitle size="xl">Reviews</SectionTitle>
                </h3>
                <h3 className="mb-3">
                  <SectionTitle>Profile</SectionTitle>
                </h3>
              </div>
            </section>
          </ShowIf>
        </div>
      </Page>
    </SlideInModal>
  );
};

export default DesignEditor;
