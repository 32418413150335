import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import './ReadMore.scss';
interface ReadMoreProps {
  text: React.ReactNode;
  lines?: number;
  showMoreText?: string;
  showMoreClassName?: string;
  disabled?: boolean;
  enableCounter?: boolean;
}

const ReadMore: React.FC<ReadMoreProps> = ({
  text = '',
  lines = 3,
  showMoreClassName,
  disabled,
  enableCounter,
}) => {
  const [showFullContent, setShowFullContent] = useState(false);
  const [contentLines, setContentLines] = useState<any>();

  const ref: any = useRef();

  function toggleContent() {
    !disabled && setShowFullContent(!showFullContent);
  }

  useEffect(() => {
    if (enableCounter && text) {
      const el = ref.current;
      const divHeight = el.offsetHeight;
      let lineHeight: any = window
        .getComputedStyle(el, null)
        .getPropertyValue('line-height');
      lineHeight = +lineHeight.replace('px', '');
      var l = Math.round(divHeight / lineHeight);
      setContentLines(l);
    }
  }, [enableCounter]);

  if (!text) {
    return null;
  }

  return (
    <div style={{ '--lines': lines } as React.CSSProperties}>
      <div
        className={classnames('ReadMore-content', { visible: showFullContent })}
        ref={ref}
      >
        {text}
      </div>
      <span
        className={classnames('mt-2 font-semibold', showMoreClassName, {
          hidden:
            ((contentLines || contentLines === 0) && contentLines < lines) ||
            !text,
        })}
      >
        <span onClick={toggleContent} className="cursor-pointer">
          {showFullContent ? 'show less' : 'read more'}
        </span>
      </span>
    </div>
  );
};
export default ReadMore;
