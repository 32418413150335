import * as React from 'react';
import useToggle from '../../hooks/useToggle';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import { Page, List, ListItem, Icon, Checkbox, Button } from 'framework7-react';
import Navbar from '../../lib/Navbar/Navbar';
import BackBtn from '../../lib/BackBtn/BackBtn';
import PageTitle from '../../lib/PageTitle/PageTitle';
import { IAlbum, EAlbumStatus } from '../../types/album';
import Img from '../../lib/Img/Img';
import { selectDeselectItem, isSubscribedTo } from '../../utils';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import { MdContentCopy } from 'react-icons/md';
import ShowIf from '../../lib/ShowIf/ShowIf';
import TagInput from '../../lib/TagInput/TagInput';
import useUserStore from '../../hooks/useUserStore';
import { ESubItemId } from '../../types/subItem';
import SmartAlbumPaywall from '../SmartAlbumPaywall/SmartAlbumPaywall';

interface IAddPhotosProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  images?: string[];
  onSubmit?: (e?: any) => void;
}
const AddPhotos: React.FC<IAddPhotosProps> = ({
  open,
  onClose,
  onSubmit,
  images = [],
}) => {
  const addNew = useToggle();
  const { user } = useUserStore();
  const paywall = useToggle();

  const [selectedAlbums, setSelectedAlbums] = React.useState<string[]>([]);

  const [newAlbums, setNewAlbums] = React.useState<IAlbum[]>([]);

  React.useEffect(() => {
    return () => {
      setSelectedAlbums([]);
      setNewAlbums([]);
    };
  }, [open]);

  function handleSelectAlbum(id) {
    const items = selectDeselectItem(id, selectedAlbums);
    setSelectedAlbums(items);
  }

  function handleAddNewAlbum() {
    if (!isSubscribedTo(ESubItemId.smartAlbums, user.subscriptions || [])) {
      return paywall.open();
    }
    addNew.open();
  }

  function handleSubmit() {
    const formatted = {
      srcs: images,
      albumIds: selectedAlbums,
      newAlbums,
    };
    onSubmit?.(formatted);
  }

  function handleCreateNewAlbum(name) {
    if (newAlbums.findIndex((al) => al.name === name) >= 0) {
      return;
    }
    const newAlbum: IAlbum = {
      name,
      images: images.map((src) => ({ src })),
      coverImg: images[0],
      auto: false,
      default: false,
      status: EAlbumStatus.visible,
      tags: [],
      userId: user._id,
    };
    setNewAlbums([...newAlbums, newAlbum]);
  }

  function handleRemoveAlbum(e) {
    const idx = +e.target.dataset.index;
    const updated = [...newAlbums];
    updated.splice(idx, 1);
    setNewAlbums(updated);
  }

  function handleSubscribedToSmartAlbums() {
    paywall.close();
    addNew.open();
  }

  return (
    <SlideInModal direction="right" open={open} fullScreen onClose={onClose}>
      <Page>
        <Navbar leftContent={<BackBtn onClick={onClose} />} />
        <div className="px-4 pb-20">
          <h2>
            <PageTitle>Add to albums</PageTitle>
          </h2>

          <ShowIf condition={addNew.on}>
            <TagInput
              placeholder="Album name"
              onAdd={handleCreateNewAlbum}
              onClear={addNew.toggle}
            />
          </ShowIf>
          <ShowIf condition={addNew.off}>
            <List className="mt-2 mb-0" noHairlines>
              <ListItem>
                <Icon slot="media">
                  <div
                    className="text-3xl transform rotate-90 text-primary flex-center"
                    style={{ height: 45, width: 45 }}
                  >
                    <MdContentCopy />
                  </div>
                </Icon>
                <div>
                  <SectionTitle size="base">Add new album</SectionTitle>
                </div>
                <Button
                  className="px-5 bg-gray-200"
                  round
                  onClick={handleAddNewAlbum}
                >
                  add
                </Button>
              </ListItem>
            </List>
          </ShowIf>
          <List className="mt-0">
            {newAlbums.map((album, idx) => (
              <ListItem key={idx}>
                <Icon slot="media" className="relative">
                  <Img
                    src={album.coverImg}
                    size="64x64"
                    className="rounded-lg"
                  />
                  <div className="absolute top-0 left-0">
                    <button
                      className="w-5 h-5 text-white transform -translate-x-2 -translate-y-1 bg-red-500 rounded-full focus:outline-none active:bg-red-400 flex-center"
                      onClick={handleRemoveAlbum}
                      data-index={idx}
                    >
                      <i className="pointer-events-none fal fa-minus" />
                    </button>
                  </div>
                </Icon>
                <div>
                  <SectionTitle size="base">{album.name}</SectionTitle>{' '}
                  <span className="text-sm text-gray-500">(new album)</span>
                </div>
                <Checkbox
                  name="coverImg"
                  className="border rounded-full opacity-25 checkbox-white chekbox-shadow border-primary"
                  checked={true}
                  readonly
                />
              </ListItem>
            ))}

            {user.albums?.map((album) => (
              <ListItem
                key={album._id}
                onClick={() => handleSelectAlbum(album._id)}
              >
                <Icon slot="media">
                  <Img
                    src={album.coverImg}
                    size="64x64"
                    className="rounded-lg"
                  />
                </Icon>
                <SectionTitle size="base">{album.name}</SectionTitle>
                <Checkbox
                  name="coverImg"
                  className="border rounded-full checkbox-white chekbox-shadow border-primary"
                  checked={selectedAlbums.includes(album._id || '')}
                  readonly
                />
              </ListItem>
            ))}
          </List>
        </div>
        <div className="fixed bottom-0 left-0 z-10 w-full p-3 bg-translucent-400 backdrop-blur-20">
          <Button
            large
            raised
            fill
            onClick={handleSubmit}
            disabled={
              (!selectedAlbums.length && !newAlbums.length) ||
              (addNew.on && !newAlbums.length)
            }
          >
            Submit
          </Button>
        </div>

        <SlideInModal
          open={paywall.on}
          onClose={paywall.close}
          fullScreen
          direction="right"
        >
          <Page>
            <SmartAlbumPaywall
              onCancel={paywall.close}
              onSubscribed={handleSubscribedToSmartAlbums}
            />
          </Page>
        </SlideInModal>
      </Page>
    </SlideInModal>
  );
};

export default AddPhotos;
