import React from 'react';
import Spinner from '../../lib/Spinner/Spinner';

interface ThemeLoaderProps {
  website: string;
  themeId: string;
}

const ThemeLoader: React.FunctionComponent<ThemeLoaderProps> = ({
  website,
  themeId,
}) => {
  if (!themeId) {
    return null;
  }
  const ThemeComponent = React.lazy(() =>
    import(`../../themes/${themeId || 'basic'}`)
  );
  return (
    <div>
      <React.Suspense fallback={<Spinner fullScreen />}>
        <ThemeComponent website={website} />
      </React.Suspense>
    </div>
  );
};
export default React.memo(ThemeLoader);
