import * as React from 'react';
import {
  Popup,
  Page,
  Button,
  List,
  ListInput,
  PageContent,
} from 'framework7-react';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import PageTitle from '../../lib/PageTitle/PageTitle';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_KEY } from '../../constants';
import { isProSubscribed, isOnTrial } from '../../utils';
import useUserStore from '../../hooks/useUserStore';
import ShowIf from '../../lib/ShowIf/ShowIf';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';

interface IUpgradeToProProps {
  open?: boolean;
  onClose?: (e?: any) => void;
}
const stripePromise = loadStripe(STRIPE_KEY || '');

const UpgradeToPro: React.FC<IUpgradeToProProps> = ({ open, onClose }) => {
  const { user } = useUserStore();
  return (
    <Popup opened={open} onPopupClosed={onClose}>
      <Page className="bg-white" pageContent={false}>
        <PageContent className="flex flex-col py-16">
          <ShowIf condition={open}>
            <PopupNavbar sticky onCancel={onClose} hideRightBtn />
            {(!isProSubscribed(user.subscriptions) ||
              isOnTrial(user.subscriptions)) && (
              <>
                <header className="px-4 mt-3">
                  <PageTitle>Upgrade to Pro</PageTitle>
                  <div className="mt-1">
                    {!user.hadTrial && (
                      <SectionTitle>Try for free for 14 days!</SectionTitle>
                    )}
                    <br />
                    <SectionTitle>
                      <small>
                        {!user.hadTrial && <>then </>}
                        only $11.99 per month
                      </small>
                    </SectionTitle>
                  </div>
                </header>
                <section className="flex-grow mt-5 pt-5 flex flex-col">
                  <Elements stripe={stripePromise}>
                    <CheckoutForm />
                  </Elements>
                </section>
              </>
            )}
            {isProSubscribed(user.subscriptions) &&
              !isOnTrial(user.subscriptions) && (
                <>
                  <header className="px-4 mt-3">
                    <PageTitle>
                      Congrats! <br /> Enjoy the Pro features without
                      restrictions
                    </PageTitle>

                    <div className="mt-5 px-4">
                      <Button raised fill onClick={onClose} large>
                        Close
                      </Button>
                    </div>
                  </header>
                </>
              )}
          </ShowIf>
        </PageContent>
      </Page>
    </Popup>
  );
};

export default UpgradeToPro;
