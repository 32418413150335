import * as React from 'react';
import useToggle from '../../hooks/useToggle';
import ShowIf from '../../lib/ShowIf/ShowIf';
import useSelectItem from '../../hooks/useSelectItem';
import Img from '../../lib/Img/Img';
import ImgUploader from '../../lib/ImgUploader/ImgUploader';
import ImgActionMenu from '../ImgActionMenu/ImgActionMenu';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import GalleryBrowser from '../GalleryBrowser/GalleryBrowser';
import useUserStore from '../../hooks/useUserStore';

interface IProfileImgEditorProps {
  onSave?: (e?: any) => void;
}
const ProfileImgEditor: React.FC<IProfileImgEditorProps> = ({ onSave }) => {
  const { user, setUser } = useUserStore();
  const menu = useToggle();
  const browseGallery = useToggle();
  const [uploadingImg, setUploadingImg, resetUploadingImg] = useSelectItem('');

  function handleUploadComplete(img) {
    const src = img.target.value;
    setUser({ profileImg: src });
    onSave?.({ profileImg: src });
    resetUploadingImg();
  }

  function handleMenuSelect(option) {
    if (option === 'upload') {
      document.getElementById('profileImg')?.click();
    } else {
      browseGallery.open();
    }
  }

  function handleSaveImageFromGallery(img) {
    setUser({ profileImg: img.src });
    onSave?.({ profileImg: img.src });
    browseGallery.close();
  }

  return (
    <section className="flex-center">
      <div onClick={menu.open}>
        <ShowIf
          condition={!uploadingImg}
          elseTemplate={
            <Img
              src={uploadingImg}
              grayScale
              size="96x96"
              className="mx-auto rounded-full"
            />
          }
        >
          <Img
            src={user.profileImg}
            size="96x96"
            className="mx-auto rounded-full"
          />
        </ShowIf>

        <div className="mt-1 text-base text-center text-primary">
          change business logo
        </div>
        <ImgUploader
          id="profileImg"
          onUploadStart={setUploadingImg}
          onUploadComplete={handleUploadComplete}
        />
      </div>
      <ImgActionMenu
        open={menu.on}
        onClose={menu.close}
        onMenuSelect={handleMenuSelect}
      />
      <SlideInModal
        open={browseGallery.on}
        onClose={browseGallery.close}
        fullScreen
        direction="right"
      >
        <GalleryBrowser
          onClose={browseGallery.close}
          onSave={handleSaveImageFromGallery}
        />
      </SlideInModal>
    </section>
  );
};

export default ProfileImgEditor;
