import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import queryString from 'query-string';
import useBackendApi from '../../hooks/useBackendApi';
import useToast from '../../hooks/useToast';
import useUserStore from '../../hooks/useUserStore';
import { wizardStepsUrlMapping } from '../../static/wizardSteps';
interface InstagramAuthProps {
  onVerified?: (e?: any) => void;
  redirectUri?: string;
}

const InstagramAuth: React.FunctionComponent<InstagramAuthProps> = ({
  onVerified,
  redirectUri,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { post } = useBackendApi();
  const { user, setUser } = useUserStore();
  const { errorToast, successToast } = useToast();
  useEffect(() => {
    const parsedQuery = queryString.parse(location.search);
    if (parsedQuery) {
      const { code } = parsedQuery;

      if (code) {
        handleInstagramAuth(code);
      }
    }
  }, [location.search]);

  function redirectUserToNextStep(stepName) {
    history.push(wizardStepsUrlMapping[stepName]);
  }

  async function handleInstagramAuth(code: string) {
    try {
      let instagramAuth = {};
      if (!user.instagramAuth?.token) {
        instagramAuth = await post('/v1/instagram/access-token', { code, redirectUri });
      }
      const updated = await post(`/v1/signup/${user.wizardStep}`, {
        published: true,
      });
      setUser({ instagramAuth, ...updated });
      successToast(
        'Successfully verified. You may now select images from Instagram.'
      );
      redirectUserToNextStep(updated.wizardStep);
      onVerified?.();
    } catch (err) {
      errorToast(err.toString());
    }
  }
  return null;
};
export default InstagramAuth;
