import * as React from 'react';
import LoginLayout from '../../components/LoginLayout/LoginLayout';
import cogoToast from 'cogo-toast';
import useBackendApi from '../../hooks/useBackendApi';
import useWizaardStepsStore from '../../hooks/useWizardStepsStore';
import { useHistory } from 'react-router';
import { setUserDataToStorage } from '../../utils';
import PageTitle from '../../lib/PageTitle/PageTitle';
import Img from '../../lib/Img/Img';
import Logo from '../../components/Logo/Logo';

interface ISignupProps { }
const Signup: React.FC<ISignupProps> = ({ }) => {
  const { post } = useBackendApi();
  const history = useHistory();
  const { setWizardSteps } = useWizaardStepsStore();

  async function onCreateAccount(userInput) {
    const { email, password } = userInput;
    try {
      let data = await post('/v1/auth/register', {
        email,
        password,
      });

      if (data.token) {
        setUserDataToStorage({ token: data.token });
        setWizardSteps(data.wizardSteps);
        return history.push('/dashboard');
      } else {
        cogoToast.error('An error has occured');
      }
    } catch (err) {
      if (err?.response?.data) {
        cogoToast.error(err?.response?.data);
      }
      console.error(err);
    }
  }

  return (
    <LoginLayout
      topCtaBtnLabel="LOG IN"
      topCtaLabel="Already have an account?"
      topCtaLink="/login"
      mainCtaLabel="Create account"
      hideForgotPassword
      onMainCtaClick={onCreateAccount}
      ctaExtension={
        <div className="text-center text-gray-400 mt-4 mb-10">
          <p>Get setup in under 3 minutes</p>
        </div>
      }
    >
      <div className="px-4">
        <h1 className="text-4xl">
          <div className="flex">
            <Logo className="text-primary" style={{ width: 100 }} />
          </div>
        </h1>

        <h2>
          <PageTitle weight="bold">The Virtual assistant</PageTitle>
        </h2>
        <div className="text-3xl">for makeup artists</div>
        <div className="mt-3 text-lg ">
          Nishi will help you set up your website, sync your website with
          instagram, collect reviews from customers and more.
        </div>
      </div>
    </LoginLayout>
  );
};

export default Signup;
