import 'dotenv/config';

const API_URL = process.env.REACT_APP_API_URL;
const ENV = process.env.REACT_APP_ENV;
const SPA_URL = process.env.REACT_APP_SPA_URL;
const IG_REDIRECT_URI = process.env.REACT_APP_IG_REDIRECT_URI;
const IG_GALLERY_REDIRECT_URI = process.env.REACT_APP_IG_GALLERY_REDIRECT_URI;
const IG_PREVIEW_REDIRECT_URI = process.env.REACT_APP_IG_PREVIEW_REDIRECT_URI;
const IG_REVIEWS_REDIRECT_URI = process.env.REACT_APP_IG_REVIEWS_REDIRECT_URI;

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
const IG_APP_ID = '3048413095202488';
const IG_CLIENT_SECRET = 'c3b87b0cd7b6a4b87b57d273898fbed0';

const DEFAULT_THEME = {
  primaryColor: '#007aff',
  font: `system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
  url: '',
  heading: {
    color: '#000000',
    font: `system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
    url: '',
  },
};
export {
  API_URL,
  STRIPE_KEY,
  SPA_URL,
  IG_APP_ID,
  IG_GALLERY_REDIRECT_URI,
  IG_CLIENT_SECRET,
  IG_REDIRECT_URI,
  DEFAULT_THEME,
  IG_PREVIEW_REDIRECT_URI,
  IG_REVIEWS_REDIRECT_URI,
  ENV
};
