import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter } from 'react-router-dom';

// Import F7 Bundle
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import F7-React Plugin
import Framework7React, { App as F7App, View } from 'framework7-react';
/* Initialize sentry */
Sentry.init({ dsn: "https://9320088c94014d498e90a635858758f5@o400995.ingest.sentry.io/5287567" });

// Init F7-React Plugin
Framework7.use(Framework7React);



const f7Params = {
  name: 'Flayr Provider',
  id: 'com.provider.flayr',
  theme: 'ios',
  clicks: {
    externalLinks: '.external',
  },
};

export const f7Context = React.createContext<{ f7: any }>({
  f7: {},
});

ReactDOM.render(
  <React.StrictMode>
    <F7App params={f7Params}>
      <View>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </View>
    </F7App>
  </React.StrictMode>,
  document.getElementById('root')
);

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
