import * as React from 'react';
import { IImage } from '../../types/image';
import Img from '../../lib/Img/Img';
import { ReactSortable } from 'react-sortablejs';
import ShowIf from '../../lib/ShowIf/ShowIf';
import { Checkbox } from 'framework7-react';
import classnames from 'classnames';

interface IImgGridProps {
  images?: IImage[];
  onSort?: (e?: any) => void;
  onDelete?: (e?: any) => void;
  sortable?: boolean;
  selectable?: boolean;
  selectedImages?: IImage[];
  placeholder?: string;
  placeholders?: string[];
  imagesPerRow?: any;
  onImageSelect?: (e?: any) => void;
  className?: string;
}
const ImgGrid: React.FC<IImgGridProps> = ({
  images = [],
  onSort,
  sortable,
  selectable,
  selectedImages = [],
  onImageSelect,
  placeholder = '',
  imagesPerRow = { default: 3 },
  className = '',
  placeholders = [],
}) => {
  const [sorted, setSorted] = React.useState<any[]>([]);

  const [selectedImgMapping, setSelectedImgMapping] = React.useState<any>({});

  React.useEffect(() => {
    if (images.length) {
      const sortedImgs: any[] = images.map((img, key) => ({
        ...img,
        id: img._id || key,
      }));
      if (placeholder) {
        sortedImgs.unshift({
          src: placeholder,
          id: new Date().getTime(),
          placeholder: true,
        });
      }

      if (placeholders.length) {
        placeholders.map((ph, i) =>
          sortedImgs.unshift({
            src: ph,
            id: new Date().getTime() + +'-' + i,
            placeholder: true,
          })
        );
      }
      setSorted(sortedImgs);
    }
  }, [images, placeholder, placeholders.length]);

  React.useEffect(() => {
    if (selectable && selectedImages) {
      const mapping = {};
      selectedImages.map((img) => {
        mapping[img._id || ''] = true;
      });
      setSelectedImgMapping(mapping);
    }
  }, [selectedImages]);

  React.useEffect(() => {
    return () => {
      setSelectedImgMapping({});
    };
  }, []);

  function handleSorted(e) {
    setSorted(e);
    onSort && onSort(e);
  }

  return (
    <ShowIf condition={!!sorted.length}>
      <ReactSortable
        className={classnames(
          'grid gap-px',
          `grid-cols-${imagesPerRow.default || 3}`,
          className,
          {
            [`md:grid-cols-${imagesPerRow.md}`]: !!imagesPerRow.md,
          }
        )}
        list={sorted}
        setList={handleSorted}
        ghostClass="opacity-25"
        disabled={!sortable}
      >
        {sorted.map((img, idx) => (
          <div key={idx}>
            <div className="relative">
              <Img
                src={img.src || ''}
                size="square"
                onClick={() => onImageSelect?.(img)}
                id={img._id}
                grayScale={img.placeholder || img.existing}
                className={classnames({ 'opacity-75': img.placeholders })}
              />
              <ShowIf condition={selectable && !img.existing}>
                <div className="absolute top-0 right-0 pr-2 pt-2 pointer-events-none">
                  <Checkbox
                    name="coverImg"
                    className="checkbox-white chekbox-shadow"
                    checked={selectedImgMapping[img._id || ''] || false}
                    readonly
                  />
                </div>
              </ShowIf>
              {img.existing && (
                <div className="absolute top-0 right-0 pr-2 pt-2 pointer-events-none text-white">
                  Already added
                </div>
              )}
            </div>
          </div>
        ))}
      </ReactSortable>
    </ShowIf>
  );
};

export default ImgGrid;
