import * as React from 'react';
import { Popup, Page } from 'framework7-react';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import { IImage } from '../../types/image';
import PageTitle from '../../lib/PageTitle/PageTitle';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import ImgGrid from '../ImgGrid/ImgGrid';

interface IReorderPhotosProps {
  open?: boolean;
  onClose?: (e: any) => void;
  images?: IImage[];
  onDone?: (e: any) => void;
}
const ReorderPhotos: React.FC<IReorderPhotosProps> = ({
  open,
  onClose,
  images = [],
  onDone,
}) => {
  const [sortedImages, setSortedImages] = React.useState<IImage[]>([]);

  React.useEffect(() => {
    setSortedImages([]);
  }, [images]);

  function handleDone() {
    onDone?.(sortedImages);
  }

  return (
    <Popup opened={open} onPopupClosed={onClose}>
      <Page className="bg-white">
        <PopupNavbar
          className="py-2 px-2 sticky top-0 bg-white z-10"
          onCancel={onClose}
          onDone={handleDone}
        />
        <div className="px-4">
          <PageTitle>Reorder Photos</PageTitle>
        </div>

        <div className="px-3 py-2 flex items-center bg-blue-500 text-white mt-3">
          <i className="fal fa-info-circle mr-3 text-xl" />
          <div>Drag photos to reorder then tap the done button to save</div>
        </div>

        <div className="px-4 mt-4">
          <SectionTitle>Pick photos</SectionTitle>
        </div>

        <section className="grid col grid-cols-6 gap-1 mt-3 pl-3">
          <div className="col-span-5">
            <ImgGrid
              images={images}
              imagesPerRow={4}
              sortable
              onSort={setSortedImages}
            />
          </div>
          <div className="col-span-1" />
        </section>
      </Page>
    </Popup>
  );
};

export default ReorderPhotos;
