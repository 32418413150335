import * as React from 'react';
import {
  Popup,
  Searchbar,
  Segmented,
  Button,
  Page,
  PageContent,
} from 'framework7-react';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import AppointmentList from '../AppointmentList/AppointmentList';
import useSelectItem from '../../hooks/useSelectItem';

interface ISearchGigsProps {
  open?: boolean;
  onClose?: (e?: any) => void;
}
const SearchGigs: React.FC<ISearchGigsProps> = ({ open, onClose }) => {
  const [selectedTab, setSelectedTab] = React.useState<
    'booked' | undefined | 'offered'
  >();
  const [searchTerm, setSearchTerm, clearSearchTerm] = useSelectItem('');

  return (
    <Popup opened={open} onPopupClosed={onClose} animate={false}>
      <Page pageContent={false}>
        <PageContent>
          <PopupNavbar
            hideLeftBtn
            onDone={onClose}
            rightBtnClassname="font-normal"
            rightBtnLabel="Close"
            sticky
            className="bg-white"
          />
          <section className="searchbar" style={{ zIndex: 1 }}>
            <div className="searchbar-inner">
              <div className="searchbar-input-wrap rounded-lg overflow-hidden">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="rounded"
                  placeholder="Search"
                />
                <i className="searchbar-icon" />
                {!!searchTerm && (
                  <span
                    className="input-clear-button"
                    onClick={clearSearchTerm}
                  />
                )}
              </div>
            </div>
          </section>

          <div className="mt-2 mb-3 px-3">
            <Segmented raisedAurora strong>
              <Button
                active={selectedTab === 'offered'}
                onClick={() => setSelectedTab('offered')}
              >
                Offers
              </Button>
              <Button
                active={selectedTab === 'booked'}
                onClick={() => setSelectedTab('booked')}
              >
                Booked
              </Button>
              <Button
                onClick={() => setSelectedTab(undefined)}
                active={!selectedTab}
              >
                All
              </Button>
            </Segmented>
          </div>
          <AppointmentList statusFilter={selectedTab} search={searchTerm} />
        </PageContent>
      </Page>
    </Popup>
  );
};

export default SearchGigs;
