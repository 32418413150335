import React from 'react';
import cogoToast from 'cogo-toast';

export default function useToast() {

  function catchErrorToast(errObj) {
    if (errObj?.response?.data) {
      cogoToast.error(errObj?.response?.data);
    }
  }

  function successToast(msg: string) {
    cogoToast.success(msg);
  }

  function errorToast(msg: string) {
    cogoToast.error(msg);
  }

  return {
    catchErrorToast,
    successToast,
    errorToast
  };
}
