import * as React from 'react';
import { IReview } from '../../types/review';
import Img from '../../lib/Img/Img';
import dayjs from 'dayjs';
import ShowIf from '../../lib/ShowIf/ShowIf';
import StarRating from '../../lib/StarRating/StarRating';
import Toggle from '../../lib/Toggle/Toggle';
import ReadMore from '../../lib/ReadMore/ReadMore';
import classnames from 'classnames';
import { reomoveItemFromArray } from '../../utils';
import useSelectItem from '../../hooks/useSelectItem';
import FullScreenImgViewer from '../../lib/FullScreenImgViewer/FullScreenImgViewer';

interface IReviewItemProps {
  review: IReview;
  enableHideToggle?: boolean;
  onToggle?: (e?: any) => void;
  onCollectReviews?: (e?: any) => void;
  enableReviewCard?: boolean;
  onAdd?: (e?: any) => void;
  onDownload?: (e?: any) => void;
  placeholderImg?: string;
  onDelete?: (e?: any) => void;
}
const ReviewItem: React.FC<IReviewItemProps> = ({
  review,
  enableHideToggle,
  onToggle,
  onCollectReviews,
  enableReviewCard,
  onAdd,
  onDownload,
  placeholderImg,
  onDelete,
}) => {
  const [fullScImg, setFullScImg, clearFullScImg] = useSelectItem('');

  function handleToggle() {
    onToggle?.(review);
  }

  function handleDownload() {
    onDownload?.(review);
  }

  function handleAdd() {
    onAdd?.(review);
  }

  function handleDelete(e) {
    const index = +e.target.dataset.index;
    const newImages = reomoveItemFromArray(
      review.images,
      review!.images?.[index]
    );
    onDelete?.({ ...review, images: newImages });
  }

  return (
    <div
      className={classnames(
        'px-3 pt-3 bg-white rounded-md mb-4 shadow overflow-hidden'
      )}
    >
      <ShowIf condition={enableHideToggle}>
        <section className="flex items-center justify-between px-3 py-2 mb-2 -mx-3 -mt-3 bg-white border-b border-gray-200">
          <div className="text-base">Show review</div>
          <Toggle
            checked={review.status === 'visible'}
            onChange={handleToggle}
            color="green"
          />
        </section>
      </ShowIf>

      <section
        className={classnames({
          'opacity-50': review.status === 'hidden',
        })}
      >
        <section className="flex mb-1">
          <ShowIf
            condition={!!review.img}
            elseTemplate={
              <div className="p-2 text-xs text-white bg-gray-500 rounded">
                {review.customerName.slice(0, 2)}
              </div>
            }
          >
            <Img
              src={review.img}
              size="32x32"
              className="rounded"
              grayScale={review.status === 'hidden'}
            />
          </ShowIf>

          <div className="pl-3">{review.customerName}</div>

          <div className="ml-auto text-gray-600">
            {dayjs(review.date).format('MMM YYYY')}
          </div>
        </section>

        <section className="flex items-center mb-3">
          <div>
            <p
              className={classnames(
                'font-semibold',
                review.status !== 'hidden' ? 'text-primary' : 'text-black'
              )}
            >
              {review.score}/5 Stars
            </p>
          </div>
          <div className="ml-auto">
            <StarRating
              rating={review.score}
              outlineStarClassName="text-2xl"
              fillColor={review.status === 'hidden' ? 'black' : undefined}
              starClassName={classnames('ml-2 text-lg')}
            />
          </div>
        </section>

        <section className="mb-3">
          <ReadMore text={review.comments} lines={5} enableCounter />
        </section>

        <ShowIf condition={!!review.images?.length}>
          <div className="p-2 py-3 overflow-x-auto">
            <div className="flex flex-no-wrap">
              {review.images?.map((img, key) => (
                <div
                  className="relative flex-none mr-3 w-6/16 md:w-3/12"
                  key={key}
                >
                  <Img
                    src={img}
                    grayScale={
                      placeholderImg === img || review.status === 'hidden'
                    }
                    className="rounded-md shadow-md"
                    size="square"
                    onClick={() => setFullScImg(img)}
                  />

                  {!!onDelete && (
                    <div className="absolute top-0 left-0">
                      <button
                        className={classnames(
                          'text-white w-6 h-6 rounded-full shadow transform -translate-y-2 -translate-x-2 focus:outline-none active:bg-red-400',
                          {
                            'bg-red-500': review.status !== 'hidden',
                            'bg-gray-800': review.status === 'hidden',
                          }
                        )}
                        disabled={review.status === 'hidden'}
                        onClick={handleDelete}
                        data-index={key}
                      >
                        <i className="text-base pointer-events-none fal fa-times" />
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </ShowIf>

        <ShowIf condition={review.placeholder}>
          <div className="mt-2 mb-3 text-gray-600" onClick={onCollectReviews}>
            This is just an example and not shown on your website.
            <span className="text-black"> Collect reviews.</span>
          </div>
        </ShowIf>

        <div
          className={classnames(
            'bg-white -mx-3 py-2 flex justify-start items-stretch border-t border-gray-200 leading-tight',
            review.status !== 'hidden'
              ? 'text-primary'
              : 'text-black pointer-events-none'
          )}
        >
          <button
            className="w-1/3 px-5 focus:outline-none focus:opacity-50 flex-center"
            onClick={handleAdd}
          >
            <span className="block m-0">
              <i className="block m-0 text-xl fal fa-plus" />
              <span className="text-xs">Photo</span>
            </span>
          </button>
          <ShowIf condition={enableReviewCard}>
            <button
              onClick={handleDownload}
              className="w-1/3 px-5 focus:outline-none focus:opacity-50 flex-center"
            >
              <span>
                <i className="static block m-0 text-xl fab fa-instagram" />
                <span className="text-xs">Share on Instagram</span>
              </span>
            </button>
          </ShowIf>
        </div>
      </section>
      <FullScreenImgViewer
        img={fullScImg}
        open={!!fullScImg}
        onClose={clearFullScImg}
      />
    </div>
  );
};

export default ReviewItem;
