import * as React from 'react';

interface IPageTitleProps {
  size?: string;
  weight?: string;
}
const PageTitle: React.FC<IPageTitleProps> = ({
  children,
  size = '3xl',
  weight = 'semibold',
}) => {
  return (
    <span className={`font-${weight} text-heading text-${size}`}>
      {children}
    </span>
  );
};

export default PageTitle;
