import * as React from 'react';
import { List, ListItem, Icon } from 'framework7-react';

interface ITagsListProps {
  items?: string[];
  reverse?: boolean;
  onChange?: (e?: any) => void;
}
const TagsList: React.FC<ITagsListProps> = ({
  items = [],
  onChange,
  reverse,
}) => {
  const [listItems, setListItems] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!!items) {
      if (reverse) {
        setListItems(items.slice().reverse());
      } else {
        setListItems(items);
      }
    }
  }, [items]);

  function handleDelete(idx) {
    const newList = [...listItems];
    newList.splice(idx, 1);
    if (reverse) {
      onChange?.(newList.slice().reverse());
    } else {
      onChange?.(newList);
    }
  }

  return (
    <List>
      {listItems.map((item, idx) => (
        <ListItem key={item + idx} title={item}>
          <Icon slot="media">
            <button
              className="px-3 focus:outline-none active:text-blue-200 text-primary"
              onClick={() => handleDelete(idx)}
            >
              <i className="fal fa-trash-alt" />
            </button>
          </Icon>
        </ListItem>
      ))}
    </List>
  );
};

export default TagsList;
