import { EWizardStep } from '../types/user';

// export const wizardSteps = [
//   EWizardStep.businessName,
//   EWizardStep.instaLogin,
//   EWizardStep.kit,
//   EWizardStep.contact,
// ];

export const wizardStepsUrlMapping = {
  [EWizardStep.businessName]: '/dashboard/setup/business-name',
  [EWizardStep.instaLogin]: '/dashboard/setup/instagram',
  [EWizardStep.instaUsername]: '/dashboard/setup/instagram-username',

  [EWizardStep.kit]: '/dashboard/setup/kit',
  [EWizardStep.contact]: '/dashboard/setup/contact',
  [EWizardStep.complete]: '/dashboard/complete',
  [EWizardStep.profileImg]: '/dashboard/setup/profile-img',
};

export const wizardStepsHeadingMapping = {
  [EWizardStep.businessName]: 'Your business profile',
  [EWizardStep.instaLogin]: 'Sync photos from Instagram',
  [EWizardStep.instaUsername]: 'Sync photos from Instagram',

  [EWizardStep.kit]: 'My Kit',
  [EWizardStep.contact]: 'Contact Details',
  [EWizardStep.profileImg]: 'Profile Image',
};

export const wizardStepsSubHeadingMapping = {
  [EWizardStep.businessName]: `We'll set you up with your own free business website`,
  [EWizardStep.instaLogin]: `We'll automatically download a few photos for you, you can always change these later`,
  [EWizardStep.instaUsername]: `We'll automatically download a few photos for you, you can always change these later`,

  [EWizardStep.kit]: 'What brands do you carry in your kit',
  [EWizardStep.contact]: 'How can customers get in touch with you',
  [EWizardStep.profileImg]: 'Upload your profile Image',
};
export const wizardStepCtaMapping = {
  [EWizardStep.businessName]: 'Next',
  [EWizardStep.instaLogin]: 'Next',
  [EWizardStep.instaUsername]: 'Next',
  [EWizardStep.kit]: 'Next',
  [EWizardStep.contact]: 'Next',
  [EWizardStep.profileImg]: 'Next',
};

export const wizardStepsImgMapping = {
  [EWizardStep.businessName]: `https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/r_Y3HD0-V.png`,
  [EWizardStep.instaLogin]: `https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/sJ_20p-Sh.png`,
  [EWizardStep.instaUsername]: `https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/sJ_20p-Sh.png`,
  [EWizardStep.kit]: '',
  [EWizardStep.contact]: '',
};

export const wizardStepsImgSizeMapping = {
  [EWizardStep.businessName]: `128x128`,
  [EWizardStep.instaLogin]: `96x96`,
  [EWizardStep.kit]: '',
  [EWizardStep.contact]: '',
};
