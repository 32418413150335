import React from 'react';
import Img from '../Img/Img';

interface FullScreenImgViewerProps {
  img?: string;
  open?: boolean;
  onClose?: (e?: any) => void;
}

const FullScreenImgViewer: React.FunctionComponent<FullScreenImgViewerProps> = ({
  open,
  onClose,
  img,
}) => {
  if (!open) {
    return null;
  }
  return (
    <div className="fixed z-30 top-0 left-0 h-screen w-screen flex-center py-10">
      <div
        className="fixed top-0 left-0 h-screen w-screen bg-translucent-700"
        onClick={onClose}
      />
      <div className="z-10 relative -mt-4 w-8/12">
        <div className="text-right mb-3">
          <i
            className="fas fa-times-circle text-2xl text-gray-400"
            onClick={onClose}
          />
        </div>
        <Img src={img} />
      </div>
    </div>
  );
};
export default FullScreenImgViewer;
