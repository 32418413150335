import * as React from 'react';
import { Button } from 'framework7-react';
import ShowIf from '../ShowIf/ShowIf';

interface IPopupNavbarProps {
  className?: string;
  onCancel?: (e?: any) => void;
  onDone?: (e?: any) => void;
  letfBtnLabel?: string;
  rightBtnLabel?: React.ReactNode;
  hideLeftBtn?: boolean;
  hideRightBtn?: boolean;
  rightBtnDisabled?: boolean;
  backIcon?: boolean;
  title?: string;
  sticky?: boolean;
  rightBtnClassname?: string;
}
const PopupNavbar: React.FC<IPopupNavbarProps> = ({
  className = '',
  onCancel,
  onDone,
  letfBtnLabel = 'Cancel',
  rightBtnLabel = 'Done',
  hideLeftBtn,
  hideRightBtn,
  backIcon,
  title,
  sticky,
  rightBtnDisabled,
  rightBtnClassname = '',
}) => {
  return (
    <nav
      className={
        'flex-center-y px-1 ' +
        className +
        (sticky
          ? ` py-2 bg-translucent-400 backdrop-blur-20 sticky top-0 z-10`
          : '')
      }
    >
      <ShowIf condition={!hideLeftBtn}>
        <Button className="flex" onClick={onCancel}>
          <ShowIf condition={backIcon}>
            <i className="far fa-chevron-left text-xl mr-1 mt-px pt-px" />
          </ShowIf>

          <span className="normal-case font-normal text-base">
            {letfBtnLabel}
          </span>
        </Button>
      </ShowIf>

      <ShowIf condition={!!title}>
        <div className="text-center flex-grow font-semibold text-base">
          {title}
        </div>
      </ShowIf>
      <ShowIf condition={!hideRightBtn}>
        <Button
          onClick={onDone}
          className={`ml-auto ${rightBtnClassname}`}
          disabled={rightBtnDisabled}
        >
          <span className="normal-case text-base">{rightBtnLabel}</span>
        </Button>
      </ShowIf>
    </nav>
  );
};

export default PopupNavbar;
