import { combineReducers } from 'redux';
import { IAppStore } from '../types/appStore';
import loaderReducer from './loader.reducer';
import requestsReducer from './requests.reducer';
import userReducer from './user.reducer';
import navLinkReducer from './navLink.reducer';
import wizardStepsReducer from './wizardSteps.reducer';
import subItemsReducer from './subItems.reducer';

export default combineReducers<IAppStore>({
  loader: loaderReducer,
  requests: requestsReducer,
  user: userReducer,
  navLinks: navLinkReducer,
  wizardSteps: wizardStepsReducer,
  subItems: subItemsReducer
});
