import * as React from 'react';
import { Page, PageContent, Button } from 'framework7-react';
import Navbar from '../../lib/Navbar/Navbar';
import BackBtn from '../../lib/BackBtn/BackBtn';
import PageTitle from '../../lib/PageTitle/PageTitle';
import ShowIf from '../../lib/ShowIf/ShowIf';

interface IAddAlbumLayoutProps {
  step: string;
  onNext?: (e?: any) => void;
  onBack?: (e?: any) => void;
  nextDisabled?: boolean;
}
const AddAlbumLayout: React.FC<IAddAlbumLayoutProps> = ({
  step,
  onNext,
  onBack,
  children,
  nextDisabled,
}) => {
  return (
    <Page pageContent={false}>
      <PageContent>
        <Navbar
          leftContent={<BackBtn onClick={onBack} />}
          rightContent={
            <Button
              className="normal-case text-base"
              onClick={onNext}
              disabled={nextDisabled}
            >
              Add album
            </Button>
          }
        />
        <div className="px-4">
          <h1>
            <PageTitle>Add album</PageTitle>
          </h1>
          {children}
        </div>
      </PageContent>
    </Page>
  );
};

export default AddAlbumLayout;
