import * as React from 'react';
import { IAppointment } from '../../types/appointment';
import { timeMapping } from '../../static/timeMapping';
import classnames from 'classnames';
import { ListItem } from 'framework7-react';

interface IAppointmentItemProps {
  appointment: IAppointment;
  onClick?: (e?: any) => void;
}

const statusBgMapping = {
  offered: 'bg-yellow-500',
  expired: 'bg-red-500',
  booked: 'bg-green-500',
};
const AppointmentItem: React.FC<IAppointmentItemProps> = ({
  appointment,
  onClick,
}) => {
  function handleClick() {
    onClick?.(appointment);
  }

  return (
    <div
      className="flex w-full py-2 border-t border-gray-200 px-4"
      onClick={handleClick}
    >
      <div className="text-xs pr-2 self-center" style={{ minWidth: '5em' }}>
        <p>{timeMapping[appointment.time?.from || '']}</p>
        <p className="text-gray-500">
          {timeMapping[appointment.time?.to || '']}
        </p>
      </div>

      <div
        className={classnames(
          'self-stretch px-px',
          statusBgMapping[appointment.status || '']
        )}
      />

      <div className="pl-3 self-center">
        <p>
          #{appointment._id} - {appointment.customerName}
        </p>
        <p className="text-xs text-gray-500">
          {appointment.location?.city} {appointment.location?.state}
        </p>
      </div>
    </div>
  );
};

export default AppointmentItem;
