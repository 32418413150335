import React from 'react';
import useBackendApi from '../../hooks/useBackendApi';
import { useParams, useLocation } from 'react-router';
import ReviewTemplateCard from '../../components/ReviewTemplateCard/ReviewTemplateCard';
import useToggle from '../../hooks/useToggle';
import queryString from 'query-string';
import { reviewCardTemplates } from '../../static/reviewCardTemplates';

interface ReviewCardProps {}

const ReviewCard: React.FunctionComponent<ReviewCardProps> = ({}) => {
  const [reviewSource, setReviewSource] = React.useState<any>('nishi');
  const [review, setReview] = React.useState<any>();
  const [template, setTemplate] = React.useState<any>({});
  const { reviewId } = useParams();
  const loaded = useToggle();
  const location = useLocation();

  const { get } = useBackendApi();

  async function fetchData() {
    const fetched = await get(`/v1/review/${reviewId}`);
    setReview(fetched);
  }

  React.useEffect(() => {
    let didCancel = false;

    const query = queryString.parse(location.search);
    if (query.id) {
      const tpl = reviewCardTemplates.find((t) => t.id === query.id);
      setTemplate(tpl);
    }

    if (query.source) {
      setReviewSource(query.source);
    }

    if (!didCancel) {
      fetchData();
    }
    return () => {
      didCancel = true;
    };
  }, []);

  if (!review) return null;
  return (
    <>
      <div id="card">
        <ReviewTemplateCard
          template={template}
          review={review}
          logo={review.user.profileImg}
          onImgLoaded={loaded.open}
          source={reviewSource}
        />
      </div>
      {loaded.on && <div id="loaded" />}
    </>
  );
};
export default ReviewCard;
