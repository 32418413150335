import * as React from 'react';
import { List, ListInput, ListItem, Icon } from 'framework7-react';
import useUserStore from '../../hooks/useUserStore';
import './Kit.scss';
import { f7Context } from '../..';
import useBackendApi from '../../hooks/useBackendApi';

interface IKitProps {}

const Kit: React.FC<IKitProps> = ({}) => {
  const [brands, setBrands] = React.useState<string[]>([]);
  const { get } = useBackendApi();
  const { f7 } = React.useContext(f7Context);

  const { user, setUser } = useUserStore();

  const [kit, setKit] = React.useState<string>('');
  const kitRef: any = React.useRef('');
  const kitsRef: any = React.useRef(user.kit || []);

  function handleInputChange(e) {
    setKit(e.target.value);
    kitRef.current = e.target.value;
  }

  async function fetchBrands() {
    const brands = await get('/v1/kit');
    if (brands.length) {
      const formattedBrands = brands.map((i) => i.name);
      setBrands(formattedBrands);
    }
  }

  React.useEffect(() => {
    fetchBrands();
  }, []);

  React.useEffect(() => {
    let autocomplete;
    if (f7 && brands.length) {
      autocomplete = f7.autocomplete.create({
        inputEl: '#kit',
        openIn: 'dropdown',
        dropdownPlaceholderText: 'Try "MAC"',
        source: (search, render) => {
          if (!search) {
            return render([]);
          }
          return render(
            brands.filter((brand) => {
              const regex = new RegExp(search, 'i');
              return regex.test(brand);
            })
          );
        },
        on: {
          closed: (autocomplete) => {
            const value = autocomplete.value[0];
            if (value) {
              handleAddFromSelect(value);
            }
          },
        },
      });
    }
    return () => {
      if (autocomplete) {
        autocomplete.destroy();
      }
    };
  }, [brands]);

  function handleClear() {
    setKit('');
    kitRef.current = '';
  }

  function handleAdd() {
    if (kitsRef.current.findIndex((k) => k.name === kit) >= 0) {
      return handleClear();
    }
    if (kitRef.current?.trim()) {
      const newKit = [...user.kit, { img: '', name: kitRef.current }];
      setUser({
        kit: newKit,
      });
      kitsRef.current = newKit;
      handleClear();
    }
  }

  function handleAddFromSelect(name) {
    if (kitsRef.current.findIndex((k) => k.name === name) >= 0) {
      return handleClear();
    }
    const newKit = [...kitsRef.current, { name, img: '' }];
    kitsRef.current = newKit;
    setUser({
      kit: kitsRef.current,
    });
    handleClear();
  }

  function handleDelete(index) {
    const newKit = user.kit.slice().reverse();
    newKit.splice(index, 1);
    setUser({
      kit: newKit.reverse(),
    });
    kitsRef.current = newKit.reverse();
  }

  return (
    <div className="Kit">
      <section
        className="overflow-y-auto -mx-5 border-b border-gray-100"
        style={{ maxHeight: '40vh' }}
      >
        <List noHairlines>
          {user.kit
            .slice()
            .reverse()
            .map((k, idx) => (
              <ListItem key={k.name + idx} title={k.name}>
                <Icon slot="media">
                  <button
                    className="px-3 focus:outline-none active:text-blue-200 text-primary"
                    onClick={() => handleDelete(idx)}
                  >
                    <i className="fal fa-trash-alt" />
                  </button>
                </Icon>
              </ListItem>
            ))}
        </List>
      </section>

      <section className="z-10 ">
        <section className="relative Kit-input">
          <div>
            <List noHairlines className="m-0">
              <ListInput
                outline
                label="Kit"
                floatingLabel
                type="text"
                placeholder="Your kit"
                name="instaUsername"
                className="mb-4 -mx-4"
                inputId="kit"
                value={kit}
                onChange={handleInputChange}
                autocomplete="off"
                autocorrect="off"
                autocapitalize="none"
                spellcheck="false"
              />
            </List>
          </div>
          <button className="active:text-gray-500" onClick={handleClear}>
            <i className="fal fa-times" />
          </button>
          <button className="active:text-gray-500" onClick={handleAdd}>
            <i className="fal fa-check" />
          </button>
        </section>
      </section>
    </div>
  );
};

export default Kit;
