import React from 'react';

import useUserStore from '../../hooks/useUserStore';
import SuggestionCard from '../SuggestionCard/SuggestionCard';
import PageTitle from '../../lib/PageTitle/PageTitle';

interface ISiteSuggestionsProps {
  onVisitWebsite?: (e?: any) => void;
  onChangeWebsiteAddress?: (e?: any) => void;
  onAddBusinessNum?: (e?: any) => void;
  onAddToInstagram?: (e?: any) => void;
}

const SiteSuggestions: React.FC<ISiteSuggestionsProps> = ({
  onVisitWebsite,
  onChangeWebsiteAddress,
  onAddBusinessNum,
  onAddToInstagram,
}) => {
  const { user } = useUserStore();
  const [dismissedCards, setDismissedCards] = React.useState<string[]>(
    JSON.parse(localStorage.getItem('sitePageDismissedCards') || '[]')
  );

  function handleDismiss(name) {
    const dismissedItems = [...dismissedCards, name];
    setDismissedCards(dismissedItems);
    localStorage.setItem(
      'sitePageDismissedCards',
      JSON.stringify(dismissedItems)
    );
  }

  if (dismissedCards.length === 4) {
    return null;
  }

  return (
    <div className="mb-10">
      <h2 className="mb-3">
        <PageTitle>Suggestions</PageTitle>
      </h2>
      <div className="flex p-1 overflow-x-auto">
        <SuggestionCard
          title="View your new website"
          truncate
          text={user.website}
          onView={onVisitWebsite}
          hide={dismissedCards.includes('viewWebsite')}
          name="viewWebsite"
          onDismiss={handleDismiss}
          className="flex-none mr-3 bg-gray-100 w-7/16"
        />
        {/* <SuggestionCard
          title="Change your website address"
          truncate
          text={user.website}
          onView={onChangeWebsiteAddress}
          hide={dismissedCards.includes('websiteAddress')}
          name="websiteAddress"
          onDismiss={handleDismiss}
        /> */}

        {/* <SuggestionCard
          title="Add your ABN"
          text="or apply for one (it's free)"
          onView={onAddBusinessNum}
          hide={dismissedCards.includes('businessNum')}
          name="businessNum"
          onDismiss={handleDismiss}
        /> */}
        <SuggestionCard
          title="Add to Instagram"
          text="add your website link to Instagram"
          onView={onAddToInstagram}
          hide={dismissedCards.includes('addToInsta')}
          name="addToInsta"
          onDismiss={handleDismiss}
          className="flex-none mr-3 bg-gray-100 w-7/16"
        />
      </div>
    </div>
  );
};

export default SiteSuggestions;
