import * as React from 'react';
import { Page, PageContent, Button } from 'framework7-react';
import { IImage } from '../../types/image';
import { IAlbum } from '../../types/album';
import useUserStore from '../../hooks/useUserStore';
import ShowIf from '../../lib/ShowIf/ShowIf';
import SmartAlbumPaywall from '../SmartAlbumPaywall/SmartAlbumPaywall';
import { isSubscribedTo } from '../../utils';
import { ESubItemId } from '../../types/subItem';
import PageTitle from '../../lib/PageTitle/PageTitle';
import ImgPicker from '../ImgPicker/ImgPicker';
import Navbar from '../../lib/Navbar/Navbar';
import BackBtn from '../../lib/BackBtn/BackBtn';
import AlbumInstaSync from '../AlbumInstaSync/AlbumInstaSync';
import useBackendApi from '../../hooks/useBackendApi';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import AddAlbumLayout from '../AddAlbumLayout/AddAlbumLayout';

interface IAddAlbumProps {
  onClose?: (e?: any) => void;
  onDone?: (e?: any) => void;
}

const steps = ['selectImages', 'instaSync'];
const AddAlbum: React.FC<IAddAlbumProps> = ({ onClose, onDone }) => {
  const { user, setUser } = useUserStore();
  const { post } = useBackendApi();
  const subscribed = isSubscribedTo(
    ESubItemId.smartAlbums,
    user.subscriptions || []
  );

  const [step, setStep] = React.useState<any>(steps[0]);
  const [album, setAlbum] = React.useState<IAlbum>({
    name: '',
    images: [],
    userId: user._id,
    tags: [],
  });

  function back() {
    const prevStep = steps[steps.indexOf(step) - 1];
    if (!prevStep) {
      return onClose?.();
    }
    setStep(prevStep);
  }

  function handleImageChange(images) {
    setAlbum({ ...album, images });
  }

  function next() {
    if (steps.indexOf(step) + 1 === steps.length) {
      handleSaveData();
    }
    setStep(steps[steps.indexOf(step) + 1]);
  }

  async function handleSaveData() {
    const dataToSave = formatAlbum(album);
    const newAlbum = await post('/v1/album', dataToSave, {
      showToastOnSuccess: true,
      successMsg: `Created album ${dataToSave.name}`,
      showToastOnError: true,
    });
    const albums = [...(user.albums || []), newAlbum];
    setUser({
      albums,
    });
    onClose?.();
    onDone?.();
  }

  function isNextBtnDisabled() {
    return (
      (step === steps[0] && !album.images?.length) ||
      (step === steps[1] && !album.name)
    );
  }

  return (
    <div className="w-full">
      {/* <ShowIf
        condition={subscribed}
        elseTemplate={<SmartAlbumPaywall onCancel={onClose} />}
      > */}
      <AddAlbumLayout
        step={step}
        onBack={back}
        onNext={next}
        nextDisabled={isNextBtnDisabled()}
      >
        {step === 'selectImages' && (
          <>
            <hr className="mb-2 border-t border-gray-200" />
            <h2 className="mb-2">
              <SectionTitle size="lg">Pick photos</SectionTitle>
            </h2>

            <div className="-mx-4">
              <ImgPicker
                selectedImages={album.images}
                onChange={handleImageChange}
              />
            </div>
          </>
        )}

        {step === 'instaSync' && (
          <AlbumInstaSync album={album} onChange={setAlbum} />
        )}
      </AddAlbumLayout>
      {/* <Page pageContent={false}>
          <PageContent>
            <Navbar leftContent={<BackBtn onClick={back} />} />
            <div className="px-4">
              <h1>
                <PageTitle>Add album</PageTitle>
              </h1>
            </div>
          </PageContent>
        </Page> */}
      {/* </ShowIf> */}
    </div>
  );
};

function formatAlbum({ name, images = [], userId, tags = [] }: IAlbum) {
  const coverImg = images[0]?.src;
  tags = tags
    .map((kw: string) => kw.replace('#', '').trim().toLowerCase())
    .filter((kw) => kw);
  return {
    userId,
    name,
    imageIds: images.map((img) => img._id),
    coverImg,
    default: false,
    status: 'visible',
    tags: tags,
    auto: false,
  } as IAlbum;
}

export default AddAlbum;
