import * as React from 'react';
import { Page, PageContent, Progressbar } from 'framework7-react';
import useInterval from '../../hooks/useInterval';
import { useHistory } from 'react-router';
import useUserStore from '../../hooks/useUserStore';
import { EWizardStep } from '../../types/user';
import Img from '../../lib/Img/Img';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import useBackendApi from '../../hooks/useBackendApi';

interface ISetupCompleteProps {}
const steps = [
  'Setting up profile photo',
  'Setting up images',
  'Setting up content',
];
const SetupComplete: React.FC<ISetupCompleteProps> = ({}) => {
  const history = useHistory();
  const { get } = useBackendApi();
  const [progress, setProgress] = React.useState(0);
  const [step, setStep] = React.useState<any>(0);
  const { user, setUser } = useUserStore();

  React.useEffect(() => {
    if (!user._id) {
      fetchProfileData(user._id);
    }
  }, []);

  const fetchProfileData = async (userId) => {
    try {
      const data = await get('/v1/user/profile-data/' + userId);
      setUser(data.user);
    } catch (err) {
      console.error(err);
    }
  };

  useInterval(() => {
    if (step <= 2) {
      const newStep = step + 1;
      setProgress((newStep / steps.length) * 100);
      setStep(newStep);
    } else {
      fetchProfileData(user._id);
      history.push('/dashboard');
    }
  }, 2500);

  return (
    <SlideInModal fullScreen disableAnimation open>
      <Page pageContent={false}>
        <PageContent className="flex flex-col items-stretch p-5 bg-white">
          <section className="flex items-end justify-center flex-grow">
            <div className="flex w-1/3 px-3 mb-4">
              <Img src="/nishi-logo.svg" />
            </div>
          </section>
          <section className="flex-grow">
            <div className="text-4xl font-bold">We're creating your site</div>
            <div className="text-xl">{steps[step]}</div>

            <div className="mt-5">
              <Progressbar progress={progress} />
            </div>
          </section>
        </PageContent>
      </Page>
    </SlideInModal>
  );
};

export default SetupComplete;
