import { IImage } from './image';

export interface IAlbum {
  userId?: string;
  _id?: string;
  name?: string;
  coverImg?: string;
  imageIds?: string[];
  images?: IImage[];
  default?: boolean;
  auto?: boolean;
  status?: EAlbumStatus;
  tags?: string[];
}

export enum EAlbumStatus {
  visible = 'visible',
  hidden = 'hidden',
  dismissed = 'dismissed',
}
