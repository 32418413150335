import * as React from 'react';
import ShowIf from '../ShowIf/ShowIf';
import Img from '../Img/Img';

interface IUploadImgBoxProps {
  img?: string;
  iconSize?: string;
  uploading?: boolean;
  icon?: React.ReactNode;
  className?: string;
  onClick?: (e?: any) => void;
}
const UploadImgBox: React.FC<IUploadImgBoxProps> = ({
  img,
  iconSize = '2xl',
  uploading,
  icon,
  className = '',
  onClick,
}) => {
  return (
    <ShowIf
      condition={!img}
      elseTemplate={
        <Img
          grayScale={uploading}
          src={img}
          size="square"
          className="rounded-lg"
        />
      }
    >
      <div
        className={
          'border border-primary text-primary rounded-lg square ' + className
        }
        onClick={onClick}
      >
        <div className="flex-center square-inner">
          {icon || <i className={`fal fa-plus text-${iconSize}`} />}
        </div>
      </div>
    </ShowIf>
  );
};

export default UploadImgBox;
