import * as React from 'react';
import ActionSheet from '../../lib/ActionSheet/ActionSheet';
import useUserStore from '../../hooks/useUserStore';
import Img from '../../lib/Img/Img';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import { formatSmsLinkForDevice, copyToClipboard } from '../../utils';
import { Link } from 'framework7-react';
import useToggle from '../../hooks/useToggle';

interface IShareSiteProps {
  open?: boolean;
  onClose?: (e?: any) => void;
}
const ShareSite: React.FC<IShareSiteProps> = ({ open, onClose }) => {
  const { user } = useUserStore();
  const website = user.website || '';
  const copied = useToggle();

  function handleCopy() {
    copyToClipboard(website);
    copied.open();
  }

  React.useEffect(() => {
    if (copied.on) {
      setTimeout(() => {
        copied.close();
      }, 1000);
    }
  }, [copied.on]);

  return (
    <ActionSheet open={open} onClose={onClose}>
      <section className="flex border-b border-gray-100 items-center pb-3">
        <Img src={user.profileImg} size="32x32" className="rounded-full" />
        <div className="flex-grow px-3">
          <p>
            <SectionTitle size="base">Share my site</SectionTitle>
          </p>
          <Link
            href={website}
            className="text-sm text-blue-500"
            external
            target="_blank"
          >
            {website}
          </Link>
        </div>

        <button
          className="bg-gray-100 rounded-full outline-none w-6 h-6 flex items-center justify-center self-start"
          onClick={onClose}
        >
          <i className="fal fa-times mt-1" />
        </button>
      </section>

      <section className="mb-5 pb-5">
        <div className="flex py-3 border-b border-gray-100 px-4">
          <Link
            href={`mailto:?body=${website}`}
            className="bg-white shadow mr-4 p-2 rounded-lg active:bg-gray-100"
            target="_blank"
            external
          >
            <i className="fal fa-envelope h-10 w-10 text-4xl flex items-center justify-center mt-1" />
          </Link>

          <Link
            href={formatSmsLinkForDevice('', website)}
            className="bg-white shadow mr-4 p-2 rounded-lg active:bg-gray-100"
            target="__blank"
            external
          >
            <i className="fas fa-comment h-10 w-10 text-4xl flex items-center justify-center mt-1" />
          </Link>

          <Link
            href={`https://www.facebook.com/sharer/sharer.php?u=${user.website}`}
            target="__blank"
            className="bg-white shadow mr-4 p-2 rounded-lg active:bg-gray-100"
            external
          >
            <i className="fab relative h-10 w-10 fa-facebook-square text-4xl flex items-center justify-center mt-1" />
          </Link>
        </div>

        <section className="mb-5 pb-5 mt-3">
          <button
            className="px-4 flex rounded shadow py-3 items-center active:bg-gray-100 focus:outline-none "
            onClick={handleCopy}
          >
            <span className="text-base">Copy link</span>
            <i className="fal fa-copy ml-auto text-xl" />
          </button>

          <div
            className={`mt-3 text-sm text-center ${
              copied.on ? 'text-gray-500' : 'invisible'
            } `}
          >
            link copied
          </div>
        </section>
      </section>
    </ActionSheet>
  );
};

export default ShareSite;
