import * as React from 'react';
import { IAlbum } from '../../types/album';
import { ListInput, List, Button, ListItem, Icon } from 'framework7-react';
import Toggle from '../../lib/Toggle/Toggle';
import useToggle from '../../hooks/useToggle';
import TagInput from '../../lib/TagInput/TagInput';
import ShowIf from '../../lib/ShowIf/ShowIf';
import TagsList from '../../lib/TagsList/TagsList';

interface IAlbumInstaSyncProps {
  album?: IAlbum;
  onChange?: (e: any) => void;
}
const AlbumInstaSync: React.FC<IAlbumInstaSyncProps> = ({
  album,
  onChange,
}) => {
  const enableSync = useToggle();

  function handleUserInputChange(e: any) {
    const { name, value } = e.target;
    onChange?.({ ...album, [name]: value });
  }

  function addTag(kw) {
    if (!album!.tags?.includes(kw)) {
      setTags([...(album?.tags || []), kw]);
    }
  }

  function setTags(kws) {
    onChange?.({
      ...album,
      tags: kws,
    });
  }

  return (
    <div>
      <List className="mt-1 mb-0">
        <ListInput
          className="-ml-3"
          placeholder="Title"
          name="name"
          value={album?.name || ''}
          onChange={handleUserInputChange}
        />
        {/* <ListItem className="-ml-3">
          <div className="flex">
            <div className="pt-2">
              <i className="static text-2xl fab fa-instagram text-primary" />
            </div>
            <div className="flex-grow px-4">
              <div>Sync with Instagram</div>
              <p className="mt-2 text-sm text-gray-600">
                Nishi will scan the hashtags of a photo and automatically add
                related photos to this album
              </p>
            </div>
            <div>
              <Toggle onChange={enableSync.toggle} checked={enableSync.on} />
            </div>
          </div>
        </ListItem> */}
      </List>

      {/* <ShowIf condition={enableSync.on}>
        <div className="my-2">
          <TagInput placeholder="Add a hashtag to follow" onAdd={addTag} />
        </div>

        <div className="overflow-y-auto" style={{ maxHeight: '40vh' }}>
          <TagsList items={album?.tags} onChange={setTags} reverse />
        </div>
      </ShowIf> */}
    </div>
  );
};

export default AlbumInstaSync;
