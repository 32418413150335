import * as React from 'react';
import { IReview } from '../../types/review';
import {
  Button,
  Page,
  PageContent,
  Segmented,
  Actions,
  ActionsGroup,
  ActionsButton,
} from 'framework7-react';
import './GenerateReviewCard.scss';
import classnames from 'classnames';
import useUserStore from '../../hooks/useUserStore';

import Img from '../../lib/Img/Img';

import useBackendApi from '../../hooks/useBackendApi';
import {
  feedCardTemplates,
  storyCardTemplates,
  reviewCardTemplates,
} from '../../static/reviewCardTemplates';

import ProStar from '../../lib/ProStar/ProStar';

import useToggle from '../../hooks/useToggle';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import useToast from '../../hooks/useToast';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import PageTitle from '../../lib/PageTitle/PageTitle';
import ShowIf from '../../lib/ShowIf/ShowIf';
import { IReviewCardTemplate } from '../../types/reviewCardTemplate';
import { getReviewTemplateImageCount } from '../../utils';
import DialogComponent from 'framework7/components/dialog/dialog';
import ReviewTemplateCardPicker from '../ReviewTemplateCardPicker/ReviewTemplateCardPicker';

interface IGenerateReviewCardProps {
  review: IReview;
  theme?: string;
  onDownload?: (e?: any) => void;
  onUpgrade?: (e?: any) => void;
  open?: boolean;
  onClose?: (e?: any) => void;
  type?: string;
  onAddPhoto?: (r: IReview) => void;
}
const GenerateReviewCard: React.FC<IGenerateReviewCardProps> = ({
  review,
  onDownload,
  open,
  onClose,
  onAddPhoto,
}) => {
  const { user } = useUserStore();
  const { successToast } = useToast();
  const { post } = useBackendApi();
  const reviewTemplateError = useToggle(false);
  const feed = useToggle();

  const [selectedFeedTpl, setSelectedFeedTpl] = React.useState<
    IReviewCardTemplate
  >(feedCardTemplates[0]);

  const [selectedStoryTpl, setSelectedStoryTpl] = React.useState<
    IReviewCardTemplate
  >(storyCardTemplates[0]);

  React.useEffect(() => {
    const feed = feedCardTemplates.find((t) => t.order === 1)!;
    const story = storyCardTemplates.find((t) => t.order === 1)!;
    setSelectedFeedTpl(feed);
    setSelectedStoryTpl(story);
  }, []);

  async function handleFeedDownload() {
    if (!reviewHasEnoughImagesForTemplate(review, selectedFeedTpl)) {
      return reviewTemplateError.open();
    }
    post(
      `/v1/util/route-review-card`,
      {
        url: `https://nishi-staging.nishi.app/review/generate-card/${review?._id}?id=${selectedFeedTpl.id}`,
        userId: user._id,
        width: 500,
        height: 500,
        reviewId: review?._id,
        reviewTemplateId: selectedFeedTpl.id,
      },
      { loader: false }
    );
    successToast(
      'We will email you the review card once its generated to ' + user.email
    );
    onDownload?.();
  }

  function reviewHasEnoughImagesForTemplate(
    review: IReview,
    template: IReviewCardTemplate
  ) {
    if (
      (review?.images?.length || 0) >= getReviewTemplateImageCount(template)
    ) {
      return true;
    }
    return false;
  }

  function handleBackToAddPhoto() {
    reviewTemplateError.close();
    onClose?.();
    onAddPhoto?.(review);
  }

  function handleDownloadStory() {
    if (!reviewHasEnoughImagesForTemplate(review, selectedStoryTpl)) {
      return reviewTemplateError.open();
    }
    post(
      `/v1/util/route-review-card`,
      {
        url: `https://nishi-staging.nishi.app/review/generate-card/${review?._id}?id=${selectedStoryTpl.id}`,
        userId: user._id,
        height: 960,
        width: 540,
        reviewId: review?._id,
        reviewTemplateId: selectedStoryTpl.id,
      },
      { loader: false }
    );
    successToast(
      'We will email you the review card once its generated to ' + user.email
    );
    onDownload?.();
  }

  async function handleFeedTemplateClick(id) {
    setSelectedFeedTpl(id);
  }

  function handleStoryTplSelect(id) {
    setSelectedStoryTpl(id);
  }

  function handleSelectTemplate(template) {
    if (feed.on) {
      setSelectedFeedTpl(template);
    } else {
      setSelectedStoryTpl(template);
    }
  }

  function handleDownload(type) {
    if (type === 'feed') {
      handleFeedDownload();
    } else {
      handleDownloadStory();
    }
  }

  return (
    <>
      <SlideInModal
        open={open}
        onClose={onClose}
        direction="right"
        fullScreen
        belowPopup
      >
        <Page pageContent={false}>
          <PageContent className="pb-20">
            <PopupNavbar
              letfBtnLabel="Back"
              backIcon
              onCancel={onClose}
              hideRightBtn
              sticky
            />

            <section className="md:w-1/2 md:mx-auto">
              <div className="px-4">
                <h2 className="mb-3">
                  <PageTitle>Generate review card</PageTitle>
                </h2>
                <h3 className="text-xl">
                  Pick your template, then tap the button below
                </h3>
              </div>

              <div className="px-3 pt-3">
                <div className="px-5 mx-auto">
                  <Segmented strong>
                    <Button active={feed.off} onClick={feed.close}>
                      Stories
                    </Button>
                    <Button active={feed.on} onClick={feed.open}>
                      Feed
                    </Button>
                  </Segmented>
                </div>

                <div className="mt-4">
                  <ReviewTemplateCardPicker
                    templates={reviewCardTemplates}
                    type={feed.on ? 'feed' : 'story'}
                    selected={
                      feed.on ? selectedFeedTpl.id : selectedStoryTpl.id
                    }
                    onSelect={handleSelectTemplate}
                    onDownload={handleDownload}
                  />
                </div>
              </div>
            </section>
          </PageContent>
        </Page>
      </SlideInModal>
      <Actions
        opened={reviewTemplateError.on}
        onActionsClosed={reviewTemplateError.close}
      >
        <ActionsGroup>
          <div className="p-3">
            <h3>
              <PageTitle size="2xl">
                Not enough images for this template.
              </PageTitle>
            </h3>
            <hr className="border-t border-gray-200 my-2" />

            <p className="my-4">
              This review does not have enough images for this template. Would
              you like to to go back and add photos to this review?
            </p>

            <Button large raised fill onClick={handleBackToAddPhoto}>
              <span className="text-white">Go back and add photos</span>
            </Button>
          </div>
        </ActionsGroup>
        <ActionsGroup>
          <ActionsButton bold onClick={reviewTemplateError.close}>
            Cancel
          </ActionsButton>
        </ActionsGroup>
      </Actions>
    </>
  );
};

export default GenerateReviewCard;
