import * as React from 'react';
import { IAppointment } from '../../../types/appointment';
import SectionTitle from '../../../lib/SectionTitle/SectionTitle';
import Img from '../../../lib/Img/Img';

interface ILookProps {
  appointment?: IAppointment;
}
const Look: React.FC<ILookProps> = ({ appointment }) => {
  if (!appointment?.description) {
    return null;
  }
  return (
    <div>
      <p>{appointment.description}</p>

      <div className="flex overflow-x-auto">
        {appointment?.images?.map((img, key) => (
          <div className="flex-none w-2/5 p-2" key={key}>
            <Img size="square" src={img} className="rounded shadow" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Look;
