import * as React from 'react';
import { Page, PageContent, Button, Segmented } from 'framework7-react';
import CalendarNavBar from '../../components/CalendarNavBar/CalendarNavBar';
import PageTitle from '../../lib/PageTitle/PageTitle';
import Alert from '../../lib/Alert/Alert';
import Calendar from '../../lib/Calendar/Calendar';
import AppointmentList from '../../components/AppointmentList/AppointmentList';
import useSelectItem from '../../hooks/useSelectItem';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import AppointmentDayPlanner from '../../components/AppointmentDayPlanner/AppointmentDayPlanner';
import EventForm from '../../components/EventForm/EventForm';
import useToggle from '../../hooks/useToggle';
import useUserStore from '../../hooks/useUserStore';
import useBackendApi from '../../hooks/useBackendApi';
import { IAppointment } from '../../types/appointment';
import SearchGigs from '../../components/SearchGigs/SearchGigs';
import GigsSettings from '../../components/GigsSettings/GigsSettings';
import useToast from '../../hooks/useToast';

interface IGigsProps { }
const defaultAppointment: IAppointment = {
  customerName: '',
  location: {
    city: '',
    formatted: '',
  },
  date: '',
  time: {
    from: '',
    to: '',
  },
  services: [],
};

const Gigs: React.FC<IGigsProps> = ({ }) => {
  const [selectedDate, setSelectedDate, clearSelectedDate] = useSelectItem('');
  const [newAppointment, setNewAppointment] = React.useState<IAppointment>(
    defaultAppointment
  );
  const { user, setUser, addAppointment } = useUserStore();
  const { successToast } = useToast();
  const [selectedTab, setSelectedTab] = React.useState<
    'booked' | undefined | 'offered'
  >();
  const { get, post } = useBackendApi();

  React.useEffect(() => {
    init();
  }, []);

  /* fetch appointments */
  async function init() {
    const apts = await get(`/v1/appointment`);
    setUser({ appointments: apts });
  }

  const eventForm = useToggle();
  const searchGigs = useToggle();
  const gigsSettings = useToggle();

  async function handleSaveAppointment(appointment) {
    const newApt = await post('/v1/appointment', {
      ...appointment,
      status: 'booked',
      paid: false,
    });
    const apts = await get(`/v1/appointment`);
    setUser({ appointments: apts });
    successToast('created new appointment');
    handleCloseAppointment();
  }

  function handleAddAppointment(date) {
    clearSelectedDate();
    setNewAppointment({ ...defaultAppointment, date });
    eventForm.open();
  }

  function handleCloseAppointment() {
    eventForm.close();
    setNewAppointment(defaultAppointment);
  }

  return (
    <Page className="bg-white" pageContent={false}>
      <PageContent className="pb-20">
        <CalendarNavBar
          onAdd={eventForm.open}
          onSearch={searchGigs.open}
          onSettingsClick={gigsSettings.open}
        />
        <h1 className="mb-2 px-4">
          <PageTitle>Gigs</PageTitle>
        </h1>
        {/* 
        <Alert btnLabel="View">
          <div className="pr-4">You have 2 potential gigs to respond on</div>
        </Alert>
 */}
        <div className="mt-4 border-b border-gray-200">
          <Calendar
            onDateSelect={setSelectedDate}
            selectedDates={getAppointmentDates(user.appointments)}
          />
        </div>

        <section className="px-4 mt-4">
          <Segmented strong tag="p">
            <Button
              active={selectedTab === 'booked'}
              onClick={() => setSelectedTab('booked')}
            >
              Bookings
            </Button>
            <Button
              onClick={() => setSelectedTab(undefined)}
              active={!selectedTab}
            >
              All
            </Button>
          </Segmented>
          <div className="my-3 flex">
            <Button
              className="flex-center-y normal-case text-base font-normal"
              onClick={searchGigs.open}
            >
              <i className="fal fa-clock text-lg mr-2 fa-flip-horizontal" />{' '}
              Previous Gigs
            </Button>
          </div>
        </section>

        <AppointmentList
          statusFilter={selectedTab}
          showDatesAfter={new Date()}
        />

        <AppointmentDayPlanner
          open={!!selectedDate}
          onClose={clearSelectedDate}
          date={selectedDate}
          onAdd={handleAddAppointment}
        />
        {/* Create new appointment */}
        <EventForm
          appointment={newAppointment}
          open={eventForm.on}
          onClose={handleCloseAppointment}
          onSave={handleSaveAppointment}
        />

        <SearchGigs open={searchGigs.on} onClose={searchGigs.close} />
        <GigsSettings open={gigsSettings.on} onClose={gigsSettings.close} />
      </PageContent>
    </Page>
  );
};

function getAppointmentDates(appointments) {
  if (!appointments) {
    return [];
  }
  return Object.keys(appointments);
}

export default Gigs;
