import * as React from 'react';
import { Page, Button, ActionsButton } from 'framework7-react';
import Navbar from '../../lib/Navbar/Navbar';
import PageTitle from '../../lib/PageTitle/PageTitle';
import useBackendApi from '../../hooks/useBackendApi';
import useUserStore from '../../hooks/useUserStore';
import AlbumList from '../../components/AlbumList/AlbumList';
import { useParams } from 'react-router';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import AlbumDisplay from '../../components/AlbumDisplay/AlbumDisplay';
import useSelectItem from '../../hooks/useSelectItem';
import {
  updateItemInArray,
  reomoveItemFromArray,
  isSubscribedTo,
} from '../../utils';
import { IAlbum, EAlbumStatus } from '../../types/album';
import DeleteAlbumPrompt from '../../components/DeleteAlbumPrompt/DeleteAlbumPrompt';
import useToggle from '../../hooks/useToggle';
import AddAlbum from '../../components/AddAlbum/AddAlbum';
import { ESubItemId } from '../../types/subItem';
import SmartAlbumPaywall from '../../components/SmartAlbumPaywall/SmartAlbumPaywall';
import ImgActionMenu from '../../components/ImgActionMenu/ImgActionMenu';
import AddPhotos from '../../components/AddPhotos/AddPhotos';
import ImgUploader from '../../lib/ImgUploader/ImgUploader';
import LinkInstagram from '../../components/LinkInstagram/LinkInstagram';
import InstagramAuth from '../../components/InstagramAuth/InstagramAuth';
import { IG_GALLERY_REDIRECT_URI } from '../../constants';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import SuggestedAlbums from '../../components/SuggestedAlbums/SuggestedAlbums';
import BtnContent from '../../lib/BtnContent/BtnContent';
import { IoIosPhotos } from 'react-icons/io';
import InstagramPickerV2 from '../../components/InstagramPickerV2/InstagramPickerV2';

interface IPhotosPageProps {}
const PhotosPage: React.FC<IPhotosPageProps> = ({}) => {
  const { albumId }: any = useParams();
  const [categorizedAlbums, setCategorizedAlbums] = React.useState<{
    visible: IAlbum[];
    suggested: IAlbum[];
  }>({
    visible: [],
    suggested: [],
  });

  const { user, setUser } = useUserStore();
  const { patch, post } = useBackendApi();
  const [selectedAlbum, setSelectedAlbum, clearSelectedAlbum] = useSelectItem();
  const [
    albumToDismiss,
    setAlbumToDismiss,
    clearAlbumToDismiss,
  ] = useSelectItem();

  const [
    albumToMakeVisible,
    setAlbumToMakeVisible,
    clearAlbumToMakeVisible,
  ] = useSelectItem([]);
  const instagramPrompt = useToggle();

  const [imagesToAdd, setImagesToAdd, clearImagesToAdd] = useSelectItem([]);

  const addAlbum = useToggle();
  const suggestedAlbumPaywall = useToggle();
  const photosMenu = useToggle();
  const instaPicker = useToggle();

  React.useEffect(() => {
    let didCancel = false;
    if (!didCancel) {
      // categorize albums into visible and suggested albums
      categorizeAlbums();
    }
    return () => {
      didCancel = true;
    };
  }, [user.albums]);

  React.useEffect(() => {
    // if the user lands on /album/:albumId, select the album by albumId
    if (albumId && user.albums?.length) {
      findAlbumByIdAndSelectIt(user.albums, albumId);
    }
  }, [albumId, user.albums]);

  function categorizeAlbums() {
    if (user.albums?.length) {
      const visible: any[] = [];
      const suggested: any[] = [];
      user.albums.map((al) => {
        if (al.status === 'visible') {
          visible.push(al);
        }
        if (al.auto && al.status === 'hidden') {
          suggested.push(al);
        }
      });

      setCategorizedAlbums({ visible, suggested });
    }
  }

  function findAlbumByIdAndSelectIt(albums, id) {
    const album = albums.find((al) => al._id === id);
    if (album) {
      setSelectedAlbum(album);
    }
  }

  function updateSelectedAlbum(album, clearSelection = false) {
    const albums = updateItemInArray(user.albums!, album);
    setUser({ albums });
    if (!clearSelection) {
      setSelectedAlbum(album);
    } else {
      clearSelectedAlbum();
    }
  }

  async function handleConfirmDismissAlbum() {
    const newData = { _id: albumToDismiss._id, status: 'dismissed' };
    const updated = await saveAlbumData(
      newData as IAlbum,
      `Deleted album: ${albumToDismiss.name}`
    );
    if (updated._id) {
      const albums = reomoveItemFromArray(user.albums, albumToDismiss);
      setUser({ albums });
      clearAlbumToDismiss();
    }
  }

  function saveAlbumData(newData: IAlbum, successMsg = 'success') {
    return patch('/v1/album', newData, {
      showToastOnSuccess: true,
      showToastOnError: true,
      successMsg,
    });
  }

  function handleDeleteSelectedAlbum() {
    const albums = reomoveItemFromArray(user.albums, selectedAlbum);
    setUser({ albums });
    clearSelectedAlbum();
  }

  async function makeSelectedAlbumVisible() {
    // if (!isSubscribedTo(ESubItemId.smartAlbums, user.subscriptions || [])) {
    //   return suggestedAlbumPaywall.open();
    // }
    const newAlbum = await makeAlbumVisible(selectedAlbum);
    updateSelectedAlbum(newAlbum);
  }

  async function makeSuggestedAlbumVisible(album) {
    if (!isSubscribedTo(ESubItemId.smartAlbums, user.subscriptions || [])) {
      setAlbumToMakeVisible(album);
      return suggestedAlbumPaywall.open();
    }
    const newAlbum = await makeAlbumVisible(album);
    const albums = updateItemInArray(user?.albums || [], newAlbum);
    setUser({ albums });
  }

  async function handleSubscribedToSmartAlbums() {
    suggestedAlbumPaywall.close();
    if (selectedAlbum) {
      const newAlbum = await makeAlbumVisible(selectedAlbum);
      updateSelectedAlbum(newAlbum);
    } else {
      const newAlbum = await makeAlbumVisible(albumToMakeVisible);
      const albums = updateItemInArray(user?.albums || [], newAlbum);
      setUser({ albums });
      clearAlbumToMakeVisible();
    }
  }

  function makeAlbumVisible(album) {
    if (!album) {
      return;
    }
    return saveAlbumData(
      { _id: album._id, status: EAlbumStatus.visible },
      `Added ${album.name} to your visible albums.`
    );
  }

  function handlePhotoMenuSelect(option) {
    switch (option) {
      case 'upload':
        document.getElementById('uploader')?.click();
        break;
      case 'getFromInsta':
        instaPicker.open();
        /*  if (user.instagramAuth?.token) {
           instaPicker.open();
         } else {
           instagramPrompt.toggle();
         } */
        break;
    }
  }

  function handlePhtosToAddUploaded(e) {
    const images = e.target.value;
    setImagesToAdd(images);
  }

  function handleAddPhotosClose() {
    clearImagesToAdd();
  }

  function handleAddAlbumDone() {
    if (!user.instagramAuth?.token) {
      instagramPrompt.toggle();
    }
  }

  async function handleAddPhotosToAlbumSubmit(data) {
    const updated = await post(`/v1/album/with-src-bulk`, data, {
      showToastOnSuccess: true,
      successMsg: `Added ${data.srcs.length} images to the selected albums`,
    });

    if (updated.newAlbums?.length) {
      const albums = [...(user.albums || []), ...updated.newAlbums];
      setUser({ albums });
    }
    clearImagesToAdd();
  }

  return (
    <Page>
      <InstagramAuth redirectUri={IG_GALLERY_REDIRECT_URI} />
      <div className="px-4 pt-16 pb-20">
        <div className="sticky z-20 -mx-2" style={{ top: 50 }}>
          <Navbar
            className="mb-3 bg-transparent rounded-md"
            rightContent={
              <div className="flex ml-auto">
                <Button large className="navbar-btn" onClick={photosMenu.open}>
                  <i className="text-xl fal fa-plus" />
                </Button>
              </div>
            }
          />
        </div>

        {/* suggested albums */}
        <section>
          {!!categorizedAlbums.suggested.length && (
            <h1 className="mb-3">
              <PageTitle size="2xl">Suggestions</PageTitle>
            </h1>
          )}

          <SuggestedAlbums
            className="pb-4 mb-5"
            albums={categorizedAlbums.suggested}
            onAlbumSelect={setSelectedAlbum}
            onDismiss={setAlbumToDismiss}
          />

          <DeleteAlbumPrompt
            open={!!albumToDismiss}
            onClose={clearAlbumToDismiss}
            album={albumToDismiss}
            onConfirm={handleConfirmDismissAlbum}
          />
        </section>

        <section>
          <h3 className="mb-4">
            <SectionTitle size="2xl">
              Your Albums{' '}
              <span className="text-xl text-gray-600">
                ({categorizedAlbums.visible.length})
              </span>{' '}
            </SectionTitle>
          </h3>
          <AlbumList
            albums={categorizedAlbums.visible}
            onAlbumSelect={setSelectedAlbum}
            onDismiss={setAlbumToDismiss}
            onAddAlbum={addAlbum.open}
          />
        </section>
      </div>

      <SlideInModal
        open={!!selectedAlbum?._id}
        onClose={clearSelectedAlbum}
        fullScreen
        direction="right"
        belowPopup
      >
        {!!selectedAlbum && (
          <AlbumDisplay
            onClose={clearSelectedAlbum}
            album={selectedAlbum}
            onAlbumSave={updateSelectedAlbum}
            onDeleteAlbum={handleDeleteSelectedAlbum}
            onEnableAlbum={makeSelectedAlbumVisible}
          />
        )}
      </SlideInModal>

      <SlideInModal
        open={addAlbum.on}
        onClose={addAlbum.close}
        fullScreen
        direction="right"
        belowPopup
      >
        <AddAlbum onClose={addAlbum.close} />
      </SlideInModal>

      <LinkInstagram
        open={instagramPrompt.on}
        onClose={instagramPrompt.close}
        heading={'Link your instagram'}
        desc="We noticed your Instagram account has not been linked. Please link your account so we can keep your smart albums in sync."
      />

      <SlideInModal
        open={suggestedAlbumPaywall.on}
        onClose={suggestedAlbumPaywall.close}
        fullScreen
        direction="right"
        belowPopup
      >
        <SmartAlbumPaywall
          onCancel={suggestedAlbumPaywall.close}
          onSubscribed={handleSubscribedToSmartAlbums}
        />
      </SlideInModal>

      <>
        <ImgActionMenu
          open={photosMenu.on}
          onClose={photosMenu.close}
          onMenuSelect={handlePhotoMenuSelect}
          enableInsta
          hideGalleryOption
          extension={
            <ActionsButton onClick={addAlbum.open}>
              <BtnContent icon={<IoIosPhotos className="text-2xl" />}>
                Add Album
              </BtnContent>
            </ActionsButton>
          }
        />
        <ImgUploader
          onUploadComplete={handlePhtosToAddUploaded}
          id="uploader"
          multiple
        />

        <InstagramPickerV2
          open={instaPicker.on}
          onClose={instaPicker.close}
          onAddPhotos={instaPicker.close}
          existingImages={user.images || []}
        />

        <AddPhotos
          open={!!imagesToAdd.length}
          onClose={handleAddPhotosClose}
          images={imagesToAdd}
          onSubmit={handleAddPhotosToAlbumSubmit}
        />
      </>
    </Page>
  );
};

export default PhotosPage;
