import * as React from 'react';
import { Page, PageContent, Button } from 'framework7-react';
import Navbar from '../../lib/Navbar/Navbar';
import BackBtn from '../../lib/BackBtn/BackBtn';
import ShowIf from '../../lib/ShowIf/ShowIf';
import Img from '../../lib/Img/Img';
import PageTitle from '../../lib/PageTitle/PageTitle';
import { image } from 'html2canvas/dist/types/css/types/image';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';

interface IFeaturePreviewProps {
  price?: string;
  currency?: string;
  category?: string;
  onNext?: (e?: any) => void;
  name?: string;
  desc?: string;
  image?: string;
  tagLine?: string;
  onClose?: (e?: any) => void;
  ctaLabel?: string;
  hideCtaBtn?: boolean;
  screenshots?: string[];
  navBarCtaLabel?: string;
  advisoryText?: string;
  trialPeriod?: string;
  disabled?: boolean;
  subscribed?: boolean;
}
const FeaturePreview: React.FC<IFeaturePreviewProps> = ({
  price,
  currency,
  category,
  onNext,
  onClose,
  navBarCtaLabel = 'Activate',
  name,
  tagLine,
  advisoryText,
  disabled,
  desc,
  image,
  ctaLabel = 'Activate',
  children,
  hideCtaBtn,
  subscribed,
}) => {
  return (
    <Page pageContent={false}>
      <PageContent>
        <div className="min-h-screen">
          <section className="flex-grow">
            <Navbar
              leftContent={<BackBtn onClick={onClose} />}
              rightContent={
                <Button
                  fill
                  round
                  className="normal-case"
                  onClick={onNext}
                  disabled={disabled}
                  color={subscribed ? 'green' : 'blue'}
                >
                  {navBarCtaLabel}
                </Button>
              }
            />

            <div className="p-4">
              <section className="flex-center-y">
                <div>
                  <Img src={image} size="96x96" className="rounded-lg shadow" />
                </div>
                <div className="pl-4">
                  {!!category && (
                    <h4 className="text-lg font-semibold text-gray-500 uppercase">
                      {category}
                    </h4>
                  )}
                  <h1>
                    <PageTitle>{name}</PageTitle>
                  </h1>
                  <div className="text-base text-gray-700">{tagLine}</div>
                </div>
              </section>

              {!hideCtaBtn && (
                <section className="mt-3">
                  <Button
                    large
                    fill
                    onClick={onNext}
                    className="flex normal-case flex-center-y mt2"
                    color={subscribed ? 'green' : 'blue'}
                  >
                    {ctaLabel}
                  </Button>
                  <div className="text-sm text-gray-500 text-align-center">
                    <small>{advisoryText}</small>
                  </div>
                </section>
              )}

              <section className="mt-3">
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{ __html: desc || '' }}
                />
              </section>
              {children}
            </div>
          </section>
        </div>
      </PageContent>
    </Page>
  );
};

export default FeaturePreview;
