import React from 'react';
import Img from '../../lib/Img/Img';

interface IFeaturePreviewScreenshotsProps {
  images?: string[];
}
const FeaturePreviewScreenshots: React.FC<IFeaturePreviewScreenshotsProps> = ({
  images = [],
}) => {
  return (
    <div className="flex p-1 overflow-x-auto">
      {images
        .filter((img) => !!img)
        .map((img) => (
          <div key={img} className="flex-none w-8/12 mr-3">
            <Img src={img} className="rounded-lg" />
          </div>
        ))}
    </div>
  );
};

export default FeaturePreviewScreenshots;
