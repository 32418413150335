import { IReviewCardTemplate } from '../types/reviewCardTemplate';

export const feedCardTemplates: IReviewCardTemplate[] = [
  {
    id: 'feed-1',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/URtR5crxO.jpg',
    templateId: '2',
    order: 8,
    logo: true,
    img1: true,
    img2: false,
    theme: 'light',
    type: 'feed',
  },
  {
    id: 'feed-2',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/wB-XVLbS_.jpg',
    templateId: '2',
    order: 5,
    logo: true,
    img1: true,
    img2: false,
    theme: 'dark',
    type: 'feed',
  },
  {
    id: 'feed-3',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/NzB1iR27md.jpg',
    templateId: '2',
    order: 14,
    logo: false,
    img1: true,
    img2: false,
    theme: 'light',
    type: 'feed',
  },
  {
    id: 'feed-4',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/fBSqXF6_kw.jpg',
    templateId: '2',
    order: 1,
    logo: false,
    img1: true,
    img2: false,
    theme: 'dark',
    type: 'feed',
  },
  {
    id: 'feed-5',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/KpTaXQ2EP_.jpg',
    templateId: '4',
    order: 2,
    logo: true,
    img1: true,
    img2: true,
    theme: 'light',
    type: 'feed',
  },
  {
    id: 'feed-6',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/NQTNaePSv9.jpg',
    templateId: '4',
    order: 13,
    logo: true,
    img1: true,
    img2: true,
    theme: 'dark',
    type: 'feed',
  },
  {
    id: 'feed-7',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/sgnAZk2UHG.jpg',
    templateId: '4',
    order: 10,
    logo: false,
    img1: true,
    img2: true,
    theme: 'light',
    type: 'feed',
  },
  {
    id: 'feed-8',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/zMlUYvmn3.jpg',
    templateId: '4',
    order: 7,
    logo: false,
    img1: true,
    img2: true,
    theme: 'dark',
    type: 'feed',
  },
  {
    id: 'feed-9',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/4Hl7DU6RuQ.jpg',
    templateId: '3',
    order: 11,
    logo: false,
    img1: true,
    img2: false,
    theme: 'light',
    type: 'feed',
  },
  {
    id: 'feed-10',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/g04IxVGaS.jpg',
    templateId: '3',
    order: 4,
    logo: false,
    img1: true,
    img2: false,
    theme: 'dark',
    type: 'feed',
  },
  {
    id: 'feed-11',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/GrLoPQCM2.jpg',
    templateId: 'default',
    order: 12,
    logo: false,
    img1: false,
    img2: false,
    theme: 'light',
    type: 'feed',
  },
  {
    id: 'feed-12',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/bMF1pZKgWA.jpg',
    templateId: 'default',
    order: 3,
    logo: false,
    img1: false,
    img2: false,
    theme: 'dark',
    type: 'feed',
  },

  {
    id: 'feed-13',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/XmgvAN99lM.jpg',
    templateId: '1',
    order: 9,
    logo: false,
    img1: false,
    img2: false,
    theme: 'light',
    type: 'feed',
  },
  {
    id: 'feed-14',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/yosQXXbAXf.jpg',
    templateId: '1',
    order: 6,
    logo: false,
    img1: false,
    img2: false,
    theme: 'dark',
    type: 'feed',
  },
];

export const storyCardTemplates: IReviewCardTemplate[] = [
  {
    id: '1',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/y2rL0_TaHt.jpg',
    templateId: 'story-3',
    order: 1,
    logo: true,
    img1: true,
    img2: false,
    theme: 'light',
    type: 'story',
  },
  {
    id: '2',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/EoZo1B4Gt.jpg',
    templateId: 'story-3',
    order: 14,
    logo: true,
    img1: true,
    img2: false,
    theme: 'dark',
    type: 'story',
  },
  {
    id: '3',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/1nBVBl0Iv.jpg',
    templateId: 'story-3',
    order: 11,
    logo: false,
    img1: true,
    img2: false,
    theme: 'light',
    type: 'story',
  },
  {
    id: '4',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/SWsDBiNgJM.jpg',
    templateId: 'story-3',
    order: 18,
    logo: false,
    img1: true,
    img2: false,
    theme: 'dark',
    type: 'story',
  },
  {
    id: '5',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/nyqObTpyx.jpg',
    templateId: 'story-2',
    order: 5,
    logo: true,
    img1: true,
    img2: true,
    theme: 'light',
    type: 'story',
  },
  {
    id: '6',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/cOSBHsZrae.jpg',
    templateId: 'story-2',
    order: 10,
    logo: true,
    img1: true,
    img2: true,
    theme: 'dark',
    type: 'story',
  },
  {
    id: '7',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/aKiI5fHZTG.jpg',
    templateId: 'story-2',
    order: 13,
    logo: false,
    img1: true,
    img2: true,
    theme: 'light',
    type: 'story',
  },
  {
    id: '8',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/ERooUGGhhc.jpg',
    templateId: 'story-2',
    order: 2,
    logo: false,
    img1: true,
    img2: true,
    theme: 'dark',
    type: 'story',
  },
  {
    id: '9',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/0g2c9UyLO.jpg',
    templateId: 'story-1',
    order: 19,
    logo: true,
    img1: true,
    img2: false,
    theme: 'light',
    type: 'story',
  },
  {
    id: '10',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/eE-Nq4WuQO.jpg',
    templateId: 'story-1',
    order: 8,
    logo: true,
    img1: true,
    img2: false,
    theme: 'dark',
    type: 'story',
  },
  {
    id: '11',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/0kSLa5gUr.jpg',
    templateId: 'story-1',
    order: 17,
    logo: false,
    img1: true,
    img2: false,
    theme: 'light',
    type: 'story',
  },
  {
    id: '12',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/TTsuFbQk6.jpg',
    templateId: 'story-1',
    order: 20,
    logo: false,
    img1: true,
    img2: false,
    theme: 'dark',
    type: 'story',
  },
  {
    id: '13',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/34hlPF-NP.jpg',
    templateId: 'story-1',
    order: 9,
    logo: false,
    img1: false,
    img2: false,
    theme: 'light',
    type: 'story',
  },
  {
    id: '14',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/HY_5KkCO0w.jpg',
    templateId: 'story-1',
    order: 6,
    logo: false,
    img1: false,
    img2: false,
    theme: 'dark',
    type: 'story',
  },
  {
    id: '15',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/4h-UtZ_K41.jpg',
    templateId: 'story-1',
    order: 3,
    logo: true,
    img1: false,
    img2: false,
    theme: 'light',
    type: 'story',
  },
  {
    id: '16',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/c-dmgBvg9R.jpg',
    templateId: 'story-1',
    order: 12,
    logo: true,
    img1: false,
    img2: false,
    theme: 'dark',
    type: 'story',
  },
  {
    id: '17',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/njaYzjZ0e.jpg',
    templateId: 'story-4',
    order: 7,
    logo: true,
    img1: true,
    img2: false,
    theme: 'light',
    type: 'story',
  },
  {
    id: '18',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/qxRXSe3TN.jpg',
    templateId: 'story-4',
    order: 16,
    logo: true,
    img1: true,
    img2: false,
    theme: 'dark',
    type: 'story',
  },
  {
    id: '19',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/P907_6F87.jpg',
    templateId: 'story-4',
    order: 15,
    logo: false,
    img1: true,
    img2: false,
    theme: 'light',
    type: 'story',
  },
  {
    id: '20',
    img:
      'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/GNC6nbpkC.jpg',
    templateId: 'story-4',
    order: 4,
    logo: false,
    img1: true,
    img2: false,
    theme: 'dark',
    type: 'story',
  },
];

export const reviewCardTemplates: IReviewCardTemplate[] = [
  ...storyCardTemplates,
  ...feedCardTemplates,
];
