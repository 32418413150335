import React from 'react';
import { IAlbum } from '../../types/album';
import Img from '../../lib/Img/Img';
import CloseBtn from '../../lib/CloseBtn/CloseBtn';
import ShowIf from '../../lib/ShowIf/ShowIf';
interface ISuggestedAlbumsProps {
  albums?: IAlbum[];
  className?: string;
  onAlbumSelect?: (e?: any) => void;
  onDismiss?: (e?: any) => void;
}
const SuggestedAlbums: React.FC<ISuggestedAlbumsProps> = ({
  albums = [],
  className = '',
  onAlbumSelect,
  onDismiss,
}) => {
  const [instaTag, setInstaTag] = React.useState<any>(
    localStorage.getItem('instaTag')
  );
  function handleCloseClick(e, album) {
    e.stopPropagation();
    onDismiss?.(album);
  }

  function dismissInstaAdd() {
    localStorage.setItem('instaTag', 'dismissed');
    setInstaTag('dismissed');
  }

  return (
    <div
      className={'flex flex-no-wrap items-stretch overflow-x-auto ' + className}
    >
      <ShowIf condition={instaTag !== 'dismissed'}>
        <div className="flex-none p-2 mr-2 text-white rounded-lg w-7/16 bg-insta">
          <div className="flex mb-3">
            <div className="mr-auto">
              <CloseBtn
                className="text-lg text-gray-500 bg-gray-100"
                onClick={dismissInstaAdd}
              />
            </div>
          </div>

          <div>
            <i className="static text-xl fab fa-instagram" />
            <p>
              Tag @nishi.app to automatically add your Insta photo to your
              gallery
            </p>
          </div>
        </div>
      </ShowIf>

      {albums.map((al) => (
        <div
          className="relative flex-none mr-2 text-white w-7/16"
          key={al._id}
          onClick={() => onAlbumSelect?.(al)}
        >
          <Img src={al.coverImg} size="square" className="rounded-lg" />
          <div className="absolute top-0 left-0 flex flex-col w-full h-full p-2 rounded-lg bg-translucent-500">
            <div className="flex-center-y">
              <div className="mr-auto">
                <CloseBtn
                  className="text-lg text-gray-500 bg-gray-100"
                  onClick={(e) => handleCloseClick(e, al)}
                />
              </div>
              <div className="">
                <button className="w-auto px-5 py-1 font-semibold text-black uppercase bg-gray-100 rounded-full focus:outline-none">
                  View
                </button>
              </div>
            </div>
            <div className="w-full mt-auto">
              <p className="text-sm">Suggested Album</p>
              <h3 className="text-2xl font-semibold truncate">{al.name}</h3>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SuggestedAlbums;
