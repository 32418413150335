import * as React from 'react';
import classnames from 'classnames';
import ShowIf from '../ShowIf/ShowIf';

interface IAlertProps {
  type?: string;
  icon?: React.ReactNode;
  className?: string;
  btnLabel?: string;
  onBtnClick?: (e: any) => void;
}
const Alert: React.FC<IAlertProps> = ({
  type = 'info',
  children,
  icon,
  className = '',
  btnLabel = '',
  onBtnClick,
}) => {
  return (
    <div
      className={classnames('px-4 py-2 flex items-center', className, {
        'bg-primary': type === 'info',
      })}
    >
      <section className="text-white">
        <ShowIf condition={type === 'info' && !icon}>
          <i className="far fa-exclamation-circle text-lg" />
        </ShowIf>
        {icon}
      </section>
      <section className="px-3 flex-grow text-white text-base">
        <small>{children}</small>
      </section>
      <section>
        <button
          onClick={onBtnClick}
          className={classnames(
            'bg-white py-1 px-3 rounded-full shadow uppercase focus:outline-none active:bg-gray-100 font-semibold whitespace-no-wrap',
            {
              'text-primary': type === 'info',
            }
          )}
        >
          {btnLabel}
        </button>
      </section>
    </div>
  );
};

export default Alert;
