import * as React from 'react';

interface IShowIfProps {
  condition?: boolean;
  elseTemplate?: React.ReactNode;
}
const ShowIf: React.FC<IShowIfProps> = ({
  condition,
  children,
  elseTemplate = null,
}) => {
  if (!condition) {
    return elseTemplate ? <>{elseTemplate}</> : null;
  }
  return <>{children}</>;
};

export default ShowIf;
