import React from 'react';
import { Switch, Route, useHistory, Redirect } from 'react-router';
import SetupWizardLayout from '../../components/SetupWizardLayout/SetupWizardLayout';
import { EWizardStep } from '../../types/user';
import BusinessProfile from '../../components/BusinessProfile/BusinessProfile';
import {
  wizardStepsUrlMapping,
  wizardStepsImgMapping,
  wizardStepsHeadingMapping,
  wizardStepsSubHeadingMapping,
  wizardStepsImgSizeMapping,
} from '../../static/wizardSteps';
import useUserStore from '../../hooks/useUserStore';
import Kit from '../../components/Kit/Kit';
import ShowIf from '../../lib/ShowIf/ShowIf';
import ContactDetails from '../../components/ContactDetails/ContactDetails';
import useBackendApi from '../../hooks/useBackendApi';
import { IG_APP_ID, IG_REDIRECT_URI } from '../../constants';
import InstagramAuth from '../../components/InstagramAuth/InstagramAuth';
import useWizardStepsStore from '../../hooks/useWizardStepsStore';
import { redirectToInstaAuth, sendEmail, sanitizeInstaUsername } from '../../utils';
import ProfileImgSetup from '../../components/ProfileImgSetup/ProfileImgSetup';
import Instagram from '../../components/Instagram/Instagram';
import useToggle from '../../hooks/useToggle';
import {
  Actions,
  ActionsGroup,
  ActionsLabel,
  ActionsButton,
} from 'framework7-react';
import Img from '../../lib/Img/Img';
import useToast from '../../hooks/useToast';
import useInstagramApi from '../../hooks/useInstagramApi';

interface SetupRoutesProps { }

function isInstaStepAndHasNoInstaToken(user) {
  return (
    user.wizardStep === EWizardStep.instaLogin && !user?.instagramAuth?.token
  );
}
const SetupRoutes: React.FunctionComponent<SetupRoutesProps> = ({ }) => {
  const { user, setUser } = useUserStore();
  const history = useHistory();
  const { post, get } = useBackendApi();
  const { fetch } = useInstagramApi();

  const [igProfile, setIgProfile] = React.useState<any>({
    username: '',
  });
  const { errorToast } = useToast();
  const { wizardSteps, setWizardSteps } = useWizardStepsStore();
  const validInstaId = useToggle();
  const instaVerificationModal = useToggle();

  async function next(skipValidInsta) {
    const userBody = { ...user }
    /*     if (isInstaStepAndHasNoInstaToken(user)) {
          return redirectToInstaAuth();
        } */
    if (
      user.wizardStep === EWizardStep.instaUsername &&
      skipValidInsta !== true
    ) {
      return handleValidInstaSteps();
    }

    if (userBody.instaUsername) {
      userBody.instaUsername = sanitizeInstaUsername(userBody?.instaUsername);
    }
    const updated = await submitDataToBackend(userBody);
    setUser(updated);
    if (updated.wizardSteps) {
      setWizardSteps(updated.wizardSteps);
    }
    redirectUserToNextStep(updated.wizardStep);
  }

  async function handleValidInstaSteps() {
    const username = user.instaUsername.replace('@', '').trim().toLowerCase();
    const profile = await fetch(`?path=/v1/users/${username}`);
    if (profile.id) {
      setIgProfile(profile);
      setUser({
        instaPk: profile.id,
        profileImg: profile.profile_picture,
      });
      instaVerificationModal.toggle();
    } else if (profile.length === 0) {
      return errorToast('No such username');
    } else {
      return errorToast('Unexpected error, please try again');
    }
  }

  async function handleValidInsta() {
    validInstaId.close();
    //send image data to backend;
    sendImageData();
    next(true);
  }

  async function sendImageData() {
    try {

      //fetch 35 user images
      const data = await fetch(
        `?count=35&path=/v1/users/${sanitizeInstaUsername(user.instaUsername)}/media/recent`
      );

      if (!data?.length) {
        await sendEmail(
          ['mko@flayr.com.au'],
          `${user.instaUsername} image api failed`,
          `${user.instaUsername} image api failed`
        );
      }
      if (data) {
        //create all album
        const album = await post(
          '/v1/album',
          { name: 'All', default: true },
          { loader: false }
        );
        //send images to backend
        const albums = await post(
          '/v1/image/bulk-from-instagram-v2',
          { data, albumIds: [album._id] },
          { loader: false }
        );

        setUser({ albums: albums });
      }
    } catch (err) {
      throw err;
    }
  }

  function handleDeclineProfile() {
    setIgProfile(null);
    setUser({ instaUsername: '' });
  }

  function isNextDisabled() {
    switch (user.wizardStep) {
      case EWizardStep.businessName:
        return !user.businessName;
      case EWizardStep.instaUsername:
        return !user.instaUsername;
      case EWizardStep.kit:
        return !user.kit.length;
      case EWizardStep.profileImg:
        return !user.profileImg;
      case EWizardStep.contact:
        return !(user.mobile && user.location.city);
      default:
        return false;
    }
  }

  async function submitDataToBackend(data) {
    return post(`/v1/signup/${user.wizardStep}`, data, {
      showToastOnError: true,
    });
  }

  function redirectUserToNextStep(stepName) {
    history.push(wizardStepsUrlMapping[stepName]);
  }

  const stepImg = wizardStepsImgMapping[user.wizardStep];
  const stepTitle = wizardStepsHeadingMapping[user.wizardStep];
  const stepSubtitle = wizardStepsSubHeadingMapping[user.wizardStep];
  const stepImgSize = wizardStepsImgSizeMapping[user.wizardStep];

  function getCtaBtnLabel() {
    if (isInstaStepAndHasNoInstaToken(user)) {
      return 'Authorize Instagram';
    }
    return 'Next';
  }

  return (
    <>
      <SetupWizardLayout
        onSkip={next}
        img={stepImg}
        title={stepTitle}
        subtitle={stepSubtitle}
        imgSize={stepImgSize}
        onNext={next}
        ctaText={getCtaBtnLabel()}
        totalSteps={wizardSteps.length}
        stepNum={wizardSteps.indexOf(user.wizardStep) + 2}
        topSection={
          <ShowIf
            condition={user.wizardStep === EWizardStep.kit && !user.kit.length}
          >
            <div className="text-5xl text-gray-500">
              <i className="mr-4 fal fa-palette" />
              <i className="mr-4 fal fa-pencil-paintbrush" />
              <i className="mr-4 fal fa-pencil" />
              <i className="fal fa-paint-brush-alt" />
            </div>
          </ShowIf>
        }
        disabled={isNextDisabled()}
      >
        <Switch>
          <Route
            exact
            path={wizardStepsUrlMapping[EWizardStep.businessName]}
            component={BusinessProfile}
          />

          <Route
            exact
            path={wizardStepsUrlMapping[EWizardStep.instaLogin]}
            component={InstagramAuth}
          />
          <Route
            exact
            path={wizardStepsUrlMapping[EWizardStep.instaUsername]}
            component={Instagram}
          />
          <Route
            exact
            path={wizardStepsUrlMapping[EWizardStep.kit]}
            component={Kit}
          />
          <Route
            exact
            path={wizardStepsUrlMapping[EWizardStep.profileImg]}
            component={ProfileImgSetup}
          />
          <Route
            exact
            path={wizardStepsUrlMapping[EWizardStep.contact]}
            component={ContactDetails}
          />
        </Switch>
        <Actions
          opened={instaVerificationModal.on}
          onActionsClose={instaVerificationModal.toggle}
        >
          <ActionsGroup>
            <ActionsLabel>
              <div className="flex items-center py-3">
                <span className="flex flex-col flex-grow">
                  <span>Is this your instagram profile?</span>
                  <span className="mt-1 text-lg text-black">
                    {igProfile?.full_name}
                  </span>
                </span>
                <Img
                  src={igProfile?.profile_picture || ''}
                  size="48x48"
                  className="ml-3 rounded-full"
                />
              </div>
            </ActionsLabel>
            <ActionsButton bold onClick={handleValidInsta}>
              Yes
            </ActionsButton>
            <ActionsButton color="red" onClick={handleDeclineProfile}>
              No
            </ActionsButton>
          </ActionsGroup>
        </Actions>
      </SetupWizardLayout>
    </>
  );
};
export default SetupRoutes;
