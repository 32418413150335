import React from 'react';
import { IUserChecklistItem } from '@sprajwal078/nishi-types';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import CheckListItem from '../../lib/ChecklistItem/ChecklistItem';

interface IChecklistProps {
  items?: IUserChecklistItem[];
  onView?: (e?: any) => void;
}
const Checklist: React.FC<IChecklistProps> = ({ items = [], onView }) => {
  return (
    <div>
      <h2 className="mb-3">
        <SectionTitle size="2xl">Success checklist</SectionTitle>
      </h2>

      {items?.filter(i => i.status === 'visible')?.map((cl) => (
        <CheckListItem
          checked={cl.checked}
          key={cl._id}
          title={cl.item.title}
          subTitle={cl.item.subTitle}
          onCtaClick={onView}
          id={cl._id}
        />
      ))}
    </div>
  );
};

export default Checklist;
