import React from 'react';
import 'framework7/css/framework7.bundle.min.css';
import './styles/global-styles.scss';
import AppRoutes from './routes/AppRoutes/AppRoutes';
import { f7Context } from '.';
import smartlookClient from 'smartlook-client'
import { ENV } from './constants';
import Axios from 'axios';

class App extends React.Component {
  state = {
    f7: null,
  };

  async componentDidMount() {
    if (ENV === 'production') {
      smartlookClient.init('d6b32b25684045facd4464a7a02925a9905e0b82')
    }
    this.$f7ready?.((f7) => {
      this.setState({ f7 });

    });
  }

  render() {
    return (
      <f7Context.Provider value={{ f7: this.state.f7 }}>
        <AppRoutes />
      </f7Context.Provider>
    );
  }
}

export default App;
