import React, { useEffect, useRef } from 'react';
import './Img.scss';
import classnames from 'classnames';
import 'intersection-observer';
import { resizeImage } from '../../utils';

export type ImgSizes =
  | 'square'
  | '16x16'
  | '24x24'
  | '32x32'
  | '48x48'
  | '64x64'
  | '96x96'
  | '128x128'
  | '1by1'
  | '5by4'
  | '4by3'
  | '3by2'
  | '5by3'
  | '16by9'
  | '2by1'
  | '3by1'
  | '4by5'
  | '3by4'
  | '2by3'
  | '3by5'
  | '9by16'
  | '1by2'
  | '1by3';

interface IImgProps {
  src?: string;
  id?: string;
  alt?: string;
  placeholder?: string;
  className?: string;
  imgClassName?: string;
  size?: ImgSizes;
  overlay?: string;
  position?: string;
  cover?: boolean;
  style?: any;
  lazy?: boolean;
  responsive?: boolean;
  screenSizes?: any;
  provider?: string;
  grayScale?: boolean;
  round?: boolean;
  shadow?: string;
  onClick?: (e: any) => void;
  onImgLoaded?: (e?: any) => void;
  crossOrigin?: '' | 'anonymous' | 'use-credentials' | undefined;
  imgId?: string;
  hideOnError?: boolean;
}
const Img: React.FunctionComponent<IImgProps> = ({
  src,
  id = '',
  alt = '',
  placeholder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPc3g4AAfsBQPu73qsAAAAASUVORK5CYII=',
  className = '',
  imgClassName = '',
  size = '',
  overlay = '',
  position = 'center',
  cover = true,
  style = {},
  responsive,
  screenSizes = {
    375: 1024,
    425: 425,
    768: 768,
    1024: 1024,
  },
  provider,
  lazy = true,
  onClick,
  grayScale,
  crossOrigin,
  onImgLoaded,
  imgId,
  hideOnError,
  round,
  shadow,
}) => {
  const imgRef: any = useRef(null);

  useEffect(() => {
    if (lazy && imgRef.current) {
      let imgObserver: any = window['imgObserver'];
      if (!imgObserver && !!IntersectionObserver) {
        imgObserver = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry: any) => {
            if (entry.isIntersecting) {
              entry.target.src = entry.target.dataset.src;
              if (responsive && entry.target.dataset.srcset) {
                entry.target.srcset = entry.target.dataset.srcset;
              }
              observer.unobserve(entry.target);
            }
          });
        });
      }
      if (imgObserver) {
        imgObserver.observe(imgRef.current);
      }
      if (!IntersectionObserver) {
        imgRef.current.src = imgRef.current.dataset.src;
        if (responsive) {
          imgRef.current.srcset = imgRef.current.dataset.srcset;
        }
      }
    }
  }, [imgRef, src]);

  function generateSrcSet() {
    if (src) {
      return Object.keys(screenSizes)
        .map((sc) => {
          const size = `${screenSizes[sc]}x${screenSizes[sc]}`;
          return resizeImage(size, src);
        })
        .join(', ');
    }
  }

  function handleError() {
    if (hideOnError) {
      return (imgRef.current.style.display = 'none');
    }
    if (imgRef && imgRef.current) {
      imgRef.current!.src = placeholder;
      let imgObserver: any = window['imgObserver'];
      if (imgObserver) {
        imgObserver.observe(imgRef.current);
      }
    }
  }

  function handleImgClick(e) {
    onClick && onClick({ ...e, src, id });
  }

  return (
    <figure
      style={style}
      className={classnames('img Img', className, {
        [`img-${size}`]: !!size,
        'no-cover': !cover,
        'Img-grayscale': grayScale,
        'rounded-full': round,
        [`shadow-${shadow}`]: !!shadow,
      })}
      onClick={handleImgClick}
    >
      <img
        className={classnames(imgClassName, {
          'position-top': position === 'top',
          'position-bottom': position === 'bottom',
          'position-center': position === 'center',
        })}
        onError={handleError}
        src={lazy ? placeholder : src}
        data-src={src}
        data-srcset={responsive ? generateSrcSet() : ''}
        ref={imgRef}
        alt={alt}
        crossOrigin={crossOrigin}
        onLoad={onImgLoaded}
        id={imgId}
      />

      {!!provider && <div className="Img-provider">By {provider}</div>}
    </figure>
  );
};
export default Img;
