import React from 'react';
import { Swipeable } from 'react-swipeable';
import TestimonyItem from '../TestimonyItem/TestimonyItem';
import { ITestimonyItem } from '../../types/testimonyItem';
import classnames from 'classnames';

interface TestimonyCarouselProps {
  items?: ITestimonyItem[];
}

const TestimonyCarousel: React.FC<TestimonyCarouselProps> = ({
  items = [],
}) => {
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);

  function handleSwiped(e) {
    if (items.length > 1) {
      let index = 0;
      switch (e.dir) {
        case 'Left':
          index = currentIndex === items.length - 1 ? 0 : currentIndex + 1;
          break;
        case 'Right':
          index = currentIndex === 0 ? items.length - 1 : currentIndex - 1;
          break;
      }
      setCurrentIndex(index);
    }
  }

  return (
    <Swipeable onSwiped={handleSwiped}>
      {!!items[currentIndex] && <TestimonyItem {...items[currentIndex]} />}

      <div className="flex justify-center mt-3">
        {items.map((item, key) => (
          <div
            className={classnames('w-2 h-2 mx-1 rounded-full', {
              'bg-gray-800': key === currentIndex,
              'bg-gray-300': key !== currentIndex,
            })}
            key={key}
          />
        ))}
      </div>
    </Swipeable>
  );
};
export default TestimonyCarousel;
