import * as React from 'react';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import {
  Page,
  List,
  ListInput,
  ListItem,
  Icon,
  Link,
  Button,
} from 'framework7-react';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import useUserStore from '../../hooks/useUserStore';
import PageTitle from '../../lib/PageTitle/PageTitle';
import ShowIf from '../../lib/ShowIf/ShowIf';
import { IKit } from '../../types/kit';
import useBackendApi from '../../hooks/useBackendApi';
import { f7Context } from '../..';
import useGooglePlacesApi from '../../hooks/useGooglePlacesApi';
import ShareSite from '../ShareSite/ShareSite';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import useToggle from '../../hooks/useToggle';
import { FiShare } from 'react-icons/fi';
import KitInput from '../KitInput/KitInput';
import { setUser } from '@sentry/browser';
import { sanitizeInstaUsername } from '../../utils';
import InstaProfileConfirmAction from '../InstaProfileConfirmAction/InstaProfileConfirmAction';
import ConfirmInstaUsername from '../ConfirmInstaUsername/ConfirmInstaUsername';
import TagsList from '../../lib/TagsList/TagsList';

interface IProfileEditorPopupProps {
  selectedOption?: string;
  open?: boolean;
  onClose?: (e?: any) => void;
  onSave?: (e?: any) => void;
}

const titleMapping = {
  Business: 'Business Name',
  Bio: 'About Us',
  primaryColor: 'Primary Colour',
  headingColor: 'Heading Colour',
  Instagram: 'Instagram username',
};

const placeholderMapping = {
  ...titleMapping,
  Facebook: 'https://facebook/your-fb-page.com',
};
const fieldMapping = {
  Business: 'businessName',
  Slogan: 'slogan',
  Bio: 'bio',
  Brands: 'kit',
  Location: 'location',
  Email: 'email',
  Mobile: 'mobile',
  Instagram: 'instaUsername',
  Website: 'website',
  Facebook: 'facebookPage',
  primaryColor: 'primaryColor',
  headingColor: 'headingColor',
};
const inputTypeMapping = {
  Bio: 'textarea',
};
const ProfileEditorPopup: React.FC<IProfileEditorPopupProps> = ({
  open,
  onClose,
  selectedOption = '',
  onSave,
}) => {
  const field = fieldMapping[selectedOption];
  const { user } = useUserStore();

  const shareSite = useToggle();
  const instaConfirm = useToggle();

  const [userInput, setUserInput] = React.useState<any>({});

  const [newkit, setNewKit] = React.useState('');
  const [brands, setBrands] = React.useState([]);
  const { get } = useBackendApi();
  const { f7 } = React.useContext(f7Context);

  function handlePlaceSelect(location) {
    setUserInput({ location });
  }

  useGooglePlacesApi('#location', handlePlaceSelect, {
    forceInit: field === 'location',
  });

  React.useEffect(() => {
    if (selectedOption) {
      switch (selectedOption) {
        case 'primaryColor':
          setUserInput({
            primaryColor: user.theme?.primaryColor,
          });
          break;
        case 'headingColor':
          setUserInput({
            headingColor: user.theme?.heading.color,
          });
          break;
        default:
          const input = user[field];
          setUserInput({ [field]: input });
      }
    }
  }, [selectedOption]);

  React.useEffect(() => {
    let autocomplete;
    if (field === 'kit') {
      if (!brands.length) {
        fetchBrands();
      }
      if (f7 && brands.length) {
        autocomplete = f7.autocomplete.create({
          inputEl: '#kitInput',
          openIn: 'dropdown',
          dropdownPlaceholderText: 'Try "MAC"',
          source: (search, render) => {
            if (!search) {
              return render([]);
            }
            return render(
              brands.filter((brand) => {
                const regex = new RegExp(search, 'i');
                return regex.test(brand);
              })
            );
          },
          on: {
            change: (value) => {
              setNewKit(value[0]);
            },
          },
        });
      }
    }
    return () => {
      if (autocomplete) {
        autocomplete.destroy();
        setBrands([]);
      }
    };
  }, [field, brands]);

  async function fetchBrands() {
    const brands = await get('/v1/kit');
    if (brands.length) {
      const formattedBrands = brands.map((i) => i.name);
      setBrands(formattedBrands);
    }
  }

  function handleUserInputChange(e: any) {
    const { name, value } = e.target;
    switch (field) {
      case 'location':
        setUserInput({
          location: { formatted: value },
        });
        break;
      default:
        setUserInput({ [name]: value });
    }
  }

  function handleInputClear() {
    switch (field) {
      case 'location':
        setUserInput({
          location: {
            city: '',
            formatted: '',
          },
        });
        break;
      default:
        setUserInput({
          [field]: '',
        });
    }
  }

  function handleSave() {
    switch (selectedOption) {
      case 'primaryColor':
        onSave?.({
          theme: {
            ...user.theme,
            ...userInput,
          },
        });
        break;
      case 'headingColor':
        onSave?.({
          theme: {
            ...user.theme,
            heading: {
              ...user?.theme?.heading,
              color: userInput.headingColor,
            },
          },
        });
        break;
      case 'Website':
        shareSite.open();
        break;

      case 'Instagram':
        instaConfirm.open();

        break;
      default:
        onSave?.(userInput);
    }
  }

  function handleDeleteKit(kits) {
    setUserInput({ kit: kits.map((k) => ({ name: k })) });
  }

  function handleAddKit(e) {
    const kit: IKit = { img: '', name: e };
    console.log(userInput.kit);
    setUserInput({ kit: [...userInput.kit, kit] });
  }

  function handleConfirmInstaUsername() {
    instaConfirm.close();
    setTimeout(() => {
      onSave?.({
        instaUsername: sanitizeInstaUsername(userInput.instaUsername),
      });
    }, 0);
  }

  return (
    <SlideInModal
      open={!!open}
      direction="right"
      fullScreen
      onClose={onClose}
      belowPopup
    >
      <Page>
        <PopupNavbar
          sticky
          onCancel={onClose}
          letfBtnLabel="Back"
          backIcon
          rightBtnLabel={
            field !== 'website' ? 'Save' : <FiShare className="text-2xl" />
          }
          onDone={handleSave}
        />
        <section className="px-3">
          <h1>
            <PageTitle>
              {titleMapping[selectedOption] || selectedOption}
            </PageTitle>
          </h1>

          <ShowIf
            condition={
              !(
                field === 'kit' ||
                field === 'location' ||
                field === 'website' ||
                field === 'primaryColor' ||
                field === 'headingColor'
              )
            }
          >
            <ShowIf condition={field === 'facebookPage'}>
              <div className="pl-3 mt-4">Enter your facebook page URL</div>
            </ShowIf>
            <List className="mt-2">
              <ListInput
                name={field}
                value={userInput[field] || ''}
                onChange={handleUserInputChange}
                clearButton
                onInputClear={handleInputClear}
                placeholder={
                  placeholderMapping[selectedOption] || selectedOption
                }
                type={inputTypeMapping[selectedOption] || 'text'}
                resizable={field === 'bio'}
              />
            </List>
          </ShowIf>

          <ShowIf condition={field === 'location'}>
            <List className="mt-2">
              <ListInput
                name={field}
                value={userInput.location?.formatted || ''}
                onChange={handleUserInputChange}
                clearButton
                onInputClear={handleInputClear}
                placeholder={'Location'}
                inputId="location"
              />
            </List>
          </ShowIf>

          <ShowIf condition={field === 'kit' && !!userInput?.kit}>
            <section className="pr-4 -ml-1">
              <TagsList
                items={userInput.kit?.map((k) => k.name)}
                onChange={handleDeleteKit}
                reverse
              />
            </section>

            <section className="sticky bottom-0 z-10 px-4 py-2 bg-white">
              <KitInput
                onSelect={handleAddKit}
                brands={brands || []}
                existingKit={userInput?.kit}
                onAutoCompleteSelect={(kit) => setUserInput({ kit })}
              />
            </section>
          </ShowIf>

          <ShowIf condition={field === 'website'}>
            <List className="mt-4">
              <ListInput value={userInput[field] || ''} readonly />
            </List>
          </ShowIf>
        </section>
      </Page>
      <ShareSite open={shareSite.on} onClose={shareSite.close} />
      {instaConfirm.on && (
        <ConfirmInstaUsername
          instaUsername={userInput.instaUsername}
          open={instaConfirm.on}
          onClose={instaConfirm.close}
          onDecline={instaConfirm.close}
          onConfirm={handleConfirmInstaUsername}
        />
      )}
    </SlideInModal>
  );
};

export default ProfileEditorPopup;
