import * as React from 'react';
import { IKit } from '../../types/kit';
import { Page, Popup, List, ListInput, ListItem, Icon } from 'framework7-react';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import useBackendApi from '../../hooks/useBackendApi';
import { f7Context } from '../..';
import PageTitle from '../../lib/PageTitle/PageTitle';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';

interface IKitEditorProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  onDone?: (e?: any) => void;
  kit?: IKit[];
}
const KitEditor: React.FC<IKitEditorProps> = ({
  open,
  onClose,
  onDone,
  kit = [],
}) => {
  const [newkit, setNewKit] = React.useState('');

  const [editData, setEditData] = React.useState<IKit[]>([]);
  const [brands, setBrands] = React.useState<string[]>([]);
  const { get } = useBackendApi();
  const { f7 } = React.useContext(f7Context);

  React.useEffect(() => {
    setEditData(kit);
  }, [kit]);

  React.useEffect(() => {
    fetchBrands();
  }, []);

  async function fetchBrands() {
    const brands = await get('/v1/kit');
    if (brands.length) {
      const formattedBrands = brands.map((i) => i.name);
      setBrands(formattedBrands);
    }
  }

  React.useEffect(() => {
    let autocomplete;
    if (open && f7 && brands.length) {
      autocomplete = f7.autocomplete.create({
        inputEl: '#kitInput',
        openIn: 'dropdown',
        dropdownPlaceholderText: 'Try "MAC"',
        source: (search, render) => {
          if (!search) {
            return render([]);
          }
          return render(
            brands.filter((brand) => {
              const regex = new RegExp(search, 'i');
              return regex.test(brand);
            })
          );
        },
        on: {
          change: (value) => {
            setNewKit(value[0]);
          },
        },
      });
    }
    return () => {
      if (autocomplete) {
        autocomplete.destroy();
      }
    };
  }, [brands, open]);

  function handleInputChange(e) {
    setNewKit(e.target.value);
  }

  function handleClear() {
    setNewKit('');
  }

  function handleAdd() {
    if (newkit?.trim()) {
      const kit: IKit = { img: '', name: newkit };
      setEditData([...editData, kit]);
      handleClear();
    }
  }

  function handleDelete(index) {
    const newData = editData.reverse().slice();
    newData.splice(index, 1);
    setEditData(newData.reverse());
  }

  function handleDone() {
    onDone?.(editData);
  }

  return (
    <SlideInModal open={open} onClose={onClose} direction="right" fullScreen>
      <Page className="bg-white">
        <PopupNavbar
          backIcon
          className="my-2"
          onCancel={onClose}
          onDone={handleDone}
        />

        <div className="my-2 px-4">
          <PageTitle>My Kit</PageTitle>
          <div className="text-lg">What brands do you carry in your kit</div>
        </div>

        <section className="pr-4 -ml-1">
          <List noHairlines>
            {editData
              .slice()
              .reverse()
              .map((k, idx) => (
                <ListItem key={k.name + idx} title={k.name}>
                  <Icon slot="media">
                    <button
                      className="px-3 focus:outline-none active:text-blue-200 text-primary"
                      onClick={() => handleDelete(idx)}
                    >
                      <i className="fal fa-trash-alt" />
                    </button>
                  </Icon>
                </ListItem>
              ))}
          </List>
        </section>

        <section className="px-4 py-2 bg-white z-10 sticky bottom-0">
          <div className="relative">
            <List noHairlines className="m-0">
              <ListInput
                outline
                label="Kit"
                floatingLabel
                type="text"
                placeholder="Your kit"
                name="kit"
                className="mb-4 -mx-4"
                inputId="kitInput"
                value={newkit}
                onChange={handleInputChange}
              />
            </List>
            <div className="absolute right-0 top-0 h-full flex items-center justify-end z-10 pt-2">
              <button
                className="px-3 py-2 mr-2 focus:outline-none "
                onClick={handleAdd}
              >
                <i className="fal fa-check" />
              </button>
              <button
                className="px-3 py-2 mr-2 focus:outline-none "
                onClick={handleClear}
              >
                <i className="fal fa-times" />
              </button>
            </div>
          </div>
        </section>
      </Page>
    </SlideInModal>
  );
};

export default KitEditor;
