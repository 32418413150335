import * as React from 'react';
import useUserStore from '../../hooks/useUserStore';
import { List, ListInput } from 'framework7-react';

interface IInstagramProps { }
const Instagram: React.FC<IInstagramProps> = ({ }) => {
  const { user, setUser } = useUserStore();

  function handleInputChange(e) {
    const instaUsername = e.target.value;
    setUser({ instaUsername });
  }

  return (
    <div className="-mx-4">
      <List noHairlines className="m-0">
        <ListInput
          outline
          label="Instagram handle"
          floatingLabel
          type="text"
          placeholder="Your instagram username"
          clearButton
          name="instaUsername"
          value={user.instaUsername}
          onChange={handleInputChange}
          className="mb-4"
        />
      </List>
    </div>
  );
};

export default Instagram;
