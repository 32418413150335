import * as React from 'react';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import { Button } from 'framework7-react';
import { IoIosPhotos } from 'react-icons/io';
import ShowIf from '../../lib/ShowIf/ShowIf';

interface IPreviewSiteFooterProps {
  demo?: boolean;
  onExit?: (e?: any) => void;
  onColorClick?: (e?: any) => void;
  onPhotosClick?: (e?: any) => void;
  onDesinClick?: (e?: any) => void;
  onApplyClick?: (e?: any) => void;
}
const PreviewSiteFooter: React.FC<IPreviewSiteFooterProps> = ({
  demo,
  onExit,
  onColorClick,
  onPhotosClick,
  onDesinClick,
  onApplyClick,
}) => {
  return (
    <div
      className="fixed z-10 bottom-0 backdrop-blur-20 flex px-3  text-center text-primary w-full  border-t border-gray-200"
      style={{ background: 'rgba(244,244,244,.5)' }}
    >
      <div className="pr-3 py-2 flex flex-col mr-auto">
        <h2 className="mb-1">
          <SectionTitle size="base">Site preview</SectionTitle>
        </h2>
        <button
          className="text-white py-2 px-4 rounded text-white bg-gray-500 text-xs focus:opacity-50"
          onClick={onExit}
        >
          Exit preview
        </button>
      </div>
      <div className="py-2 mx-3">
        <Button
          round
          fill
          style={{ width: 48, height: 48 }}
          className="flex-center mx-auto"
          onClick={onColorClick}
        >
          <i className="fal fa-sliders-h block m-0 text-2xl" />
        </Button>
        <span className="text-sm text-center">Colours</span>
      </div>

      <ShowIf
        condition={!demo}
        elseTemplate={
          <div className="py-2 mx-3">
            <Button
              round
              style={{
                width: 48,
                height: 48,
                background: '#4cd964',
              }}
              className="flex-center mx-auto text-white"
              onClick={onApplyClick}
            >
              <i className="fal fa-check text-2xl" />
            </Button>
            <span className="text-sm text-center" style={{ color: '#4cd964' }}>
              Apply
            </span>
          </div>
        }
      >
        <div className="py-2 mx-3">
          <Button
            round
            fill
            style={{ width: 48, height: 48 }}
            className="flex-center mx-auto"
            onClick={onPhotosClick}
          >
            <IoIosPhotos className="text-2xl" />
          </Button>
          <span className="text-sm text-center">Photos</span>
        </div>
        <div className="py-2 mx-3">
          <Button
            round
            fill
            style={{ width: 48, height: 48 }}
            className="flex-center mx-auto"
            onClick={onDesinClick}
          >
            <span className="fa-stack fa-1x">
              <i
                className="fas fa-circle fa-stack-1x text-sm"
                style={{ fontSize: '.6em' }}
              />
              <i className="fal fa-sync fa-stack-2x" />
            </span>
          </Button>
          <span className="text-sm text-center">Theme</span>
        </div>
      </ShowIf>
    </div>
  );
};

export default PreviewSiteFooter;
