import { ILoader } from '../types/loader';
import { SET_LOADER } from '../actions/types';

const initialState: ILoader = {
  loading: false,
  message: '',
};

export default function loaderReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_LOADER:
      return { ...state, ...payload };
    default:
      return state;
  }
}
