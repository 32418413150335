import * as React from 'react';
import { IAppointment } from '../../types/appointment';
import ActionSheet from '../../lib/ActionSheet/ActionSheet';
import { Button, Progressbar } from 'framework7-react';
import useWizard from '../../hooks/useWizard';
import ShowIf from '../../lib/ShowIf/ShowIf';
import EventInfo from './EventInfo/EventInfo';
import './EventForm.scss';
import ServiceSelector from './ServiceSelector/ServiceSelector';
import useToast from '../../hooks/useToast';
import dayjs from 'dayjs';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';

interface IEventFormProps {
  appointment?: IAppointment;
  open?: boolean;
  mode?: 'edit';
  onClose?: (e?: any) => void;
  onSave?: (e?: any) => void;
  className?: string;
}

const initialData: IAppointment = {
  customerName: '',
  customerEmail: '',
  customerMobile: '',
  location: {
    city: '',
    formatted: '',
  },
  date: '',
  time: {
    from: '',
    to: '',
  },
  services: [],
};

const steps = ['info', 'service'];
const EventForm: React.FC<IEventFormProps> = ({
  appointment,
  open,
  onClose,
  className = '',
  mode,
  onSave,
}) => {
  const [userInput, setUserInput] = React.useState<any>(initialData);
  const [services, setServices] = React.useState<any>([]);
  const { step, stepNumber, next, prev } = useWizard(steps);
  const { errorToast } = useToast();

  function handleUserInputChange(e: any) {
    let { name, value } = e.target;
    switch (name) {
      case 'location':
        setUserInput({
          ...userInput,
          location: value,
        });
        break;
      case 'from':
      case 'to':
        setUserInput({
          ...userInput,
          time: { ...userInput.time, [name]: value },
        });
        break;
      case 'total':
      case 'deposit':
        setUserInput({
          ...userInput,
          pricing: { ...userInput.pricing, [name]: value },
        });
        break;
      default:
        setUserInput({ ...userInput, [name]: value });
    }
  }

  React.useEffect(() => {
    if (appointment) {
      setUserInput({ ...appointment });
    } else {
      setUserInput(initialData);
    }
  }, [open, appointment]);

  function handleBack() {
    if (stepNumber === 0) {
      return onClose?.();
    }
    prev();
  }

  function handleNext() {
    if (step === 'info') {
      if (!userInput.customerName) return errorToast('client name is required');
      if (!userInput.location.lat)
        return errorToast('select a location from the dropdown');
      if (!userInput.date) return errorToast('date is required');
      return next();
    }
    onSave?.({ ...userInput, services });
  }

  function isFormValid() {
    const { customerName, location, date, time, services } = userInput;
    return (
      customerName &&
      location?.city &&
      date &&
      time?.from &&
      time.to &&
      services?.length
    );
  }

  function handleAdd() {
    onSave?.(userInput);
  }

  return (
    <ActionSheet
      open={open || false}
      onClose={onClose}
      className={`EventForm ${className}`}
      containerPaddingRemoved
    >
      <section style={{ minHeight: '60vh' }}>
        <PopupNavbar
          sticky
          letfBtnLabel="Cancel"
          onCancel={onClose}
          onDone={handleAdd}
          title={mode === 'edit' ? 'Edit event' : 'New Event'}
          rightBtnLabel={mode === 'edit' ? 'Save' : 'Add'}
          rightBtnClassname={
            isFormValid() ? '' : 'pointer-events-none text-gray-500'
          }
        />
        {/* <ShowIf condition={step === 'info'} elseTemplate={
          <ServiceSelector
            services={services}
            setServices={setServices}
          />
        }> */}
        <div className="bg-gray-100 py-3">
          <EventInfo input={userInput} onChange={handleUserInputChange} />
        </div>
        {/* </ShowIf> */}
      </section>
      {/* <div className="py-3 bg-white z-10 sticky bottom-0">
        <Button raised fill large onClick={handleNext}>
          Next
        </Button>
        <div className="my-2 text-center text-gray-500">
          {stepNumber + 1} of {steps.length} steps
        </div>
        <Progressbar progress={(stepNumber + 1 / steps.length) * 100} />
      </div> */}
    </ActionSheet>
  );
};

export default EventForm;
