import * as React from 'react';
import { PageContent, Page } from 'framework7-react';

interface VerifyEmailProps { }
const VerifyEmail: React.FC<VerifyEmailProps> = ({ }) => {


  return (
    <Page>
      <PageContent className="p-4 flex flex-col">
        <div className="px-4 mb-5">
          <h1>
            <span className="font-bold text-4xl">Check your inbox.
            
            </span>
          </h1>
          <p className="pr-5 text-lg">
            You’ve been sent an activation link.
            Thank you!
        </p>
        </div>
      </PageContent>
    </Page>

  );
};

export default VerifyEmail;
