import * as React from 'react';
import { IAppointment } from '../../../types/appointment';
import SectionTitle from '../../../lib/SectionTitle/SectionTitle';
import InfoGrid from '../InfoGrid/InfoGrid';
import { IoIosPerson } from 'react-icons/io';
import ShowIf from '../../../lib/ShowIf/ShowIf';

interface IServicesProps {
  appointment?: IAppointment;
}
const Services: React.FC<IServicesProps> = ({ appointment }) => {
  return (
    <div>
      {appointment?.services?.map((service, key) => (
        <InfoGrid
          className="mb-2"
          key={key}
          title={service.title}
          icon={<IoIosPerson className="text-3xl -mt-2" />}
        >
          {service.subTitle}
        </InfoGrid>
      ))}
    </div>
  );
};

export default Services;
