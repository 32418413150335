import React from 'react';
import loadImage from 'blueimp-load-image';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import SectionTitle from '../SectionTitle/SectionTitle';
import useToggle from '../../hooks/useToggle';
import { Button } from 'framework7-react';
import Img from '../Img/Img';
import ShowIf from '../ShowIf/ShowIf';
import { dataURItoBlob } from '../../utils';

interface ImgEditorProps {
  file?: File;
  onUpload?: (e?: any) => void;
}

const ImgEditor: React.FunctionComponent<ImgEditorProps> = ({
  file,
  onUpload,
}) => {
  const [src, setSrc] = React.useState<string>();
  const [rotation, setRotation] = React.useState(1);
  const [crop, setCrop] = React.useState({
    aspect: 1 / 1,
    x: 0,
    y: 0,
    width: 200,
    height: 200,
  });

  const [loadedImgForCropping, setLoadedImgForCropping] = React.useState<any>();

  const cropping = useToggle();

  React.useEffect(() => {
    convertFileToSrc();
  }, [file]);

  function convertFileToSrc(orientation = 1) {
    if (file) {
      loadImage(
        file,
        (canvas) => {
          const src = canvas.toDataURL();
          setSrc(src);
        },
        { canvas: true, noRevoke: true, orientation }
      );
    }
  }

  async function cropImg() {
    const newSrc = getCroppedImg(loadedImgForCropping, crop);
    setSrc(newSrc);
    cropping.toggle();
  }

  function getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return canvas.toDataURL();
  }

  function rotateLeft() {
    let newRotation = rotation - 1;
    if (rotation === 1) {
      newRotation = 8;
    }
    convertFileToSrc(newRotation);
    setRotation(newRotation);
  }

  function rotateRight() {
    let newRotation = rotation + 1;
    if (rotation === 8) {
      newRotation = 1;
    }
    convertFileToSrc(newRotation);
    setRotation(newRotation);
  }

  function handleUpload() {
    onUpload?.(src);
  }

  return (
    <div className="p-5">
      <div className="flex justify-center bg-gray-100">
        <div className="flex-none w-2/3">
          <div className={cropping.on ? 'hidden' : ''}>
            <Img src={src} />
          </div>
          <ShowIf condition={cropping.on}>
            <ReactCrop
              src={src}
              crop={crop}
              onChange={setCrop}
              disabled={cropping.off}
              keepSelection={cropping.on}
              onImageLoaded={setLoadedImgForCropping}
            />
          </ShowIf>
        </div>
      </div>
      <div className="mt-4 flex">
        <ShowIf
          condition={cropping.off}
          elseTemplate={
            <button
              className="bg-green-500 text-white  h-10 w-10 flex-ceter rounded-md mr-2"
              onClick={cropImg}
            >
              <i className="far fa-check" />
            </button>
          }
        >
          <button
            className="bg-blue-500 text-white  h-10 w-10 flex-ceter rounded-md mr-2"
            onClick={cropping.toggle}
          >
            <i className="far fa-crop" />
          </button>
          <button
            className="bg-blue-500 text-white  h-10 w-10 flex-ceter rounded-md mr-2"
            onClick={rotateLeft}
          >
            <i className="fa fa-rotate-left" />
          </button>

          <button
            className="bg-blue-500 text-white  h-10 w-10 flex-ceter rounded-md mr-2"
            onClick={rotateRight}
          >
            <i className="fa fa-rotate-right" />
          </button>
          <button
            className="bg-green-500 text-white w-auto  h-10 px-5 flex-ceter rounded-md ml-auto"
            onClick={handleUpload}
          >
            Save
          </button>
        </ShowIf>
      </div>
    </div>
  );
};
export default ImgEditor;
