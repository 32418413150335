import * as React from 'react';
import ActionSheet from '../../lib/ActionSheet/ActionSheet';
import { GrShare } from 'react-icons/gr';
import { Button, Link } from 'framework7-react';
import PageTitle from '../../lib/PageTitle/PageTitle';
import { IG_GALLERY_REDIRECT_URI } from '../../constants';
import useUserStore from '../../hooks/useUserStore';
import ShowIf from '../../lib/ShowIf/ShowIf';
import ProStar from '../../lib/ProStar/ProStar';
import ProAccountBlock from '../ProAccountBlock/ProAccountBlock';
import { IoIosStar } from 'react-icons/io';
import { redirectToInstaAuth } from '../../utils';

interface ILinkInstagramProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  heading?: string;
  desc?: string;
  redirectUri?: string;
}
const LinkInstagram: React.FC<ILinkInstagramProps> = ({
  open,
  onClose,
  heading,
  redirectUri = IG_GALLERY_REDIRECT_URI,
  desc = 'Link your Instagram to verify its your account so that you can use the pictures on the account.'
}) => {
  const { user } = useUserStore();
  return (
    <ActionSheet
      open={open}
      onClose={onClose}
      rightNavLabel="Cancel"
      showRightNav
    >
      <section style={{ minHeight: '60vh' }}>
        <ShowIf condition={!user.instagramAuth?.token}>
          <div className="pl-4">
            <span className="text-blue-500 text-5xl">
              <GrShare className="inline stroke-current" />
            </span>
          </div>
          <h1 className="mt-4">
            <PageTitle size="4xl">{heading}</PageTitle>
          </h1>
          <div className="text-xl mt-2">
            {desc}
          </div>
        </ShowIf>
      </section>

      <ShowIf
        condition={!user.instagramAuth?.token}
        elseTemplate={
          <Button large fill raised onClick={onClose}>
            Close
          </Button>
        }
      >
        <Button large fill raised onClick={() => redirectToInstaAuth(redirectUri)}>
          Verify Instagram
        </Button>
      </ShowIf>
    </ActionSheet>
  );
};

export default LinkInstagram;
