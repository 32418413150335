import * as React from 'react';
import { Actions, ActionsGroup, ActionsButton } from 'framework7-react';
import BtnContent from '../../lib/BtnContent/BtnContent';

interface IEditPhotosMenuProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  onReorder?: (e?: any) => void;
  onRename?: (e?: any) => void;
}
const EditPhotosMenu: React.FC<IEditPhotosMenuProps> = ({
  open,
  onClose,
  onRename,
  onReorder,
}) => {
  return (
    <Actions opened={open} onActionsClosed={onClose}>
      <ActionsGroup>
        <ActionsButton onClick={onRename}>
          <BtnContent icon={<i className="fal fa-info-circle" />}>
            Change album name
          </BtnContent>
        </ActionsButton>
        <ActionsButton onClick={onReorder}>
          <BtnContent icon={<i className="fal fa-list" />}>
            Reorder photos
          </BtnContent>
        </ActionsButton>
      </ActionsGroup>

      <ActionsGroup>
        <ActionsButton bold>Cancel</ActionsButton>
      </ActionsGroup>
    </Actions>
  );
};

export default EditPhotosMenu;
