import * as React from 'react';
import { List, ListInput } from 'framework7-react';
import useUserStore from '../../hooks/useUserStore';
import MobileNumberInput from '../../lib/MobileNumberInput/MobileNumberInput';
import LocationInput from '../../lib/LocationInput/LocationInput';

interface IContactDetailsProps {}
const ContactDetails: React.FC<IContactDetailsProps> = ({}) => {
  const { user, setUser } = useUserStore();

  function handleInputChange(e) {
    let { name, value } = e.target;
    setUser({
      [name]: value,
    });
  }

  return (
    <div className="pb-5">
      <MobileNumberInput
        label="Mobile"
        placeholder="Your mobile"
        name="mobile"
        value={user.mobile}
        onChange={handleInputChange}
      />

      <div className="mt-3">
        <LocationInput
          label="Business address"
          placeholder="Your business address"
          value={user.location}
          id="location"
          name="location"
          onChange={handleInputChange}
        />
        <div className="text-center text-xs text-gray-400 mb-3 px-4">
          We recommend putting in the suburb or area, it helps customers know
          your're local
        </div>
      </div>
      {/* <List noHairlines className="m-0">
        <ListInput
          outline
          label="Facebook page"
          floatingLabel
          type="text"
          placeholder="Your Facebook page"
          clearButton
          name="facebookPage"
          value={user.facebookPage}
          onChange={handleInputChange}
          className="-mx-4"
        />
      </List> */}
    </div>
  );
};

export default ContactDetails;
