import * as React from 'react';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import Img from '../../lib/Img/Img';

interface IReviewCardsDemoProps {}
const ReviewCardsDemo: React.FC<IReviewCardsDemoProps> = ({}) => {
  const sampleReviewCards = [
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/eE-Nq4WuQO.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/0g2c9UyLO.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/P907_6F87.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/y2rL0_TaHt.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/TTsuFbQk6.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/SWsDBiNgJM.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/EoZo1B4Gt.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/ERooUGGhhc.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/aKiI5fHZTG.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/nyqObTpyx.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/cOSBHsZrae.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/0kSLa5gUr.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/34hlPF-NP.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/4h-UtZ_K41.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/HY_5KkCO0w.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/c-dmgBvg9R.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/GrLoPQCM2.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/bMF1pZKgWA.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/XmgvAN99lM.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/yosQXXbAXf.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/zMlUYvmn3.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/URtR5crxO.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/sgnAZk2UHG.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/KpTaXQ2EP_.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/NQTNaePSv9.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/g04IxVGaS.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/4Hl7DU6RuQ.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/NzB1iR27md.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/wB-XVLbS_.jpg',
    'https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/fBSqXF6_kw.jpg',
  ];

  return (
    <div>
      <h2 className="mb-3">
        <SectionTitle size="2xl">Sample review cards</SectionTitle>
      </h2>

      <section className="flex flex-no-wrap overflow-x-auto pb-4">
        {sampleReviewCards.map((img) => (
          <div className="flex-none w-10/12 md:w-1/5 mr-3 p-1" key={img}>
            <Img src={img} className="rounded-lg shadow-lg" />
          </div>
        ))}
      </section>

      <h2 className="mb-3">
        <SectionTitle size="2xl">Sizing</SectionTitle>
      </h2>

      <section className="flex mb-2 items-end">
        <div className="p-px bg-insta flex items-stretch w-2/5 md:w-2/16 h-64 mr-3">
          <div className="p-px flex items-stretch flex-grow ">
            <div className="flex-grow bg-white" />
          </div>
        </div>
        <div className="p-px bg-insta flex items-stretch w-40 h-40">
          <div className="p-px flex items-stretch flex-grow ">
            <div className="flex-grow bg-white" />
          </div>
        </div>
      </section>

      <div className="flex mb-20 pb-10">
        <div className="w-2/5 md:w-2/16 mr-3 pl-2">Instagram stories</div>
        <div className="w-40 pl-2">Instagram feed</div>
      </div>
    </div>
  );
};

export default ReviewCardsDemo;
