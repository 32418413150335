
import * as React from 'react';
import useInterval from '../../hooks/useInterval';
import { Page, PageContent, Progressbar } from 'framework7-react';
import Img from '../../lib/Img/Img';

interface ISetupCompleteProgressProps {
  title?: string;
  steps?: string[];
  duration?: number;
  onFinish?: (e?: any) => void;
}
const SetupCompleteProgress: React.FC<ISetupCompleteProgressProps> = ({
  title = 'complete',
  duration = 2500,
  steps = [],
  onFinish,
}) => {
  const [progress, setProgress] = React.useState(0);
  const [step, setStep] = React.useState<any>(0);

  useInterval(() => {
    if (step <= 2) {
      const newStep = step + 1;
      setProgress((newStep / steps.length) * 100);
      setStep(newStep);
    } else {
      onFinish?.();
    }
  }, duration);
  return (
    <Page pageContent={false}>
      <PageContent className="bg-white flex flex-col p-5 items-stretch">
        <section className="flex-grow flex items-end justify-center">
          <div className="flex w-1/3 mb-4 px-3">
            <Img src="/nishi-logo.svg" />
          </div>
        </section>
        <section className="flex-grow">
          <div className="font-bold text-4xl">{title}</div>
          <div className="text-xl">{steps[step]}</div>

          <div className="mt-5">
            <Progressbar progress={progress} />
          </div>
        </section>
      </PageContent>
    </Page>
  );
};

export default SetupCompleteProgress;
