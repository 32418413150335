import * as React from 'react';
import './Calendar.scss';
import { f7Context } from '../..';
import getCalendarMatrix from './CalendarHelper';
import ShowIf from '../ShowIf/ShowIf';
import dayjs from 'dayjs';
import classnames from 'classnames';

interface ICalendarProps {
  date?: string;
  selectedDates?: string[];
  onDateSelect?: (e?: any) => void;
  value?: string;
  unAvailableDates?: string[];
}
const Calendar: React.FC<ICalendarProps> = ({
  selectedDates = [],
  value = '',
  date = '',
  onDateSelect,
}) => {
  // const { f7 } = React.useContext(f7Context);
  // const calendarContainer: any = React.useRef();

  const [currentDate, setCurrentDate] = React.useState<string>('');
  const [currentMonth, setCurrentMonth] = React.useState<string>('');
  const [currentYear, setCurrentYear] = React.useState<string>('');
  const [matrix, setMatrix] = React.useState<any>([]);
  const today = dayjs();

  React.useEffect(() => {
    setCurrentDate(date || dayjs().format('YYYY-MM-DD'));
  }, [date]);

  React.useEffect(() => {
    const dayJsDate = !!currentDate ? dayjs(currentDate) : dayjs();
    const m = getCalendarMatrix(dayJsDate.toDate());

    setMatrix(m);
    setCurrentYear(dayJsDate.format('YYYY'));
    setCurrentMonth(dayJsDate.format('MMMM'));
  }, [currentDate]);

  // React.useEffect(() => {
  //   const calendarInline = f7.calendar.create({
  //     containerEl: calendarContainer.current,
  //     multiple: true,
  //     value: [new Date(), new Date(2020, 4, 14)],
  //     on: {
  //       change: (calendar, value) => {},
  //     },
  //   });
  // }, []);

  function handleDayClick(day) {
    if (day < 0) {
      if (day > -15) {
        day = Math.abs(day);
        const newDate = dayjs(`${currentMonth} ${currentYear}`)
          .add(1, 'month')
          .date(day)
          .format('YYYY-MM-DD');
        onDateSelect?.(newDate);
        return;
      } else {
        day = Math.abs(day);
        const newDate = dayjs(`${currentMonth} ${currentYear}`)
          .subtract(1, 'month')
          .date(day)
          .format('YYYY-MM-DD');
        onDateSelect?.(newDate);
        return;
      }
    }
    onDateSelect?.(
      dayjs(`${day} ${currentMonth} ${currentYear}`).format('YYYY-MM-DD')
    );
  }

  function nextMonth() {
    setCurrentDate(dayjs(currentDate).add(1, 'month').format('YYYY-MM-DD'));
  }

  function prevMonth() {
    setCurrentDate(
      dayjs(currentDate).subtract(1, 'month').format('YYYY-MM-dd')
    );
  }

  function nextYear() {
    setCurrentDate(dayjs(currentDate).add(1, 'year').format('YYYY-MM-DD'));
  }

  function prevYear() {
    setCurrentDate(dayjs(currentDate).subtract(1, 'year').format('YYYY-MM-dd'));
  }

  return (
    <div className="Calendar">
      {/* <div id="calendar-container" ref={calendarContainer}></div> */}
      <ShowIf condition={!!matrix}>
        <div className="calendar calendar-inline">
          {/* calendar header */}
          <section className="toolbar toolbar-top no-shadow">
            <nav className="toolbar-inner">
              <div className="calendar-month-selector">
                <a
                  className="link icon-only calendar-prev-month-button"
                  onClick={prevMonth}
                >
                  <i className="icon icon-prev"></i>
                </a>

                <a className="current-month-value link">{currentMonth}</a>

                <a
                  className="link icon-only calendar-next-month-button"
                  onClick={nextMonth}
                >
                  <i className="icon icon-next"></i>
                </a>
              </div>
              <div className="calendar-year-selector">
                <a
                  className="link icon-only calendar-prev-year-button"
                  onClick={prevYear}
                >
                  <i className="icon icon-prev"></i>
                </a>

                <a className="current-year-value link">{currentYear}</a>

                <a
                  className="link icon-only calendar-next-year-button"
                  onClick={nextYear}
                >
                  <i className="icon icon-next"></i>
                </a>
              </div>
            </nav>
          </section>

          {/* calendar days */}
          <section className="calendar-week-header">
            <div className="calendar-week-day">Sun</div>
            <div className="calendar-week-day">Mon</div>
            <div className="calendar-week-day">Tue</div>
            <div className="calendar-week-day">Wed</div>
            <div className="calendar-week-day">Thu</div>
            <div className="calendar-week-day">Fri</div>
            <div className="calendar-week-day">Sat</div>
          </section>

          {/* calendar body */}
          <section className="calendar-months">
            <div className="calendar-month calendar-month-current">
              {matrix.map((week, key) => (
                <div className="calendar-row" key={key}>
                  {week.map((day) => (
                    <div
                      key={day}
                      className={classnames('calendar-day', {
                        'calendar-day-next': day < 0 && day > -14,
                        'calendar-day-prev': day < 0 && day < -14,
                        'calendar-day-today': today.isSame(
                          dayjs(`${day} ${currentMonth} ${currentYear}`),
                          'day'
                        ),
                        'calendar-day-selected': isSelectedDate(
                          selectedDates,
                          value,
                          day,
                          currentMonth,
                          currentYear
                        ),
                      })}
                      onClick={() => handleDayClick(day)}
                    >
                      <span className="calendar-day-number">
                        {Math.abs(day)}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </section>
        </div>
      </ShowIf>
    </div>
  );
};

function isSelectedDate(list, value, day, currentMonth, currentYear) {
  if (day < 0) {
    if (day > -15) {
      day = Math.abs(day);
      const newDate = dayjs(`${currentMonth} ${currentYear}`)
        .add(1, 'month')
        .date(day)
        .format('YYYY-MM-DD');
      return list.includes(newDate);
    } else {
      day = Math.abs(day);
      const newDate = dayjs(`${currentMonth} ${currentYear}`)
        .subtract(1, 'month')
        .date(day)
        .format('YYYY-MM-DD');
      return list.includes(newDate);
    }
  }

  const date = dayjs(`${day} ${currentMonth} ${currentYear}`).format(
    'YYYY-MM-DD'
  );

  return list.includes(date) || value === date;
}

export default Calendar;
