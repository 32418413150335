import * as React from 'react';
import { IAlbum, EAlbumStatus } from '../../types/album';
import AlbumItem from '../AlbumItem/AlbumItem';
import UploadImgBox from '../../lib/UploadImgBox/UploadImgBox';
import { MdContentCopy } from 'react-icons/md';
import ProStar from '../../lib/ProStar/ProStar';
import ShowIf from '../../lib/ShowIf/ShowIf';
import Img from '../../lib/Img/Img';

interface IAlbumListProps {
  albums?: IAlbum[];
  onAlbumSelect?: (e?: any) => void;
  suggested?: boolean;
  onAddAlbum?: (e?: any) => void;
  onDismiss?: (e?: any) => void;
  onAddSuggestedAlbum?: (e?: any) => void;
}
const AlbumList: React.FC<IAlbumListProps> = ({
  albums = [],
  onAlbumSelect,
  suggested = false,
  onAddAlbum,
  onDismiss,
  onAddSuggestedAlbum,
}) => {
  return (
    <div>
      {albums.map((al) => (
        <div className="mb-4" key={al._id}>
          <AlbumItem album={al} onClick={onAlbumSelect} />
        </div>
      ))}
      {/* {getAlbums(albums, suggested).map((al, idx) => (
        <div
          key={al._id}
          style={{ order: al.default ? 1 : idx + 2 }}
          className={suggested ? 'mb-3' : ''}
        >
          <AlbumItem
            album={al}
            onClick={onAlbumSelect}
            onDismiss={onDismiss}
            showCreatedBy={suggested}
            onAdd={onAddSuggestedAlbum}
          />
        </div>
      ))} */}

      {/* <ShowIf condition={!suggested}>
        <div style={{ order: 2 }} className="relative">
          <div className="absolute top-0 right-0 p-1 opacity-50">
            <ProStar />
          </div>
          <UploadImgBox
            onClick={onAddAlbum}
            className="cursor-pointer"
            icon={
              <div className="text-3xl transform rotate-90">
                <MdContentCopy />
              </div>
            }
          />
          <div className="pl-2 mt-1 font-semibold">Add Smart Album</div>
        </div>
      </ShowIf> */}
    </div>
  );
};

function getAlbums(albums: IAlbum[], suggested: boolean) {
  if (suggested) {
    return albums.filter((al) => al.auto && al.status === 'hidden');
  }
  return albums.filter((al) => al.default || al.status === 'visible');
}

export default AlbumList;
