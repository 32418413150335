import * as React from 'react';
import {
  Actions,
  ActionsGroup,
  ActionsLabel,
  ActionsButton,
  Button,
} from 'framework7-react';
import ShowIf from '../ShowIf/ShowIf';
import classnames from 'classnames';

interface IActionSheetProps {
  showLeftNav?: boolean;
  showRightNav?: boolean;
  leftNavLabel?: string;
  rightNavLabel?: string;
  title?: string;
  navTitle?: string;
  open?: boolean;
  className?: string;
  rightNavClassName?: string;
  containerPaddingRemoved?: boolean;
  onClose?: (e?: any) => void;
  onBack?: (e?: any) => void;
}
const ActionSheet: React.FC<IActionSheetProps> = ({
  open,
  onClose,
  showLeftNav,
  showRightNav,
  leftNavLabel = 'Back',
  rightNavLabel = 'Close',
  title,
  children,
  onBack,
  className = '',
  navTitle = '',
  rightNavClassName = '',
  containerPaddingRemoved,
}) => {
  return (
    <Actions opened={open} onActionsClosed={onClose}>
      <ShowIf condition={open}>
        <div
          className={classnames(
            'bg-white w-full rounded-t-lg overflow-y-auto  ' + className,
            {
              'p-4': !containerPaddingRemoved,
            }
          )}
          style={{
            maxHeight: 'calc(100vh - 60px)',
            WebkitOverflowScrolling: 'touch',
            WebkitBackfaceVisibility: 'hidden',
            WebkitPerspective: 1000,
            WebkitTransform: 'translate3d(0,0,0)',
          }}
        >
          <ShowIf condition={showLeftNav || showRightNav}>
            <div className="flex justify-between mb-3">
              <div>
                <ShowIf condition={showLeftNav}>
                  <button
                    className="text-primary text-base no-outline"
                    onClick={onBack}
                  >
                    {leftNavLabel}
                  </button>
                </ShowIf>
              </div>
              <ShowIf condition={!!navTitle}>
                <div className="text-center font-semibold">{navTitle}</div>
              </ShowIf>
              <div>
                <ShowIf condition={showRightNav}>
                  <button
                    className={`text-primary text-base no-outline ${rightNavClassName}`}
                    onClick={onClose}
                  >
                    {rightNavLabel}
                  </button>
                </ShowIf>
              </div>
            </div>
          </ShowIf>
          {!!title && <h2 className="text-xl font-semibold">{title}</h2>}
          {children}
        </div>
      </ShowIf>
    </Actions>
  );
};

export default ActionSheet;
