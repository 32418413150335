export interface ISubItem {
  _id?: string;
  name?: string;
  price?: number;
  type?: ESubItemType;
  currency?: string; //aud, usd etc
  stripeProductId?: string;
  trialPeriod?: number; //in days
  stripePriceId?: string;
  default?: boolean;
  image?: string;
  id?: string; //readable id
  desc?: string;
  tagLine?: string;
  priceDesc?: string;
  author?: string;
  interval?: 'month' | 'year';
}

export enum ESubItemType {
  feature = 'feature',
  theme = 'theme',
}

export enum ESubItemId {
  smartAlbums = 'smart-albums',
  reviewCards = 'review-cards'
}
