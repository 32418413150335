import React from 'react';
import Img from '../Img/Img';

interface TestimonyItemProps {
  image?: string;
  name?: string;
  website?: string;
  comments?: string;
}

const TestimonyItem: React.FC<TestimonyItemProps> = ({
  name,
  image,
  website,
  comments,
}) => {
  return (
    <div className="flex flex-col items-center w-full p-2 text-base">
      <p className="text-gray-700">
        <i>"{comments}"</i>
      </p>
      <Img src={image} size="48x48" className="my-2 shadow" round />
      <p>{name}</p>
      <p className="text-gray-700">{website}</p>
    </div>
  );
};
export default TestimonyItem;
