import * as React from 'react';
import { Actions, ActionsGroup, ActionsButton } from 'framework7-react';

interface IAppointmentDetailsMenuProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  onEdit?: (e?: any) => void;
}
const AppointmentDetailsMenu: React.FC<IAppointmentDetailsMenuProps> = ({
  open,
  onClose,
  onEdit,
}) => {
  return (
    <Actions opened={open} onActionsClosed={onClose}>
      <ActionsGroup>
        <ActionsButton onClick={onEdit}>Edit</ActionsButton>
      </ActionsGroup>
    </Actions>
  );
};

export default AppointmentDetailsMenu;
