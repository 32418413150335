import React, { useEffect, useState, FunctionComponent } from 'react';
import {
  Route,
  RouteProps,
  Redirect,
  RouteComponentProps,
  withRouter,
} from 'react-router';
import { ConnectedComponent } from 'react-redux';
import {
  isTokenExpired,
  getUserDataFromStorage,
  setUserDataToStorage,
} from '../../utils';

interface PrivateRouteProps extends RouteProps {
  component:
    | React.ComponentClass<any>
    | ConnectedComponent<any, any>
    | FunctionComponent<any>;
  alwaysEnable?: boolean;
}
const PrivateRoute: React.FC<PrivateRouteProps & RouteComponentProps> = ({
  component: Component,
  location,
  ...otherprops
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<
    boolean | 'intermediate' | 'inactive'
  >('intermediate');

  useEffect(() => {
    const token = getUserDataFromStorage().token;
    if (!isTokenExpired(token)) {
      return setIsAuthenticated(true);
    }
    // expired or no token
    setUserDataToStorage({ pathRequested: location.pathname });
    setIsAuthenticated(false);
  }, []);

  return (
    <Route
      {...otherprops}
      render={(props) => {
        switch (isAuthenticated) {
          case true:
            return <Component {...props} />;
          case false:
            return <Redirect to="/landing" />;
        }
      }}
    />
  );
};
export default withRouter(PrivateRoute);
