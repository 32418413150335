import * as React from 'react';
import { List, ListInput } from 'framework7-react';
import { timeMapping } from '../../../static/timeMapping';
import { IAppointment } from '../../../types/appointment';
import useGooglePlacesApi from '../../../hooks/useGooglePlacesApi';
import ServiceSelector from '../ServiceSelector/ServiceSelector';
import Datepicker from '../../../lib/Datepicker/Datepicker';

interface IEventInfoProps {
  input: IAppointment;
  onChange?: (e: any) => void;
}
const EventInfo: React.FC<IEventInfoProps> = ({ input, onChange }) => {
  function setLocation(value) {
    onChange?.({ target: { name: 'location', value } });
  }

  useGooglePlacesApi('#location', setLocation);

  function handleInputClear() {
    setLocation({ city: '', formatted: '' });
  }

  function handleDateChange(e) {
    if (e?.length) {
      onChange?.({ target: { name: 'date', value: e } });
    }
  }

  function handlePaymentDateChange(e) {
    if (e?.length) {
      onChange?.({ target: { name: 'paymentDueDate', value: e } });
    }
  }

  function handleServicesChange(services) {
    onChange?.({ target: { name: 'services', value: services } });
  }

  function handleLocationChange(e) {
    setLocation({ ...input.location, formatted: e.target.value });
  }

  return (
    <>
      <List className="m-0">
        <ListInput
          className="-ml-3"
          placeholder="Client name"
          name="customerName"
          value={input.customerName || ''}
          onChange={onChange}
        />
        <ListInput
          className="-ml-3"
          placeholder="Mobile"
          name="customerMobile"
          value={input.customerMobile || ''}
          onChange={onChange}
        />
        <ListInput
          className="-ml-4 mb-4"
          placeholder="Email"
          name="customerEmail"
          type="email"
          value={input.customerEmail || ''}
          onChange={onChange}
        />
      </List>

      <List className="my-3">
        <ListInput
          className="-ml-3"
          placeholder="Location"
          inputId="location"
          name="location"
          clearButton
          onInputClear={handleInputClear}
          value={input.location?.formatted || ''}
          onChange={handleLocationChange}
        />
      </List>

      <List className="m-0">
        <ListInput
          className="-ml-3"
          inlineLabel
          label="Date"
          placeholder="Pick date"
          name="date"
          value={input.date}
          inputId="date"
          // onCalendarChange={handleDateChange}
        />
        <Datepicker
          target="#date"
          name="date"
          value={input.date}
          onChange={onChange}
        />

        <ListInput
          className="-ml-3"
          inlineLabel
          label="Start time"
          placeholder="Pick time"
          type="select"
          name="from"
          value={input.time?.from}
          onChange={onChange}
        >
          <option value="">Pick time</option>
          {Object.keys(timeMapping).map((time) => (
            <option key={time} value={time}>
              {timeMapping[time]}
            </option>
          ))}
        </ListInput>
        <ListInput
          className="-ml-3"
          inlineLabel
          label="End time"
          placeholder="Pick time"
          type="select"
          name="to"
          value={input.time?.to}
          onChange={onChange}
        >
          <option value="">Pick time</option>
          {Object.keys(timeMapping).map((time) => (
            <option key={time} value={time}>
              {timeMapping[time]}
            </option>
          ))}
        </ListInput>
      </List>

      <ServiceSelector
        services={input.services}
        onChange={handleServicesChange}
      />

      <List className="m-0">
        <ListInput
          inlineLabel
          className="-ml-3"
          label="Total"
          placeholder="Total"
          type="number"
          name="total"
          value={input.pricing?.total || ''}
          onChange={onChange}
        />
        <ListInput
          className="-ml-3"
          label="Amound paid to date"
          inlineLabel
          placeholder="Amount Paid"
          name="deposit"
          type="number"
          value={input.pricing?.deposit || ''}
          onChange={onChange}
        />
        <ListInput
          inlineLabel
          className="-ml-3"
          label="Due date"
          placeholder="Due"
          name="paymentDueDate"
          value={input.paymentDueDate || ''}
          inputId="dueDate"
        />
        <Datepicker
          target="#dueDate"
          name="paymentDueDate"
          value={input.paymentDueDate}
          onChange={onChange}
        />
      </List>
    </>
  );
};

export default EventInfo;
