import * as React from 'react';
import Notification from '../Notification/Notification';
import { Button } from 'framework7-react';
import Navbar from '../../lib/Navbar/Navbar';

interface IHomeNavbarProps {
  onMenuClick?: (e?: any) => void;
}
const HomeNavbar: React.FC<IHomeNavbarProps> = ({ onMenuClick }) => {
  return (
    <Navbar
      logo
      leftContent={
        <div>
          <span className="text-primary text-2xl">Nishi</span>
        </div>
      }
      rightContent={
        <div className="ml-auto flex-center-y">
          <Notification />

          <Button type="button" className="navbar-btn" onClick={onMenuClick}>
            <i className="fal fa-question-circle text-2xl" />
          </Button>
        </div>
      }
    />
  );
};

export default HomeNavbar;
