import * as React from 'react';
import { Page, List, ListItem, Icon, Button, Link } from 'framework7-react';
import PageTitle from '../../lib/PageTitle/PageTitle';
import HomeNavbar from '../../components/HomeNavbar/HomeNavbar';
import Img from '../../lib/Img/Img';
import useUserStore from '../../hooks/useUserStore';
import useToggle from '../../hooks/useToggle';
import HomeMenu from '../../components/HomeMenu/HomeMenu';
import ShareSite from '../../components/ShareSite/ShareSite';
import UpgradeToPro from '../../components/UpgradeToPro/UpgradeToPro';
import useSelectItem from '../../hooks/useSelectItem';
import { useHistory, useParams } from 'react-router';
import SupportModal from '../../components/SupportModal/SupportModal';

interface IHomeProps {}
const Home: React.FC<IHomeProps> = ({}) => {
  const { user } = useUserStore();
  const menu = useToggle();
  const [selectedMenu, setSelectedMenu, clearSelectedMenu] = useSelectItem('');
  const history = useHistory();
  const { action } = useParams();

  React.useEffect(() => {
    if (selectedMenu === 'upgradeToPro') {
      history.push('/dashboard/home/upgrade-to-pro');
    }
    if (selectedMenu === 'contact') {
      document.getElementById('contact')?.click();
    }
  }, [selectedMenu]);

  function backToHome() {
    history.push('/dashboard/home/');
  }

  React.useEffect(() => {
    if (!action) {
      clearSelectedMenu();
    }
  }, [action]);

  function goToReviews() {
    history.push('/dashboard/reviews');
  }

  function goToProfile() {
    history.push('/dashboard/profile');
  }

  return (
    <Page>
      <HomeNavbar onMenuClick={menu.open} />
      {/* <HomeMenu
        open={menu.on}
        onClose={menu.close}
        onMenuSelect={setSelectedMenu}
      /> */}
      <ShareSite open={selectedMenu === 'share'} onClose={clearSelectedMenu} />
      <UpgradeToPro open={action === 'upgrade-to-pro'} onClose={backToHome} />
      <div className="p-4">
        <h1 className="mb-3">
          <PageTitle>Welcome home</PageTitle>
        </h1>

        <div className="text-lg">
          Here's a few things to make the most of your new site
        </div>
      </div>

      <List noHairlines>
        <ListItem>
          <Icon slot="media">
            <i
              className="fal fa-edit text-2xl text-center text-primary"
              style={{ width: 48 }}
            />
          </Icon>
          <div className="flex flex-grow pb-3">
            <div className="flex-grow">
              <p className="text-base">Pick a design for your site</p>
              <p className="text-gray-500 text-sm">
                Choose the theme that suits you
              </p>
            </div>
            <div>
              <Button raised round fill onClick={goToProfile}>
                go
              </Button>
            </div>
          </div>
        </ListItem>
        {/* <ListItem>
          <Icon slot="media">
            <i
              className="fab relative fa-instagram text-3xl text-primary"
              style={{ width: 48 }}
            />
          </Icon>
          <div className="flex flex-grow">
            <div className="flex-grow">
              <p className="text-base">Share reviews to Instagram</p>
              <p className="text-gray-500 text-sm">
                Pick your favorite reviews and add them to Instagram
              </p>
            </div>
            <div>
              <Button onClick={goToReviews} raised round fill>
                Go
              </Button>
            </div>
          </div>
        </ListItem> */}

        <ListItem>
          <Icon slot="media">
            <i
              className="fal relative fa-plus text-3xl text-primary text-center"
              style={{ width: 48 }}
            />
          </Icon>
          <div className="flex flex-grow">
            <div className="flex-grow">
              <p className="text-base">Get new reviews</p>
              <p className="text-gray-500 text-sm">
                Ask previous customers to leave you a review
              </p>
            </div>
            <div>
              <Button onClick={goToReviews} raised round fill>
                Go
              </Button>
            </div>
          </div>
        </ListItem>
        {/* <ListItem>
          <Icon slot="media">
            <Img src={user.profileImg} size="48x48" className="rounded-full" />
          </Icon>
          <div className="flex flex-grow">
            <div className="flex-grow">
              <p className="text-base">Get your own domain</p>
              <p className="text-gray-500 text-sm">
                Like soniamakeup.com.au <br /> or pick your own <br /> or
                connect an existing domain
              </p>
            </div>
            <div>
              <Button raised round fill>
                get
              </Button>
            </div>
          </div>
        </ListItem> */}

        {/* <ListItem>
          <Icon slot="media">
            <i
              className="fal fa-rocket-launch text-2xl text-center text-primary"
              style={{ width: 48 }}
            />
          </Icon>
          <div className="flex flex-grow pb-3">
            <div className="flex-grow">
              <p className="text-base">Promote your new site</p>
              <p className="text-gray-500 text-sm">
                Be sure to share the link to your new site
              </p>
            </div>
            <div>
              <Button
                raised
                round
                fill
                onClick={() => setSelectedMenu('share')}
              >
                go
              </Button>
            </div>
          </div>
        </ListItem> */}
        <ListItem>
          <Icon slot="media">
            <i
              className="fal fa-question-circle text-3xl text-center text-primary"
              style={{ width: 48 }}
            />
          </Icon>
          <div className="flex flex-grow pb-3">
            <div className="flex-grow">
              <p className="text-base">Support</p>
              <p className="text-gray-500 text-sm">
                Need help? Contact us <br />
                <Link
                  href="mailto:hey@flayr.io"
                  target="__blank"
                  className="underline"
                  external
                  id="contact"
                >
                  hey@flayr.io
                </Link>
              </p>
            </div>
            <div>
              <Button
                onClick={menu.toggle}
                // href="mailto:hey@flayr.io"
                // target="__blank"
                // external
                raised
                round
                fill
              >
                <span className="inline-block px-2">?</span>
              </Button>
            </div>
          </div>
        </ListItem>
      </List>

      <SupportModal open={menu.on} onClose={menu.close} />
    </Page>
  );
};

export default Home;
