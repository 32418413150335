import { SET_USER } from './types';
import { IUser } from '../types/user';
import { IAppointment } from '../types/appointment';

export function setUserAction(payload: IUser) {
  return {
    type: SET_USER,
    payload,
  };
}

export function setAppointment(payload: IAppointment) {

}
