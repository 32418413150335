import * as React from 'react';
import './MobileNumberInput.scss';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import useToggle from '../../hooks/useToggle';
import classnames from 'classnames';

interface IMobileNumberInputProps {
  label?: string;
  placeholder?: string;
  name?: string;
  value?: string;
  country?: string;
  onChange?: (e: any) => void;
  noBorder?: boolean;
}
const MobileNumberInput: React.FC<IMobileNumberInputProps> = ({
  label,
  placeholder,
  name,
  value,
  country = 'AU',
  onChange,
  noBorder,
}) => {
  const focused = useToggle();

  function handleChange(number) {
    onChange &&
      onChange({
        target: {
          name,
          value: number,
        },
      });
  }

  return (
    <div className={classnames({ 'pt-2': label })}>
      <div
        className={classnames('MobileNumberInput', {
          'MobileNumberInput-no-border': noBorder,
        })}
      >
        <div
          className={classnames('MobileNumberInput-label', {
            'MobileNumberInput-label-focused':
              focused.on || (!!value && focused.off),
          })}
        >
          {label}
        </div>
        <PhoneInput
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          flags={flags}
          defaultCountry={country}
          onFocus={focused.toggle}
          onBlur={focused.toggle}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
        />
      </div>
    </div>
  );
};

export default MobileNumberInput;
