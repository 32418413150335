import * as React from 'react';
import ActionSheet from '../../lib/ActionSheet/ActionSheet';
import { List, ListInput, Button } from 'framework7-react';

interface IAlbumRenameActionProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  onSave?: (e?: any) => void;
}
const AlbumRenameAction: React.FC<IAlbumRenameActionProps> = ({
  open,
  onClose,
  onSave,
}) => {
  const [name, setName] = React.useState<any>('');

  function handleInputChange(e) {
    setName(e.target.value);
  }

  function handleSave() {
    onSave?.(name);
  }

  return (
    <ActionSheet open={open} onClose={onClose}>
      <div className="flex flex-col w-full">
        <div className="text-black font-semibold text-2xl px-4 text-left">
          Change album name
        </div>

        <hr className="border-t border-gray-100 my-2" />
        <List className="m-0" noHairlines>
          <ListInput
            name="title"
            label="New name"
            inlineLabel
            placeholder="name"
            clearButton
            value={name}
            onChange={handleInputChange}
          />
        </List>

        <div className="mt-3 mb-5 pb-5">
          <Button large raised fill onClick={handleSave}>
            <span className="text-white">Save</span>
          </Button>
        </div>
      </div>
    </ActionSheet>
  );
};

export default AlbumRenameAction;
