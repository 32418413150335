import * as React from 'react';
import { Page, Button, PageContent, Swiper } from 'framework7-react';
import useWizard from '../../hooks/useWizard';
import PopupNavbar from '../../lib/PopupNavbar/PopupNavbar';
import SlideInModal from '../../lib/SlideInModal/SlideInModal';
import classnames from 'classnames';
import ShowIf from '../../lib/ShowIf/ShowIf';
import Img from '../../lib/Img/Img';
import SectionTitle from '../../lib/SectionTitle/SectionTitle';
import { useSwipeable } from 'react-swipeable';
import ReviewSummary from '../ReviewSummary/ReviewSummary';
import StarRating from '../../lib/StarRating/StarRating';
import useBackendApi from '../../hooks/useBackendApi';
import useUserStore from '../../hooks/useUserStore';

interface IProLearnMoreProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  onUpgrade?: (e?: any) => void;
  feature?: string;
}
const steps = ['albums', 'design', 'templates'];
const ProLearnMore: React.FC<IProLearnMoreProps> = ({
  open,
  onClose,
  onUpgrade,
  feature = 'albums',
}) => {
  const { user, setUser } = useUserStore();
  const [step, setStep] = React.useState(feature);
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (step !== 'templates') {
        const next = steps.indexOf(step) + 1;
        setStep(steps[next]);
      }
    },
    onSwipedRight: () => {
      if (step !== 'albums') {
        const prev = steps.indexOf(step) - 1;
        setStep(steps[prev]);
      }
    },
  });
  const { post } = useBackendApi();

  async function handleUpgrade() {
    const res = await post('/v1/user/trial', null, {
      showToastOnSuccess: true,
      showToastOnError: true,
      successMsg: 'Enabled pro features for 14 days',
    });
    if (res._id) {
      setUser({ subscriptions: [...(user.subscriptions || []), res] });
    }
    onClose?.();
  }

  return (
    <SlideInModal open={open} onClose={onClose} fullScreen disableAnimation>
      <Page className="bg-white" pageContent={false}>
        <PageContent className="flex flex-col">
          <PopupNavbar
            hideLeftBtn
            rightBtnLabel="Cancel"
            onDone={onClose}
            className="py-2"
          />

          <section
            className="flex-grow px-4 py-5 flex flex-col"
            {...swipeHandlers}
          >
            <ShowIf condition={step === 'albums'}>
              <div className="px-6 flex-grow">
                <div className="grid grid-cols-8 grid-rows-8 gap-3">
                  <div className="relative shadow overflow-hidden rounded col-span-4 row-span-4 row-start-1">
                    <Img
                      src="https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/DJOnTft7-.png"
                      size="square"
                      className="rounded-lg"
                    />
                    <div className="absolute bottom-0 left-0 w-full py-2 text-center bg-translucent-400 text-gray-600 backdrop-blur-20">
                      Editorial
                    </div>
                  </div>

                  <div className="relative shadow overflow-hidden rounded col-span-4 row-span-4 row-start-2">
                    <Img
                      src="https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/di-cJEqhm.png"
                      size="square"
                      className="rounded-lg"
                    />
                    <div className="absolute bottom-0 left-0 w-full py-2 text-center bg-translucent-400 text-gray-600 backdrop-blur-20">
                      Bridal
                    </div>
                  </div>

                  <div className="relative shadow overflow-hidden rounded col-span-4 row-span-4 col-start-3">
                    <Img
                      src="https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/wpp62U2Qj.png"
                      size="square"
                      className="rounded-lg"
                    />
                    <div className="absolute bottom-0 left-0 w-full py-2 text-center bg-translucent-400 text-gray-600 backdrop-blur-20 ">
                      The Races
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <div className="uppercase mt-3 text-xs text-gray-600">
                  build your brand with pro
                </div>

                <div className="my-2">
                  <SectionTitle>Albums</SectionTitle>
                </div>

                <p className="px-5 text-gray-900">
                  Help customers find inspiration in your photos faster.
                </p>
              </div>
            </ShowIf>

            <ShowIf condition={step === 'design'}>
              <div className="py-5 mx-4 flex-grow flex items-center">
                <div className="px-5 mx-auto w-3/4">
                  <Img src="https://flayr-big-collection.s3.ap-southeast-2.amazonaws.com/q7cfgqr-y.png" />
                </div>
              </div>

              <div className="mt-5 text-center">
                <div className="uppercase mt-3 text-xs text-gray-600">
                  build your brand with pro
                </div>
                <div className="my-2">
                  <SectionTitle>Site Design</SectionTitle>
                </div>

                <p className="px-5 text-gray-900">
                  Customize your color scheme to suit your brand.
                </p>
              </div>
            </ShowIf>

            <ShowIf condition={step === 'templates'}>
              <div className="py-5 mx-4 flex-grow flex-center">
                <section>
                  <div className="bg-primary text-white p-4 rounded-md text-center">
                    <div className="text-5xl font-semibold leading-tight">
                      4,9
                    </div>
                    <div className="font-semibold leading-none mb-1 text-lg">
                      <small>out of 5</small>
                    </div>
                  </div>
                  <div className="mt-1 text-center">
                    <StarRating rating={4.9} starClassName="text-lg" />
                  </div>
                </section>
              </div>

              <div className="mt-5 text-center">
                <div className="uppercase mt-3 text-xs text-gray-600">
                  build your brand with pro
                </div>
                <div className="my-2">
                  <SectionTitle>Review Templates</SectionTitle>
                </div>

                <p className="px-5 text-gray-900">
                  Customize the review template design to suit your design
                  theme. Keep in on brand.
                </p>
              </div>
            </ShowIf>
          </section>

          <div className="sticky bottom-0 py-4 bg-white px-4">
            <div className="mb-4 flex-center text-xs">
              <small className="mr-2">
                <small>
                  <i
                    className={classnames('fas fa-circle', {
                      'opacity-25': step !== 'albums',
                    })}
                  />
                </small>
              </small>
              <small className="mr-2">
                <small>
                  <i
                    className={classnames('fas fa-circle', {
                      'opacity-25': step !== 'design',
                    })}
                  />
                </small>
              </small>
              <small>
                <small>
                  <i
                    className={classnames('fas fa-circle', {
                      'opacity-25': step !== 'templates',
                    })}
                  />
                </small>
              </small>
            </div>
            <Button raised large fill onClick={handleUpgrade}>
              Try Pro - free for 14 days
            </Button>
            <Button large className="mt-1" onClick={onClose}>
              or maybe later
            </Button>
          </div>
        </PageContent>
      </Page>
    </SlideInModal>
  );
};

export default ProLearnMore;
